import API from "../config/api"
import {
	LISTA_DATA_SUCURSAL,
	CLEAN_DATA_SUCURSAL,
	LISTADO_DEPOSITO,
	LISTADO_INICIO_DEPOSITO,
	CHANGE_VALUE_INPUT,
	SAVE_LISTADO_DEPOSITO,
	REABRIR_CAJA,
	ADD_OBSERVACIONES
} from "../types/rendirType"
import { totales, formatMonto, formatFloat, fomartDate } from "../config/formatInput"
import { validarPermiso } from "../utils/Permission/checkPermission"

const requestSuccess = (type, payload) => {
    return { type, payload }
}


export const getCuentaTipoDeposito = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

			const { configuracion } = getState().config
			const isCheck = !validarPermiso(configuracion, "cerrar_caja_sucursales")
			let response

			if(!isCheck){
				response = await API.get("rendir")
			}else{
				response = await API.get("rendir/user")
			}
		  	
		    dispatch(requestSuccess(LISTADO_INICIO_DEPOSITO, response))
	      resolve({status: true, message:""})
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const getListadoSucursal = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listadoCuentaDeposito} = getState().rendir

			
		  	let response = await API.post("rendir/ordens", body)
			
		    if(response.status){
		    	let { 
		    			ventas, 
		    			recepcion, 
		    			isExiste, 
		    			depositos, 
		    			diferencia, 
		    			total_depositos, 
		    			isOpen,
		    			observaciones,
		    			idRendirCaja,
		    			edicionManual
		    	} = response.data
		    	
		    	const listadoVenta = {
	    			tbody: ventas,
	    			tfoot: totales(ventas)
	    		}

		    	const listadoRecepcion = {
	    			tbody: recepcion,
	    			tfoot: totales(recepcion)
	    		}

	    		const total = listadoVenta?.tfoot?.totalCtdo + listadoRecepcion?.tfoot?.totalPorPagar
		    	
		    	const totalDiferencia = (isExiste) ? diferencia : total
		    	let isPermisoEliminarDeposito = (isOpen===0) ? false : true
		    	if(isExiste===1){
		    		const { configuracion } = getState().config
		    		isPermisoEliminarDeposito = !validarPermiso(configuracion, "eliminar_deposito_caja_cerrada")
		    	}

		    	let cajasList = listadoCuentaDeposito.filter(x=>x.sucursal_id===Number(body.sucursal_id))

	    		const payload = {
	    			listadoVenta,
	    			listadoRecepcion,
	    			rendirCtdo: formatMonto(listadoVenta?.tfoot?.totalCtdo || 0),
		    		rendirPorPagar: formatMonto(listadoRecepcion?.tfoot?.totalPorPagar || 0),
		    		rendirTotal: formatMonto(total),
		    		diferencia: totalDiferencia,
		    		depositos: depositos || [],
		    		total_depositos: (isExiste) ? total_depositos : 0,
		    		disabled: parseFloat(totalDiferencia) === 0 ? true : totalDiferencia < 0 ? true : false,
		    		isCajaCerrada: isOpen,
		    		isPermisoEliminarDeposito,
		    		listadoObservaciones: observaciones,
		    		idRendirCaja,
		    		isInitial: (ventas.length>0 || recepcion.length>0) ? false : true,
		    		isExiste,
		    		edicionManual,
		    		search: {
		    			sucursal_id: body.sucursal_id,
		    			fecha: body.fecha
		    		},
		    		cajasListSucursal: cajasList
	    		}
				
		       dispatch(requestSuccess(LISTA_DATA_SUCURSAL, payload))
		    }
		    
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const cleanListadoSucursal = (body) => (dispatch, getState) => {
	const payload = {
		listadoVenta: [],
		listadoRecepcion: [],
		depositos: [],
		listadoObservaciones: [],
		edicionManual: [],
		search: { sucursal_id: null, fecha: new Date()}
	}
	dispatch(requestSuccess(CLEAN_DATA_SUCURSAL, payload))
}


export const addDeposito = (body) => (dispatch, getState) => {
	const { listadoDepositos, diferencia } = getState().rendir

	const { monto } = body
	const depositos =  [ ...listadoDepositos, body ]
	const total = depositos.reduce((acumulado, data) => acumulado + parseFloat(data.monto), 0)
	const diferenciaTotal = Math.abs(diferencia - monto)

	const disabled = diferenciaTotal === 0 ? true : false
	const payload = {
		depositos,
		totalMonto: total,
		diferencia: diferenciaTotal,
		disabledAdd: disabled
	}

	dispatch(requestSuccess(LISTADO_DEPOSITO, payload))
}


export const addRendirCaja = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

			const data = {
				...body,
				total_contado: formatFloat(body.total_contado),
				total_depositos: formatFloat(body.total_depositos),
				total_por_pagar: formatFloat(body.total_por_pagar),
				totales: formatFloat(body.totales),
				diferencia: formatFloat(body.diferencia)
			}

		  	let response = await API.post("rendir", data)

		  	if(response.status){
		  		//const { configuracion } = getState().config
		  		const { diferencia } = getState().rendir

		    	//let isPermisoEliminarDeposito = !validarPermiso(configuracion, "eliminar_deposito_caja_cerrada")
		    	
		  		const payload = {
					isCajaCerrada: 0,
		    		isPermisoEliminarDeposito: false,
		    		isExiste: true,
		    		isInitial: false,
		    		idRendirCaja: response.idRendirCaja,
		    		observaciones: response.observaciones,
		    		listadoDepositos: response.depositos,
		    		disabledAdd: (diferencia>0) ? false : true
				}
				dispatch(requestSuccess(REABRIR_CAJA, payload))
		  	}
		    
	        resolve({ status: response.status, message: response.message })

	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const dataSearch = (name, valor, isFecha) => (dispatch, getState) => {
	
	const { busqueda } = getState().rendir
	if(isFecha){
		while (valor.indexOf("/") !== -1){
	        valor = valor.replace("/", "C")
	    }
	}
	
    const payload = {
    	...busqueda,
    	[name]: valor
    }

	dispatch(requestSuccess(CHANGE_VALUE_INPUT, payload))
}

export const searchGlobalRendicion = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.post("rendir/search", body)

		  	if(response.status){
		  		const payload = {
		  			...body,
		  			desde: fomartDate(body.desde),
		  			hasta: fomartDate(body.hasta)
		  		}

		  		dispatch(requestSuccess(CHANGE_VALUE_INPUT, payload))
		  	}
		    
	        resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const removeDeposito = (index, deposito_id) => async (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
				const { listadoDepositos,  rendirTotal } = getState().rendir
				const depositos = listadoDepositos.filter((deposito, i) => i !== index)
				const depDelete = listadoDepositos.find((deposito, i) => i === index)
			
				const montoDeposito = depositos.reduce((acumulado, dep) => acumulado + parseFloat(dep.monto), 0)
				const montoDiferencia = formatFloat(rendirTotal) - montoDeposito

				const payload = {
					listado: depositos,
					total: montoDeposito,
					diferencia: montoDiferencia,
					disabledAdd: (montoDiferencia>0) ? false : true
				}

				if(depDelete.hasOwnProperty('deposito_id') && depDelete.hasOwnProperty('rendir_id')){
					const body = { deposito_id: depDelete.deposito_id, id: depDelete.rendir_id }
					let response = await API.post(`rendir/delete`, body)
					if(response.status){
						dispatch(requestSuccess(SAVE_LISTADO_DEPOSITO, payload))
					}
					resolve({ status: response.status, message: response.message })
				}else{
					dispatch(requestSuccess(SAVE_LISTADO_DEPOSITO, payload))
					resolve({ status: true, message: "Se ha eliminado correctamente" })
				}
     	}catch(error){
      		resolve({status: false, message:error})
    	}
	})
}

export const exportarExcelCaja = (body) => async (dispatch, getState) => {

  try{
  		const { isCajaCerrada } = getState().rendir

  		if(isCajaCerrada===0){

  			let response = await API.putFile('rendir/export', body )	

	        response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `rendicion-caja.xlsx`;
                        a.click();
                      });
  		}        
    }catch(error){
    }
}


export const reabrirCajaCerrada = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

			const { configuracion } = getState().config
			const isCheck = validarPermiso(configuracion, "reabrir_caja")

			if(isCheck){
				let response = await API.post("rendir/open/caja", body)

			    if(response.status){
			    	const { diferencia } = getState().rendir
			    	const payload = {
			    		isCajaCerrada: 1,
			    		isPermisoEliminarDeposito: true,
			    		isExiste: true,
			    		isInitial: false,
			    		idRendirCaja: response.idRendirCaja,
			    		observaciones: response.observaciones,
			    		listadoDepositos: response.depositos,
			    		disabledAdd: (diferencia>0) ? false : true
		    		}

			       dispatch(requestSuccess(REABRIR_CAJA, payload))
			    }
		      resolve({ status: response.status, message: response.message })
		  }else{
		  	resolve({ status: false, message: "No tiene permiso para abrir la caja" })
		  }
		    
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const createdObservacion = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const { idRendirCaja } = getState().rendir

		    let response = await API.post(`rendir/${idRendirCaja}/comment`,{comentarios: body})

		    if(response.status){
		    	const payload = response.observaciones

		       dispatch(requestSuccess(ADD_OBSERVACIONES, payload))
		    }
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}