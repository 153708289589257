import API from "../config/api"
import {
	LIST_DATA_INICIO,
	UPDATED_TITLE_BOTON,
	SEARCH_NOMINA,
	CLEAR_NOMINA_STATUS,
	SAVE_NOMINA,
	CHANGE_IMPRESION
} from "../types/nominaType"
import { validarPermiso } from "../utils/Permission/checkPermission"

export const requestSuccess = (type, payload) => {

    return { type, payload }
}

export const getListado = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("nominas")

		    if(response.status){
		       dispatch(requestSuccess(LIST_DATA_INICIO, response))
		    }
	      resolve(response)
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const createdNomina = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

			const { configuracion } = getState().config
			
			if(!validarPermiso(configuracion, "crear_nomina"))
			{
		    	reject({ status: false, message: "No tiene permiso de crear nominas" })
			}else{

				const { listOrden } = getState().orders
			  	let response = await API.post("nominas", body)
			    if(response.status){
			    	const payload = {
			    		dataNomina: {
			    			...body,
			    			numero_nomina: response.numero_nomina,
			    			fecha_viaje: response.fecha_viaje,
			    			ordens: listOrden.filter(orden => orden.check===true)
			    		},
			    		titleDestino: body.destino_id,
			    		isActiveNomina: true,
			    		nominas: response.nominas
			    	}
				
			       dispatch(requestSuccess(SAVE_NOMINA, payload))
			    }
		      resolve({ status: response.status, message: response.message, numero_nomina: response.numero_nomina })
		   	}
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const officeNomina = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  let response = await API.post("nominas/home", body)
		  if(response.status){
		  		const { listOrden } = getState().orders
		    	const payload = {
		    		dataNomina: {
		    			...body,
		    			numero_nomina: response.numero_nomina,
		    			fecha_viaje: response.fecha_viaje,
		    			ordens: listOrden.filter(orden => orden.check===true)
		    		},
		    		titleDestino: body.destino_id,
		    		isActiveNomina: true,
		    		nominas: response.nominas
		    	}
		       dispatch(requestSuccess(SAVE_NOMINA, payload))
		    }
	      resolve({ status: response.status, message: response.message, numero_nomina: response.numero_nomina })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const updateTitleBtn = (title, isVisible) => (dispatch, getState) => {
    dispatch(requestSuccess(UPDATED_TITLE_BOTON, { title, isVisible }))
}

export const searchNomina = (numero_nomina) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.get(`nominas/${numero_nomina}`)

		    if(response.status){
		    	const payload = {
		    		dataNomina: response.nomina,
		    		titleDestino: response.nomina.destino_nomina,
		    		isActive: true
		    	}
		
		       dispatch(requestSuccess(SEARCH_NOMINA, payload))
		    }
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const isClearNomina = () => (dispatch, getState) => {
	const payload = {
		dataNomina: {},
		titleDestino: "",
		isActiveNomina: false
	}
    dispatch(requestSuccess(CLEAR_NOMINA_STATUS, payload))
}

export const changeIsImpresion = (title) => (dispatch, getState) => {
    dispatch(requestSuccess(CHANGE_IMPRESION, title))
}