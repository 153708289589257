import API from "../config/api"
import {
	LOGIN,
	LOGOUT,
	LISTADO_DATA_USER,
	SEARCH_USER, 
	DATA_DELETE_USER, 
	IS_VISIBLE_FORMULARIO_USER, 
	SELECTED_ROWS_USER,
	DATA_ADD_USER,
	DATA_ACTIVAR_DESACTIVAR_USER,
	DATA_EDIT_USER,
	DATA_ADD_USER_VEHICLE
} from "../types/userType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const login = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.post("auth/login", body)

		    if(response.status){
		    	const user = {
		    		email: body.email,
		    		name: response.name,
		    		token: response.token,
		    		permissions: response.permissions,
		    		comuna_id: response.comuna_id,
		    		sucursal_id: response.sucursal_id
		    	}
		       localStorage.setItem("user", JSON.stringify(user))
		       dispatch(requestSuccess(LOGIN, response.name))
		    }
	      resolve(response)
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const loadUser = () => (dispatch, getState) => {
	try {
	    const serializedData = JSON.parse(localStorage.getItem("user"))
	    if (serializedData === null){
	      return undefined 
	    }
	    dispatch(requestSuccess(LOGIN, JSON.parse(localStorage.getItem("user"))))
	} catch (error) {
	    return undefined 
	}
}



export const logout = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("auth/logout")

		    if(response.status){
		       localStorage.clear()
		       dispatch(requestSuccess(LOGOUT, null))
		    }
	        resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const listadoUsuario = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/usuarios")	

			dispatch(requestSuccess(LISTADO_DATA_USER, response))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const searchPorId = (id, statusArr, ) => (dispatch, getState) => {
	const {allListUsuario} = getState().users
	const user = allListUsuario.find(user => user.id===id)
    const payload = {
		seletected: {...user},
		...statusArr
	}

	if(payload){
	  	dispatch(requestSuccess(SEARCH_USER, payload))
	}
}

export const deleteUser = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {allListUsuario} = getState().users

		  	let response = await API.get(`admin/usuarios/delete/${id}`)

		    if(response.status){
				
				const payload =allListUsuario.filter(rol => rol.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_USER, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const isVisibleFormularioUser = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		isAssignVehicle: false,
		isClave: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_USER, payload))
	  	 
	}
}

export const getSelectedRows = (rows) => (dispatch, getState) => {
	let estatus = ""
	let title = ""
	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].is_activo===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
	}
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount>0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}

	dispatch(requestSuccess(SELECTED_ROWS_USER, payload))
}


export const addUser = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.post("admin/usuarios", body)

		    if(response.status){
				const usuario = {
						...body,
						id: response.id, 
						fullName: `${body.nombre} ${body.surname}`,
						is_activo: 1,
						name_status: "Activado"
					}
				const {allListUsuario} = getState().users
				const data = [...allListUsuario, usuario]
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_USER, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const addUserFile = (formData, body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			
		  	let response = await API.postFile("admin/usuarios", formData, body)

		    if(response.status){

	
		    	//let fecha = body.fecha_vencimiento
		    	/**if(body.nombre_rol==="Conductor"){
			    	fecha_venc = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
		    	}		 */    	
		    	
				const usuario = {
						...body,
						id: response.id, 
						fullName: `${body.nombre} ${body.surname}`,
						is_activo: 1,
						name_status: "Activado",
						fecha_vencimiento: body.nombre_rol==="Conductor" ? body?.fecha_vencimiento : "",
						fecha: body.nombre_rol==="Conductor" ? body?.fecha_vencimiento : "",
						imagen: response.imagen
					}
				
				const {allListUsuario} = getState().users
	
				let userList = allListUsuario

				userList.unshift(usuario)
				const data = userList
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_USER, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){

	      resolve({status: false, message:error})
	    }
	})
}

export const editUser = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {allListUsuario, uDatos} = getState().users

		  	let response = await API.post(`admin/usuarios/${uDatos.id}`, body)

		    if(response.status){
				
				const data =allListUsuario.map(user => {
					if(user.id === uDatos.id){
						return {
							...body, 
							id: uDatos.id,
							fullName: `${body.nombre} ${body.surname}`,
							is_activo: uDatos.is_activo,
							email: uDatos.email
						};
					}
					return user
				})  
				const payload = { listado: data, isForm: false, datos: {} }	

		        dispatch(requestSuccess(DATA_EDIT_USER, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editUserFile = (formData, body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {allListUsuario, uDatos} = getState().users

		  	let response = await API.postFile(`admin/usuarios/${uDatos.id}`, formData)

		    if(response.status){

		    	let fecha_venc =""
		    	let fecha = body.fecha_vencimiento

		    	if(body.nombre_rol==="Conductor"){
		    		fecha_venc =body.fecha_vencimiento
		    	}
		    					
				const data =allListUsuario.map(user => {
					if(user.id === uDatos.id){
						return {
							...body, 
							id: uDatos.id,
							fullName: `${body.nombre} ${body.surname}`,
							is_activo: uDatos.is_activo,
							email: uDatos.email,
							fecha_vencimiento: body.nombre_rol==="Conductor" ? fecha : "",
							fecha: fecha_venc,
							imagen: response.imagen
						};
					}
					return user
				})  
				const payload = { listado: data, isForm: false, datos: {} }	

		        dispatch(requestSuccess(DATA_EDIT_USER, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changeStatus = (id, statusName, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {allListUsuario, uIsSelectedRows} = getState().users

			const body = {
				type: "estatus",
				opcion: statusName.toLowerCase()
			}

		  	let response = await API.post(`admin/usuarios/${id}`, body)

		    if(response.status){
				
				const listado = allListUsuario.map(user => {
					
					if(user.id === id){
						return {
							...user, 
							is_activo: status, 
							name_status: statusName
						};
					}
					return user
				})  

				const payload = {
					rows: {
						...uIsSelectedRows,
						selectedRows: uIsSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								is_activo: status,
								name_status: statusName
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado
				}

		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_USER, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changePassword = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {uDatos} = getState().users
		  	let response = await API.post(`admin/usuarios/change/${uDatos.id}`, body)
	        resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const addVehicleUser = (data) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

			let { uDatos, allListUsuario } = getState().users

			let sVehicleAdd = [], sVehicleDelete=[]

			for(let index in data.camions){
				const vehicle = data.camions[index]
				const isExistVehicle = uDatos.camions?.find(item => item.placa===vehicle.placa)
				if(!isExistVehicle){
					sVehicleAdd.push(vehicle.idVehiculo)
				}
			}

			for(let index in uDatos.camions){
				const vehicle = uDatos.camions[index]
		
				const isExistVehicle = data.camions?.find(item => item.placa===vehicle.placa)
		
				if(!isExistVehicle){
					sVehicleDelete.push(vehicle.idVehiculo)
				}
			}

			const body = { sVehicleAdd, sVehicleDelete }
	
		  	let response = await API.post(`admin/usuarios/${uDatos.id}/vehicle`, body)

		    if(response.status){

				const listado = allListUsuario.map(item => {
					let camionsList = (item.id===uDatos.id) ? response.camions : item.camions
					return {
						...item,
						camions: camionsList
					}
				})
				const payload = { listado, isForm: false, isVehicle: false}
				
		        dispatch(requestSuccess(DATA_ADD_USER_VEHICLE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


