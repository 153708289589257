import React, { Fragment } from "react"
import {  
	Form,
	Col,
	Table
} from 'react-bootstrap'
import descargar from '../../assets/images/descargar.png'
import { useSelector, useDispatch } from "react-redux"
import { searchDocument } from '../../actions/facturacionAction'


const TableSuccess = () => {

	const { listEntregada } = useSelector((state) => state.orders)

	const dispatch = useDispatch()
	
	const obtenerDocumento = async (event, row) => {
		event.preventDefault()
		
		if(row.url.length===0){
			const body = { tipo_documento: row.documento, numero_documento: row.folio}
			await dispatch(searchDocument(body))
		}else{
			let a = document.createElement('a');
			a.href = row.url;
			a.target = "_blank";
			a.download = `documento_${row.folio}.pdf`;
			document.body.appendChild(a);
			a.click();
		}
	}

	return (
		<Fragment>
			<Form.Row>
				<Col xs={12}>
					<Form.Group as={Col}>
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th>N° de Folio</th>
							      <th>Documento</th>
							      <th>Descargar</th>
							    </tr>
							  </thead>
							  <tbody>
							  {
							  		listEntregada?.length  > 0 ? (
							  			listEntregada?.map((documento, index) => (
							  				<tr className="tr-vacia" key={index}>
						  						<td>{documento.folio}</td>
								  				<td>{documento.documentacion}</td>
								  				<td>
								  					<button 
															style={{"height": "37px"}} 
															className="btn btn-xs pb-0 pt-1 mb-0" 
															key={documento.folio}
															onClick={(event)=>obtenerDocumento(event, documento)}
													>
														<img src={descargar} height="30" alt="Descargar Documento"  />
													</button>
								  				</td>
								  			</tr>
							  			))
							  		) : (
							  			<tr className="tr-vacia">
					  						<td></td>
							  				<td></td>
							  				<td></td>
							  			</tr>
							  		)
							  	}
							  	
							  </tbody>
						</Table>
					</Form.Group>
				</Col>
			</Form.Row>	
		</Fragment>

	)
}

export default TableSuccess

