import { useState, useEffect } from "react"
import { getHome } from "../../actions/orderAction"
import { useDispatch, useSelector } from "react-redux"


export const useTool = () => {

	const dispatch = useDispatch()
	const [oficina, setOficina ] = useState([])
	const { sucursales, tipo_envio } = useSelector((state) => state.orders)

	useEffect(() => {
		getInitialData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getInitialData = async () => {
		if(sucursales.length===0){
			await dispatch(getHome())
		}
	}

	const cargarOficinas = (e, valorSucursalId) => {

		const id =  (e !==undefined ) ? e.target.value : valorSucursalId
		
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)
			}

			setOficina(detComuna)
		}
	}

	return {
		cargarOficinas,
		sucursales,
		getInitialData,
		oficina,
		tipo_envio
	}
}