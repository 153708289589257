import { useEffect } from "react"
import {  
	Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { BiX, BiEditAlt, BiSave } from "react-icons/bi"
import { useState } from 'react'
import { toast } from 'react-toastify'
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form"
import { onPaste, validateOnlyNumber } from "../../../../utils/validationInput";

const BodyCarga = ({ index, id, alto, ancho, largo, cantidad, peso, 
	register, visible, editRow, deleteRow, metraje_cubico, setValue, getValues, control }) => {

    //const [ totalPagar, setTotalPagar ] = useState(valor_neto)
    const [ nroPeso, setNroPeso ] = useState(peso);
    const [ nroLargo, setNroLargo] = useState(largo);
    const [ nroAncho, setNroAncho ] = useState(ancho);
    const [ nroAlto, setNroAlto ] = useState(alto);
    const [ nroM3, setNroM3 ] = useState(metraje_cubico);

    useEffect(() => {
      //setTotalPagar(valor_neto);
      setNroPeso(peso);
      setNroLargo(largo);
      setNroAncho(ancho);
      setNroAlto(alto);
      setNroM3(metraje_cubico);
    
    }, [peso, largo, ancho, alto, metraje_cubico])

    const editM3 = (index) => {

      let total = 0
      if(nroAlto!==""){
        total = nroAlto
      }
      if(nroAncho!==""){
        total = total * nroAncho
      }
      if(nroLargo!==""){
        total = total * nroLargo
      }

      setValue(`cargas[${index}].metraje_cubico`, total)
      setNroM3(total)
    }

    const validateBulto = (event, name) => {
   
      if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
        toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
        return false
      }
    }

           
	return (
		<tr key={index}>
	      <td>
	      	<input
                type="hidden"
                name={`cargas[${index}].id`}
                defaultValue={id}
                ref={register}
                className="form-control"
              />
              {
                visible ? (
                  <OverlayTrigger overlay={<Tooltip id="tooltip-alto">m</Tooltip>}>
                    <Controller
                      control={control}
                      name={`cargas[${index}].alto`}
                      render={(props) => (
                        <NumericFormat
                          allowNegative={false}
                          className="form-control"
                          decimalScale={2}
                          name={props.name}
                          id={props.name}
                          fixedDecimalScale={false}
                          placeholder="Alto (m)"
                          isAllowed={({floatValue}) => {
                            // Define your validation rules here
                            return true;
                          }}
                          onPaste={(e) => e.preventDefault()}
                          decimalSeparator={','}
                          value={nroAlto}
                          //defaultValue={ordenInfo.valor_declarado_carga}
                          onValueChange={(values) => {
                            props.onChange(values.floatValue);
                            setNroAlto(values.floatValue);
                          }}
                          onBlur={() => editM3()}
                          maxLength={15}
                        />
                      )}
                      autoComplete="off"
								/>
                  </OverlayTrigger>
                ) : (
                  <input
                    type="hidden"
                    name={`cargas[${index}].alto`}
                    defaultValue={alto}
                    ref={register}
                    className="form-control"
                    step="0.01" 
                    min="0"
                  />
                )
                }
                  {!visible && 
                <NumericFormat
                  displayType="text"
                  className="text-padding"
                  decimalScale={2}
                  fixedDecimalScale={false}
                  decimalSeparator={','}
                  value={nroAlto}
                />
              }
			</td>
	      <td>
        {
            visible ? (
              <OverlayTrigger overlay={<Tooltip id="tooltip-ancho">m</Tooltip>}>
                 <Controller
                      control={control}
                      name={`cargas[${index}].ancho`}
                      render={(props) => (
                        <NumericFormat
                          allowNegative={false}
                          className="form-control"
                          decimalScale={2}
                          name={props.name}
                          id={props.name}
                          fixedDecimalScale={false}
                          placeholder="Ancho (m)"
                          isAllowed={({floatValue}) => {
                            // Define your validation rules here
                            return true;
                          }}
                          onPaste={(e) => e.preventDefault()}
                          decimalSeparator={','}
                          value={nroAncho}
                          //defaultValue={ordenInfo.valor_declarado_carga}
                          onValueChange={(values) => {
                            props.onChange(values.floatValue);
                            setNroAncho(values.floatValue);
                          }}
                          onBlur={() => editM3()}
                          maxLength={15}
                        />
                      )}
                      autoComplete="off"
								/>
              </OverlayTrigger>
            ) : (
              <input
                type="hidden"
                name={`cargas[${index}].ancho`}
                defaultValue={ancho}
                ref={register}
                className="form-control"
                step="0.01" 
                min="0"
              />
            )
            }
              
              {!visible && 
            <NumericFormat
              displayType="text"
              className="text-padding"
              decimalScale={2}
              fixedDecimalScale={false}
              decimalSeparator={','}
              value={nroAncho}
            />
          }
	      </td>
          <td>
          {
              visible ? (
                <OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
                   <Controller
                      control={control}
                      name={`cargas[${index}].largo`}
                      render={(props) => (
                        <NumericFormat
                          allowNegative={false}
                          className="form-control"
                          decimalScale={2}
                          name={props.name}
                          id={props.name}
                          fixedDecimalScale={false}
                          placeholder="Largo (m)"
                          isAllowed={({floatValue}) => {
                            // Define your validation rules here
                            return true;
                          }}
                          onPaste={(e) => e.preventDefault()}
                          decimalSeparator={','}
                          value={nroLargo}
                          //defaultValue={ordenInfo.valor_declarado_carga}
                          onValueChange={(values) => {
                            props.onChange(values.floatValue);
                            setNroLargo(values.floatValue);
                          }}
                          onBlur={() => editM3()}
                          maxLength={15}
                        />
                      )}
                      autoComplete="off"
								/>
                </OverlayTrigger>
              ) : (
                <input
                  type="hidden"
                  name={`cargas[${index}].largo`}
                  defaultValue={largo}
                  ref={register}
                  className="form-control"
                  step="0.01" 
                  min="0"
                />
              )
              }
                {!visible &&  
              <NumericFormat
                displayType="text"
                className="text-padding"
                decimalScale={2}
                fixedDecimalScale={false}
                decimalSeparator={','}
                value={nroLargo}
              />
            }
          </td>
          <td>
             <input
                type="hidden"
                name={`cargas[${index}].metraje_cubico`}
                defaultValue={nroM3}
                ref={register}
                className="form-control"
                step="0.01" 
              />
	      	<NumericFormat
              displayType="text"
              className="text-padding"
              decimalScale={2}
              fixedDecimalScale={false}
              decimalSeparator={','}
              thousandSeparator={"."}
              value={nroM3}
            />
          </td>
	      <td>
            <input
                type={visible ? "text" : "hidden"}
                name={`cargas[${index}].cantidad`}
                defaultValue={cantidad}
                ref={register}
                className="form-control"
                onBlur={(event) => validateBulto(event, `cargas[${index}].cantidad`)}
                onKeyPress={(event) => validateOnlyNumber(event)}
                onPaste={(event) => onPaste(event)}
              />
            {!visible && cantidad}
          </td>
          <td>
          {
              visible ? (
                <Controller
                      control={control}
                      name={`cargas[${index}].peso`}
                      render={(props) => (
                        <NumericFormat
                          allowNegative={false}
                          className="form-control"
                          decimalScale={2}
                          name={props.name}
                          id={props.name}
                          fixedDecimalScale={false}
                          placeholder="Largo (m)"
                          isAllowed={({floatValue}) => {
                            // Define your validation rules here
                            return true;
                          }}
                          onPaste={(e) => e.preventDefault()}
                          decimalSeparator={','}
                          value={nroPeso}
                          //defaultValue={ordenInfo.valor_declarado_carga}
                          onValueChange={(values) => {
                            props.onChange(values.floatValue);
                            setNroPeso(values.floatValue);
                          }}
                          maxLength={15}
                        />
                      )}
                      autoComplete="off"
								/>
              ) : (
                <input
                  type="hidden"
                  name={`cargas[${index}].peso`}
                  defaultValue={nroPeso}
                  ref={register}
                  className="form-control"
                  step="0.01" 
                  min="0"
                />
              )
              }
                
                {!visible && 
              <NumericFormat
                displayType="text"
                className="text-padding"
                decimalScale={2}
                fixedDecimalScale={false}
                decimalSeparator={','}
                thousandSeparator={"."}
                value={nroPeso}
              />
            }
          </td>
	      <td>
	      	<Button variant={!visible ? "warning" : "success" } type="button" size="sm" onClick={(event)=>editRow(event, index, visible)} className="mr-1 pl-1 pr-1">
    			    {!visible ? <BiEditAlt style={{fontSize: '20px'}} /> : <BiSave style={{fontSize: '20px'}} /> }
    			</Button>
	      	<Button variant="danger" type="button" size="sm" onClick={(event)=>deleteRow(event, index)} className="pl-1 pr-1">
    			    <BiX style={{fontSize: '20px'}} />
    			</Button>		
	      </td>
	    </tr>

	)
}

export default BodyCarga