export const formatCreateDataOrdenFlete = (data) => {

    let infoOrden = {
        comentarios: data?.comentarios,
        numero_orden: data?.numero_orden,
        fecha_orden: data?.fecha_orden,
        tipo_envio_id: data?.tipo_envio_id,
        forma_pago_id: typeof data?.forma_pago_id==="undefined" ? null : data?.forma_pago_id,
        valor_declarado_carga: typeof data?.valor_declarado_carga==="undefined" ? null : data?.valor_declarado_carga,
        numero_documento_carga: typeof data?.numero_documento_carga==="undefined" ? null : data?.numero_documento_carga,
        email: data?.email,
        documentacion_id: typeof data?.documentacion_id==="undefined" ? null : data?.documentacion_id,
        documentacion_id_pago: typeof data?.documentacion_id_pago==="undefined" ? null : data?.documentacion_id_pago,
        forma_pago_descripcion: typeof data?.forma_pago_descripcion==="undefined" ? null : data?.forma_pago_descripcion,
        comunas: [
            {
                type: "origen",
                oficina_id: data?.oficina_origen_id,
                sucursal_id: data?.sucursal_origen_id
            },
            {
                type: "destino",
                oficina_id: data?.oficina_destino_id,
                sucursal_id: data?.sucursal_destino_id
            }
        ]
    }

    let clientes = [
        {
            type: "cliente_principal",
            rut: data?.cliente_rut,
            isPrincipal: true,
            razon_social: data?.razon_social,
            codigo_interno: data?.codigo_interno_nro ?? "",
        },
        {
            type: "remitente",
            rut: data?.rut_remitente,
            isPrincipal: false,
            razon_social: data?.razon_social_remitente,
            codigo_interno: data?.cliente_rut===data?.rut_remitente ? data?.codigo_interno_nro : "",
        },
        {
            type: "destinatario",
            rut: data?.rut_destinatario,
            isPrincipal: false,
            direccion: data?.direccion_destinatario,
            telefono: data?.telefono_avisas,
            razon_social: data?.razon_social_destinatario,
            codigo_interno: data?.cliente_rut===data?.razon_social_destinatario ? data?.codigo_interno_nro : "",
        },
    ]

    infoOrden["clientes"] = clientes

    let detCargas = []
    for(let index in data?.cargas){

        let carga = data?.cargas[index]
    
        detCargas.push({
            alto: typeof carga?.alto==="undefined" ? null : carga?.alto,
            ancho: typeof carga?.ancho==="undefined" ? null : carga?.ancho,
            largo:  typeof carga?.largo==="undefined" ? null : carga?.largo,
            bulto: typeof carga?.bulto==="undefined" ? null : carga?.bulto,
            peso: typeof carga?.peso==="undefined" ? null : carga?.peso,
            metraje_cubico: typeof carga?.metraje_cubico==="undefined" ? null : carga?.metraje_cubico,
            valor_neto: data?.type_price==="no_price" ? 0 : carga?.valor_neto,
            id: carga?.id,
            contenido: carga?.contenido
        })
    }

    infoOrden["cargas"] = detCargas 

    let payment = {
        type_price: data?.type_price,
        descuento: data?.type_price==="no_price" ? "No" : data?.descuento,
        subtotal_orden: data?.type_price==="no_price" ? null : data?.subtotal_orden,
        valor_iva: data?.type_price==="no_price" ? null : data?.valor_iva,
        valor_neto: data?.type_price==="no_price" ? null : data?.valor_neto,
        total: data?.type_price==="no_price" ? null : data?.total,
    }

    infoOrden["payment"] = payment

    infoOrden["cliente_factura"] = {
        rut: data?.rut_remitente
    }
 
    return infoOrden     
}

export const formatEditDataOrdenFlete = (data) => {

    let infoOrden = {
        observacion: data?.comentarios,
        numero_orden: data?.numero_orden,
        fecha_orden: data?.fecha_orden,
        tipo_envio_id: data?.tipo_envio_id,
        email: data?.email,
        forma_pago_descripcion: typeof data?.forma_pago_descripcion==="undefined" ? null : data?.forma_pago_descripcion,
        comunas: [
            {
                type: "origen",
                comuna_id: data?.oficina_origen_id,
                sucursal_id: data?.sucursal_origen_id
            },
            {
                type: "destino",
                comuna_id: data?.oficina_destino_id,
                sucursal_id: data?.sucursal_destino_id
            }
        ]
    }

    let clientes = [
        {
            type: "remitente",
            data: {
                rut: data?.rut_remitente,
                razon_social: data?.razon_social_remitente,
                codigo_interno: data?.cliente_rut===data?.rut_remitente ? data?.codigo_interno_nro : ""
            }, 
            principal: data?.cliente_rut===data?.rut_remitente ? true : false    
        },
        {
            type: "destinatario",
            data: {
                rut: data?.rut_destinatario,
                direccion: data?.direccion_destinatario,
                telefono: data?.telefono_avisas,
                razon_social: data?.razon_social_destinatario,
                codigo_interno: data?.cliente_rut===data?.razon_social_destinatario ? data?.codigo_interno_nro : ""
            },
            principal: data?.cliente_rut===data?.rut_destinatario ? true : false            
        }
    ]

    infoOrden["clientes"] = clientes

    let detCargas = []
    for(let index in data?.cargas){

        let carga = data?.cargas[index]
     
        detCargas.push({
            alto: typeof carga?.alto==="undefined" ? null : carga?.alto,
            ancho: typeof carga?.ancho==="undefined" ? null : carga?.ancho,
            largo:  typeof carga?.largo==="undefined" ? null : carga?.largo,
            bulto: typeof carga?.bulto==="undefined" ? null : carga?.bulto,
            peso: typeof carga?.peso==="undefined" ? null : carga?.peso,
            metraje_cubico: typeof carga?.metraje_cubico==="undefined" ? null : carga?.metraje_cubico,
            total: data?.type_price==="no_price" ? 0 : carga?.valor_neto,
            id: carga?.id,
            contenido: carga?.contenido
        })
    }

    infoOrden["cargas"] = detCargas 

    let payment = {
        type_price: data?.type_price,
        descuento: data?.type_price==="no_price" ? "No" : data?.descuento,
        subtotal_orden: data?.type_price==="no_price" ? null : data?.subtotal_orden,
        valor_iva: data?.type_price==="no_price" ? null : data?.valor_iva,
        valor_neto: data?.type_price==="no_price" ? null : data?.valor_neto,
        total: data?.type_price==="no_price" ? null : data?.total,
        forma_pago_id: typeof data?.forma_pago_id==="undefined" ? null : data?.forma_pago_id,
        valor_declarado_carga: typeof data?.valor_declarado_carga==="undefined" ? null : data?.valor_declarado_carga,
        numero_documento_carga: typeof data?.numero_documento_carga==="undefined" ? null : data?.numero_documento_carga,
        documentacion_id: typeof data?.documentacion_id==="undefined" ? null : data?.documentacion_id,
        documentacion_id_pago: typeof data?.documentacion_id_pago==="undefined" ? null : data?.documentacion_id_pago
    }

    infoOrden["payment"] = payment

    infoOrden["cliente_factura"] = {
        rut: data?.rut_remitente
    }

    infoOrden["sucursal_name"] = data?.sucursal_name;

    return infoOrden     
}