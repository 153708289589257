export const INITIAL_RETIRO = "INITIAL_RETIRO"
export const LISTADO_RETIRO_USER = "LISTADO_RETIRO_USER"
export const LISTADO_PENDIENTE_RETIRO = "LISTADO_PENDIENTE_RETIRO"
export const SEARCH_RETIRO = "SEARCH_RETIRO"
export const SELECTED_ROWS = "SELECTED_ROWS"
export const IS_VISIBLE_LISTADO = "IS_VISIBLE_LISTADO"
export const FILTRADO_MOVIL = "FILTRADO_MOVIL"
export const ADD_DETALLE_CARGA_DESTINO = "ADD_DETALLE_CARGA_DESTINO"
export const SAVE_DESTINO = "SAVE_DESTINO"
export const EDIT_LIST_DESTINO = "EDIT_LIST_DESTINO"
export const SELECT_DESTINO = "SELECT_DESTINO"
export const SELECTED_PREORDEN = "SELECTED_PREORDEN"
export const RESET_LIST_ALL = "RESET_LIST_ALL"
export const UPDATE_CARGAS_RETIRO = "UPDATE_CARGAS_RETIRO"
export const UPDATE_DATA_RETIRO = "UPDATE_DATA_RETIRO"
export const UPDATE_CANCEL_LIST_RETIRO = "UPDATE_CANCEL_LIST_RETIRO"


export const LOADING_DETAILS_RETIRO = "LOADING_DETAILS_RETIRO"
export const SEARCH_RETIRO_BY_NRO = "SEARCH_RETIRO_BY_NRO"
export const LISTADO_ALL = "LISTADO_ALL"
export const LOADING_DATA = "LOADING_DATA"
export const PREVIEW_TICKET_RETIRO = "PREVIEW_TICKET_RETIRO"
export const RESET_PREVIEW_TICKET_RETIRO = "RESET_PREVIEW_TICKET_RETIRO"
export const UPDATE_REMITENTE_RETIRO = "UPDATE_REMITENTE_RETIRO"
export const FILTRADO_RETIRO = "FILTRADO_RETIRO"