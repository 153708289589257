const Correlativo = { 
	columnas: [
		{
			name: 'Módulo',
			selector: (row, i) => row.title_modulo,
			sortable: true
		},
		{
			name: 'Correlativo Inicial',
			selector: (row, i) => row.correlativo_inicial,
			sortable: true
		},
        {
			name: 'Correlativo Actual',
			selector: (row, i) => row.correlativo_actual,
			sortable: true
		},
		{
			name: 'Últ. Actualización',
			selector: (row, i) => row.updated_at,
			sortable: true
		}
	]
}

export default Correlativo