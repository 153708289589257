import { BiEditAlt } from "react-icons/bi"

const TableIncomplete = ({ listOrdens, isLoading, handleSelectedOrden }) => {

	return (
		<div className="table-responsive table-retiro-responsive">
			<table className="table table-bordered table-sm bg-white table-retiro">
				<thead className="bg-warn-blue">
				    <tr>
				      	<th className="text-center"># Orden</th>
				      	<th className="text-center">Fecha</th>
				      	<th className="text-center">Rut Remitente</th>
				      	<th className="text-center" style={{width: "300px"}}>Remitente</th>
				      	<th className="text-center">Origen</th>
				      	<th className="text-center">Destino</th>
				      	<th className="text-center">Teléfono</th>
				      	<th className="text-center">Cant Bultos</th>
                          <th className="text-center"></th>
				    </tr>
				 </thead>
				 <tbody>
				 {
				 	listOrdens.length>0 &&
				 	listOrdens.map((item, key) => 
				 		<tr key={key}>
				 			<td className="text-center">{item.numero_orden}</td>
				 			<td className="text-center">{item.fecha_orden_search}</td>
				 			<td className="text-center">{item.remitente?.rut}</td>
				 			<td>{item.remitente?.nombre_completo}</td>
				 			<td>{item?.origen?.nombre}</td>
				 			<td>{item?.destino?.nombre}</td>
				 			<td>{item.remitente?.telefono}</td>
				 			<td>{item.cant_bulto}</td>
				 			<td className="text-center">
                                <button 
                                    className="btn btn-warning p-1" 
                                    type="button" 
                                    onClick={()=>handleSelectedOrden(item)}
                                >
                                    <BiEditAlt style={{fontSize: '20px'}} />
                                </button>
                            </td>
				 		</tr>
				 	)
				 }
				 {(!isLoading && listOrdens.length===0) && <tr><td colSpan={9}>No hay registros disponibles</td></tr>}
				 {(isLoading && listOrdens.length===0) && <tr><td colSpan={9}>Buscando...</td></tr>}
				 </tbody>
			</table>
		</div>
	)
}

export default TableIncomplete