export const BUSQUEDA_CLIENTE = "BUSQUEDA_CLIENTE"
export const NUEVA_FACTURACION = "NUEVA_FACTURACION"
export const IS_LOADING_DATA = "IS_LOADING_DATA"
export const HISTORICO_FACTURAS = "HISTORICO_FACTURAS"
export const SELECTED_OPTION = "SELECTED_OPTION"
export const HIDDEN_BOTONES = "HIDDEN_BOTONES"
export const ADD_DOCUMENT_PENDIENT = "ADD_DOCUMENT_PENDIENT"
export const ADD_PAYMENT_METHODS = "ADD_PAYMENT_METHODS"
export const SEARCH_CLIENTE_PENDIENT = "SEARCH_CLIENTE_PENDIENT"
export const SEARCH_INVOICE_BY_PARAMETERS = "SEARCH_INVOICE_BY_PARAMETERS"
export const LOADING_SEARCH_PAYMENT = "LOADING_SEARCH_PAYMENT"