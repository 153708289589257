import { 
	LISTADO_DATA_CORRELATIVO,
	IS_VISIBLE_FORMULARIO_CORRELATIVO,
	SELECTED_ROWS_CORRELATIVO,
	SEARCH_CORRELATIVO,
	DATA_ADD_CORRELATIVO,
	DATA_EDIT_CORRELATIVO,
	DATA_DELETE_CORRELATIVO
} from "../types/correlativoType"

const INITIAL_STATE = {
	correlativoSelected: {},
	listCorrelativo: [],
	corIsCargando: true,
	corIsForm: false,
	corIsEdit: false,
	corDatos: {},
	corIsSelectedRows: { }
}

const correlativoReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_CORRELATIVO:
			return {
				...state,
				listCorrelativo: action.payload.listCorrelativos,
				corIsCargando: false
			}
		case SELECTED_ROWS_CORRELATIVO:
			return {
				...state,
				corIsSelectedRows: action.payload
			}
		case IS_VISIBLE_FORMULARIO_CORRELATIVO:
			return {
				...state,
				corIsForm: action.payload.isForm,
				corIsEdit: action.payload.isEdit,
				corDatos: action.payload.datos
			}
		case SEARCH_CORRELATIVO:
			return {
				...state,
				corDatos: action.payload.seletected,
				corIsForm: action.payload.isForm,
				corIsEdit: action.payload.isEdit
			}
		case DATA_ADD_CORRELATIVO:
			return{
				...state,
				listCorrelativo: action.payload.listado,
				corIsForm: action.payload.isForm
			}
		case DATA_EDIT_CORRELATIVO:
			return {
				...state,
				listCorrelativo: action.payload.listado,
				corDatos: action.payload.datos,
				corIsForm: action.payload.isForm
			}
		case DATA_DELETE_CORRELATIVO:
			return {
				...state,
				listCorrelativo: action.payload
			}
		default: return state
	}
}

export default correlativoReducer