import React, { useState } from "react"
import {  
	Col,
	Form,
    Button
} from 'react-bootstrap'
import ModalFactura from '../../components/Factura/ModalFactura'
import FormOrden from "../../components/Encomienda/OrdenesEntregadas/Formulario"
import TablaOrden from "../../components/Encomienda/OrdenesEntregadas/TableEntrega"
import PanelDetalle from "../../components/Layouts/PanelDetalle"
import TableSuccess from '../../components/Encomienda/TableSuccess'
import { useDispatch, useSelector } from "react-redux"
import { OrdenesPorPagar, assignOrdenCaja } from "../../actions/orderAction"
import { useForm } from "react-hook-form"


const OrdenEntregada = ({ toast }) => {

	const dispatch = useDispatch()
    const { listEntregada  } = useSelector((state) => state.orders)
	const { register, handleSubmit, errors } = useForm()
    const [ hidden, setHidden ] = useState(false)
    const [ infoData, setInfoData ] = useState({})


	const onSubmit =  async (data, event) => {
    	event.preventDefault()

    	if(!data.hasOwnProperty("ordenes")){
    		toast.error("No tiene ordenes por devolver", {position: toast.POSITION.TOP_RIGHT})
    		return false
    	}


        if(data.ordenes.filter(x=>x.documentacion_id==="").length>0){
            toast.error("Complete los datos del listado", {position: toast.POSITION.TOP_RIGHT})
            return false
        }

        const information = {
            ordenes: data.ordenes,
            rut_remitente: data.ordenes[0].rut_remitente,
            rut_destinatario: data.ordenes[0].rut_destinatario,
            documentacion_id: data.ordenes[0].documentacion_id
        }

        if(parseInt(information.documentacion_id)===1){
            setHidden(true)
            setInfoData(information) 
        }else{
            await funcFacturar(information)
        }
           	
    }

    const funcFacturar = async (information) => {
        const data = {
            ordenes: information.ordenes.map(orden=> orden.orden_flete_id),
            factura: {
                rut: information?.factura?.rut_factura,
                razon_social: information?.factura?.razon_social_factura,
                direccion: information?.factura?.direccion_factura,
                telefono: information?.factura?.contacto_factura,
                email: information?.factura?.email_factura,
                sucursal_id: information?.factura?.ciudad_factura,
                comuna_id: information?.factura?.comuna_factura,
                rubro: information?.factura?.giro_factura,
            },
            rut_remitente: information.rut_remitente,
            rut_destinatario: information.rut_destinatario,
            documentacion_id: information.documentacion_id
        }

        const response = await dispatch(OrdenesPorPagar(data))
        if(response.status){
            const body = {
                fecha_entrega: response.fecha_entrega,
                ordens: information.ordenes.map(orden=> orden.orden_flete_id)
            }
            await dispatch(assignOrdenCaja(body))

            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            document.getElementById("frmOrdenEntregada").reset()
            return true
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            return false
        }
    }



	return (
		<>
			<PanelDetalle title="DETALLE DE LA ORDENES ENTREGADAS" subtitle="INGRESO DE ORDENES POR PAGAR" check={true}>
                <FormOrden toast={toast} />
                <Form id="frmOrdenEntregada" onSubmit={handleSubmit(onSubmit)}>
                    <TablaOrden register={register} errors={errors} toast={toast} />

                    <Form.Row className="m-3">
                        <Col className="text-right">
                            <Button variant="warning" type="submit">
                                PROCESAR
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
                {listEntregada.length>0 && <TableSuccess />}
            </PanelDetalle>
             {hidden && <ModalFactura 
                            setHidden={setHidden} 
                            hidden={hidden} 
                            data={infoData} 
                            toast={toast} 
                            funcFactura={funcFacturar} 
                            title={"FACTURA A NOMBRE DE:"}  
                            isVisibleFactura={true}
                            nameModulo="entrega"
                            /> }
		</>
	)
}

export default OrdenEntregada