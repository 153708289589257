const baseURL = process.env.REACT_APP_API_URL || 'http://apiws.cacemexpress.cl/api'

const generateHeaders = (endMethod) => {
  const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : null
  return {
  	method: endMethod,
    headers: {
      "Access-Control-Allow-Headers" : "Content-Type",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  }
}

const  setData = (endMethod, data) => {
  const headers = generateHeaders(endMethod)
  return {
  	...headers,
  	body: JSON.stringify(data)
  }
}

const generateHeadersFile = (data, endMethod) => {
  const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : null
  return {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: data
   }
}


const API = {
	get(endPoint) {
		return fetch(`${baseURL}/${endPoint}`, generateHeaders("GET"))
				.then(response => response.json())
	},

	post(endPoint, body) {
		return fetch(`${baseURL}/${endPoint}`, setData("POST", body))
				.then(response => response.json())
	},

  put(endPoint, body) {
    return fetch(`${baseURL}/${endPoint}`, setData("PUT", body))
        .then(response => response.json())
  },

  getFile(endPoint) {
    return fetch(`${baseURL}/${endPoint}`, generateHeaders("GET"))
  },

  putFile(endPoint, body) {
    return fetch(`${baseURL}/${endPoint}`, setData("POST", body))
  },

  postFile: (endPoint, body) => {
    return fetch(`${baseURL}/${endPoint}`, generateHeadersFile(body, "POST"))
            .then(response => response.json())
  },
}


export default API

