import React from "react"
import {  
	Row,
	Col,
	Form
} from 'react-bootstrap'

const PanelDetalle = ({ title, subtitle, children, check, classRow="" }) => {
	return (
		<Row className={check ? "bg-soft-blue mb-1 border-panel" : classRow}>
			<Col className="contenedor-cliente">
				<Form.Row className="bg-blue margin-panel">
					<Col xs={2} className={check ? "min-height-80 my-auto" : "my-auto"}>
				     	<Form.Group as={Col} className="text-center">
					    	<p className="fw-600 font-s-8">{ title }</p>
					    </Form.Group>
				    </Col>
				    <Col xs={10} className="bg-soft-blue">
				    	{
				    		subtitle!=="" && (
				    			<Form.Row className="bg-warn-blue">
									<Form.Group as={Col} className="mb-2 mt-2">
									    	<p className="text-center mb-0 pb-0 fw-600">{ subtitle }</p>
									</Form.Group>
								</Form.Row>
				    		)
				    	}
						{children}
				    </Col>
				</Form.Row>
			</Col>
		</Row>
	)
}

export default PanelDetalle