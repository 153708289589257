import { Link } from "react-router-dom"
import { formatNumberCurrency } from "../../../utils/validationInput"


const CurrentBillList = ({ listDocuments, isLoading, isViewDetail, estadoText }) => {

	return (
		<div className="table-responsive table-retiro-responsive">
			<table className="table table-bordered table-sm bg-white table-retiro">
				<thead className="bg-warn-blue">
				    <tr>
				      	<th className="text-center"># Factura</th>
				      	<th className="text-center">Estado</th>
				
						  {estadoText==="Factura Pendiente" && (
							
							<th className="text-center">Fecha</th>
						)}
						{(isViewDetail && estadoText!=="Factura Pendiente") && (
							
							<th className="text-center">{estadoText==="Factura Abono" ? 'F. Abono' : 'F. Cancelación'}</th>
						)}
				      	<th className="text-center">C. interno</th>
				      	<th className="text-center">Cliente</th>
				      	<th className="text-center">RUT</th>
				      	
				      	{!isViewDetail && <th className="text-center">Cant. Órdenes</th>}
				      	<th className="text-center">Monto</th>
						{(estadoText==="Factura Abono" && listDocuments.length>0) && <th className="text-center">Monto Abono</th>} 
				      	{ isViewDetail && <th className="text-center"></th>}
				    </tr>
				 </thead>
				 <tbody>
				 {
				 	listDocuments.length>0 &&
				 	listDocuments.map((item, key) => 
				 		<tr key={key}>
				 			<td className="text-center">{item?.numero_folio}</td>
				 			<td className="text-center">{item?.estado}</td>
							 {estadoText==="Factura Pendiente" && <td className="text-center">{item?.fecha}</td>}
							 {(isViewDetail && estadoText!=="Factura Pendiente") && <td className="text-center">{item?.fecha_cancelacion}</td>}
				 			<td className="text-center">{item?.codigo_interno}</td>
				 			<td>{item?.cliente.nombre_completo}</td>
				 			<td className="text-center">{item?.cliente.rut}</td>
				 			{!isViewDetail && <td className="text-center">{item?.cantidad_ordenes}</td>}
				 			
				 			<td className="text-right">{formatNumberCurrency(item?.monto)}</td>	
							{item?.estado==="Factura Abono" && <td className="text-right">{formatNumberCurrency(item?.monto_abono)}</td>} 
				 			{isViewDetail && (
				 				<td className="text-center">
					 				<Link 
					 					to={{
										    pathname: `/facturacion/detalle/${item?.numero_folio}/pago`,
										    state: { data: item }
										 }}
					 				>Ver detalle</Link>
					 			</td>	
					 		)}		 			
				 		</tr>
				 	)
				 }
				 {(!isLoading && listDocuments.length===0) && <tr><td colSpan={isViewDetail ? 9 : 8}>No hay registros disponibles</td></tr>}
				 {(isLoading && listDocuments.length===0) && <tr><td colSpan={isViewDetail ? 9 : 8}>Buscando...</td></tr>}
				 </tbody>
			</table>
		</div>
	)
}

export default CurrentBillList