import React, { Fragment, useState, useEffect } from "react"
import {  
	Table,
	Button,
	Col,
	Row,
	Form
} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import TbodyOrden from "./TbodyOrden"
import { selectedOrdens, calcularTotalSelected, updatedSelectedOrdens, cleanListadoOrden } from "../../actions/orderAction"
import { searchNomina, isClearNomina, changeIsImpresion, updateTitleBtn } from "../../actions/nominaAction"
import { Link } from 'react-router-dom'
import { formatNumberCurrency, onPaste, validateOnlyNumber } from "../../utils/validationInput"

const TableOrden = ({ register, errors, getValues, setValue, toast, btnNomina, isSearch, setIsSearch }) => {

	const dispatch = useDispatch() 
	const [nameBtn, setNameBtn ] = useState("SELECCIONAR TODO")

	const { nominas, orders, users } = useSelector((state) => {
		return {
			nominas: state.nominas,
			orders: state.orders,
			users: state.users
		}
	})
	const { listNominas, isActiveNomina, dataNomina, typeImpresion } = nominas
	const { listOrden, totalSelectedOrden, title } = orders
	const { sucursal_id  } = users

	const [ errorsNominaNro, setErrorsNominaNro ] = useState({status: false, message: ""})
	

	const changeStatus = (event) => {
		event.preventDefault()
		let status 
		if(nameBtn==="SELECCIONAR TODO"){
			setNameBtn("DESELECCIONAR TODO")
			status = true
		}else{
			setNameBtn("SELECCIONAR TODO")
			status = false
		}
		dispatch(selectedOrdens(status))
		dispatch(calcularTotalSelected())
	}

	const disabledCheck = (event) => {
		const { value, checked } = event.target
		dispatch(updatedSelectedOrdens(value, checked))
		dispatch(calcularTotalSelected(true))

		if (document.querySelectorAll('.form-check-input:checked').length===listOrden.length){
			setNameBtn("DESELECCIONAR TODO")
		}else{
			setNameBtn("SELECCIONAR TODO")
		}

	}

	useEffect(() => {
		if(title===true){
			setNameBtn("SELECCIONAR TODO")
		}

		if(!isActiveNomina){
			setValue("nomina_id", "")
			setValue("numero_nomina", "")
		}

		if(dataNomina?.numero_nomina){
			setValue("nomina_id", dataNomina.numero_nomina)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title, isActiveNomina])


	const buscarNomina = async (event) => {
		event.preventDefault()
		setIsSearch(true)
		const title = btnNomina.title;
		dispatch(updateTitleBtn("", false))
		setErrorsNominaNro({status: false, message: false})
		dispatch(cleanListadoOrden())
		
		if(getValues("numero_nomina").length===0 && getValues("nomina_id").length===0){
			toast.error("Seleccione una nómina", {position: toast.POSITION.TOP_RIGHT})
			setIsSearch(false)
			return false
		}

		if(getValues("numero_nomina").length!==0 && getValues("nomina_id").length!==0){
			setErrorsNominaNro({status: true, message: "Solo un tipo de búsqueda"})
		}
		let numero_nomina
		if(getValues("numero_nomina").length!==0){
			numero_nomina = getValues("numero_nomina")
		}else if(getValues("nomina_id").length!==0){
			numero_nomina = getValues("nomina_id")
		}
		dispatch(isClearNomina())	
		const response = await dispatch(searchNomina(numero_nomina))

		if(response.status){
			document.getElementById("frmBusquedaGlobal").reset()
			document.getElementById("frmBusquedaOrden").reset()
		}else{
			toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
		}
		dispatch(updateTitleBtn(title, false))
		dispatch(cleanListadoOrden())
		dispatch(calcularTotalSelected())
		setIsSearch(false)
		document.getElementById("sucursal_origen_id").value= sucursal_id
	}

	const changeTypeImpresion = (event) => {
		if(event.target.checked){
			dispatch(changeIsImpresion("ticket"))
		}else{
			dispatch(changeIsImpresion("carta"))
		}
	}

	return (
		<Fragment>
			<Col xs={12} className="table-nomina">
		    	<Table responsive size="sm" bordered id="table-orden">
	  				<thead>
	  					<tr>
		  					{!isActiveNomina && !isSearch && <th style={{"width":"20px"}}>#</th>  }
		  					<th style={{"width":"90px"}}>Número Orden</th>
		  					<th style={{"width":"80px"}}>Fecha</th>
							<th style={{"width":"180px"}}>Cliente</th>
							<th style={{"width":"90px"}}>Of. Origen</th>
							<th style={{"width":"90px"}}>Of. Destino</th>
							<th style={{"width":"90px"}}>Origen</th>
							<th style={{"width":"90px"}}>Destino</th>
							<th style={{"width":"180px"}}>Destinatario</th>
							<th style={{"width":"180px"}}>Dirección</th>
							<th style={{"width":"60px"}}>Bultos</th>
							<th style={{"width":"60px"}}>Kilos</th>
							<th style={{"width":"60px"}}>Entrega</th>
							<th style={{"width":"120px"}}>F. Pago</th>
							<th style={{"width":"60px"}}>Valor</th>
						</tr>
	  				</thead>
	  				<tbody>
	  					{
	  						listOrden.length > 0 ? (
	  							listOrden.map((orden, index) => 
	  								<TbodyOrden
	  									key={index}
	  									indice={index}
	  									{...orden}
	  									disabledCheck={disabledCheck}
	  									register={register}
	  									isActiveNomina={isActiveNomina}
										isVisible={!isSearch}
	  								/>
	  							)
	  						) : (
	  							<tr>
			  						<td colSpan={15}>No hay registro disponibles</td>
			  					</tr>
	  						)
	  					}
	  					
	  				</tbody>
	  			</Table>
			</Col>
			{
				(listOrden.length > 0 && !isActiveNomina) && (
					!isSearch && (
					<Col xs={12} className="text-center mt-3">
						<Button type="submit" variant="warning" className="ml-2" onClick={(event)=>changeStatus(event)}>
						    {nameBtn}
						 </Button>
					</Col>
					)
				)
			}
			
			<Col xs={12}>
				<Row className="panel-contenedor--info pt-5">
					<Col sm={3} className="bg-warn-blue border-panel">
							<Form.Group as={Col} className="text-center">
						    	<Form.Label className="my-4">TOTAL SELECCIONADOS</Form.Label>
						    </Form.Group>
					</Col>
					<Col sm={9} className="bg-gray border-panel pt-2">
						<Form.Row>
						    <Col lg md={4} sm={6} xs={4}>
						     	<Form.Group as={Col} controlId="total_bultos" className="pr-0">
							    	<Form.Label>Bultos</Form.Label>
									<Form.Control 
								    	type="text" 
								    	name="total_bultos" 
								    	defaultValue={totalSelectedOrden.total_bultos || ""}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    <Col lg md={4} sm={6} xs={4}>
						      	<Form.Group as={Col} controlId="total_kilos" className="pr-1 pl-0">
							    	<Form.Label>Kilos</Form.Label>
									<Form.Control 
								    	type="text" 
								    	name="total_kilos" 
								    	defaultValue={formatNumberCurrency(totalSelectedOrden.total_kilos) || ""}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    <Col lg md={4} sm={6} xs={4}>
						     	<Form.Group as={Col} controlId="cantidad_orden_flete" className="pr-1 pl-0">
							    	<Form.Label>N° Ordenes</Form.Label>
							    	<Form.Control 
								    	type="text" 
								    	name="cantidad_orden_flete" 
								    	defaultValue={totalSelectedOrden.cantidad_orden || ""}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    <Col lg md={4} sm={6} xs={4}>
						     	<Form.Group as={Col} controlId="numero_nomina" className="pr-1 pl-0">
							    	<Form.Label>Buscar Nómina</Form.Label>
							    	<Form.Control 
								    	type="text" 
								    	name="numero_nomina"
								    	ref={register} 
										onKeyPress={(event) => validateOnlyNumber(event)}
										onPaste={(event) => onPaste(event)}
								    />
								    {errorsNominaNro.status && (
								    	<Form.Text className="text-danger">
								      		{errorsNominaNro?.message}
								    	</Form.Text>
						   			 )}
							    </Form.Group>
						    </Col>
						    <Col lg md={4} sm={6} xs={4}>
						     	<Form.Group as={Col} controlId="nomina_id" className="pr-1 pl-0">
							    	<Form.Label>Nómina</Form.Label>
							    	<Form.Control 
									    	as="select"
									    	name="nomina_id" 
									    	ref={register}
									    >
								    	<option value="">Seleccione</option>
								    	{listNominas.length > 0 && (
								    			listNominas.map(nomina => <option key={nomina.id} value={nomina.numero_nomina}>{nomina.numero_nomina}</option>)
								    		)
								    	}
								 	</Form.Control>
							    </Form.Group>
						    </Col>
						    <Col lg md={4} sm={6} xs={4}>
						     	<Form.Group as={Col}  className="mt-3 pl-0">
							    	<Button variant="warning" type="button" disabled={isSearch}  block onClick={(event)=>buscarNomina(event)}>
									     {isSearch ? 'Buscando...' : 'VER' } 
									</Button>
							    </Form.Group>
						    </Col>
						</Form.Row>
						{
							isActiveNomina && (
									<Form.Row>
									    <Col lg={10} md={10} sm={6} xs={4}>
									     	<Form.Group as={Col} controlId="imprimir_nomina" className="pr-4 pl-2 pt-3 text-md-right">
									     		<Form.Check 
									     			type="checkbox" 
											    	name="imprimir_nomina" 
											    	onChange = { (event) => changeTypeImpresion(event)}
											    	defaultChecked={typeImpresion==="ticket" ? true : false }
							             			ref={register}
											      	label="Imprimir nómina en impresora térmica"
											    />
										    </Form.Group>
									    </Col>
									    <Col lg={2} md={2} sm={6} xs={4}>
								    		<Link to={`/nominas/${dataNomina.numero_nomina}/ticket`}> 
									  			<Form.Group as={Col} className="pl-0">
													 <Button variant="warning" block>IMPRIMIR</Button> 
											    </Form.Group>
									  		</Link>
									    </Col>
									</Form.Row>
							)
						}
					</Col>
				</Row>
			</Col>
		</Fragment>
	)
}

export default TableOrden


