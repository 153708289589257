import {  
	Form,
	Col,
	Card,
	Row
} from 'react-bootstrap'
import { useDispatch } from "react-redux"
import { aplicarDescuentoRecargo, updateOrdenes } from '../../actions/guiaDespachoAction'
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form"


const TipoOperacion = ({ register, getValues, toast, setValue,control, valorDescuento, setValorDescuento }) => {
	const dispatch = useDispatch()

	const verificarTipoOperacion = (event) => {
		event.preventDefault()
		//setValue('valor_descuento_recarga', '')
		setValorDescuento("")
		setValue('recargo_global', 0)
		setValue('descuento_global', 0)
		dispatch(updateOrdenes())

		if(getValues('descuento_recargo').length===0){
			toast.error("Debe seleccionar el tipo de operación", {position: toast.POSITION.TOP_RIGHT})
			setValue('tipo_valor', '')
			return false
		}
		
	}

	const aplicarDsctoRecargo = (event) => {
		//setValue('valor_descuento_recarga', event.target.value.length>0 ? parseInt(event.target.value) : 0)
		if(getValues('descuento_recargo').length===0){
			toast.error("Debe seleccionar el tipo de operación", {position: toast.POSITION.TOP_RIGHT})
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			return false
		}
		if(getValues('tipo_valor').length===0){
			toast.error("Debe seleccionar el tipo de valor", {position: toast.POSITION.TOP_RIGHT})
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			return false
		}
		const valor = getValues("valor_descuento_recarga")  ??  0
		const result = dispatch(aplicarDescuentoRecargo(valor, getValues('tipo_valor'), getValues('descuento_recargo')))
		
		if(result.payload.totals<0){
			toast.error("El monto no debe ser mayor al subtotal de la guía", {position: toast.POSITION.TOP_RIGHT})
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			dispatch(aplicarDescuentoRecargo(0, getValues('tipo_valor'), getValues('descuento_recargo')))
			return false
		} 
	
		if(result.payload.recargo_global > result.payload.listado_totales?.subtotal){
			toast.error("El monto no debe ser mayor al subtotal de la guía", {position: toast.POSITION.TOP_RIGHT})
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			dispatch(aplicarDescuentoRecargo(0, getValues('tipo_valor'), getValues('descuento_recargo')))
			return false
		} 

		if(result.payload.descuento_global > result.payload.listado_totales?.subtotal){
			toast.error("El monto no debe ser mayor al subtotal de la guía", {position: toast.POSITION.TOP_RIGHT})
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			dispatch(aplicarDescuentoRecargo(0, getValues('tipo_valor'), getValues('descuento_recargo')))
			return false
		} 

	}

	const changeTipo = (event) => {
		setValue('tipo_valor', '')
		//setValue('valor_descuento_recarga', '')
		setValorDescuento("")

		setValue('recargo_global', 0)
		setValue('descuento_global', 0)
		dispatch(updateOrdenes())
		
	}


	return (
		<Card border="Light">
	    <Card.Header as="h6" className="bg-warn-blue text-dark">TIPO DE OPERACIONES</Card.Header>
	    <Card.Body className="bg-soft-blue pt-4 pb-5">
	     	<Row>
	     		<Col lg={12} md={12} sm={12}>
			      	<Form.Group controlId="descuento_recargo">
			      		<Form.Label>Descuentos / Recargos</Form.Label>
						<Form.Control 
							   as="select"
						    	name="descuento_recargo"
						    	ref={register} 
						    	onChange={(event)=>changeTipo(event)}
						    >
						    <option value=""></option>
					    	<option value="descuento">Descuento</option>
					    	<option value="recarga">Recarga</option>
					 	</Form.Control>
			    	</Form.Group>
			    </Col>
	     		<Col lg={6} md={12} sm={12}>
			      	<Form.Group controlId="tipo_valor">
				    	<Form.Label>Tipo valor</Form.Label>
					    <Form.Control 
							   as="select"
						    	name="tipo_valor"
						    	ref={register} 
						    	onChange={(event)=>verificarTipoOperacion(event)}
						    >
						    <option value=""></option>
					    	<option value="%">%</option>
					    	<option value="$">$</option>
					 	</Form.Control>
				    </Form.Group>
			    </Col>
			    <Col lg={6} md={12} sm={12}>
			      	<Form.Group controlId="valor_descuento_recarga">
				    	<Form.Label>Valor</Form.Label>
						<Controller
								control={control}
								name="valor_descuento_recarga"  
								render={(props) => (
									<NumericFormat
										allowNegative={false}
										className="form-control"
										decimalScale={2}
										name={props.name}
										id={props.name}
										fixedDecimalScale={false}
										isAllowed={({floatValue}) => {
											// Define your validation rules here
											return true;
										}}
										onPaste={(e) => e.preventDefault()}
										decimalSeparator={','}
										thousandSeparator={"."}
										value={valorDescuento}
										//defaultValue={ordenInfo.valor_declarado_carga}
										onValueChange={(values) => {
											props.onChange(values.floatValue);
											setValorDescuento(values.floatValue);
											aplicarDsctoRecargo(values.floatValue)
										}}
										maxLength={15}
									/>
								)}
								autoComplete="off"
							/>
				    	{/**<Form.Control 
					    	type="text" 
					    	name="valor_descuento_recarga"
					    	onChange={(event)=>aplicarDsctoRecargo(event)}
			                ref={register}
							autoComplete="off"
			                //onKeyPress={(event)=>valideKey(event)}
					    /> */}
				    </Form.Group>
			    </Col>
			</Row>
	    </Card.Body>
	  </Card>
		
	)
}

export default TipoOperacion