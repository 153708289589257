import { validarPermiso } from "../Permission/checkPermission"
import dayjs from 'dayjs';
const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON

const getSucursal= (tipo_oficina, value) => {
    const oficina = value?.comunas?.find(item => item?.tipo_oficina === tipo_oficina);

    if(oficina) return oficina;

    return "";
}

const getCliente= (tipo_cliente, value) => {
    const cliente = value?.clientes?.find(item => item?.tipo_cliente === tipo_cliente);

    if(cliente) {
        if(cliente.rut===RUT_MESON){
            cliente.nombre_completo=cliente?.name_cliente;
        }
        return cliente;
    }

    return "";
}



export const formatGetOrden = (ordenes, module, sucursalIdUser, configuracion) => {
    try{
        let data = ordenes;
        let message = "";
        let estatus = true;

        for(let key in ordenes){
            const value = ordenes[key];
            data[key]['fecha_orden_search'] = value?.fecha_orden_search
            data[key]['fecha_orden'] = dayjs(value?.fecha_orden_search).format('DD-MM-YYYY')
            data[key]['numero_orden'] = value?.numero_orden;
            data[key]['valor_declarado_carga'] = value?.valor_declarado_carga;
            data[key]['total_bultos'] = value?.total_bultos;
            data[key]['total_kilos'] = value?.total_kilos;

            const sucursal_origen = getSucursal("origen", value);
            if(sucursal_origen){
                data[key]['sucursal_origen'] = sucursal_origen?.sucursal?.nombre;
                data[key]['oficina_origen'] = sucursal_origen?.nombre;
                data[key]['sucursal_origen_id'] = sucursal_origen?.id;
            }

            const sucursal_destino = getSucursal("destino", value);
            if(sucursal_origen){
                data[key]['sucursal_destino'] = sucursal_destino?.sucursal?.nombre;
                data[key]['oficina_destino'] = sucursal_destino?.nombre;
            }

            const cliente_remitente = getCliente("remitente", value);
            if(cliente_remitente){
                data[key]['cliente_remitente'] = cliente_remitente?.nombre_completo;
                data[key]['cliente_rut_remitente'] = cliente_remitente?.rut;
            }

            const cliente_destinatario = getCliente("destinatario", value);
            if(cliente_destinatario){
                data[key]['cliente_destinatario'] = cliente_destinatario?.nombre_completo;
                data[key]['direccion_destinatario'] = cliente_destinatario?.direccion;
                data[key]['cliente_rut_destinatario'] = cliente_destinatario?.rut;
            }
          
            data[key]['tipo_envio'] = value?.tipo_envio ? value?.tipo_envio?.nombre : "";
            data[key]['forma_pago'] = value?.forma_pago ? value?.forma_pago?.descripcion : "";
            data[key]['monto_total'] = value?.total;
            data[key]['check'] = value?.check;
            data[key]['id'] = value?.id;

            const ultimoEstado = value?.ultimoEstado;
            const createdUserOrden = value?.createdUserOrden;

            data[key]['estado'] = ultimoEstado ? ultimoEstado?.descripcion : "";
            data[key]['estado_id'] = ultimoEstado?.estado_id;
            data[key]['usuario'] = createdUserOrden?.user_name;
            data[key]['is_active'] = ultimoEstado?.is_active;

            data[key]['numero_nomina'] = value?.numero_nomina;
            data[key]['camion_app_mobile'] = "";
            data[key]['usuario_entrego_app_mobile'] = "";
            data[key]['camion_app_mobile'] = value?.camion_app_mobile;
            data[key]['usuario_entrego_app_mobile'] = value?.usuario_entrego_app_mobile;
            

            let documento_pago = "";
            let nro_documento_pago="";
          
            if(typeof value?.entregaOrdenFlete !=="undefined" && value?.entregaOrdenFlete!==null){
                documento_pago =  value?.entregaOrdenFlete?.documentacion ? value?.entregaOrdenFlete?.documentacion?.descripcion : "";

                if(value?.entregaOrdenFlete?.documentacion && value?.entregaOrdenFlete?.documentacion?.descripcion==="Boleta-1"){
                    nro_documento_pago = "";
                }else{
                    nro_documento_pago = value?.entregaOrdenFlete?.nro_documento;
                }
            }

            data[key]['documento_pago'] = documento_pago;
            data[key]['nro_documento_pago'] = nro_documento_pago;
           
        }

        if(typeof module !=="undefined" && module==="nomina"){

            if(!validarPermiso(configuracion, "editar_orden_flete") && data?.length>0){
                if(data[0]['sucursal_origen_id']===sucursalIdUser && data[0]['estado_id']!==1){
                    data=[];
                    message="No tiene permiso de visualizar la orden";
                    estatus=false;
                }
            }
        }

        let destino_nomina="";

        if(data?.length>0){
            destino_nomina="";
            if(typeof data[0]["sucursal_destino"] !=="undefined" && data[0]["sucursal_destino"]!==""){
            destino_nomina=data[0]["sucursal_destino"];
            }

            message="Encontrado con éxito";
            estatus=true;
        }
        return { data, destino_nomina, estatus, message };
    }catch(error){
        return { data: [], destino_nomina: "", estatus: false, message: "Hubo un error al visualizar los resultados" };
    }
}