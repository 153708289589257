const Camion = { 
	columnas: [
		{
			name: 'Placa',
			selector: (row, i) => row.placa,
			sortable: true
		},
		{
			name: 'Número interno',
			selector: (row, i) => row.numero_interno,
			sortable: true
		},
		{
			name: 'Activo',
			selector: (row, i) => row.name_estatus,
			sortable: true
		}
	]
}

export default Camion