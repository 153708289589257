import { useEffect, useState } from "react"
import { addDataBasicFactura, listDataBasicFactura, getDataEditFactura } from "../../actions/basicFacturaAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'


export const useDataBasicFacturacion = () => {

	const dispatch = useDispatch()

	const { dataBasic, listDataBasicFacturacion } = useSelector((state) => state.dataBasicInvoice)

	const { register, handleSubmit, errors, reset } = useForm()
	const [ bloquearInput, setBloquearInput ] = useState(true)
	const [ loading, setLoading ] = useState(false)



	const onSubmit = async (data) => {
		try {
			setLoading(true)

			let response = await dispatch(addDataBasicFactura(data))
			setLoading(false)

			if(response.status){
				onReset()
				toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error("Hubo un error al actualizar los datos", {position: toast.POSITION.TOP_RIGHT})
			}
		}catch(error){
			setLoading(false)
	      	toast.error("Hubo un error, intente más tarde", {position: toast.POSITION.TOP_RIGHT})
	    }
	}

    const getDataInitial = async () => {
        await dispatch(listDataBasicFactura())
    }

	const saveDataEdit = (item) => {
		if(typeof item !=="undefined"){
			dispatch(getDataEditFactura(item))
			reset({...item})
			setBloquearInput(bloquearInput => !bloquearInput)
		}
    }

	const onReset = () => {
		setBloquearInput(bloquearInput => true)
		reset({
			rut_emisor: "",
			razon_social_emisor: "",
			giro_emisor: "",
			ciudad_emisor: "",
			comuna_emisor: "",
			direccion_emisor: "",
			usuario_soap: "",
			clave_soap: ""
		})

    }

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const state = {
		dataBasic, 
        listDataBasicFacturacion,
		loading,
		bloquearInput
	}


	const formElement = {
		register,
		handleSubmit,
		onSubmit,
		errors
	}

	const actions = {
		saveDataEdit,
		onReset
	}

	return {
		state,
		formElement,
		actions	
	}
}