import {  Modal } from 'react-bootstrap'


const AnularRetiro = ({ show, numero_documento, handleAnular, onSubmit, register, handleSubmit, errors, loading }) => {


	return (
		<Modal
			 size="lg"
	        show={show}
	        aria-labelledby="example-custom-modal-styling-title"
	        backdrop="static"
        	keyboard={false}
	      >
	        <Modal.Header>
	          <Modal.Title id="example-custom-modal-styling-title">
	            Anular retiro
	          </Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<form id="frmAnular" onSubmit={handleSubmit((data) =>onSubmit(data,numero_documento))}>
		        	<div className="row">
			            <div className="col-12">
							<div className="form-group">
							    <label>Motivo</label>
							    <textarea
							    	name="motivo_anular" 
							    	id="motivo_anular"
							    	rows={4} 
							    	className="form-control"
							    	ref={register({required: {value: true, message: 'Requerido'}})} 
							   />
							   {errors.motivo_anular && (
							    	<span className="text-danger">
							      		{errors?.motivo_anular?.message}
							    	</span>
							    )}
							  </div>
						</div>
						<div className="col-12 text-right pb-4">
							<button 
								type="submit" 
								className="pl-5 pr-5 mr-3 btn btn-warning"
								disabled={loading}
							>
					            {loading ? 'Anulando...' : 'Anular' }
					        </button>
					        <button type="button" className="pl-5 pr-5 btn btn-secondary" onClick={(event) => handleAnular(event)}>
					            Cerrar
					       	</button>
						</div>
		          	</div>
	          	</form>
	        </Modal.Body>
	      </Modal>
	)
}

export default AnularRetiro