const Oficina = { 
	columnas: [
		{
			name: 'Nombre',
			selector: (row, i) => row.nombre,
			sortable: true
		},
		{
			name: 'Activo',
			selector: (row, i) => row.name_estatus,
			sortable: true
		}
	]
}

export default Oficina