import API from "../config/api"
import {
	LISTADO_DATA_CORRELATIVO,
	IS_VISIBLE_FORMULARIO_CORRELATIVO,
	SELECTED_ROWS_CORRELATIVO,
	SEARCH_CORRELATIVO,
	DATA_ADD_CORRELATIVO,
	DATA_EDIT_CORRELATIVO,
	DATA_DELETE_CORRELATIVO
} from "../types/correlativoType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const listadosCorrelativo = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/correlativos")

			 const payload = {
                listCorrelativos: response.listado,
			 }
			 
			dispatch(requestSuccess(LISTADO_DATA_CORRELATIVO, payload))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const searchPorIdCorrelativo = (id) => (dispatch, getState) => {
	const {listCorrelativo} = getState().correlativo
	const correlativoSelected = listCorrelativo.find(ret => ret.id===id)
    const payload = {
		seletected: {...correlativoSelected},
		isForm: true,
		isEdit: true
	}

	if(payload){
	  	dispatch(requestSuccess(SEARCH_CORRELATIVO, payload))
	  	 
	}
}

export const isVisibleFormularioCorrelativo = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_CORRELATIVO, payload))
	  	 
	}
}

export const getSelectedRowsCorrelativo = (rows) => (dispatch, getState) => {
	dispatch(requestSuccess(SELECTED_ROWS_CORRELATIVO, rows))
}


export const addCorrelativo = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
            let response = await API.post("admin/correlativos", body)

            if(response.status){
                const dataCorrelativo = {
                    ...body, 
                    id: response.id,
					correlativo_inicial: body.correlativo_actual,
					updated_at: response.updated_at
                }
                const {listCorrelativo} = getState().correlativo
                const data = [...listCorrelativo, dataCorrelativo]
                const payload = { listado: data, isForm: false}

                dispatch(requestSuccess(DATA_ADD_CORRELATIVO, payload))
            }
            resolve({ status: response.status, message: response.message })
        }catch(error){
            resolve({status: false, message:error})
        }
	})
}

export const editCorrelativo = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listCorrelativo, corDatos} = getState().correlativo

		  	let response = await API.post(`admin/correlativos/${corDatos.id}`, body)

		    if(response.status){
				const listado = listCorrelativo.map(item => {
					
					if(item.id === corDatos.id){
						return response.data
					}
					return item
				})  
				const payload = { listado, isForm: false, datos: {} }	
		        dispatch(requestSuccess(DATA_EDIT_CORRELATIVO, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteCorrelativo = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listCorrelativo} = getState().correlativo

		  	let response = await API.get(`admin/correlativos/delete/${id}`)

		    if(response.status){
				
				const payload =listCorrelativo.filter(item => item.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_CORRELATIVO, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}