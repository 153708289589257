import {  
	Row,
	Button,
	Col
} from 'react-bootstrap'
import { useEffect, useState } from "react";
import Layout from "../../components/Layouts/Layout"
import CardRetiro from "../../components/Layouts/CardRetiro";
import TicketOrden from '../../components/Orders/PDF/TicketOrden';
import TicketCorrelativo from '../../components/Orders/PDF/TicketCorrelativo';
import loading_gif from "../../assets/images/loading.gif";
import { Link, useParams } from 'react-router-dom'
import { PDFViewer, Document } from "@react-pdf/renderer";
import { getDataTicketRetiroPreOrden } from '../../actions/retiroAction';
import {  useDispatch } from "react-redux"


const PreviewRetiro = (props) => {

	const [ data, setData ] = useState([])
	const [ loading, setLoading ] = useState(true)
    const dispatch = useDispatch()
    const { numero_retiro } = useParams()

    const  getDataRetiro = async () => {
		try{
            let response = await dispatch(getDataTicketRetiroPreOrden(numero_retiro))
            if(!response.status){
                setLoading(false)
            }
            setData(data => response?.data)
        }catch(error){
            setLoading(false)
            setData(data => [])
        }
	  }
  
	  useEffect(() => {
		  getDataRetiro()
		  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [numero_retiro])


	return (
		<Layout 
	    	titlePanel="Retiros"
	    	modulo="retiros"
	    >
			<Row>
				<Col xs={12}>
                    <CardRetiro title="RETIRO - TICKET DE PRE-ORDENES">
                        <section style={{minHeight: '100vh', width: '100%'}}>
                            <div className="col-12 pr-0">
                            {
                                data?.length>0 && (
                                    <h2>Su número de retiro es: <span className="text-orange">{numero_retiro}</span> </h2>
                                ) 
                            }
                            {
                                (data?.length===0 && !loading) && (
                                    <h2> El número de retiro no se encuentra registrado</h2>
                                )
                            }
                            </div>
                            <div className="col-12 text-right pr-0">
								<Link to={`/retiros/busqueda`}> 
									<Button variant="warning" type="button" className="btn btn-warning pl-5 pr-5 mb-4">
										Regresar
									</Button>
								</Link>	
                            </div>
                            {loading && 
                                    <div className="container-pdf">
                                        <img src={loading_gif} alt="Cargando..." />
                                        <p className="text-loading-pdf">{loading ? "Espere unos segundos, estamos generando los tickets del retiro..." : ""}</p>
                                    </div>
                            }					
                            {
                                data?.length>0 && (
                                    <div>
                                    <PDFViewer style={{ width: "100%", height: "90vh", border: 'none' }} className="pdf-ticket">
                                        <Document onRender={()=> {
                                            setLoading(false)
                                        }} >
                                            {
                                                data?.map((item, key) => {
                                                    if(!item?.isTicketCorrelativo) {
                                                        return (
                                                        <TicketOrden 
                                                        key={key} 
                                                        {...item} 
                                                        />)
                                                    }else{
                                                        return (
                                                            <TicketCorrelativo
                                                                key={key}
                                                                numero_correlativo={item?.numero_correlativo}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </Document>
                                    </PDFViewer>
                                </div>
                                )
                            }
                        </section>	
					</CardRetiro>
				</Col>
			</Row>
	    </Layout>
	)
}

export default PreviewRetiro