import { 
	RESET_PREVIEW_TICKET_RETIRO,
	SAVE_DESTINO,
	PREVIEW_TICKET_RETIRO,
	EDIT_LIST_DESTINO,
	INITIAL_RETIRO,
	LISTADO_ALL,
	SELECTED_ROWS,
	LOADING_DATA,
	FILTRADO_MOVIL,
	SELECT_DESTINO,
	SEARCH_RETIRO_BY_NRO,
	SELECTED_PREORDEN,
	RESET_LIST_ALL,
	UPDATE_DATA_RETIRO,
	UPDATE_CANCEL_LIST_RETIRO,
	LOADING_DETAILS_RETIRO,
	UPDATE_REMITENTE_RETIRO,
	FILTRADO_RETIRO
} from "../types/retiroType"

const INITIAL_STATE = {
	estadosAsignar: [],
	estadosRebajar: [],
	retirosAll: [],
	rowsNomina: [],
	isLoadingAsignar: false,
	isLoadingNomina: false,
	isLoadingConsulta: false,
	isLoadingDetRetiro: false,
	titleDetailRetiro: "Retiro Nro:",
	detRetiro: { 
		id: null,
	    numero_retiro: null,
	    fecha: "",
	    direccion_retiro: "",
	    comuna_name: "",
	    sucursal_name: "",
	    rut_remitente: "",
	    razon_social_remitente: "",
	    telefono_remitente: "",
	    check: false,
	    cant_preorden: 1,
	    camion_id: null,
	    ultimo_estado: "",
	    preorden: [],
	    estados: []
	},
	preordenSelected: {},
	isPreviewRetiro: false,
	nroRetiro: "",
	listDestino: [],
	infoDestino: {cargas: [], visible: false},
	selectedAll: false,
	dataRetiro: { },
	isVisbleEdit: false,
	isSelectedRows: {},
	isCargando: true,
	retiroOriginal: []	
}

const retiroReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case RESET_PREVIEW_TICKET_RETIRO:
			return {
				...state,
				isPreviewRetiro: action.payload.isPreviewRetiro
			}
		case SAVE_DESTINO:
			return {
				...state,
				listDestino: action.payload.listDestino,
				infoDestino: action.payload.infoDestino
			}
		case PREVIEW_TICKET_RETIRO:
			return {
				...state,
				isPreviewRetiro: action.payload.isPreviewRetiro,
				nroRetiro: action.payload.nroRetiro,
				listDestino: action.payload.listDestino,
			}
		case SELECT_DESTINO:
			return {
				...state,
				listDestino: action.payload.listDestino,
				infoDestino: action.payload.infoDestino
			}
		case EDIT_LIST_DESTINO:
			return {
				...state,
				listDestino: action.payload
			}
		case INITIAL_RETIRO:
			return {
				...state,
				estadosAsignar: action.payload.estadoSelected,
				estadosRebajar: action.payload.estadosRebajar
			}
		case LISTADO_ALL:
			return {
				...state,
				rowsNomina: action.payload.listRetiro,
				retirosAll: action.payload.listRetiro,
				isLoadingNomina: action.payload.isLoadingNomina,
				isLoadingAsignar: action.payload.isLoadingAsignar,
				isLoadingConsulta: action.payload.isLoadingConsulta,
				retiroOriginal:action.payload.listRetiro,
			}
		case FILTRADO_MOVIL:
			return {
				...state,
				rowsNomina: action.payload
			}
		case FILTRADO_RETIRO:
			return {
				...state,
				retirosAll: action.payload
			}
		case RESET_LIST_ALL:
			return {
				...state,
				isRloading: action.payload.loading,
				retirosAll: action.payload.list
			}
		case SEARCH_RETIRO_BY_NRO:
			return {
				...state,
				detRetiro: action.payload.detRetiro,
				isLoadingDetRetiro: action.payload.loading,
				titleDetailRetiro: action.payload.titleDetailRetiro
			}
		
		case LOADING_DATA:
			return {
				...state,
				isLoadingNomina: action.payload.isLoadingNomina,
				isLoadingAsignar: action.payload.isLoadingAsignar,
				isLoadingConsulta: action.payload.isLoadingConsulta,
				retirosAll: action.payload.listRetiro
			}
		case LOADING_DETAILS_RETIRO:
			return {
				...state,
				isLoadingDetRetiro: action.payload.loading
			}
		
		case UPDATE_REMITENTE_RETIRO:
			return {
				...state,
				detRetiro: action.payload.infoRetiro
			}

		
		case SELECTED_ROWS:
			return {
				...state,
				isSelectedRows: action.payload
			}

		case SELECTED_PREORDEN:
			return {
				...state,
				preordenSelected: action.payload.preordenSelected
			}
		
		case UPDATE_DATA_RETIRO:
			return {
				...state,
				detRetiro: action.payload.detRetiro
			}
		case UPDATE_CANCEL_LIST_RETIRO:
			return {
				...state,
				retirosAll: action.payload.list,
				detRetiro: action.payload.infoRetiro
			}
		default: return state
	}
}

export default retiroReducer