import React, { useState, forwardRef } from "react"
import {  
	Col,
	Row,
	Form,
	Table,
	InputGroup,
	Button,
	FormControl
} from 'react-bootstrap'
import TitlePrincipal from "../../../components/Layouts/TitlePrincipal"
import PanelDetalle from "../../../components/Layouts/PanelDetalle"
import TbodyDeposito from "../../../components/Rendicion/Depositos/TbodyDeposito"
import { NumericFormat } from "react-number-format";
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addDeposito, removeDeposito } from "../../../actions/rendirAction"
import { formatMonto } from "../../../config/formatInput"
import Swal from 'sweetalert2'
import { BiCalendar } from "react-icons/bi";
registerLocale('es', es)

const DetalleDeposito = ({ register, errors, toast, control }) => {

	const dispatch = useDispatch()
	const [startDate, setStartDate] = useState(new Date())
	const { 
		cajasListSucursal, 
		listadoTipoRendir, 
		listadoDepositos, 
		diferencia, 
		totalDeposito,
		disabledAdd,
		isPermisoEliminarDeposito,
		isCajaCerrada
	} = useSelector((state) => state.rendir)
	const [ totalMonto, setTotalMonto ] = useState("")

	const addDepositoLine = (event) => {
		event.preventDefault()

		const diferenciaTmp = document.getElementById("diferencia").value

		if(diferenciaTmp < 0){
			toast.error("El monto de diferencia es menor a 0, no se puede agregar depositos", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		const fecha_deposito = document.getElementById("fecha_deposito").value
		const cuenta_deposito = document.getElementById("cuenta_deposito")
		const tipo = document.getElementById("tipo")
		const monto = totalMonto

		if(fecha_deposito!=="" && cuenta_deposito.value!=="" && tipo.value!=="" && monto!==""){
		
			if(monto<=diferencia){
				const textoCuenta = cuenta_deposito.options[cuenta_deposito.selectedIndex].text
				const textoTipo = tipo.options[tipo.selectedIndex].text
				const data = {
					fecha: fecha_deposito,
					cuenta_deposito_id: cuenta_deposito.value,
					cuenta_deposito_text: textoCuenta,
					tipo_rendir_id: tipo.value,
					tipo_rendir_text: textoTipo,
					monto
				}
				dispatch(addDeposito(data))
				clearDeposito()
				toast.info("Los depositos serán registrados cuando cierre la caja", {position: toast.POSITION.TOP_RIGHT})
			}else{
				toast.error("El monto no puede ser mayor a la diferencia", {position: toast.POSITION.TOP_RIGHT})
				return false
			}			
		}else{
			toast.error("Falta algunos datos", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
		
	}

	const clearDeposito = () => {
		document.getElementById("cuenta_deposito").value = ""
		document.getElementById("tipo").value = ""
		setTotalMonto("")
	}


	const deleteRow = async (event, index) => {
		const depDelete = listadoDepositos.find((deposito, i) => i === index)
		let mensaje ='¿Desea eliminar el deposito de la lista?'
		if(depDelete.hasOwnProperty('deposito_id') && depDelete.hasOwnProperty('rendir_id')){
			mensaje ='La caja se va descuadrar. ¿Está seguro que desea eliminar el deposito de la lista?'
		}

		Swal.fire({
		  title: mensaje,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  	const response = await dispatch(removeDeposito(index))			    
		  	if(response.status){
        		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        	}else{
        		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        	}	
		  }
		})  
		
	}
	
	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				className="date_from"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));


	return (
		<>
			<TitlePrincipal title="DEPÓSITOS" />
			<Row className="bg-soft-blue">
				<Col md={8} sm={12} >
					<Table bordered size="sm" responsive className="table-global mt-4">
						  <thead className="bg-warn-blue">
						    <tr>
						      <th>Fecha</th>
						      <th>Cuenta</th>
						      <th>Tipo</th>
						      <th>Monto</th>
						      {isPermisoEliminarDeposito && (<th></th>) }
						    </tr>
						  </thead>
						  <tbody>
						  	{
						  		listadoDepositos?.length  > 0 ? (
						  			listadoDepositos?.map((deposito, index) => {
						  				return (
						  					<TbodyDeposito 
						  						key={index}
						  						index={index}
						  						{...deposito} 
						  						formatMonto={formatMonto}
						  						isPermisoEliminarDeposito={isPermisoEliminarDeposito}
						  						deleteRow={deleteRow}
						  					/>
						  				)
						  			})
						  		) : (
						  			<tr className="tr-vacia">
								      <td></td>
								      <td></td>
								      <td></td>
								      <td></td>
								      {isPermisoEliminarDeposito && (<td></td>)}
								    </tr>
						  		)
						  	}
						  </tbody>
					</Table>
				</Col>
				<Col md={4} sm={12} >
					<Form.Row className="pt-4">
						<Col xs={6}>
					     	<Form.Group as={Col} controlId="total_depositos"className="pr-1 pl-0">
						    	<Form.Label>Total Depositos</Form.Label>
						    	<Form.Control 
						    		type="text" 
						    		name="total_depositos" 
						    		value={formatMonto(totalDeposito) || ''} 
						    		ref={register}
						    		readOnly 
						    	/>
						    </Form.Group>
					    </Col>
					</Form.Row>
					<Form.Row>
						<Col xs={6}>
					     	<Form.Group as={Col} controlId="diferencia"className="pr-1 pl-0">
						    	<Form.Label>Diferencia</Form.Label>
						    	<Form.Control 
						    		type="text" 
						    		name="diferencia" 
						    		value={formatMonto(diferencia) || ''} 
						    		ref={register}
						    		readOnly 
						    	/>
						    </Form.Group>
					    </Col>
					</Form.Row>
				</Col>
			</Row>
			<Row className="bg-soft-blue">
				<Col md={10} sm={12} >
					<PanelDetalle title="Montos a rendir" subtitle="" check={false}>
						<Form.Row className="pt-3 bg-gray">
							<Col lg md={6} xs={4}>
						     	<Form.Group as={Col} controlId="fecha_deposito" className="pr-1">
							    	<Form.Label>Fecha deposito</Form.Label>
							    	<DatePicker 
											type="text" 
											dateFormat="dd/MM/yyyy"
											selected={startDate} 
											onChange={date => setStartDate(date)} 
											className="form-control"
											name="fecha_deposito" 
											id="fecha_deposito"
											locale="es"	
											customInput={<ExampleCustomInputFrom />}										
									/>
							    </Form.Group>
						    </Col>
							<Col lg md={6} xs={4}>
						     	<Form.Group as={Col} controlId="cuenta_deposito" className="pr-1 pl-0">
							    	<Form.Label>Cuenta Deposito</Form.Label>
							    	<Form.Control 
								    	as="select"
								    	name="cuenta_deposito" 
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		cajasListSucursal.length > 0 && (
							    			cajasListSucursal.map(deposito => <option key={deposito.id} value={deposito.id}>{`${deposito.nombre_banco} - ${deposito.numero_cuenta}`}</option>)
							    		)
							    	}
							 	</Form.Control>
							    </Form.Group>
						    </Col>
						    <Col lg md={6} xs={4}>
						     	<Form.Group as={Col} controlId="tipo" className="pr-1 pl-0">
							    	<Form.Label>Tipo</Form.Label>
							    	<Form.Control 
								    	as="select"
								    	name="tipo" 
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		listadoTipoRendir.length > 0 && (
							    			listadoTipoRendir.map(rendir => <option key={rendir.id} value={rendir.id}>{rendir.descripcion}</option>)
							    		)
							    	}
							 	</Form.Control>
							    </Form.Group>
						    </Col>
						    <Col lg md={6} xs={4}>
						    	<Form.Group as={Col} controlId="monto" className="pr-1 pl-0">
							    	<Form.Label>Monto</Form.Label>
								    <InputGroup >
									    <InputGroup.Prepend>
									      <InputGroup.Text>$</InputGroup.Text>
									    </InputGroup.Prepend>
										<Controller
											control={control}
											name="monto"
											render={(props) => (
												<NumericFormat
													allowNegative={false}
													className="form-control"
													decimalScale={2}
													name={props.name}
													id={props.name}
													fixedDecimalScale={false}
													isAllowed={({floatValue}) => {
														// Define your validation rules here
														return true;
													}}
													onPaste={(e) => e.preventDefault()}
													decimalSeparator={','}
													thousandSeparator={"."}
													value={totalMonto}
													//defaultValue={ordenInfo.valor_declarado_carga}
													onValueChange={(values) => {
														props.onChange(values.floatValue);
														setTotalMonto(values.floatValue);
													}}
													maxLength={15}
												/>
											)}
											autoComplete="off"
										/>
									    {/**<Form.Control 
									    	type="text" 
									    	name="monto" 
									    /> */}
									  </InputGroup>
							    </Form.Group>
						    </Col>
						    {isCajaCerrada===1 && (
						    	<Col lg md={2} sm={12}>
									<Form.Row className="pt-2">
										<Form.Group as={Col} className="mt-3 pl-0">
									    	<Button 
									    		variant="warning" 
									    		type="button" 
									    		className="pl-4 pr-4" 
									    		onClick={(event) => addDepositoLine(event)}
									    		disabled={disabledAdd}
									    	>
											    AGREGAR
											</Button>
									    </Form.Group>
								    </Form.Row>
								</Col>
						    )}
						</Form.Row>
					</PanelDetalle>
				</Col>
				
			</Row>
		</>
	)
}

export default DetalleDeposito