import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Forms from "../../components/Login/Forms";

const Login = () => {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      localStorage.clear();
    }
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={7} sm={12} className="col-login--image order-md-0 order-1">
          <Image src={logo} fluid className="img-login mb-5" />
          <h1 className="text-white">Seguridad y Confianza</h1>
        </Col>
        <Col
          md={5}
          sm={12}
          className="col-login-form my-auto order-md-1 order-0"
        >
          <h2>Inicia sesión</h2>
          <p>
            <q>
              El éxito en los negocios se logra no solo con visión y talento,
              sino también con determinación y perseverancia.
            </q>{" "}
            Colin Powell.
          </p>
          <Forms />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
