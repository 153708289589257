import React from "react"
import {  
	Col,
	Row,
	Table
} from 'react-bootstrap'
import TitlePrincipal from "../../../components/Layouts/TitlePrincipal"
import { formatMonto } from "../../../config/formatInput"

const DetalleEdicion = ({edicionManual}) => {

	return (
		<>
			<TitlePrincipal title="ORDENES EDITADAS" />
			<Row className="bg-soft-blue">
				<Col md={8} sm={12} >
					<Table bordered size="sm" responsive className="table-global mt-4">
						  <thead className="bg-warn-blue">
						    <tr>
						      <th>Número Orden</th>
						      <th>Fecha Actualizado</th>
						      <th>Monto</th>
						      <th>Monto Anterior</th>
						    </tr>
						  </thead>
						  <tbody>
						  	{
						  		edicionManual?.length  > 0 ? (
						  			edicionManual?.map((manual, index) => {
						  				return (
						  					<tr key={index}>
										      <td>{manual.numero_orden}</td>
										      <td>{manual.fecha}</td>
										      <td>{formatMonto(manual.monto_nuevo)}</td>
										      <td>{formatMonto(manual.monto_anterior)}</td>
										    </tr>
						  				)
						  			})
						  		) : (
						  			<tr className="tr-vacia">
								      <td></td>
								      <td></td>
								      <td></td>
								      <td></td>
								    </tr>
						  		)
						  	}
						  </tbody>
					</Table>
				</Col>

			</Row>
		</>
	)
}

export default DetalleEdicion