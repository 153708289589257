import {  
	Card
} from 'react-bootstrap'


const CardRetiro = ({title, classBody="", children}) => {

	return (
	    <>
			<Card className="mb-5 mt-3 card-retiro shadow-sm">
			  {title && <Card.Header as="h6" className="card-header-white">{title}</Card.Header>}
			  <Card.Body className={classBody}>
			    {children}		    
			  </Card.Body>
			</Card>

	    </>
	)
}

export default CardRetiro