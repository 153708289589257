import { generateDoc, assignOrdenCaja } from "../../actions/orderAction"
import { useDispatch, useSelector } from "react-redux"
import Swal from 'sweetalert2'

export const useDocumentBoleta = (toast, loadingPago, setLoadingPago) => {

	const dispatch = useDispatch()
	const { dataOrders } = useSelector((state) => state.orders)

	const generateDocumento = async (e, data, setValue) => {
		e.preventDefault()

		Swal.fire({
			  title: '¿Seguro desea generar el documento de pago?',
			  icon: 'question',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Sí',
			  cancelButtonText: 'No'
			}).then(async (result) => {
			  if (result.isConfirmed) {
			  		setLoadingPago(true)
			  		const body = {
						numero_orden: data?.numero_orden,
						documentacion_id: parseInt(data?.documentacion_id_pago),
						rut_remitente: data?.rut_remitente,
						factura: {}
					}
					let response = await dispatch(generateDoc(body)) 
					if(response.status) {
						setValue("numero_documento", response.numero_documento)
						const dataAssign = {
			                fecha_entrega: dataOrders.fecha_entrega_orden,
			                ordens: [dataOrders.ordenFleteId]
			            }
			            await dispatch(assignOrdenCaja(dataAssign))
						
						toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
					}else{
						toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
					}
					setLoadingPago(false)
			  }
			})
	}

	return {
		generateDocumento
	}
}