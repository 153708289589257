import React, { Fragment, useEffect, useState  } from "react"
import {  
	Form,
	Col,
	Button,
	Table,
	OverlayTrigger, 
	Tooltip
} from 'react-bootstrap'
import BodyCarga from "./BodyCarga"

import { useDispatch } from "react-redux"
import { saveDetalleCarga, resultTotales, deleteDetalleCarga } from "../../../actions/orderAction"
import { NumericFormat } from "react-number-format";
import { onPaste, validateOnlyLetterNumber, validateOnlyNumber } from "../../../utils/validationInput";
import Swal from 'sweetalert2'


const TablaCarga = ({ register, errors, getValues, ordenInfo, toast, disabledBtnCrear, validateForm, visibleCarga, setValue }) => {

	const dispatch = useDispatch()

	const [ totalPagar, setTotalPagar ] = useState("")
	const [ nroKilo, setNroKilo ] = useState("");
	const [ nroLargo, setNroLargo] = useState("");
	const [ nroAncho, setNroAncho ] = useState("");
	const [ nroAlto, setNroAlto ] = useState("");
	const [ nroM3, setNroM3 ] = useState("");
	const [ cargasList, setCargaList ] = useState([]);

	const saveCarga = (event) => {
	
		const listCarga = ordenInfo?.cargas==="" ? [] : ordenInfo?.cargas
		const findVisible = listCarga?.findIndex(item  => item.visible);

		if(findVisible!==-1){
			toast.error(`La carga ${findVisible+1} esta abierta para editar, debe guardar el cambio para continuar`, {position: toast.POSITION.TOP_RIGHT})
			return false
		}
		const nro_bultos= document.getElementById("nro_bultos").value
		const nro_kilos = nroKilo//document.getElementById("nro_kilos").value
		const nros_m3 = nroM3//document.getElementById("nro_m3").value
		const detalle_carga = document.getElementById("detalle_carga").value
		const total_cargas = validateForm ? totalPagar : 0
		const alto = nroAlto//document.getElementById("alto").value
		const ancho = nroAncho//document.getElementById("ancho").value
		const largo = nroLargo//document.getElementById("largo").value
		
		if(nro_bultos!=="" && detalle_carga!==""){
			if(validateForm){
				if(total_cargas===""){
					toast.error("Debe ingresar el total de la carga", {position: toast.POSITION.TOP_RIGHT})
					return false
				}	
				if(nros_m3===""){
					toast.error("Debe ingresar el total metros cubicos", {position: toast.POSITION.TOP_RIGHT})
					return false
				}	
				if(nro_kilos===""){
					toast.error("Debe ingresar el peso", {position: toast.POSITION.TOP_RIGHT})
					return false
				}					
			}
			if(parseInt(nro_bultos)===0 && parseInt(nro_kilos)===0 && parseInt(nros_m3)===0){
				toast.error("Mínimo 1 bulto", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			const nro_bulto = parseFloat(nro_bultos)
			const nro_kilo = nro_kilos!=="" ? nro_kilos : 0
			const nro_m3 = nros_m3!=="" ? parseFloat(nros_m3) : 0
			const contenido = detalle_carga
			const valor_neto = parseFloat(total_cargas)
			const carga = {
				id: "",
				bulto: nro_bulto,
				peso: nro_kilo,
				metraje_cubico: nro_m3,
				contenido,
				valor_neto,
				largo,
				ancho,
				alto
			}
	
			dispatch(saveDetalleCarga(carga, validateForm))
			dispatch(resultTotales(getValues("descuento")))
			cleanInput()
		}else{
			toast.error("Los datos de la carga están incompletos", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const validateBulto = (event) => {
	
		if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
			toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
			document.getElementById("nro_bultos").value = ""
			return false
		}
	}

	const cleanInput = () => {
		document.getElementById("nro_bultos").value = ""
		document.getElementById("nro_m3").value = ""
		document.getElementById("detalle_carga").value = ""
		setTotalPagar("")
		setNroKilo("")
		setNroLargo("")
		setNroAncho("")
		setNroAlto("");
		setNroM3("");
	}

	const calculateMCubico = () => {
		let total = 0
		if(nroAlto!==""){
			total = nroAlto
		}
		if(nroAncho!==""){
			total = total * nroAncho
		}
		if(nroLargo!==""){
			total = total * nroLargo
		}


		///document.getElementById("nro_m3").value = formatNumberCurrency(total)
		setNroM3(total);
	}

	const deleteRow = (index, descuento) => {
		Swal.fire({
		  title: `¿Seguro desea eliminar el detalle de la carga?`,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  	dispatch(deleteDetalleCarga(index))
			dispatch(resultTotales(descuento))			    
		  }
		})
	}

	useEffect(() => {
	  setCargaList(ordenInfo?.cargas);
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ordenInfo?.cargas])

	useEffect(() => {
		setTotalPagar("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [validateForm])
	

	return (
		<Fragment>
			<Form.Row >
				<Col >
					<Form.Group as={Col} className="mb-1">
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th className="text-center">Bultos</th>
							      <th className="text-center">Kilos</th>
								  <th className="text-center">Largo</th>
								  <th className="text-center">Ancho</th>
								  <th className="text-center">Alto</th>
							      <th className="text-center">M3</th>					 								  
							      <th className="detalle-carga-th">Detalle de carga</th>
							      <th>Total</th>
							      {visibleCarga && <th className="detalle-opciones"></th>}
							    </tr>
							  </thead>
							  <tbody>
							  	{
							  		cargasList?.length  > 0 ? (
										cargasList?.map((carga, index) => {
							  				return (
							  					<BodyCarga 
							  						key={index}
							  						index={index}
							  						{...carga} 
							  						register={register}
							  						descuento={getValues("descuento")}
							  						getValues={getValues}
													visibleCarga={visibleCarga}
													setValue={setValue}
													deleteRow={deleteRow}
													validateForm={validateForm}
							  					/>
							  				)
							  			})
							  		) : (
							  			<tr className="tr-vacia">
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
											  <td></td>
							  				<td></td>
							  				<td></td>
							  			</tr>
							  		)
							  	}
							  </tbody>
						</Table>
						{errors.cargas && (
					    	<Form.Text className="text-danger">
					      		{errors?.cargas?.message}
					    	</Form.Text>
					    )}
					</Form.Group>
	
			     	<Form.Group as={Col} className="mb-0">
				    	<Form.Label>Ingresar datos <small className="text-muted">(*Los valores de Alto, ancho, largo deben ingresarse en metros)</small></Form.Label>
				    </Form.Group>
		
				</Col>
			</Form.Row>				
			<Form.Row>
		     	<Col md sm={3}>
			      	<Form.Group as={Col} controlId="nro_bultos" className="pr-0">
					    <Form.Control 
			                type="text" 
					    	name="nro_bultos"  
					    	placeholder="N° bultos"
			                className="form-control" 
							maxLength="2"
							onKeyPress={(event) => validateOnlyNumber(event)}
							onBlur={(event) => validateBulto(event)}
							onPaste={(event) => onPaste(event)}
			              />
			    	</Form.Group>
			    </Col>
			    <Col md sm={3}>
			     	<Form.Group as={Col} controlId="nro_kilos" className="pr-1 pl-0">
					 	<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							placeholder="kilos"
							name="nro_kilos"
							id="nro_kilos"
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							value={nroKilo}
							onValueChange={(values) => setNroKilo(values.floatValue)}
							maxLength={15}
						/>
				    </Form.Group>
			    </Col>
				<Col md sm={3}>
			     	<Form.Group as={Col} controlId="largo" className="pr-1 pl-0">
					 <OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							placeholder="Largo"
							name="largo"
							id="largo"
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							value={nroLargo}
							onValueChange={(values) => setNroLargo(values.floatValue)}
							onBlur={() => calculateMCubico()}
							maxLength={15}
						/>
						</OverlayTrigger>
				    </Form.Group>
			    </Col>
				<Col md sm={3}>
			     	<Form.Group as={Col} controlId="ancho" className="pr-1 pl-0">
					 	<OverlayTrigger overlay={<Tooltip id="tooltip-ancho">m</Tooltip>}>
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder="Ancho"
								name="ancho"
								id="ancho"
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								value={nroAncho}
								onValueChange={(values) => setNroAncho(values.floatValue)}
								onBlur={() => calculateMCubico()}
								maxLength={15}
							/>
						</OverlayTrigger>
				    </Form.Group>
			    </Col>
				<Col md sm={3}>
			     	<Form.Group as={Col} controlId="alto" className="pr-1 pl-0">
					 	<OverlayTrigger overlay={<Tooltip id="tooltip-alto">m</Tooltip>}>
						 	<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder="Alto"
								name="alto"
								id="alto"
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								value={nroAlto}
								onValueChange={(values) => setNroAlto(values.floatValue)}
								onBlur={() => calculateMCubico()}
								maxLength={15}
							/>
						</OverlayTrigger>
				    </Form.Group>
			    </Col>			
				<Col md sm={3}>
			     	<Form.Group as={Col} controlId="nro_m3" className="pr-1 pl-0">
					 	<NumericFormat
							displayType="text"
							allowNegative={false}
							className="form-control text-padding"
							decimalScale={2}
							placeholder="M3"
							name="nro_m3"
							id="nro_m3"
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							thousandSeparator={"."}
							value={nroM3}
							readOnly
							onValueChange={(values) => setNroM3(values.floatValue)}
							maxLength={15}
						/>
				    </Form.Group>
			    </Col>
			     <Col sm={4} xs={12}>
			     	<Form.Group as={Col} controlId="detalle_carga" className="pr-1 pl-0">
				    	<Form.Control 
					    	type="text" 
					    	name="detalle_carga" 
					    	placeholder="Detalle de carga"
							autoComplete="off"
							onKeyPress={(event) => validateOnlyLetterNumber(event)}
							onPaste={(event) => onPaste(event)}
					    />
				    </Form.Group>
			    </Col>
			    <Col md sm={3}>
			     	<Form.Group as={Col} controlId="total_carga" className="pl-0">
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							name="total_carga"
							id="total_carga"
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							thousandSeparator={"."}
							value={totalPagar}
							onValueChange={(values) => setTotalPagar(values.floatValue)}
							maxLength={15}
							readOnly={!validateForm}
						/>
				    </Form.Group>
			    </Col>
			    <Col md sm={3}>
			     	<Form.Group as={Col} controlId="btnSaveCarga" className="pl-0">
				    	<Button variant="warning" type="button" disabled={disabledBtnCrear} block onClick={(e)=>saveCarga(e)} size="sm">
						    Ok
						</Button>
				    </Form.Group>
			    </Col>
			</Form.Row>
		</Fragment>

	)
}

export default TablaCarga