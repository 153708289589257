import React from "react"
import {  
	Button
} from 'react-bootstrap'

import { formatMonto } from "../../config/formatInput"


const Tbody = ({ index, numero_orden, descripcion, unitario, cantidad, 
	monto_descuento, tipo_descuento, total, exento, deleteRow
}) => {

	return (
		<tr key={index}>
	      <td>
            {numero_orden}
		  </td>
	      <td>
	      	{descripcion}
	      </td>
	      <td>
	      	{cantidad}
	      </td>
	       <td>
	      	{formatMonto(unitario)}
	      </td>
	      <td>
	      	{formatMonto(monto_descuento)}
	      </td>
	      <td>
	      	{tipo_descuento==="" ? "Ninguno" : tipo_descuento}
	      </td>
	      <td>
	      	{exento ? "Si" : "No"}
	      </td>
	      <td>
	      	{formatMonto(total)}
	      </td>
	      <td>
	      	<Button variant="danger" type="button" size="sm" onClick={()=>deleteRow(numero_orden)}>
			    X
			</Button>
	      </td>
	    </tr>

	)
}

export default Tbody
