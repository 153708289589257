import { useForm } from "react-hook-form"
import { useState } from "react"
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { cancelRetiro, cancelPreOrdenRetiro } from '../../actions/retiroAction'
import { useDispatch } from "react-redux"

export const useCancelRetiro = () => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors } = useForm({
		defaultValues: {descripcion: ""}
	})
	const [anularModal, setModalModal ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ numeroSelect, setNumeroSelect ] = useState({ type: "", numero: ""})

	const onSubmit = async (data, numero_documento) => {		
		setLoading(true)
		let title = numeroSelect?.type==="retiro" ? "el retiro" : "la pre orden"
		Swal.fire({
		  title: `¿Está seguro que desea anular ${title}?`,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  		const body = {
					numero_documento: numeroSelect?.numero,
					descripcion: data.motivo_anular
				}

				let response = {}

				if(numeroSelect?.type==="retiro"){
					response = await dispatch(cancelRetiro(body))
				}else{
					response = await dispatch(cancelPreOrdenRetiro(body))
				}

				setLoading(false)
				if(response.status){
					setModalModal(false)
					toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
				}else{
					toast.error("Hubo un error al anular el retiro", {position: toast.POSITION.TOP_RIGHT})
				}
		  }else{
		  	setLoading(false)
		  }
		})
		
	}

	const handleAnular = (event, option) => {
		event.preventDefault()
		if(typeof option!=="undefined"){
			setNumeroSelect(numeroSelect => option)
		}
		setModalModal(!anularModal)
	}



	return {
		onSubmit,
		register, 
		handleSubmit, 
		errors,
		anularModal,
		handleAnular,
		loading
	}
}