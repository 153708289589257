import {  
	Modal,
	Button,
	Form
} from 'react-bootstrap'

import DetalleCarga from "./DetalleCarga"
import FormTotales from "./FormTotales"
import { toast } from 'react-toastify' 

import { useOrdenIncomplete } from '../../../hooks/Order/useOrdenIncomplete'

const ModalEditPrecio = ({ completedModal, openCloseModalCompleted, dataOrden, 
	setCompletedModal, documentacionsCarga, forma_pago, isAdmin }) => {

	const { onSubmit, register, handleSubmit, loading, handleChangeTotal, 
			applyDescuento, setValue, getValues, control, errors, handleTipoDoc,
			statusValidate }= useOrdenIncomplete(toast, setCompletedModal)

	return (
		<Modal
			 size="lg"
	        show={completedModal}
	        onHide={() => openCloseModalCompleted(false)}
	        dialogClassName="modal-90w"
	        aria-labelledby="example-custom-modal-styling-title"
	      >
	        <Modal.Header closeButton>
	          <Modal.Title id="example-custom-modal-styling-title">
	            Orden de Flete - { dataOrden.numero_orden }
	          </Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<form 
	        		id="frmOrden" 
	        		onSubmit={handleSubmit(data => onSubmit(data, dataOrden?.numero_orden))}	
	        	>
		        	
					<Form.Group controlId="observacion">
						<Form.Label>Observación</Form.Label>
						<Form.Control 
							type="text" 
							name="observacion" 
							defaultValue={dataOrden?.comentarios || ""}
							readOnly={!isAdmin}
							ref={register}
						/>
					</Form.Group>
					
		        	<DetalleCarga 
		        		orden={dataOrden} 
		        		register={register} 
		        		documentacionsCarga={documentacionsCarga} 
		        		handleChangeTotal={handleChangeTotal}
		        		forma_pago={forma_pago}
		        		setValue={setValue}
		        		isAdmin={isAdmin}
						getValues={getValues}
						control={control}
						errors={errors}
						handleTipoDoc={handleTipoDoc}
						statusValidate={statusValidate}
		        	/>
		          	<FormTotales register={register} applyDescuento={applyDescuento} {...dataOrden} control={control} />
		          	<div className="modal-preorden">
                            <button 
			          			type="submit" 
			          			className="btn btn-warning btn-w-95"
			          			disabled={loading}
			          		>
			          			{loading ? "Actualizando..." : "Actualizar" }
			          		</button>
		          		<Button className="btn btn-secondary ml-2 btn-w-95" onClick={()=>openCloseModalCompleted(false)}>Cerrar</Button>
		          	</div>
	          	</form>
	        </Modal.Body>
	      </Modal>
	)
}

export default ModalEditPrecio