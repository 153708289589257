import React, { useEffect, useState } from "react"
import {  
	Form,
	Button,
	Col
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import Sucursales from "../../components/Orders/Sucursales"
import Clientes from "../../components/Orders/Clientes/Principal"
import DetalleCarga from "../../components/Orders/Carga/DetalleCarga"
import Observaciones from "../../components/Orders/Observaciones"
import Pagos from "../../components/Orders/Pagos"

import { ToastContainer, toast } from 'react-toastify'
import ModalFactura from '../../components/Factura/ModalFactura'
import { useCreateOrden } from '../../hooks/Order/useCreateOrden'

const CreatedOrder = () => {
	
	const { actions, formElement, state } = useCreateOrden()

	const { validateForm, isLoading, infoData, hidden, isVisible, dataOrders, initialOrden, disabledBtnCrear, 
		statusValidate, visibleCarga,bloquear, setBloquear  } = state
	const { register, handleSubmit, errors, reset, getValues, setValue, control } = formElement
	const { handleblockPaidInput, setLoading, onSubmit, handleTipoDoc,
		setHidden, addOrdenAction, getDatInitial, setOpenForm } = actions

	const [ loadingPago, setLoadingPago ] = useState(false)
	
	useEffect(() => {
		getDatInitial()
		register({ name: "cargas" })

		 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialOrden]) 

	return (
	    <Layout 
	    	titlePanel="Orden de flete manual"
	    	isIcon={false}
	    	ruta="home"
	    	modulo="orders_create"
	    >
	    	<Form id="frmOrders" onSubmit={handleSubmit(onSubmit)}>
	    		<Sucursales 
					register={register} 
					errors={errors} 
					reset={reset} 
					initial={false} 
					ordenInfo={dataOrders} 
					getValues={getValues} 
					control={control} 
					editOrden={false}
					setValue={setValue}
				/>
	    		<Clientes 
					register={register} 
					errors={errors} 
					setValue={setValue} 
					ordenInfo={dataOrders} 
					disabledBtnCrear={disabledBtnCrear} 
					toast={toast} 
					control={control}
					getValues={getValues}
				/>
	    		<DetalleCarga 
					register={register} 
					errors={errors} 
					getValues={getValues} 
					ordenInfo={dataOrders} 
					toast={toast}  
					handleblockPaidInput={handleblockPaidInput} 
					validateForm={validateForm}
					handleTipoDoc={handleTipoDoc}
					statusValidate={statusValidate} 
					addOrden={true}
					visibleCarga={visibleCarga}
					setValue={setValue}
					control={control}
				/>
	    		<Pagos 
	    			register={register} 
	    			errors={errors} 
	    			setValue={setValue} 
	    			ordenInfo={dataOrders} 
	    			getValues={getValues} 
	    			toast={toast} 
	    			setOpenForm={setOpenForm}
	    			loadingPago={loadingPago}
	    			setLoadingPago={setLoadingPago}
					validateForm={validateForm}
					bloquear={bloquear} 
					setBloquear={setBloquear} 
	    		/>
	    		<Observaciones register={register} errors={errors} ordenInfo={dataOrders} />
	    		<Form.Row className="mb-5 ml-2 mr-2 mt-3">
					<Col sm={12} className="d-group-container">
						<div className="d-group-button">
							<Button 
								variant="outline-warning" 
								className="btn-orden mr-2" 
								type="button" 
								block 
								disabled={true}>
								ANULAR ORDEN
							</Button> 
							<Button 
								variant="outline-warning" 
								className="btn-orden mt-0" 
								type="button" 
								block 
								disabled={true}
							> 
								IMPRIMIR ORDEN
							</Button>
						</div>
				  		<Button 
				  			className="btn-size"
				  			variant="warning" 
				  			type="submit" 
				  			disabled={isLoading}
				  		>
				    		{isLoading ? 'Procesando...' : 'GUARDAR' }
				  		</Button>
				  	</Col>
	    		</Form.Row>
		    	<ToastContainer />
	    	</Form>

	    	
	    	{hidden && 
					<ModalFactura 
						setHidden={setHidden} 
						hidden={hidden} 
						data={infoData} 
						funcFactura={addOrdenAction} 
						title={"FACTURA A NOMBRE DE:"}  
						isVisibleFactura={isVisible}
						nameModulo="orden"
						setLoading={setLoading}
						isLoading={isLoading}
					/> 
			}

       </Layout>
	)
}

export default CreatedOrder