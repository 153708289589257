import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'
import Permisos from "./Permisos"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addRole, editRole } from "../../../actions/roleAction"
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"

const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	const { rIsEdit, listPermisos } = useSelector((state) => state.roles)
    
    const onSubmit = async (data, e) => {
		setIsLoading(true)

		const cantPermiso = data.permisos.filter(permiso =>permiso!==false)
		if(cantPermiso.length > 0){
			let response
			const body = {
				nombre_rol: data.nombre_rol,
				permisos: cantPermiso
			}

			if(rIsEdit){
				response = await dispatch(editRole(body))
			}else{
				response = await dispatch(addRole(body))
			}
	        
	        if(response.status){
	            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
	        }else{
	            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
	        }
		}else{
			toast.error("Debe asignar los permisos", {position: toast.POSITION.TOP_RIGHT})
		}
		
        setIsLoading(false)
    }


	return (
            <Form id="frmCliente" onSubmit={handleSubmit(onSubmit)}>
            	<Card border="Light">
				    <Card.Header as="h6" className="bg-warn-blue text-dark">{!rIsEdit ? 'CREAR ROL' : 'EDITAR ROL' }</Card.Header>
				    <Card.Body className="bg-soft-blue">
				     	<Row>
				     		<Col lg={12} md={12} sm={12}>
						      	<Form.Group controlId="nombre_rol" >
						      		<Form.Label>Nombre</Form.Label>
						      		<Form.Control 
									    	type="text" 
									    	name="nombre_rol" 
							                autoFocus
							                defaultValue={datos?.nombre_rol || ""}
							             	ref={register({required: {value: true, message: 'Requerido'}})}
											 onKeyPress={(event) => validateOnlyLetter(event)}
											 onPaste={(event) => onPaste(event)}
									    />
									{errors.nombre_rol && (
								    	<Form.Text className="text-danger">
								      		{errors?.nombre_rol?.message}
								    	</Form.Text>
								    )}
						    	</Form.Group>
						    </Col>
						    <Col lg={12} md={12} sm={12}>
						      	<Form.Group controlId="permisos" >
						      		<Form.Label>Permisos</Form.Label>
						      		<Form.Text className="text-muted pb-3 pt-3">
								      Selecciona los permisos para cada ROL.
								    </Form.Text>
						      		<Row className="pl-4 pr-4">
							      		{Object.keys(listPermisos).length>0 && (
							      			Object.entries(listPermisos).map((permiso, key) => (
							      				<Permisos 
								      				key={key} 
								      				clave={key} 
								      				listado={permiso[1]} 
								      				register={register} 
								      				rol={datos} 
								      			/>
							      			)
							      		)							      			
							      		)}
						      		</Row>
						    	</Form.Group>
						    </Col>
						</Row>
				    </Card.Body>
				</Card>
			   <Row>
			   	<Col xs={12} className="text-right mt-3">
					<Form.Group as={Col}>
				    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
						    {isLoading ? 'Procesando...' : rIsEdit ? 'Modificar' : 'Guardar' }
						</Button>
				    </Form.Group>
				</Col>
			   </Row>
            </Form>
	)
}

export default Formulario