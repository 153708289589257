import React, { useState, useEffect  } from "react"
import {  
	Col,
	Row,
	Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import Layout from "../../components/Layouts/Layout"
import OpcionesEntrega from "./OpcionesEntrega"
import Devolucion from "./Devolucion"
import FacturaCredito from "./FacturaCredito"
import OrdenEntregada from "./OrdenEntregada"
import { useDispatch } from "react-redux"
import { resetListadoOrdenes, getHome } from "../../actions/orderAction"
import { getListado } from "../../actions/metodoPagoAction"
import { usePermission } from "../../hooks/Permission/usePermission"

const PanelOpciones = () => {

	const dispatch = useDispatch()
	const { validarPermiso } = usePermission()
	const [ isInicial, setInicial ] = useState(false)
	const [ isEntrega, setIsEntrega ] = useState(false)
	const [ isDevolucion, setIsDevolucion ] = useState(false)
	const [ isCredito, setIsCredito ] = useState(false)
	const [ isPorPagar, setIsPorPagar ] = useState(false)
	
	
	const changeBtnEncomienda = (event) => {
		setIsEntrega(true)
		setInicial(true)
		setIsDevolucion(false)
		setIsCredito(false)
		setIsPorPagar(false)
		dispatch(resetListadoOrdenes())
		if(document.getElementById("frmDevolucion")){
			document.getElementById("frmDevolucion").reset()
		}
		
	}

	const changeBtnDevolucion = (event) => {
		setIsEntrega(false)
		setIsDevolucion(true)
		setInicial(true)
		setIsCredito(false)
		setIsPorPagar(false)
		dispatch(resetListadoOrdenes())
		if(document.getElementById("frmEntrega")){
			document.getElementById("frmEntrega").reset()
		}
	}

	const changeBtnCredito = (event) => {
		setIsEntrega(false)
		setIsDevolucion(false)
		setInicial(true)
		setIsCredito(true)
		setIsPorPagar(false)
		dispatch(resetListadoOrdenes())
		if(document.getElementById("frmFacturaCredito")){
			document.getElementById("frmFacturaCredito").reset()
		}
	}

	const changeBtnPorPagar = (event) => {
		setIsEntrega(false)
		setIsDevolucion(false)
		setInicial(true)
		setIsCredito(false)
		setIsPorPagar(true)
		dispatch(resetListadoOrdenes())
		if(document.getElementById("frmOrdenEntregada")){
			document.getElementById("frmOrdenEntregada").reset()
		}
	}

	const getDataInicial = async () => {
		await dispatch(getHome())
		await dispatch(getListado())
	}
	useEffect(() => {
		getDataInicial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	return (
		<Layout 
	    	titlePanel="Entregar / Devolver"
	    	modulo="entregar_devolver"
	    >
	    	<Row>
	    		<Col xs={12} className="pt-4 pl-5 pb-4">
	    			<Button variant={isEntrega ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2" onClick={(event) => changeBtnEncomienda(event)}>
			    		Entrega de encomienda
			  		</Button>
			  		<Button variant={isDevolucion ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2" onClick={(event) => changeBtnDevolucion(event)}>
			    		Devolución de ordenes
			  		</Button>
			  		{
			  			validarPermiso("factura_a_credito") && (
		  					<Button variant={isCredito ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2" onClick={(event) => changeBtnCredito(event)}>
					    		Factura a crédito
					  		</Button>
			  			)
			  		}			  		
			  		<Button variant={isPorPagar ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" onClick={(event) => changeBtnPorPagar(event)}>
			    		Órdenes por Pagar
			  		</Button>
	    		</Col>
	    	</Row>

	    	{
	    		(isInicial && isEntrega) && (
	    				<OpcionesEntrega toast={toast}  />
	    		)
	    	}
	    	{
	    		(isInicial && isDevolucion) && (
	    				<Devolucion toast={toast} />
	    		)
	    	}
	    	{
    			(isInicial && isCredito) && (
	    			<FacturaCredito toast={toast} />
	    		)
	    	}
	    	{
	    		(isInicial && isPorPagar) && (
	    				<OrdenEntregada toast={toast} />
	    		)
	    	}
	    	<ToastContainer />
	    </Layout>
	)
}

export default PanelOpciones