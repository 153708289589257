import { 
	BUSQUEDA_CLIENTE,
	IS_LOADING_DATA,
	NUEVA_FACTURACION,
	HISTORICO_FACTURAS,
	SELECTED_OPTION,
	HIDDEN_BOTONES,
	ADD_DOCUMENT_PENDIENT,
	ADD_PAYMENT_METHODS,
	SEARCH_CLIENTE_PENDIENT,
	SEARCH_INVOICE_BY_PARAMETERS,
	LOADING_SEARCH_PAYMENT
} from "../types/facturacionType"

const INITIAL_STATE = {
	listOrdenes: [],
	opcionVisualizar: "default",
	isLoadingSearch: false,
	busqueda: {},
	listHistorico: [],
	cliente: {},
	resultView: true,
	name_excel: "",
	name_pdf: "",
	hiddenBtn: true,
	listFiltrado: [],
	listDocumentClient: [],
	cancelationPayment: {
		listDocumentPendient: [],
		listDetailPayment: [],
		total: 0
	},
	resultListInvoice: [],
	isLoadingPayment: false,
	dataSearch: {
		estatus_id: 24, 
		from_date: new Date(), 
		to_date:  new Date(), 
	}
}

const userReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case BUSQUEDA_CLIENTE:
			return {
				...state,
				listOrdenes: action.payload.listado,
				opcionVisualizar: action.payload.opcion,
				isLoadingSearch: action.payload.isCargando,
				busqueda: action.payload.busqueda,
				cliente: action.payload.cliente,
				resultView: action.payload.resultView
			}
		case IS_LOADING_DATA:
			return {
				...state,
				isLoadingSearch: action.payload,
				daIsCargando: action.payload
			}
		case NUEVA_FACTURACION:
			return {
				...state,
				listOrdenes: action.payload.listado,
				opcionVisualizar: action.payload.opcion,
				busqueda: action.payload.busqueda,
				resultView: action.payload.resultView
			}
		case HISTORICO_FACTURAS:
			return {
				...state,
				listHistorico: action.payload.historial,
				opcionVisualizar: action.payload.opcion,
				isLoadingSearch: action.payload.isCargando,
				resultView: action.payload.resultView,
				cliente: action.payload.cliente,
				listFiltrado: action.payload.historial
			}
		case SELECTED_OPTION:
			return {
				...state,
				listHistorico: action.payload.historial,
				listOrdenes: action.payload.ordenes,
				resultView:action.payload.resultView,
				name_pdf: "",
				name_excel: ""
			}
		case HIDDEN_BOTONES:
			return {
				...state,
				hiddenBtn: action.payload.hiddenBtn,
				listFiltrado: action.payload.listFiltrado
			}
		case ADD_DOCUMENT_PENDIENT:
			return {
				...state,
				cancelationPayment: {
					...state.cancelationPayment,
					listDocumentPendient: action.payload.listDocument,
					total: action.payload.total,
					remainingAmount: action.payload.remaining_amount
				}
			}
		case ADD_PAYMENT_METHODS:
			return {
				...state,
				cancelationPayment: {
					...state.cancelationPayment,
					listDetailPayment: action.payload.listPaymentMethods
				}
			}
		case SEARCH_CLIENTE_PENDIENT:
			return {
				...state,
				cancelationPayment: action.payload.data,
				listDocumentClient: action.payload.listDocumentClient
			}
		case SEARCH_INVOICE_BY_PARAMETERS:
			return {
				...state,
				resultListInvoice: action.payload.resultList,
				isLoadingSearch: action.payload.isLoading,
				dataSearch: action.payload.dataSearch
			}
		case LOADING_SEARCH_PAYMENT:
			return {
				...state,
				isLoadingPayment: action.payload
			}
		default: return state
	}
}

export default userReducer