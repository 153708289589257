import API from "../config/api"
import {
	BUSCAR_CLIENTE_CODIGO_INTERNO,
	LISTADO_CLIENTE,
	DATA_ADD_CLIENTE,
	SELECTED_ROWS_CLI,
	SEARCH_CLIENTE,
	DATA_EDIT_CLIENTE,
	DATA_DELETE_CLIENTE,
	IS_VISIBLE_FORMULARIO,
	DATA_ACTIVAR_DESACTIVAR_CLI
} from "../types/clienteType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const searchCliente = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const data = {
				...body,
				value: body.value.toLowerCase()
			}

			
		  	let response = await API.post("clientes/search", data)
	      resolve(response)
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const habilitarNuevoCliente = (status) => (dispatch, getState) => {
	const payload = {
		status
	}
	dispatch(requestSuccess(BUSCAR_CLIENTE_CODIGO_INTERNO, payload))
}

export const listadoCliente = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/clientes")
			let listCliente = response.clientes;
			if(response.clientes?.length>0){
				listCliente = response.clientes?.map(item => {
					return {
						...item,
						numero_cuentas_join: item?.numero_cuentas?.map(cuenta => cuenta?.numero_cuenta).join(", ")
					}
				})
			}
			dispatch(requestSuccess(LISTADO_CLIENTE, listCliente))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const addCliente = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.post("admin/clientes", body)

		    if(response.status){
				const cliente = {...body, id: response.id,is_activo: 1, name_estatus: "Activado"}
				let {listado} = getState().cliente
				const updateClient = {
					...cliente,
					nnumero_cuentas_join: body?.numero_cuenta?.map(cuenta => cuenta?.value).join(", ")
				}
				let data = [updateClient, ...listado]
				const payload = { listado: data, isForm: false}
				
		        dispatch(requestSuccess(DATA_ADD_CLIENTE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const getSelected = (rows) => (dispatch, getState) => {
	
	let estatus = ""
	let title = ""

	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].is_activo===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
	}
	
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount >0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}
	dispatch(requestSuccess(SELECTED_ROWS_CLI, payload))
}

export const searchPorId = (rut) => (dispatch, getState) => {
	const {listado} = getState().cliente

    const payload = {
		seletected: listado.find(ret => ret.rut===rut),
		isForm: true,
		isEdit: true
	}

	if(payload){
	  	dispatch(requestSuccess(SEARCH_CLIENTE, payload))
	  	 
	}
}

export const editCliente = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listado, datos} = getState().cliente

		  	let response = await API.post(`admin/clientes/${datos.id}`, body)

		    if(response.status){
				
				const data =listado.map(cliente => {
					if(cliente.id === datos.id){
						return {
							...body, 
							id: datos.id,
							is_activo: cliente.is_activo,
							name_estatus: cliente.name_estatus,
							numero_cuentas_join: body?.numero_cuenta?.map(cuenta => cuenta?.value).join(", ")
						};
					}
					return cliente
				})  
				const payload = { 
					listado: data, 
					isForm: false, 
					datos: {},
					btnActivar: {
						isVisible: false,
						nameBtn: "Desactivar",
						title: "Desactivado"
					}
				}	
			
		        dispatch(requestSuccess(DATA_EDIT_CLIENTE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteCliente = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listado} = getState().cliente

		  	let response = await API.get(`admin/clientes/delete/${id}`)

		    if(response.status){
				
				const payload =listado.filter(cliente => cliente.id!==id) 
				
		        dispatch(requestSuccess(DATA_DELETE_CLIENTE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const isVisibleFormularioCliente = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO, payload))
	  	 
	}
}

export const changeStatus = (id, opcion, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listado, isSelectedRows} = getState().cliente

			const body = {
				type: "estatus",
				opcion: opcion.toLowerCase()
			}

		  	let response = await API.post(`admin/clientes/${id}`, body)

		    if(response.status){
				
				const listadoCliente = listado.map(cliente => {
					
					if(cliente.id === id){
						return {...cliente, is_activo: status, name_estatus: opcion};
					}
					return cliente
				})  

				const payload = {
					rows: {
						...isSelectedRows,
						selectedRows: isSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								is_activo: status,
								name_estatus: opcion
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado: listadoCliente
				}

		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_CLI, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteCodeByClient = (numero_cuenta) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.get(`admin/clientes/delete/${numero_cuenta}/code`)
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

