const TbodyHistorico = ({ descripcion, usuario, fecha, indice }) => {

	return (
		<tr>
	      <td>{indice + 1}</td>
	      <td>{descripcion}</td>
	      <td>{usuario}</td>
	      <td>{fecha}</td>
	    </tr>
	)
}

export default TbodyHistorico