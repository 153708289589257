import { BiX, BiEditAlt } from "react-icons/bi"

const TablaDestion = ({ listDestino, editDestino, deleteDestino }) => {

	return (
		<div className="table-responsive">
			<table className="table table-bordered table-sm bg-white table-retiro">
				<thead className="bg-warn-blue">
				    <tr>
				      	<th className="text-center">RUT</th>
				      	<th className="text-center">Nombre y Apellido</th>
				      	<th className="detalle-carga-th">Teléfono</th>
				      	<th className="text-center">Oficina</th>
				      	<th className="text-center">Dirección</th>
				      	<th className="detalle-carga-th">Observación</th>
				      	<th className="detalle-opciones"></th>
				    </tr>
				 </thead>
				 <tbody>
				 {
				 	listDestino.length>0 &&
				 	listDestino.map((item, index) => 
				 		<tr key={index}>
				 			<td>{item?.rut}</td>
				 			<td>{item?.razon_social}</td>
				 			<td>{item?.telefono}</td>
				 			<td>{item?.comuna_name}</td>
				 			<td>{item?.direccion}</td>
				 			<td>{item?.observacion}</td>
				 			<td>
				 				<button className="btn btn-warning mr-1 pl-1 pr-1" type="button" size="sm" onClick={(event)=>editDestino(event, item, index)}>
				    			    <BiEditAlt style={{fontSize: '20px'}} />
				    			</button>
				    			<button className="btn btn-danger pl-1 pr-1" type="button" size="sm" onClick={(event)=>deleteDestino(event, index)}>
				    			    <BiX style={{fontSize: '20px'}} />
				    			</button>	
				 			</td>
				 		</tr>
				 	)
				 }
				 {listDestino.length===0 && <tr><td colSpan={7}>No hay destinatario asignado</td></tr>}
				 </tbody>
			</table>
		</div>
	)
}	

export default TablaDestion