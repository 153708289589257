import { useState, useEffect } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm, Controller } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addConductor, editConductor } from "../../../actions/conductorAction"
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import { validarRut } from "../../../utils/rut"

registerLocale('es', es)
const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors, control, getValues, setValue } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ isChangePhoto, setIsChangePhoto ] = useState(false)
	const [ file, setFile ] = useState("")
	
	const { coIsEdit  } = useSelector((state) => state.conductores)

    useEffect(() => {
		if(Object.keys(datos).length>0){
			if(datos.fecha_vencimiento!=null){
				setValue("fecha_vencimiento", new Date(datos.fecha_vencimiento))
				if(datos.imagen!==null){
					setFile(datos.imagen)
				}
			}else{
				setFile(undefined)
			}
		}
		return () => URL.revokeObjectURL(file)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    const onSubmit = async (data, e) => {
		setIsLoading(true)

		if(isChangePhoto && data.documento[0].size>2000000){
			toast.error("El tamaño máximo del documento es 2MB", {position: toast.POSITION.TOP_RIGHT})
			setIsLoading(false)
			return false
		}

		const rut = data.rut
		const isValid = validarRut(rut)
		if(!isValid.status){
			toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
			setIsLoading(false)
			return false
		}

		let fechaD = getValues("fecha_vencimiento")
		let fecha = fechaD.getFullYear() + '-' + (fechaD.getMonth() + 1) + '-' + fechaD.getDate()

		let formData = new FormData()
        formData.append('fecha_vencimiento',fecha)
        formData.append('licencia_conducir', data.licencia_conducir)
        formData.append('nombre_completo', data.nombre_completo)
        formData.append('rut', data.rut)
        formData.append('cambiar_foto', isChangePhoto)

        if(!coIsEdit || isChangePhoto){
        	formData.append('documento', data.documento[0])
    	}

		let response 

		if(coIsEdit){
			response = await dispatch(editConductor(formData, data, fechaD))
		}else{
			response = await dispatch(addConductor(formData, data, fechaD))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

    const isVisiblePanel = () => {
    	setIsChangePhoto(!isChangePhoto)
    }

    const handleFile = (event) => {
    	if (!event.target.files || event.target.files.length === 0) {
            setFile(undefined)
            return false
        }
    	setFile(URL.createObjectURL(event.target.files[0]))
    }

	return (
    	<Form method="POST" id="frmConductor" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" autoComplete="false">
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!coIsEdit ? 'CREAR CONDUCTOR' : 'EDITAR CONDUCTOR' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
			     	<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="rut" >
					      		<Form.Label>Rut</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="rut" 
						                autoFocus
						                defaultValue={datos?.rut || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
								    />
								{errors.rut && (
							    	<Form.Text className="text-danger">
							      		{errors?.rut?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="nombre_completo" >
					      		<Form.Label>Nombre y Apellido</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="nombre_completo" 
						                defaultValue={datos?.nombre_completo || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
								    />
								{errors.nombre_completo && (
							    	<Form.Text className="text-danger">
							      		{errors?.nombre_completo?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>	

					<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="licencia_conducir" >
					      		<Form.Label>Licencia conducir</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="licencia_conducir" 
								    	maxLength="12"
						                defaultValue={datos?.licencia_conducir || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
								    />
								{errors.licencia_conducir && (
							    	<Form.Text className="text-danger">
							      		{errors?.licencia_conducir?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="fecha_vencimiento" >
					      		<Form.Label>Fecha Vencimiento</Form.Label>
								<Controller
						            control={control}
						            name="fecha_vencimiento"
						            render={(props) => (
						              <DatePicker
						                dateFormat="dd/MM/yyyy"
						                placeholderText="dd/MM/yyyy"
						                onChange={(e) => props.onChange(e)}
						                selected={props.value}
						                className="form-control"
						                id="fecha_vencimiento"
						                name="fecha_vencimiento"
						                autoComplete="off"
										locale="es"
						              />
						            )}
						            rules={{required: {value: true, message: 'Requerido'}}}
						          />
								{errors.fecha_vencimiento && (
							    	<Form.Text className="text-danger">
							      		{errors?.fecha_vencimiento?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>	

					<Row>
						{coIsEdit && (
			     			<Col lg={12} md={12} sm={12}>
						      	<Form.Group>
								    <Form.Check 
								    	type="checkbox" 
								    	name="cambiar_foto" 
								    	id="cambiar_foto" 
				             			checked={isChangePhoto}
				             			label="Cambiar la foto del documento"
				             			onChange={(event)=>isVisiblePanel(event)}
				             			ref={register}
								   	/>
								 </Form.Group>
						    </Col>
			     		)}
						{(!coIsEdit || isChangePhoto) && (
							<Col lg={12} md={12} sm={12}>
						      	<Form.Group controlId="documento" >
									<Form.File 
										id="documento" 
										name="documento"
										label="Foto del documento" 
										onChange={(event)=>handleFile(event)}
										ref={register({required: {value: true, message: 'Requerido'}})}
										accept="image/*"
									/>
									{errors.documento && (
								    	<Form.Text className="text-danger">
								      		{errors?.documento?.message}
								    	</Form.Text>
								    )}
						    	</Form.Group>
						    </Col>
						)}
					</Row>	
					{file && (
						<Row>
							<Col lg={12} md={12} sm={12}>
						      	<Form.Group >
									<img src={file} width="150" className="img-thumbnail" alt="Licencia" />
						    	</Form.Group>
						    </Col>
						</Row>		
					)}
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : coIsEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form>
	)
}

export default Formulario