import { 
	LOGIN,
	LOGOUT,
	LISTADO_DATA_USER,
	SEARCH_USER, 
	DATA_DELETE_USER, 
	IS_VISIBLE_FORMULARIO_USER, 
	SELECTED_ROWS_USER,
	DATA_ADD_USER,
	DATA_ACTIVAR_DESACTIVAR_USER,
	DATA_EDIT_USER,
	DATA_ADD_USER_VEHICLE
} from "../types/userType"

const INITIAL_STATE = {
	name: "",
	sucursal_id:"",
	comuna_id: "",
	allListUsuario: [],
	uIsCargando: true,
	uIsForm: false,
	uIsClave: false,
	uIsEdit: false,
	uDatos: {},
	uIsSelectedRows: [],
	uBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	},
	email: "",
	uIsVehicle: false
}

const userReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOGIN:
			return {
				...state,
				name: action.payload.name,
				sucursal_id: action.payload.sucursal_id,
				comuna_id: action.payload.comuna_id,
				email: action.payload.email
			}
		case LOGOUT:
			return {
				...state,
				name: "",
				sucursal_id: "",
				comuna_id: ""
			}
		case LISTADO_DATA_USER:
			return {
				...state,
				allListUsuario: action.payload.users,
				uIsCargando: false
			}
		case SELECTED_ROWS_USER:
			return {
				...state,
				uIsSelectedRows: action.payload.rows,
				uBtnActivar: action.payload.btnActivar
			}
		case IS_VISIBLE_FORMULARIO_USER:
			return {
				...state,
				uIsForm: action.payload.isForm,
				uIsEdit: action.payload.isEdit,
				uDatos: action.payload.datos,
				uBtnActivar: action.payload.btnActivar,
				uIsVehicle: action.payload.isAssignVehicle,
				uIsClave: action.payload.isClave,
			}
		case DATA_DELETE_USER:
			return {
				...state,
				allListUsuario: action.payload
			}
		case SEARCH_USER:
			return {
				...state,
				uDatos: action.payload.seletected,
				uIsForm: action.payload.isForm,
				uIsEdit: action.payload.isEdit,
				uIsClave: action.payload.isClave,
				uIsVehicle: action.payload.isVehicle
			}
		case DATA_ADD_USER:
			return{
				...state,
				allListUsuario: action.payload.listado,
				uIsForm: action.payload.isForm
			}
		case DATA_ACTIVAR_DESACTIVAR_USER:
			return {
				...state,
				uIsSelectedRows: action.payload.rows,
				uBtnActivar: action.payload.btnActivar,
				allListUsuario: action.payload.listado
			}
		case DATA_EDIT_USER:
			return {
				...state,
				allListUsuario: action.payload.listado,
				uDatos: action.payload.datos,
				uIsForm: action.payload.isForm
			}
		case DATA_ADD_USER_VEHICLE:
			return {
				...state,
				allListUsuario: action.payload.listado,
				uDatos: action.payload.datos,
				uIsForm: action.payload.isForm,
				uIsVehicle: action.payload.isVehicle
			}
		default: return state
	}
}

export default userReducer