import { useEffect, forwardRef } from "react";
import {  
	Form,
	Button,
	Col,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import { usePermission } from "../../../hooks/Permission/usePermission"
import { useSelector } from "react-redux"
import { getDateYMD } from '../../../utils/formatFecha';
import { toast } from 'react-toastify'
import { BiCalendar } from "react-icons/bi";

registerLocale('es', es)
const SearchGlobal = ({ onSubmit, handleSubmit, control, setValue, register, isBuscar, sucursales }) => {

	const { validarPermiso } = usePermission()

	const { sucursal_id  } = useSelector((state) => state.users)

	useEffect(() => {
		if(sucursal_id!==""){
			setValue("sucursal_search_id", sucursal_id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales]) 

	const validateDateBlur = (props, event) => {
	
		const { message, error } = getDateYMD(event.target.value)

		if(error){
			toast.error(message, {position: toast.POSITION.TOP_RIGHT})
			props.onChange(new Date())
			return false
		}
	}

	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<div className="row">
    		<div className="col-md-11 mx-auto pb-3 pt-3">
    			<Form id="frmBusquedaGlobal" role="form" onSubmit={handleSubmit(onSubmit)}>
					<Form.Row className="pt-3 pb-4 shadow-sm border frmSearch">
						<Col md={3} xs={12}>
						 	<Form.Group as={Col} controlId="sucursal_search_id" className="pr-0">
						    	<Form.Label>Sucursal</Form.Label>
								<Form.Control 
								    	as="select"
								    	name="sucursal_search_id" 
								    	ref={register}
								    	disabled={!validarPermiso("cambiar_sucursal")}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		sucursales.length > 0 && (
							    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
						    </Form.Group>
						</Col>
						 <Col md={3} xs={12}>
						 	<Form.Group as={Col} controlId="fecha_recepcion" className="pl-0 pr-0">
						    	<Form.Label>Fecha Recepción</Form.Label>
								<Controller
						            control={control}
						            name="fecha_recepcion"
						            render={(props) => (
						              <DatePicker
						                dateFormat="dd/MM/yyyy"
						                placeholderText="Select date"
						                onChange={(e) => props.onChange(e)}
						                selected={props.value}
						                className="form-control"
										locale="es"
										onBlur={(e) => validateDateBlur(props, e)}
										maxDate={new Date()}
										autoComplete="off"
										customInput={<ExampleCustomInputFrom />}
						              />
						            )}
						          />
						    </Form.Group>
						</Col>					    
					    <Col md={3} xs={12}>
					     	<Form.Group as={Col} className="mt-4 pl-0">
					  			<Button variant="warning" type="submit" block disabled={isBuscar}> 
						    		{isBuscar ? 'Buscando...' : 'Buscar' }
						  		</Button>
						    </Form.Group>
					    </Col>
					</Form.Row>
				</Form>
    		</div>
    	</div>
	)
}

export default SearchGlobal