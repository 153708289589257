import API from "../config/api"
import {
	LISTADO_DATA_CTA,
	SELECTED_ROWS_CTA,
	IS_VISIBLE_FORMULARIO_CTA,
	SEARCH_CUENTA,
	DATA_ADD_CUENTA,
	DATA_EDIT_CUENTA,
	DATA_ACTIVAR_DESACTIVAR_CTA,
	DATA_DELETE_CTA
} from "../types/cuentaDepositoType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const listadoCuenta = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/cuentas")
			dispatch(requestSuccess(LISTADO_DATA_CTA, response.cuentas))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const listadoCuentaOnlyActive = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/cuentas/enabled")
			dispatch(requestSuccess(LISTADO_DATA_CTA, response.cuentas))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const getSelectedRows = (rows) => (dispatch, getState) => {
	let estatus = ""
	let title = ""
	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].is_activo===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
	}
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount>0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}
	dispatch(requestSuccess(SELECTED_ROWS_CTA, payload))
}

export const isVisibleFormularioCta = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_CTA, payload))
	  	 
	}
}

export const searchPorId = (id) => (dispatch, getState) => {
	const {listadoCta} = getState().ctaDeposito
	
    const payload = {
		seletected: listadoCta.find(ret => ret.id===id),
		isForm: true,
		isEdit: true
	}


	if(payload){
	  	dispatch(requestSuccess(SEARCH_CUENTA, payload))
	  	 
	}
}

export const addCuenta = (body, name_sucursal) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.post("admin/cuentas", body)

		    if(response.status){
				const cuenta = {
					...body, 
					id: response.id, 
					sucursal:{nombre:name_sucursal}, 
					is_activo: 1,
					name_estatus: "Activado"
				}
				const {listadoCta} = getState().ctaDeposito
				const data = [...listadoCta, cuenta]
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_CUENTA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editCuenta= (body, name_sucursal) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listadoCta, cDatos} = getState().ctaDeposito

		  	let response = await API.post(`admin/cuentas/${cDatos.id}`, body)

		    if(response.status){
				
				const data =listadoCta.map(cuenta => {
					if(cuenta.id === cDatos.id){
						return {
							...body, 
							id: cDatos.id, 
							sucursal:{nombre:name_sucursal},
							is_activo: cuenta.is_activo,
							name_estatus: cuenta.name_estatus
						};
					}
					return cuenta
				})  
				const payload = { 
					listado: data, 
					isForm: false, 
					datos: {},
					btnActivar: {
						isVisible: false,
						nameBtn: "Desactivar",
						title: "Desactivado"
					}
				}	
				
		        dispatch(requestSuccess(DATA_EDIT_CUENTA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changeStatus = (id, opcion, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listadoCta, cIsSelectedRows } = getState().ctaDeposito

			const body = {
				type: "estatus",
				opcion: opcion.toLowerCase()
			}

		  	let response = await API.post(`admin/cuentas/${id}`, body)

		    if(response.status){
				
				const listado = listadoCta.map(cuenta => {
					
					if(cuenta.id === id){
						return {...cuenta, is_activo: status, name_estatus: opcion};
					}
					return cuenta
				})  

				const payload = {
					rows: {
						...cIsSelectedRows,
						selectedRows: cIsSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								is_activo: status,
								name_estatus: opcion
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado
				}

		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_CTA, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteCuenta = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listadoCta} = getState().ctaDeposito

		  	let response = await API.get(`admin/cuentas/delete/${id}`)

		    if(response.status){
				
				const payload =listadoCta.filter(rol => rol.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_CTA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}