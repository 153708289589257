import React, { useState, useEffect, forwardRef } from "react"
import {  
	Form,
	Button,
	Col,
	Dropdown,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import TbodyOrden from "./TbodyOrden"
import LoadingSearch from "../../Utilidades/LoadingSearch"
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import { useForm } from "react-hook-form"
import "react-datepicker/dist/react-datepicker.css"
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from "react-redux"
import { filteredOrdenesRange, calcularTotalSelected, cleanListadoOrden } from "../../../actions/orderAction"
import { usePermission } from "../../../hooks/Permission/usePermission"
import { isAfter, getDateYMD, isBefore, isSame } from '../../../utils/formatFecha';
import { toast } from 'react-toastify'
import dayjs from 'dayjs';
import { onPaste, validateOnlyOrden } from '../../../utils/validationInput';
import { BiCalendar } from "react-icons/bi";


registerLocale('es', es)
const GlobalSearch = ({ loadingSearch }) => {

	const dispatch = useDispatch()
	const { validarPermiso } = usePermission()
	const { register, handleSubmit, errors, setValue, getValues } = useForm()
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())
	const [textSelectedEstado, setTextSelectedEstado] = useState("Seleccione")
	const [textSelectedPago, setTextSelectedPago] = useState("Seleccione")
	const [pageOffset, setPageOffset] = useState(0)
	const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)

	const { users, orders  } = useSelector((state) => {
		return {
			users: state.users,
			orders: state.orders
		}
	})

	const { sucursales, estadosAll, forma_pago, dataSearch, listOrden  } = orders
	const { sucursal_id  } = users

	const onSubmit = async (data, e) => {
		setLoading(true);
		await getListOrdenByFilter(data, 0)
		setLoading(false);
	}

	const getListOrdenByFilter = async (data, page) => {
		dispatch(cleanListadoOrden(true))
		dispatch(calcularTotalSelected())

		if(data.numero_orden!==""){
			let ordenFormat = data.numero_orden?.replace("'", "-")
			setValue("numero_orden", ordenFormat)
		}
	
		const information = {
			...data,
			fecha_desde: document.getElementById("fecha_desde").value,
			fecha_hasta: document.getElementById("fecha_hasta").value,
			sucursal_origen_id: document.getElementById("sucursal_origen_id").value,
			check: true,
			search_multiple: true,
			generarPDF: true
		}

		const response = await dispatch(filteredOrdenesRange(information, "ordens", (page+1)))
		
		if(response?.status){
			setPageCount(response?.countPaginate ?? 0)
			setPageOffset(page)
		}else{
			toast.error("No hay registros disponibles", {position: toast.POSITION.TOP_RIGHT})
		}
		dispatch(calcularTotalSelected())
	}

	const handleSelected = (typeText) => {
		const data = getValues();
		if(typeText==="estado"){
			setTextSelectedEstado(textSelectedEstado => data?.estado_id?.length===0 ? 'Seleccione' : 
						`${data?.estado_id?.length} seleccionado${data?.estado_id?.length===1 ? "" : "s"}`)
		}else {
			setTextSelectedPago(textSelectedPago => data?.forma_pago_id?.length===0 ? 'Seleccione' : 
						`${data?.forma_pago_id?.length} seleccionado${data?.forma_pago_id?.length===1 ? "" : "s"}`)
		}		
	}

	useEffect(() => {
		if(Object.keys(dataSearch).length > 0 ){
			setValue("sucursal_origen_id", dataSearch.sucursal_origen_id)
			setValue("sucursal_destino_id", dataSearch.sucursal_destino_id)
			setValue("estado_id", dataSearch.estado_id)
			setValue("fecha_desde", dataSearch.fecha_desde)
		}
		if(sucursal_id!==""){
			setValue("sucursal_origen_id", sucursal_id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales])
    
    const handlePageChange = async (event) => {
      
      // TODO Only change displayed selected page
      // when its content is loaded in useEffect.
      setPageOffset(event.selected);

	  const data = getValues()
	  await getListOrdenByFilter(data, event.selected);
    };

	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(dayjs(startDate).format('DD/MM/YYYY'))
	
		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			return false
		}
	
		if (!isSame(dateStart.dateSelected, endDate) && isAfter(dateStart.dateSelected, endDate)) {
			toast.error('La fecha inicial no puede ser superior a la fecha final', {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			setEndDate(new Date())
			return false
        }
	}

	const validateEndDateBlur = () => {

		const dataEnd = getDateYMD(dayjs(endDate).format('DD/MM/YYYY'))

		if(dataEnd.error){
			toast.error(dataEnd.message, {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}
		
		if (!isSame(dataEnd.dateSelected, dayjs(startDate).format('YYYY-MM-DD')) && isBefore(dataEnd.dateSelected, startDate)) {
			toast.error('La fecha final no puede ser inferior a la fecha inicial', {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
        }
	}

	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mt-4">
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<Col xs={12}>
	    	<Form onSubmit={handleSubmit(onSubmit)} id="frmBusquedaGlobal" role="form">
				<Form.Row>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="fecha_desde" className="pr-0">
					    	<Form.Label>Periodo Fecha</Form.Label>
							<DatePicker 
								type="text" 
								dateFormat="dd/MM/yyyy"
								selected={startDate} 
								onChange={date => setStartDate(date)} 
								className="form-control"
								name="fecha_desde" 
								id="fecha_desde"
								ref={register}
								locale="es"
								maxDate={new Date()}
								//onBlur={(event) => validateStartDateBlur(event)}
								onCalendarClose={()=>validateStartDateBlur()}
								autoComplete="off"
								customInput={<ExampleCustomInputFrom />}
							/>
							
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				      	<Form.Group as={Col} controlId="fecha_hasta" className="pr-1 pl-0">
							<DatePicker 
								type="text" 
								dateFormat="dd/MM/yyyy"
								selected={endDate} 
								onChange={date => setEndDate(date)} 
								className="form-control mt-4"
								name="fecha_hasta" 
								id="fecha_hasta"
								ref={register}
								locale="es"
								maxDate={new Date()}
								//onBlur={(event) => validateEndDateBlur(event)}
								onCalendarClose={()=>validateEndDateBlur()}
								autoComplete="off"
								customInput={<ExampleCustomInputTo />}								
							/>
							
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="sucursal_origen_id" className="pr-1 pl-0">
					    	<Form.Label>Ciudad Origen</Form.Label>
					    	<Form.Control 
							    	as="select"
							    	name="sucursal_origen_id" 
							    	disabled={!validarPermiso("cambiar_sucursal")}
					                ref={register}
							    >
						    	<option value="">Seleccione</option>
						    	{
						    		sucursales.length > 0 && (
						    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
						    		)
						    	}
						 	</Form.Control>
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="sucursal_destino_id" className="pr-1 pl-0">
					    	<Form.Label>Ciudad destino</Form.Label>
					    	<Form.Control 
							    	as="select"
							    	name="sucursal_destino_id" 
					                ref={register}
							    >
						    	<option value="">Seleccione</option>
						    	{
						    		sucursales.length > 0 && (
						    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
						    		)
						    	}
						 	</Form.Control>
						 	{errors.sucursal_destino_id && (
						    	<Form.Text className="text-danger">
						      		{errors?.sucursal_destino_id?.message}
						    	</Form.Text>
						    )}
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="estado_id" className="pr-1 pl-0">
					    	<Form.Label>Estado</Form.Label>
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic" className="btn-select-multiple">
									{textSelectedEstado}
								</Dropdown.Toggle>

								<Dropdown.Menu className="p-3" style={{ width: '300px'}}>
									{
										estadosAll.length > 0 && (
											estadosAll.map((estado, key) => 
												<Form.Group key={key} controlId={estado?.id} className="mb-0">
													<Form.Check 
														type="checkbox" 
														label={estado.descripcion} 
														id={estado?.id}
														name="estado_id"
														ref={register}	
														value={estado?.id}
														onChange={() => handleSelected("estado")}
													/>
												</Form.Group>
											)
										)
									}
								</Dropdown.Menu>
							</Dropdown>
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="forma_pago_id" className="pr-1 pl-0">
					    	<Form.Label>Forma Pago</Form.Label>
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic" className="btn-select-multiple">
									{textSelectedPago}
								</Dropdown.Toggle>

								<Dropdown.Menu className="p-3" style={{ width: '300px'}}>
									{
										forma_pago.length > 0 && (
											forma_pago.map((forma, key) => 
												<Form.Group key={key} controlId={`key${forma?.id}`} className="mb-0">
													<Form.Check 
														type="checkbox" 
														label={forma.descripcion} 
														id={`key${forma?.id}`}
														name="forma_pago_id"
														ref={register}	
														value={forma?.id}
														onChange={() => handleSelected("forma_pago")}
													/>
												</Form.Group>
											)
										)
									}
								</Dropdown.Menu>
							</Dropdown>
					    </Form.Group>
				    </Col>
				    <Col lg md={4} sm={6} xs={4}>
				     	<Form.Group as={Col} controlId="numero_orden" className="pr-1 pl-0">
					    	<Form.Label>N° Orden</Form.Label>
					    	<Form.Control 
						    	type="text" 
						    	name="numero_orden" 
						    	defaultValue={dataSearch?.numero_orden || ""}
				                ref={register}
								maxLength={20}
								onKeyPress={(event) => validateOnlyOrden(event)}
								onPaste={(event) => onPaste(event)}
						    />
					    </Form.Group>
				    </Col>
				</Form.Row>
				<Form.Row>
				 <Col className="text-right">
				     	<Form.Group as={Col} className="mt-3 pl-0">
					    	<Button variant="warning" type="submit" className="pl-5 pr-5" disabled={loading}>
							    {loading? 'Buscando...' : 'Mostrar'}
							</Button>
					    </Form.Group>
				    </Col>
				</Form.Row>
			</Form>
			{(!loadingSearch && listOrden.length > 0) && <p><strong>Total de registro:</strong> {listOrden?.length} </p>}			
			<div className="table-responsive">
				<table id="table-orden" className="table table-sm table-bordered table-filtered">
					<thead>
						<tr>
							<th>Número Orden</th>
							<th>Estado</th>
							<th>Fecha</th>
							<th>Cliente</th>
							<th>Of. Origen</th>
							<th>Of. Destino</th>
							<th>Origen</th>
							<th>Destino</th>
							<th>Destinatario</th>
							<th>Dirección</th>
							<th>Bultos</th>
							<th>Entrega</th>
							<th>F. Pago</th>
							<th>Total</th>
							<th>Doc. Pago</th>
							<th>Nro.Doc. Pago</th>
							<th>Nro. Nómina</th>
							<th>Usuario App Mobile</th>
							<th>Camión App Mobile</th>
						</tr>
					</thead>
					<tbody>
						{
							(!loadingSearch && listOrden.length > 0) && (
								listOrden.map((orden, index) => 
									<TbodyOrden
										key={index}
										indice={index}
										{...orden}
									/>
								)
							) 
						}
						{
							(!loadingSearch && listOrden.length === 0) && (
								<tr>
									<td colSpan={19}>No hay registro disponibles</td>
								</tr>
							) 
						}
						{
							loadingSearch && (
								<tr>
									<td colSpan={19}><LoadingSearch /></td>
								</tr>								
							)
						}
					</tbody>
				</table>
			</div>

			

			<ReactPaginate
					previousLabel="Anterior"
					nextLabel="Siguiente"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					pageCount={pageCount}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={handlePageChange}
					containerClassName="pagination"
					activeClassName="active"
					forcePage={pageOffset}

				/>
		</Col>
	)
}

export default GlobalSearch