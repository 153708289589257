import {Row, Col, Table } from 'react-bootstrap'
import {  deleteOrdenes, updateOrdenes } from '../../actions/guiaDespachoAction'
import { useDispatch } from "react-redux"
import Tbody from './Tbody'

const Detalle = ({ordenes, setValue, setValorDescuento }) => {

	const dispatch = useDispatch()
	

	const deleteRow = (numero_orden) => {
	
		const result = dispatch(deleteOrdenes(numero_orden))
		if(result===0){
			setValue('tipo_valor', '')
			//setValue('valor_descuento_recarga', '')
			setValorDescuento("")
			setValue('descuento_recargo', '')
			setValue('recargo_global', 0)
			setValue('descuento_global', 0)
			dispatch(updateOrdenes())
		}
	}

	return (
		<Row>
			<Col>
				<Table bordered size="sm" responsive className="table-global mb-0">
					  <thead className="bg-warn-blue">
					    <tr>
					      <th>Código</th>
					      <th>Descripción</th>
					      <th>Cantidad</th>
					      <th>Unitario</th>
					      <th>Descuento</th>
					      <th>Tipo descuento</th>
					      <th>Exento</th>
					      <th>Sub Total</th>
					      <th>Eliminar</th>
					    </tr>
					  </thead>
					  <tbody>
					  	{
					  		ordenes?.length  > 0 ? (
						  			ordenes?.map((orden, index) => {
						  				return (
						  					<Tbody 
						  						key={index}
						  						index={index}
						  						{...orden} 
						  						deleteRow={deleteRow}
						  					/>
						  				)
						  			})
						  		) : (
						  			<tr className="tr-vacia">
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  				<td></td>
						  			</tr>
						  		)
					  	}
					  </tbody>
				</Table>
			</Col>
		</Row>
		
	)
}

export default Detalle