import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom"
import { createdRetiro, resetDestination } from "../../actions/retiroAction"
import { getHome } from "../../actions/orderAction"
import { searchCliente } from "../../actions/clienteAction"
import { listadoCamion } from "../../actions/camionAction"

export const useRetiroCreated = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const { register, handleSubmit, errors, setValue, getValues, control } = useForm()
	const { retiros, orders, camions, users } = useSelector((state) => {
		return {
			retiros: state.retiros,
			orders: state.orders,
			camions: state.camions,
			users: state.users,
		}
	})
	const { dataRetiro, listDestino, isPreviewRetiro, nroRetiro } = retiros
	const { sucursales, tipo_envio, forma_pago } = orders
	const { caListado } = camions
	const { sucursal_id, comuna_id  } = users

	const [ isLoading, setIsLoading ] = useState(false)
	const [oficinaOrigen, setOficinaOrigen ] = useState([])
	const [oficinaDestino, setOficinaDestino ] = useState([])

	const getDataInitial = async () => {
		const repsonse = await dispatch(getHome())

		if(repsonse?.sucursales?.length > 0){
			cargarOficinas(undefined, "origen", sucursal_id)
		}

		if(caListado.length===0){
			await dispatch(listadoCamion())
		}
	}

	const handleFormRetiro = () => {
		dispatch(resetDestination("created"))

		if(document.getElementById("razon_social_remitente")){
			document.getElementById("razon_social_remitente").readOnly=true
		}	   	
	}

	const cargarOficinas = (e, type, valor) => {
	
		const id =  (e !==undefined ) ? e.target.value : valor
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
		
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)
			}
		
			if(type === "origen") {
		    	setOficinaOrigen(detComuna) 
		    }else if(type === "destino") {
		    	setOficinaDestino(detComuna)
		    }
		}
	}

	const onSubmit =  async (data, event) => {
		event.preventDefault()
		setIsLoading(true)
	
		if(listDestino.length>0){
			let response = await dispatch(createdRetiro(data))
	    	if(response.status){
	    		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
	    		event.target.reset()
				history.push(`/retiros/${response.nroRetiro}/ticket`)
	    	}else{
	    		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
	    	}
		}else{
			toast.error("Debe de ingresar el detalle del destino", {position: toast.POSITION.TOP_RIGHT})
		}
		
    	setIsLoading(false)
	}


	return {
		dataRetiro, 
		listDestino, 
		isPreviewRetiro, 
		nroRetiro,
		sucursales,
		caListado,
		isLoading,
		oficinaOrigen,
		oficinaDestino,
		handleFormRetiro,
		cargarOficinas,
		getDataInitial,
		onSubmit,
		register, 
		handleSubmit, 
		errors, 
		setValue, 
		getValues,
		searchCliente,
		sucursal_id,
		comuna_id,
		control,
		tipo_envio,
		forma_pago
	}
}