import { 
	LISTAR_DATA_INICIO,
	DATA_SEARCH_ORDEN,
	CLEAN_FORM_ORDER,
	DATA_ADD_ORDEN,
	TOTALES_UPDATE,
	SAVE_DETALLE_CARGA,
	FILTERED_ORDERS,
	UPDATE_SELECTED_ORDEN_NOM,
	TOTAL_SELECTED_ORDEN_NOM,
	CLEAN_SELECTED_ORDEN_NOM,
	LOADING_SEARCH_ORDERS_NOM,
	CLEAN_NAME_DESTINO_NOM,
	CANCEL_ORDEN,
	SEARCH_CLIENTE_ORDENES,
	UPDATE_CLIENTE_ORDENES,
	UPDATE_CLIENTE_ORDENES_INPUT,
	CLEAR_TOTAL_ORDEN_ENTREGA,
	UPDATE_INPUT_DOCUMENTO,
	UPDATE_TOTAL_MONTO,
	PREVIEW_PDF_ORDENES,
	BLOQUEAR_CLIENTE,
	GENERAR_NOTA_CREDITO,
	DISABLED_GENERATE_DOCUMENT,
	SEARCH_ORDEN_RECEIVE,
	LIST_ORDER_INCOMPLETE,
	SELECTED_ORDEN_INCOMPLETE,
	UPDATE_ORDEN_INCOMPLETE,
	EDIT_CARGA_WITH_PRICE_ORDEN
} from "../types/ordersType"
import { ordenInitial } from "../utils/OrdenFlete/DataInitialOrden"

const INITIAL_STATE = {
	dataOrders: ordenInitial,
	totales: {
		total_bultos: 0,
      	total_kilos: 0,
      	total_metro_cubico: 0,
      	subtotal_orden: 0,
      	valor_neto: 0,
      	valor_iva: 0,
      	total: 0,
	},
	sucursales: [],
	documentacionsCarga: [],
	documentacionsPago: [],
	documentacionsPagoSinB1: [],
	tipo_envio: [],
	forma_pago: [],
	estados: [],
	estadosAll: [],
	listOrden: [],
	totalSelectedOrden: {},
	loadingSearch: false,
	title: false,
	title_destino: {
		name_pendiente_sucursal: "",
		name_enviado: ""
	},
	initialOrden: false,
	disabledBtnCrear: false,
	disabledBtnAnular: true,
	disabledImprimir: true,
	disabledTracking: true,
	updateTotal: true,
	isActiveNomina: false,
	listOrdenEntregar: [],
	disabledBtnPago: true,
	totalMonto: 0,
	name_pdf: "",
	name_excel: "",
	disabledSearch: true,
	dataSearch: {},
	name_pdf_orden: "",
	isBloqueCta: false,
	listEntregada: [],
	visibleMetodoPago: false,
	estadoRetiros: [],
	listOrdensReceive: [],
	isLoadingReceive: false,
	infoReceive: {receive: 0, completed: 0, incomplete: 0, otherOffice: 0, orderToCome: 0 },
	listOrderIncomplete: [],
	isLoadingOrderIncomplete: false,
	orderSelectedIncomplete: {},
	visibleCarga: true
}

const orderReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTAR_DATA_INICIO:
			return {
				...state,
				sucursales: action.payload.sucursales,
				documentacionsCarga: action.payload.documentacionsCarga,
				documentacionsPago: action.payload.documentacionsPago,
				tipo_envio: action.payload.tipo_envio,
				forma_pago: action.payload.forma_pago,
				estados: action.payload.estados,
				estadosAll: action.payload.estadosAll,
				documentacionsPagoSinB1: action.payload.documentacionsPagoSinB1,
				estadoRetiros: action.payload.estadoRetiros
			}
		case DATA_SEARCH_ORDEN:
			return {
				...state,
				dataOrders: action.payload.orden,
				initialOrden: true,
				disabledBtnAnular: action.payload.disabledBtnAnular,
				disabledBtnCrear: action.payload.disabledBtnCrear,
				disabledImprimir: action.payload.disabledImprimir,
				disabledTracking: action.payload.disabledTracking,
				visibleCarga: action.payload.visibleCarga,
				isBloqueCta: action.payload.isBloqueCta
			}
		case DATA_ADD_ORDEN:
			return {
				...state,
				dataOrders: action.payload.dataOrders,
				initialOrden: true
				//disabledBtnAnular: action.payload.disabledBtnAnular,
				//disabledBtnCrear: action.payload.disabledBtnCrear,
				//disabledImprimir: action.payload.disabledImprimir,
				//disabledTracking: action.payload.disabledTracking
			}
		case CANCEL_ORDEN:
			return {
				...state,
				disabledBtnAnular: action.payload.disabledBtnAnular,
				disabledBtnCrear: action.payload.disabledBtnCrear,
				dataOrders: {
					...state.dataOrders,
					user_name: action.payload.user_name,
      	  			estado_orden: action.payload.estado_orden,
      	  			listEstado: action.payload.listEstado
				}
			}
		case CLEAN_FORM_ORDER:
			return {
				...state,
				dataOrders: action.payload,
				initialOrden: true
			}
		case TOTALES_UPDATE:
			return {
				...state,
				initialOrden: true,
				totales: { ...action.payload }
			}

		case SAVE_DETALLE_CARGA:
			return {
				...state,
				dataOrders: {
					...state.dataOrders,
					cargas: action.payload,
				}
			}
		case FILTERED_ORDERS:
			return {
				...state,
				listOrden: action.payload.orders,
				loadingSearch: false,
				destino_nomina: action.payload.destino_nomina,
				//name_pdf: action.payload.name_pdf,
				dataSearch: action.payload.dataSearch,
				disabledSearch: action.payload.disabledSearch,
				name_excel: action.payload.name_excel
			}
		case UPDATE_SELECTED_ORDEN_NOM:
			return {
				...state,
				listOrden: action.payload
			}
		case TOTAL_SELECTED_ORDEN_NOM:
			return {
				...state,
				totalSelectedOrden: action.payload
			}
		case CLEAN_SELECTED_ORDEN_NOM:
			return {
				...state,
				totalSelectedOrden: action.payload.totales,
				listOrden: action.payload.listadoOrden,
				title: true,
				destino_nomina: action.payload.destino_nomina,
				name_pdf: action.payload.name_pdf,
				name_excel: action.payload.name_excel
			}
		case LOADING_SEARCH_ORDERS_NOM:
			return {
				...state,				
				loadingSearch: true,
			}
		case CLEAN_NAME_DESTINO_NOM:
			return {
				...state,
				title_destino: action.payload
			}
		case SEARCH_CLIENTE_ORDENES:
			return {
				...state,
				listOrdenEntregar: action.payload.listOrdenEntregar,
				disabledBtnPago: false
			}
		case UPDATE_CLIENTE_ORDENES:
			return {
				...state,
				listOrdenEntregar: [ ...state.listOrdenEntregar, action.payload.details ],
				listEntregada: action.payload.entregadas
			}
		case UPDATE_CLIENTE_ORDENES_INPUT:
			return {
				...state,
				listOrdenEntregar: action.payload.updateOrden,
				visibleMetodoPago: action.payload.visibleMetodoPago
			}
		case CLEAR_TOTAL_ORDEN_ENTREGA:
			return {
				...state,
				listOrdenEntregar: action.payload.ordenes,
				disabledBtnPago: true,
				totalMonto: 0,
				listEntregada: action.payload.entregadas,
				visibleMetodoPago: action.payload.visibleMetodoPago
			}
		case UPDATE_INPUT_DOCUMENTO:
			return {
				...state,
				listOrdenEntregar: action.payload
			}
		case UPDATE_TOTAL_MONTO:
			return {
				...state,
				totalMonto: action.payload
			}
		case PREVIEW_PDF_ORDENES:
			return {
				...state,
				name_pdf_orden: action.payload.pdf_name
			}
		case BLOQUEAR_CLIENTE:
			return {
				...state,
				isBloqueCta: action.payload
			}
		case GENERAR_NOTA_CREDITO:
			return {
				...state,
				dataOrders: action.payload
			}
		case DISABLED_GENERATE_DOCUMENT:
			return {
				...state,
				dataOrders: action.payload
			}
		case SEARCH_ORDEN_RECEIVE:
			return {
				...state,
				isLoadingReceive: action.payload.isLoadingReceive,
				listOrdensReceive: action.payload.data,
				infoReceive: action.payload.infoReceive
			}
		case LIST_ORDER_INCOMPLETE:
			return {
				...state,
				listOrderIncomplete: action.payload.listOrderIncomplete,
				isLoadingOrderIncomplete: action.payload.isLoadingOrderIncomplete
			}
		case SELECTED_ORDEN_INCOMPLETE:
			return {
				...state,
				orderSelectedIncomplete: action.payload.orderSelected
			}
		case UPDATE_ORDEN_INCOMPLETE:
			return {
				...state,
				listOrderIncomplete: action.payload.listOrdens
			}
		case EDIT_CARGA_WITH_PRICE_ORDEN:
			return {
				...state,
				dataOrders: {
					...state.dataOrders,
					cargas: action.payload.cargas
				}				
			}
		default: return state
	}
}

export default orderReducer