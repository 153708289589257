import { Fragment } from "react"
import {  
	Row,
	Form,
	Col,
	Button
} from 'react-bootstrap'
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"

const FormAsignar = ({ register, errors, isLoading, caListado }) => {


	return (
		<Fragment>
			<Row className="bg-soft-blue panel-contenedor--info mb-1">
				<Col xs={12}>
					<Form.Row>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="camion_id" >
						    	<Form.Label>Móvil</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="camion_id" 
						        		ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		caListado.length > 0 && (
							    			caListado.map(camion => <option key={camion.id} value={camion.id}>{`${camion.numero_interno} ${camion.placa}`}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.camion_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.camion_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					      	<Form.Group as={Col} controlId="detalle" >
					      		<Form.Label>Detalle</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="detalle" 
										maxLength={255}
						             	ref={register}
										onKeyPress={(event) => validateOnlyLetter(event)}
										onPaste={(event) => onPaste(event)}
								    />
								{errors.detalle && (
							    	<Form.Text className="text-danger">
							      		{errors?.detalle?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Form.Row>
					<Form.Row>
						<Col xs={12}>
							<Form.Group as={Col}>
						    	<p className="font-weight-bold fs-12">
									**Antes de asignar, debe seleccionar los retiros por medio del cuadro de selección
								</p>
						    </Form.Group>
						</Col>
						<Col xs={12} className="text-right">
							<Form.Group as={Col}>
						    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
								    {isLoading ? 'Procesando...' : 'Asignar'}
								</Button>
						    </Form.Group>
						</Col>
					</Form.Row>
				</Col>
			</Row>

		</Fragment>
	)
}

export default FormAsignar