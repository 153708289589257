import React, { Fragment } from "react"
import {  
	Col,
	Row,
	Form
} from 'react-bootstrap'
import { useSelector } from "react-redux"
import { formatNumberCurrency } from "../../../utils/validationInput";

const TableOrden = () => {

	const { totalSelectedOrden } = useSelector((state) => state.orders)

	return (
		<Fragment>
			<Col xs={12}>
				<Row className="panel-contenedor--info pt-5">
					<Col lg={3} className="bg-warn-blue border-panel">
							<Form.Group as={Col} className="text-center">
						    	<Form.Label className="my-4">TOTAL SELECCIONADOS</Form.Label>
						    </Form.Group>
					</Col>
					<Col lg={9} className="bg-gray border-panel pt-2">
						<Form.Row>
							<Col>
						     	<Form.Group as={Col} controlId="totales" className="pr-1 pl-0">
							    	<Form.Label>Total órdenes</Form.Label>
							    	<Form.Control 
								    	type="text" 
								    	name="totales" 
								    	defaultValue={!totalSelectedOrden.total ? "" : formatNumberCurrency(totalSelectedOrden.total)}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    <Col>
						     	<Form.Group as={Col} controlId="total_bultos" className="pr-0">
							    	<Form.Label>Bultos</Form.Label>
									<Form.Control 
								    	type="text" 
								    	name="total_bultos" 
								    	defaultValue={totalSelectedOrden.total_bultos || ""}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    <Col>
						      	<Form.Group as={Col} controlId="total_kilos" className="pr-1 pl-0">
							    	<Form.Label>Kilos</Form.Label>
									<Form.Control 
								    	type="text" 
								    	name="total_kilos" 
								    	defaultValue={formatNumberCurrency(totalSelectedOrden.total_kilos) || ""}
								    	readOnly
								    />
							    </Form.Group>
						    </Col>
						    
						</Form.Row>
					</Col>
				</Row>
			</Col>

		</Fragment>
	)
}

export default TableOrden


