import API from "../config/api"
import {
	LISTADO_ALL,
	LOADING_DATA,
	IS_VISIBLE_LISTADO,
	FILTRADO_MOVIL,
	SAVE_DESTINO,
	EDIT_LIST_DESTINO,
	SELECT_DESTINO,
	SEARCH_RETIRO_BY_NRO,
	SELECTED_PREORDEN,
	RESET_LIST_ALL,
	UPDATE_DATA_RETIRO,
	UPDATE_CANCEL_LIST_RETIRO,
	LOADING_DETAILS_RETIRO,
	INITIAL_RETIRO,
	PREVIEW_TICKET_RETIRO,
	UPDATE_REMITENTE_RETIRO,
	FILTRADO_RETIRO
} from "../types/retiroType"
import { validarPermiso } from "../utils/Permission/checkPermission"
import { formatDataRetiro, formatDataPreOrdenEdit } from '../utils/OrdenFlete/DataInsertUpdateUtils'
import { formatMonto } from "../config/formatInput"

export const requestSuccess = (type, payload) => {

    return { type, payload }
}


//Reseter los destintarios
export const resetDestination = (typeAction) => (dispatch, getState) => {
	const { listDestino } = getState().retiros

	const payload  = {
	 	listDestino: typeAction==="created" ? []: listDestino,
	 	infoDestino: { 
	 		rut: "",
		    razon_social: "",
		    telefono: "",
		    email: "",
		    sucursal_id: "",
		    comuna: "",
		    direccion: "",
		    cargas: [],
		    observacion: ""
		}
	}

	dispatch(requestSuccess(SAVE_DESTINO, payload))
}

//Crear retiro
export const createdRetiro = (body) =>  async (dispatch, getState) => {
	try{
		
		const { listDestino } = getState().retiros
		const { configuracion } = getState().config

		if(!validarPermiso(configuracion, "crear_retiro")){
			return { status: false, message: "No tiene permiso de crear retiros" }
		}else{
		
			const { dataDestino, cliente } = formatDataRetiro(body, listDestino)

			const infoRetiro = {
				direccion: body?.direccion_retiro,
    			oficina_id: body?.comuna_origen_id,
    			destinatarios: dataDestino,
    			remitente: cliente
			}
			
			let response = await API.post("retiros", infoRetiro)

			if(response.status){
				const payload = { isPreviewRetiro: true, nroRetiro: response?.data?.numero_retiro, listDestino: [] }
				dispatch(requestSuccess(PREVIEW_TICKET_RETIRO, payload))
			}

	      	return { status: response.status, message: response.message, nroRetiro: response?.data?.numero_retiro }
		}
    }catch(error){
      return {status: false, message:error}
    }
}

//Guardar multiple destinos
export const saveMultipleDestination = (data, typeAction) => (dispatch, getState) => {
	try {
		const { listDestino } = getState().retiros

		let list = []
		if(typeAction==="add"){
			list = [ ...listDestino, data ]
		}else{
			list = listDestino.map(item => {
				if(item.visible===true){
					return data
				}
				return item
			})
		}

		const payload  = {
		 	listDestino: list,
		 	infoDestino: { 
		 		rut: "",
			    razon_social: "",
			    telefono: "",
			    email: "",
			    sucursal_id: "",
			    comuna: "",
			    direccion: "",
			    cargas: [],
			    observacion: ""
			}
		}

		dispatch(requestSuccess(SAVE_DESTINO, payload))

		return { status: true }
	}catch(error){
		return { status: false }
	}	
}

//Guardar carga
export const saveCargas = (body, typeAction) => (dispatch, getState) => {
	const { infoDestino, listDestino } = getState().retiros

	let cargas = infoDestino.cargas
	let cargasEdit = []

	if(typeAction==="add"){
		cargasEdit = [...cargas, body ]	
	}else if(typeAction==="delete"){

		cargasEdit = cargas.filter((item, index) => index!==body.index)
	}else if(typeAction==="edit"){

		cargas[body.index] = { ...body, visible: false }
		cargasEdit = cargas
	}else if(typeAction==="enabled"){
		
		cargasEdit = cargas.map((item, id) => {
			if(id===body.index){
				item.visible=true
			}
			return item
		})
	}

	let payload = {
		listDestino: listDestino.map(item => {
			if(item.visible===true){
				item.cargas = cargasEdit
			}

			return item
		}),
		infoDestino: { ...infoDestino, cargas: cargasEdit}
	}
	dispatch(requestSuccess(SAVE_DESTINO, payload))
}

//Seleccionar destino
export const selectedDestination = (item, index) => (dispatch, getState) => {
	const { listDestino } = getState().retiros

	let destinos = listDestino.map((item, key) => {
		item.visible = false

		if(key===index){
			item.visible = true
		}
		return item
	})

	let payload = {
		listDestino: destinos,
		infoDestino: item
	}

	dispatch(requestSuccess(SELECT_DESTINO, payload))
}

//Eliminar destinatario
export const deleteDestination = (body, typeAction) => (dispatch, getState) => {
	const { listDestino } = getState().retiros

	let destinos = listDestino

	let payload = {}

	payload = destinos.filter((item, index) => index!==body.index)

	dispatch(requestSuccess(EDIT_LIST_DESTINO, payload))
}

//Obtener los estados de búsqueda por retiro
export const getEstadoInitial = () => async (dispatch, getState) => {
	try{
	  	let response = await API.get(`retiros`)

	  	const { data } = response
	  	const payload = { 
			estadoSelected: data?.main, 
			estadosRebajar: data?.secondary
		}

		dispatch(requestSuccess(INITIAL_RETIRO, payload))

      	return { status: response.status, message: "" }
    }catch(error){
    	dispatch(requestSuccess(INITIAL_RETIRO, { estadoSelected: [], estadosRebajar: [] }))
      	return {status: false, message:error}
    }
}

//Búsqueda por parámetros
export const getRetiroByUser = (body, page) => async (dispatch, getState) => {
	try{

		let isLoadingNomina = (body.type_assign === "paysheet") ? true : false
		let isLoadingAsignar = (body.type_assign === "assign") ? true : false
		let isLoadingConsulta = (body.type_assign === "all") ? true : false

		dispatch(requestSuccess(LOADING_DATA, { 
			isLoadingNomina, isLoadingAsignar, isLoadingConsulta, listRetiro: [] 
		}))

	  	let response = await API.post(`retiros/assign/vehicle?page=${page}&limit=500`, body)
		
	  	const payload = {
	  		listRetiro: response.data,
	  		isLoadingNomina: false,
	  		isLoadingAsignar: false
	  	}
		
		dispatch(requestSuccess(LISTADO_ALL, payload))

      	return { status: response.status, message: response.message, countPaginate: response?.total_paginate  }
    }catch(error){
    	dispatch(requestSuccess(LOADING_DATA, { 
    		isLoadingNomina: false, isLoadingAsignar: false, isLoadingConsulta: false, listRetiro: [] 
    	}))
      	return {status: false, message:error}
    }
}

export const getAllRetiroByParams = (body, page) => async (dispatch, getState) => {
	try{

		let isLoadingNomina = (body.type_assign === "paysheet") ? true : false
		let isLoadingAsignar = (body.type_assign === "assign") ? true : false
		let isLoadingConsulta = (body.type_assign === "all") ? true : false

		dispatch(requestSuccess(LOADING_DATA, { 
			isLoadingNomina, isLoadingAsignar, isLoadingConsulta, listRetiro: [] 
		}))

	  	let response = await API.post(`retiros/all?page=${page}&limit=1000`, body)
		
	  	const payload = {
	  		listRetiro: response.data,
	  		isLoadingNomina: false,
	  		isLoadingAsignar: false
	  	}
	
		dispatch(requestSuccess(LISTADO_ALL, payload))

      	return { status: response.status, message: response.message, countPaginate: response?.countPaginate }
    }catch(error){
    	dispatch(requestSuccess(LOADING_DATA, { 
    		isLoadingNomina: false, isLoadingAsignar: false, isLoadingConsulta: false, listRetiro: [] 
    	}))
      	return {status: false, message:error}
    }
}

//Asignar vehículo
export const asignarMovil = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const { configuracion } = getState().config
			if(!validarPermiso(configuracion, "asignar_retiro")){
				reject({ status: false, message: "No tiene permiso de asignar retiros" })
			}else{
				let response = await API.post("retiros/assign/vehicle/update", body)

	      		resolve({ status: response.status, message: response.message })
			}
		  
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

//Nominar retiros
export const saveUpdateState = (body) => async (dispatch, getState) => {
	try{
		const { configuracion } = getState().config
		if(!validarPermiso(configuracion, "nomina_retiro")){
			return { status: false, message: "No tiene permiso de asignar retiros" }
		}else{
			let response = await API.post("retiros/nominate", body)

      		return { status: response.status, message: response.message }
		}
	  
    }catch(error){
      	return {status: false, message:error}
    }
}

//Filtrar por movil en la nómina
export const filtradoMovil = (text) => (dispatch, getState) => {
	const { retiroOriginal } = getState().retiros

	const nomina = (text!=="") ? retiroOriginal.filter(item => 
		item?.mobile_internal_number?.toString()?.toLowerCase()===text.toLowerCase())
		: retiroOriginal

	dispatch(requestSuccess(FILTRADO_MOVIL, nomina))
}

export const filtradoRetiro = (text) => (dispatch, getState) => {
	const { retiroOriginal } = getState().retiros

	const nomina = (text!=="") ? retiroOriginal.filter(item => 
		item?.number_withdrawal_order?.toString()?.toLowerCase()?.includes(text.toLowerCase())) 
		: retiroOriginal
		
	dispatch(requestSuccess(FILTRADO_RETIRO, nomina))
}

//Exportar listado de nomina de retiro en PDF
export const exportarPdfNomina = () => async (dispatch, getState) => {
	try{
		const { rowsNomina } = getState().retiros
		if(rowsNomina.length>0){
			let data = rowsNomina.map(item => {
						return {
							numero_retiro: item.numero_retiro,
							movil: item.camion_numero_interno===null ? "" : item.camion_numero_interno,
							direccion_retiro: item.direccion_retiro,
							comuna_retiro: item.comuna_name,
							rut_remitente: item.rut_remitente,
							remitente: item.razon_social_remitente,
							telefono_remitente: item.telefono_remitente,
							detalle_interno: item.detalle_interno,
							estado: item.ultimo_estado
						}
					})	

	      	let response = await API.putFile(`retiros/download`, {retiros: data })	

	        response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `listado-retiros.pdf`;
                        a.click();
                      });

	      return { status: true, message: "Descargado con éxito" }
	  }else{
	  	return { status: false, message: "No hay registros para descargar" }
	  }
    }catch(error){
      	return {status: false, message:error}
    }
}

//Limpiar el listado de retiro
export const resetLitRetiro = () => (dispatch, getState) => {

	const payload = {
		loading: true,
		list: []
	}

	dispatch(requestSuccess(RESET_LIST_ALL, payload))
}

//Exportar a excel
export const exportRetiroExcel = (type) =>  (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const { retirosAll } = getState().retiros

			const body = { list_retiros: retirosAll }
			
		  	let response = await API.putFile(`retiros/export/excel`, body)	

	       	response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `listado-retiros.xlsx`;
                        a.click();
                      });

	      	resolve({ status: true, message: "Descargado con éxito" })
	    }catch(error){
	      resolve({status: false, message:"Hubo un problema, intente más tarde"})
	    }
	})
}

//Buscar retiro por el número
export const searchRetiroByNro = (numero_retiro) => async (dispatch, getState) => {
	try{
		let payload = {
			loading: true,
			titleDetailRetiro: `RETIRO NRO: ${numero_retiro}`,
			detRetiro: { 
				id: null,
			    numero_retiro: null,
			    fecha: "",
			    direccion_retiro: "",
			    comuna_name: "",
			    sucursal_name: "",
			    rut_remitente: "",
			    razon_social_remitente: "",
			    telefono_remitente: "",
			    check: false,
			    cant_preorden: 1,
			    camion_id: null,
			    ultimo_estado: "",
			    preorden: [],
			    estados: [],
			    email: ""
			}
		}

		dispatch(requestSuccess(SEARCH_RETIRO_BY_NRO, payload))

		let response = await API.get(`retiros/${numero_retiro}`)

		if(response.status){


				let preordenes = response.data.preorden.map(pre => {
					return {
						...pre,
						monto_descuento: pre.monto_descuento===null ? 0 : pre.monto_descuento,
						subtotal_orden: pre.subtotal_orden===null ? 0 : pre.subtotal_orden,
						total_bultos: pre.total_bultos===null ? 0 : pre.total_bultos,
						total: pre.total===null ? 0 : pre.total,
						total_kilos: pre.total_kilos===null ? 0 : pre.total_kilos,
						total_metro_cubico: pre.total_metro_cubico===null ? 0 : pre.total_metro_cubico,
						valor_iva: pre.valor_iva===null ? 0 : pre.valor_iva,
						valor_neto: pre.valor_neto===null ? 0 : pre.valor_neto
					}
				})
				response.data.preorden = preordenes
					
			payload = {
				detRetiro: response.data,
				loading: false,
				titleDetailRetiro: `RETIRO NRO: ${numero_retiro} - ${typeof response.data?.ultimo_estado!=="undefined" ? response.data?.ultimo_estado?.toUpperCase() : ""}`
			}

			dispatch(requestSuccess(SEARCH_RETIRO_BY_NRO, payload))
		}

	    return { status: response.status, message: response.message }
	  
    }catch(error){
		dispatch(requestSuccess(LOADING_DETAILS_RETIRO, {loading: false}))
      	return {status: false, message:error}
    }
}

//Anular el retiro
export const cancelRetiro = (body) => async (dispatch, getState) => {
	try{

		const { retirosAll, detRetiro } = getState().retiros
		const { name } = getState().users
		const { configuracion } = getState().config


		if(!validarPermiso(configuracion, "anular_retiro")){
			return{ status: false, message: "No tiene permiso para anular retiros" }
		}else{
			let response = await API.post("retiros/cancel", body)

			if(response.status){
				let list = retirosAll.map(item => {
					if(parseInt(item.numero_retiro)===parseInt(body.numero_retiro)){
						item.ultimo_estado="Anulado"
					}
					return item
				})

				let info = { 
					estado: "Anulado", 
					fecha_hora: response?.data?.fecha_hora,
					usuario: name,
					observacion: body.descripcion 
				}
				let infoRetiro = detRetiro
				infoRetiro = {...infoRetiro, ultimo_estado: "Anulado", estados: [...infoRetiro.estados, info ]}

				const payload = { list, infoRetiro }

				dispatch(requestSuccess(UPDATE_CANCEL_LIST_RETIRO, payload))
			}

	      	return { status: response.status, message: response.message }
		}
    }catch(error){
      return {status: false, message:error}
    }
}

//Anular el retiro
export const cancelPreOrdenRetiro = (body) => async (dispatch, getState) => {
	try{

		const { retirosAll, detRetiro } = getState().retiros
		const { configuracion } = getState().config

		if(!validarPermiso(configuracion, "anular_preorden")){
			return{ status: false, message: "No tiene permiso para anular pre orden" }
		}else{
			let response = await API.post(`retiros/cancel/preorden`, body)

			if(response.status){
				let listRetiro = retirosAll.map(item => {

					let preordenAll = item?.preorden?.map(preorden => {
						if(preorden.numero_preorden===body.numero_documento){
							preorden.ultimo_estado="Nulas"
						}
						return preorden
					})
					
					return { ...item, preorden: preordenAll}
				})
				
				let  preordens = detRetiro?.preorden?.map(preorden => {
					if(preorden.numero_preorden===body.numero_documento){
						preorden.ultimo_estado="Nulas"
					}
					return preorden
				})
				let infoRetiro = {...detRetiro, preorden: preordens}

				const payload = { list: listRetiro, infoRetiro }

				dispatch(requestSuccess(UPDATE_CANCEL_LIST_RETIRO, payload))
			}

	      	return { status: response.status, message: response.message }
		}
    }catch(error){
      return {status: false, message:error}
    }
}

//Editar Remitente retiro
export const updatedRemitenteRetiro = (body) =>  async (dispatch, getState) => {
	try{
		//const { configuracion } = getState().config
		const { detRetiro } = getState().retiros
		const { configuracion } = getState().config

		if(!validarPermiso(configuracion, "editar_retiro")){
			return { status: false, message: "No tiene permiso para editar retiros" }
		}else{

			const { cliente } = formatDataRetiro(body, detRetiro?.preorden)
			const data = { 
				cliente,
				forma_pago_id: body.forma_pago_id
			 }
			
			let response = await API.post(`retiros/update/${detRetiro?.numero_retiro}/remitente`, data)

			if(response.status){

				let infoRetiro = detRetiro
				infoRetiro = {
					...infoRetiro, 
					rut_remitente: body.rut_remitente, 
					razon_social_remitente: body?.razon_social_remitente,
					telefono_remitente: body?.telefono_remitente,
					email_remitente: body?.email_remitente,
					sucursal_origen_name: body?.sucursal_name,
					comuna_origen_name: body?.comuna_name,
					direccion_retiro: body?.direccion_retiro
				}
				const payload = { infoRetiro }
				dispatch(requestSuccess(UPDATE_REMITENTE_RETIRO, payload))
			}

	      	return { status: response.status, message: response.message }
	  	}
    }catch(error){
      return {status: false, message:error}
    }
}

//Editar Remitente retiro
export const updateDataPreOrden = (body, numero_preorden) => async (dispatch, getState) => {
	try{
		const { detRetiro } = getState().retiros
		const { name } = getState().users
		const { configuracion } = getState().config

		if(!validarPermiso(configuracion, "editar_retiro")){
			return { status: false, message: "No tiene permiso para editar la pre-orden" }
		}else{

			let info = formatDataPreOrdenEdit(body, detRetiro?.numero_retiro)

			let response = await API.post(`retiros/updated/${numero_preorden}/preorden`, info)

			if(response.status){
				
				let updateRetiro = detRetiro
				if(response.data.retiro?.nombre!==""){
					updateRetiro.estados = [
					 	...updateRetiro.estados, 
						{
						    estado: response.data?.retiro.nombre,
						    fecha_hora: response.data?.retiro.hora,
						    usuario: name,
						    observacion: "",
						    type: ""
						}
					]
				}
				for(let index in updateRetiro.preorden){
					let preorden = updateRetiro.preorden[index]

					if(preorden.numero_preorden===numero_preorden){
						updateRetiro.preorden[index] = {
							...preorden, 
							...body ,
							telefono_avisas: body.telefono_destinatario,
							is_edit: response?.data?.is_edit,
							ultimo_estado: response.data.preorden?.nombre!=="" ? response.data.preorden.nombre : preorden?.ultimo_estado
						}
					}
				}

				const payload = {
					detRetiro: updateRetiro
				}

				dispatch(requestSuccess(UPDATE_DATA_RETIRO, payload))
			}

		    return { status: response.status, message: response.message }
	  	}
    }catch(error){
      return {status: false, message:error}
    }
}

//Aplicar total
export const applyResultTotales = (descuento) => (dispatch, getState) => {
	let result = {
		total_bultos: 0,
      	total_kilos: 0,
      	total_metro_cubico: 0,
      	subtotal_orden: 0,
      	valor_neto: 0,
      	valor_iva: 0,
      	total: 0,
      	montoDiferencia: 0
	}

	try{

		const { preordenSelected } = getState().retiros

		let dataCargaOrden = preordenSelected.cargas
		
		if(dataCargaOrden && dataCargaOrden.length > 0){

	    	let totalBulto = dataCargaOrden.reduce((acumulado, bultos) => acumulado + parseFloat(bultos.cantidad), 0)
			let totalKilo = dataCargaOrden.reduce((acumulado, kilos) => acumulado + parseFloat(kilos.peso), 0)
			let totalM3 = dataCargaOrden.reduce((acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico), 0)
			let subTotal = dataCargaOrden.reduce((acumulado, valor) => acumulado + parseFloat(valor.valor_neto), 0)

			let totals = 0
			let applyDscto = 0
			let totaliva = 0
			let montoDiferencia = 0
			if(descuento==="Si"){
				applyDscto = Math.round(subTotal * (10/100))
				totals = subTotal - applyDscto
				montoDiferencia = Math.round(totals / 1.19)
				totaliva=totals-montoDiferencia
				subTotal = totals - totaliva
			}else{
				totals = subTotal
				montoDiferencia = Math.round(totals / 1.19)
				totaliva=totals-montoDiferencia
				subTotal = totals - totaliva
			}

			result = {
				total_bultos: totalBulto,
	          	total_kilos: totalKilo,
	          	total_metro_cubico: totalM3,
	          	subtotal_orden: subTotal,
	          	valor_neto: subTotal,
	          	valor_iva: totaliva,
	          	total: totals,
	          	montoDiferencia
			}
	  	}
	  	return result

	}catch(error){
		return result
	}
}

//Visible listado
export const isVisibleListado = (isvisible) => (dispatch, getState) => {
	dispatch(requestSuccess(IS_VISIBLE_LISTADO, isvisible))
}

//Resultados
export const resultTotales = (carga, descuento, idPreoden) => (dispatch, getState) => {
	let result = {
		total_bultos: 0,
      	total_kilos: 0,
      	total_metro_cubico: 0,
      	subtotal_orden: 0,
      	valor_neto: 0,
      	valor_iva: 0,
      	total: 0,
      	montoDiferencia: 0
	}

	try{
		const { detRetiro } = getState().retiros

		let orden = detRetiro.preorden.find(item => item.orden_flete_id===idPreoden)
	
		for(let key in orden.cargas){
				
			let cargaOrden = orden.cargas[key]
			if(parseInt(cargaOrden.id)===parseInt(carga.id)){
				cargaOrden.valor_neto = carga.valor_neto
			}
		}
		let dataCargaOrden = orden.cargas
	
		if(dataCargaOrden && dataCargaOrden.length > 0){

	    	let totalBulto = dataCargaOrden.reduce((acumulado, bultos) => acumulado + parseFloat(bultos.cantidad), 0)
			let totalKilo = dataCargaOrden.reduce((acumulado, kilos) => acumulado + parseFloat(kilos.peso), 0)
			let totalM3 = dataCargaOrden.reduce((acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico), 0)
			let subTotal = dataCargaOrden.reduce((acumulado, valor) => acumulado + parseFloat(valor.valor_neto), 0)

			let totals = 0
			let applyDscto = 0
			let totaliva = 0
			let montoDiferencia = 0
			if(descuento==="Si"){
				applyDscto = Math.round(subTotal * (10/100))
				totals = subTotal - applyDscto
				montoDiferencia = Math.round(totals / 1.19)
				totaliva=totals-montoDiferencia
				subTotal = totals - totaliva
			}else{
				totals = subTotal
				montoDiferencia = Math.round(totals / 1.19)
				totaliva=totals-montoDiferencia
				subTotal = totals - totaliva
			}

			result = {
				total_bultos: totalBulto,
	          	total_kilos: totalKilo,
	          	total_metro_cubico: totalM3,
	          	subtotal_orden: subTotal,
	          	valor_neto: subTotal,
	          	valor_iva: totaliva,
	          	total: totals,
	          	montoDiferencia
			}
	  	}
	  	return result

	}catch(error){
		return result
	}
}

//Seleccionar pre-orden
export const selectedPreordenByRetiro = (item) => (dispatch, getState) => {
	
	let preordenSelected = item

	let dataCargaOrden = preordenSelected.cargas

	if(dataCargaOrden && dataCargaOrden.length > 0){

		let totalBulto = dataCargaOrden.reduce((acumulado, bultos) => acumulado + parseFloat(bultos.cantidad), 0)
		let totalKilo = dataCargaOrden.reduce((acumulado, kilos) => acumulado + parseFloat(kilos.peso), 0)
		let totalM3 = dataCargaOrden.reduce((acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico), 0)
		let subTotal = dataCargaOrden.reduce((acumulado, valor) => acumulado + parseFloat(valor.valor_neto), 0)

		let totals = 0
		let applyDscto = 0
		let totaliva = 0
		let montoDiferencia = 0
		if(item?.descuento==="Si"){
			applyDscto = Math.round(subTotal * (10/100))
			totals = subTotal - applyDscto
			montoDiferencia = Math.round(totals / 1.19)
			totaliva=totals-montoDiferencia
			subTotal = totals - totaliva
		}else{
			totals = subTotal
			montoDiferencia = Math.round(totals / 1.19)
			totaliva=totals-montoDiferencia
			subTotal = totals - totaliva
		}

		preordenSelected = {
			...preordenSelected,
			total_bultos: formatMonto(totalBulto),
			total_kilos: formatMonto(totalKilo),
			total_metro_cubico: formatMonto(totalM3),
			subtotal_orden: formatMonto(subTotal),
			valor_neto: formatMonto(subTotal),
			valor_iva: formatMonto(totaliva),
			total: formatMonto(totals),
			montoDiferencia
		}
		
	}

	const payload = {
		preordenSelected
	}

	dispatch(requestSuccess(SELECTED_PREORDEN, payload))
}


export const getDataTicketRetiroPreOrden = (numero_retiro) => async (dispatch, getState) => {
	try{
	
		let response = await API.get(`retiros/${numero_retiro}/ticket`)

		return { status: response.status, message: response.message, data: response?.data }

	}catch(error){
		return {status: false, message:error, data: []}
	}
}