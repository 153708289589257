const Conductor = { 
	columnas: [
		{
			name: 'RUT',
			selector: (row, i) => row.rut,
			sortable: true
		},
		{
			name: 'Nombre y Apellido',
			selector: (row, i) => row.nombre_completo,
			sortable: true
		},
		{
			name: 'Licencia conducir',
			selector: (row, i) => row.licencia_conducir,
			sortable: true,
			cell: row =>  <>
							{row.imagen && (
								<a href={row.imagen} target="_blank" rel="noopener noreferrer" key={row.numero_orden}>
									<img className="img-foto-licencia mr-2"  src={row.imagen} alt="licencia" />
									<span>{row.licencia_conducir}</span>
								</a>
							)}
						  </>
		},
		{
			name: 'Vencimiento',
			selector: (row, i) => row.fecha,
			sortable: true
		},
		{
			name: 'Activo',
			selector: (row, i) => row.name_estatus,
			sortable: true
		}
	]
}

export default Conductor