import React, { useEffect, useState } from "react"
import {  
	Button,
	Col,
	Row
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import TitlePrincipal from "../../components/Layouts/TitlePrincipal"
import GlobalSearch from "../../components/Orders/Consultas/GlobalSearch"
import PanelSeleccionado from "../../components/Orders/Consultas/PanelSeleccionado"
import { useDispatch, useSelector } from "react-redux"
import { getHome, exportarExcel, exportPdf } from "../../actions/orderAction"
import { useHistory } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

const SearchOrden = () => {
	
	const dispatch = useDispatch()
	const { loadingSearch, disabledSearch  } = useSelector((state) => state.orders)
	const history = useHistory()
	const [ isLoadingPdf, setIsLoadingPdf ] = useState(false)
	const [ isLoadingExcel, setIsLoadingExcel ] = useState(false)

	useEffect(() => {
		dispatch(getHome())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	const handleClick = async (event) => {
		setIsLoadingExcel(true)
        await dispatch(exportarExcel())
        setIsLoadingExcel(false)
    }

    const handleExportPdf = async (event) => {
    	setIsLoadingPdf(true)
    	const response = await dispatch(exportPdf())
    	if(response.status){
    		setIsLoadingPdf(false)
    		history.push("/orders/preview/file")
    	}else{
    		setIsLoadingPdf(false)
    	}
    }

	return (
	    <Layout 
	    	titlePanel="Consulta de Órdenes"
	    	modulo="orders_search"
	    >
	    	
	    	<TitlePrincipal title="Filtros" />
	    	<Row className="bg-gray panel-contenedor--info">
		    	<GlobalSearch loadingSearch={loadingSearch} />
	    	</Row>
	    	{ !loadingSearch && (
				<Row>
					<Col sm={8} xs={12}>
						<PanelSeleccionado />
					</Col>
					<Col sm={4} xs={12}>
						<div className="mt-5 pt-4">
							<Button 
								variant="warning" 
								type="button" 
								onClick={(event) => handleClick(event)}  
								className="mr-1 pl-5 pr-5" 
								disabled={disabledSearch}> 
								{isLoadingExcel ? "Espere..." : "Excel"}
							</Button>
							<Button 
								variant="warning" 
								type="button" 
								onClick={(event) => handleExportPdf(event)}  
								className="pl-5 pr-5" 
								disabled={disabledSearch}> 
								{isLoadingPdf ? "Espere..." : "Imprimir"}
							</Button>
						</div>
					</Col>
				</Row>
		    )}
			<ToastContainer />
	    </Layout>
	)
}

export default SearchOrden