import { Fragment, useEffect, useState } from "react"
import {  Form } from 'react-bootstrap'
import { useEditRetiro } from "../../../hooks/Retiro/useEditRetiro"
import { useTool } from "../../../hooks/Tools/useTool"
import { Controller } from "react-hook-form"
import { useDispatch } from "react-redux"
import ModalSelectNroCuenta from "../../Orders/Clientes/ModalSelectNroCuenta";
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { validateOnlyRUT, validateOnlyLetter, onPaste, validateOnlyNumber } from "../../../utils/validationInput"
const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON	

const FormRemitente = ({ detRetiro, isAdmin, searchCliente, validarRut, toast, forma_pago }) => {

	const dispatch = useDispatch()
	const { onSubmit, register, handleSubmit, 
		 control, setValue, loading, errors, getValues } = useEditRetiro(detRetiro)

	const { cargarOficinas, sucursales, oficina } = useTool()
	const [ openCloseCta, setOpenCloseCta ] = useState(false)
	const [ clienteSelected, setClienteSelected] = useState(false)

	useEffect(() => {
		if(oficina.length===0){
			cargarOficinas(
				undefined, 
				detRetiro?.sucursal_id
			)
		}	
		setValue("sucursal_origen_id", detRetiro?.sucursal_id)		
		setValue("comuna_origen_id", detRetiro?.comuna_id)
		setValue("forma_pago_id", detRetiro?.forma_pago_id)	
		setValue("rut_remitente", detRetiro?.rut_remitente)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detRetiro?.sucursal_id])

	const onKeyDownRemitente = async (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			await searchClienteRemitente()
		}
	}

	const onBlurHandle = async (event) => {
		if(event.target.value.length!==0){
			await searchClienteRemitente()
		}
	}

	const searchClienteRemitente = async () => {
		let search
		const rut = document.getElementById("rut_remitente").value
		const isValid = validarRut(rut)
		if(isValid.status){
			search = {search_type : "rut_cliente", value: rut }
		}else{
			toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
			return false
		}	
		
		const data = await dispatch(searchCliente(search))

		if(data.status){

			  if(rut===RUT_MESON){
				setValue("razon_social_remitente", "")
				setValue("codigo_interno", "")
				setValue("direccion_retiro", "")
			}else if(data?.cliente?.numero_cuentas?.length===1){
				setValue("rut_remitente", data?.cliente.rut)
				setValue("razon_social_remitente", data?.cliente.nombre_completo)
				setValue("telefono_remitente", data?.cliente?.telefono)
				setValue("email_remitente", data?.cliente?.email)
				setValue("codigo_interno", data?.cliente?.numero_cuentas[0]?.numero_cuenta)
				setValue("direccion_retiro", data?.cliente.direccion)
			}else if(data?.cliente?.numero_cuentas?.length>0){
				setClienteSelected(data.cliente)
				setOpenCloseCta(true)
				//setValue("razon_social_remitente", data.cliente.nombre_completo)
				//document.getElementById("razon_social_remitente").readOnly=true
			}else{
				setValue("razon_social_remitente", data.cliente.nombre_completo)
				setValue("codigo_interno", "")
				setValue("telefono_remitente", data?.cliente?.telefono)
				setValue("email_remitente", data?.cliente?.email)
				setValue("direccion_retiro", data?.cliente.direccion)
			}

		}else{
			setValue("razon_social_remitente", "")
			setValue("telefono_remitente", "")
			setValue("codigo_interno", "")
			setValue("email_remitente", "")
			setValue("direccion_retiro", "")
			document.getElementById("razon_social_remitente").readOnly=false
		}
	}

	const handleOpenCloseCta = () => {
		setOpenCloseCta(!openCloseCta)
		setValue("razon_social_remitente", "")
		document.getElementById("razon_social_remitente").readOnly=true
		setValue("telefono_remitente", "")
		setValue("email_remitente", "")
		setValue("codigo_interno", "")
	}	

	const selectCodeClient = (codigo) => {

		setValue("rut_remitente", clienteSelected.rut)
		setValue("razon_social_remitente", clienteSelected.nombre_completo)
		document.getElementById("razon_social_remitente").readOnly=true
		setValue("telefono_remitente", clienteSelected?.telefono)
		setValue("email_remitente", clienteSelected?.email)
		setValue("direccion_retiro",clienteSelected.direccion)
		//setHabCliente(true)
			

		//setValue("codigo_interno", "si")
		//setCodigo(true)
		setValue("codigo_interno", codigo)
		setOpenCloseCta(!openCloseCta)
	}

	const cambiarFormaPago = (event) => {
		if(parseInt(event.target.value)===3){
			if(getValues("codigo_interno")===""){
				toast.error("El cliente no es cuenta correntista", {position: toast.POSITION.TOP_RIGHT})
				setValue("forma_pago_id", "")
				return false
			}
		}
	}


	return (
		<Fragment>
			<div className="row">
				<div className="col-6">
					<p className="bg-warn-blue mb-0 fw-600 text-center p-0 mb-4">Remitente</p>
					<form className="row mt-4 mb-4">		
						<div className="col-sm-12">
							<h6 className="mb-4">Datos Personales</h6>
							<div className="row">
								<div className="col-sm-12 col-md-4 col-lg-4">
									<Form.Group controlId="rut_remitente">
										<Form.Label>Rut</Form.Label>
										<Controller
											render={(props) => (
												<MaskedInput
													mask={RutTextMask}
													className="form-control"
													id="rut_remitente" 
													name="rut_remitente"
													value={props.value}
													maxLength={12}
													readOnly={!isAdmin}
													defaultValue={detRetiro?.rut_remitente || ""}
													onChange={(value)=>{
														props.onChange(value)
													}}
													//onBlur={(event) => handleBlurRut(event, setError)}
													onKeyDown={(event) => onKeyDownRemitente(event)}
													onBlur={(event) => onBlurHandle(event)}
													onKeyPress={(event) => validateOnlyRUT(event)}
												/>
											)}
											control={control}
											name="rut_remitente"  
											rules={{required: {value: true, message: 'Requerido'}}}
										/>
										{/**<Form.Control 
											type="text" 
											name="rut_remitente"
											readOnly={!isAdmin}
											maxLength={14}
											defaultValue={detRetiro?.rut_remitente || ""}
											ref={register({required: {value: true, message: 'Requerido'}})}
											onKeyDown={(event) => onKeyDownRemitente(event)}
											onBlur={(event) => onBlurHandle(event)}
										/> */}
										{errors.rut_remitente && (
											<Form.Text className="text-danger">
												{errors?.rut_remitente?.message}
											</Form.Text>
										)}
									</Form.Group>
								</div>
								<div className="col-sm-12 col-md-8 col-lg-8">
									<Form.Group controlId="razon_social_remitente">
										<Form.Label>Remitente</Form.Label>
										<Form.Control 
											type="text" 
											name="razon_social_remitente" 
											readOnly={!isAdmin}
											maxLength={160}
											defaultValue={detRetiro?.razon_social_remitente || ""}
											ref={register({required: {value: true, message: 'Requerido'}})}
											onKeyPress={(event) => validateOnlyLetter(event)}
											onPaste={(event) => onPaste(event)}
										/>
										{errors.razon_social_remitente && (
											<Form.Text className="text-danger">
												{errors?.razon_social_remitente?.message}
											</Form.Text>
										)}
									</Form.Group>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-4 col-lg-4">
									<Form.Group controlId="codigo_interno">
										<Form.Label>Cod. interno</Form.Label>
										<Form.Control 
											type="text" 
											name="codigo_interno" 
											maxLength={4}
											readOnly={true}
											defaultValue={detRetiro?.codigo_interno || ""}
											ref={register({
												maxLength: {value: 4, message: 'Mínimo 4 dígitos'}
											})}
											autoComplete="off"
											onKeyPress={(event) => validateOnlyNumber(event)}
											onPaste={(event) => onPaste(event)}
										/>
										{errors.codigo_interno && (
											<Form.Text className="text-danger">
												{errors?.codigo_interno?.message}
											</Form.Text>
										)}
									</Form.Group>
								</div>
								<div className="col-sm-12 col-md-8 col-lg-8">
									<Form.Group controlId="email_remitente">
										<Form.Label>Teléfono</Form.Label>
										<Form.Control 
											type="text" 
											name="telefono_remitente" 
											readOnly={!isAdmin}
											maxLength={9}
											defaultValue={detRetiro?.telefono_remitente || ""}
											ref={register({
												required: {value: true, message: 'Requerido'},
												minLength: {value: 9, message: 'Mínimo 9 dígitos'}
											})}
											autoComplete="off"
											onKeyPress={(event) => validateOnlyNumber(event)}
											onPaste={(event) => onPaste(event)}
										/>
										{errors.telefono_remitente && (
											<Form.Text className="text-danger">
												{errors?.telefono_remitente?.message}
											</Form.Text>
										)}
									</Form.Group>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-4 col-lg-4">
									<Form.Group controlId="telefono_remitente">
										<Form.Label>Forma de pago</Form.Label>
										<Form.Control 
											as="select"
											name="forma_pago_id" 
											ref={register({required: {value: true, message: 'Requerido'}})}
											onChange={(event) => cambiarFormaPago(event)}
										>
										<option value="">Seleccione</option>
										{
											forma_pago.length > 0 && (
												forma_pago?.filter(item => item.descripcion!=="Contado").map(pago => <option key={pago.id} value={pago.id}>{pago.descripcion}</option>)
											)
										}
									</Form.Control>
									{errors.forma_pago_id && (
										<Form.Text className="text-danger">
											{errors?.forma_pago_id?.message}
										</Form.Text>
									)}
									</Form.Group>
								</div>
								<div className="col-sm-12 col-md-8 col-lg-8">
									<Form.Group controlId="email_remitente">
										<Form.Label>E-mail</Form.Label>
										<Form.Control 
											type="text" 
											name="email_remitente" 
											readOnly={!isAdmin}
											maxLength={255}
											defaultValue={detRetiro?.email || ""}
											ref={register({
												required: {value: true, message: 'Requerido'},
												//eslint-disable-next-line
												pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
											})}
											onPaste={(event) => onPaste(event)}
										/>
										{errors.email_remitente && (
											<Form.Text className="text-danger">
												{errors?.email_remitente?.message}
											</Form.Text>
										)}
									</Form.Group>
								</div>
							</div>
						</div>
						<div className="col-sm-12">
							<h6 className="mb-4 mt-3">Dirección de retiro</h6>
							<div className="row">
								<div className="col-sm-12 col-md-6 col-lg-6">
									<Form.Group controlId="sucursal_origen_id">
										<Form.Label>Sucursal</Form.Label>
											{ !isAdmin ?
												<Form.Control 
													type="text" 
													name="sucursal_origen_name" 
													readOnly={!isAdmin}
													defaultValue={detRetiro?.sucursal_name || ""}
													ref={register({required: {value: true, message: 'Requerido'}})}
												/>
												:
												<Controller
													control={control}
													name="sucursal_origen_id"  
													rules={{required: {value: true, message: 'Requerido'}}}
													render={(props) => (
														<select 
															value={props.value}
															className="form-control" 
															id="sucursal_origen_id"
															name="sucursal_origen_id" 
															onChange={(value)=>{
																props.onChange(value)
																cargarOficinas(value, "")
															}}
														>
															<option value="">Seleccione</option>
															{
																sucursales.length > 0 && (
																	sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
																)
															}
														</select>
													)}
													defaultValue={detRetiro?.sucursal_id}
												/>
											}
									</Form.Group>
								</div>
								<div className="col-sm-12 col-md-6 col-lg-6">
									<Form.Group controlId="comuna_origen_id">
										<Form.Label>Comuna</Form.Label>
											{
												!isAdmin ? 
													<Form.Control 
														type="text" 
														name="comuna_origen_name" 
														readOnly={!isAdmin}
														defaultValue={detRetiro?.comuna_name || ""}
														ref={register({required: {value: true, message: 'Requerido'}})}
													/>
												:
												<Controller
													control={control}
													name="comuna_origen_id"  
													rules={{required: {value: true, message: 'Requerido'}}}
													render={(props) => (
														<select 
															value={props.value}
															className="form-control" 
															id="comuna_origen_id" 
															name="comuna_origen_id"
															onChange={(value)=>{
																props.onChange(value)
																setValue("comuna_origen_id", value.target.value)
															}}  
														>
															<option value="">Seleccione</option>
															{
																oficina.length > 0 && (
																	oficina.map(comuna => <option key={comuna.id} value={comuna.id}>{comuna.nombre}</option>)
																)
															}
														</select>
													)}
													defaultValue={detRetiro?.comuna_id}
												/>
											}
									</Form.Group>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Form.Group controlId="direccion_retiro" >
										<Form.Label>Dirección</Form.Label>
										<textarea 
												type="text" 
												name="direccion_retiro"
												className="form-control" 
												readOnly={!isAdmin}
												rows={3}
												cols={3}
												maxLength={255}
												defaultValue={detRetiro?.direccion_retiro || ""}
												ref={register({required: {value: true, message: 'Requerido'}})}
											/>
									</Form.Group>
								</div>
							</div>
						</div>
						{(isAdmin && detRetiro?.ultimo_estado!=="Finalizado") &&
							<div className="col-sm-12 text-right">
								<button 
									type="button" 
									className="btn btn-warning"
									onClick={handleSubmit((data) =>onSubmit(data))}
									disabled={loading}
								>{loading ? 'Editando...' : 'Guardar cambios' }</button>
								
							</div>
						}
					</form>
				</div>
				<div className="col-6">
					<p className="bg-warn-blue mb-0 fw-600 text-center pr-0 mb-4">Estados</p>
					<div className="table-responsive">
						<table className="table table-bordered table-sm bg-white table-retiro">
							<thead className="bg-warn-blue">
								<tr>
									<th className="text-center">Fecha/Hora</th>
									<th className="text-center">Estado</th>
									<th className="text-center">Usuario</th>
									<th className="text-center" style={{"width": "150px"}}>Observación</th>
								</tr>
							</thead>
							<tbody>
								{detRetiro?.estados?.length>0 &&
									detRetiro?.estados?.map((item, key) => 
											<tr key={key}>
												<td>{item.fecha_hora}</td>
												<td>{item.estado}</td>
												<td>{item.usuario}</td>
												<td>{item.observacion}</td>
											</tr>
										)
								}
								{detRetiro?.estados?.length===0 && <tr><td colSpan={4}>No tiene estados asignado</td></tr>}
							</tbody>
						</table>
					</div>
				</div>

			</div>
			{openCloseCta && 
				<ModalSelectNroCuenta 
					handleOpenCloseCta={handleOpenCloseCta} 
					openCloseCta={openCloseCta}  
					cliente={clienteSelected}
					selectCodeClient={selectCodeClient}
				/>}	
		</Fragment>
	)
}

export default FormRemitente