import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addCorrelativo, editCorrelativo, isVisibleFormularioCorrelativo } from "../../../actions/correlativoAction"
import { validateOnlyNumber, onPaste, validateOnlyLetter } from "../../../utils/validationInput"

const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	const { corIsEdit  } = useSelector((state) => state.correlativo)
    
    const onSubmit = async (data, e) => {
		setIsLoading(true)

		if(isNaN(data?.correlativo_actual)){
			setIsLoading(false)
			toast.error("El correlativo debe ser númerico", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		if(parseInt(data?.correlativo_actual)>2147483647){
			setIsLoading(false)
			toast.error("El número del correlativo está fuera del rango", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
		let response

		if(corIsEdit){
			response = await dispatch(editCorrelativo(data))
		}else{
			response = await dispatch(addCorrelativo(data))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

	const handleBack = () => {
		dispatch(isVisibleFormularioCorrelativo(false))
	}


	return (
        <Form id="frmUsuario" onSubmit={handleSubmit(onSubmit)}>
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!corIsEdit ? 'CREAR CORRELATIVO' : 'EDITAR CORRELATIVO' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
			     	<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="title_modulo" >
					      		<Form.Label>Nombre del módulo</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="title_modulo" 
						                autoFocus
						                defaultValue={datos?.title_modulo || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
										 onKeyPress={(event) => validateOnlyLetter(event)}
										 onPaste={(event) => onPaste(event)}
										 maxLength={15}
								    />
								{errors.title_modulo && (
							    	<Form.Text className="text-danger">
							      		{errors?.title_modulo?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="correlativo_actual" >
					      		<Form.Label>Correlativo Actual</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="correlativo_actual" 
						                defaultValue={datos?.correlativo_actual || ""}
										onKeyPress={(event) => validateOnlyNumber(event)}
										 onPaste={(event) => onPaste(event)}
										maxLength={12}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
								    />
								{errors.correlativo_actual && (
							    	<Form.Text className="text-danger">
							      		{errors?.correlativo_actual?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>
					
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
					<Button variant="secondary" type="button"  className="pl-5 pr-5 mr-2" onClick={()=>handleBack()}>
					    Regresar
					</Button>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : corIsEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form>
	)
}

export default Formulario