import React from "react"
import {  
	Row,
	Col
} from 'react-bootstrap'

const TitlePrincipal = ({ title, classTitle }) => {
	return (
		<Row className={!classTitle ? "bg-warn-blue" : `${classTitle}`}>
			<Col className="text-center pt-0">
				<p className="mb-0 fw-600">{ title }</p>
			</Col>
		</Row>
	)
}

export default TitlePrincipal