import { 
	LISTADO_DATA_CA,
	SELECTED_ROWS_CA,
	IS_VISIBLE_FORMULARIO_CA,
	SEARCH_CA,
	DATA_EDIT_CA,
	DATA_ADD_CA,
	DATA_ACTIVAR_DESACTIVAR_CA,
	DATA_DELETE_CA
} from "../types/camionType"

const INITIAL_STATE = {
	caListado: [],
	caIsCargando: true,
	caIsForm: false,
	caIsEdit: false,
	caDatos: {},
	caIsSelectedRows: [],
	caBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	}
}

const camionReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_CA:
			return {
				...state,
				caListado: action.payload,
				caIsCargando: false
			}
		case IS_VISIBLE_FORMULARIO_CA:
			return {
				...state,
				caIsForm: action.payload.isForm,
				caIsEdit: action.payload.isEdit,
				caDatos: action.payload.datos,
				caBtnActivar: action.payload.btnActivar,
				caIsSelectedRows: []
			}
		case SEARCH_CA:
			return {
				...state,
				caDatos: action.payload.seletected,
				caIsForm: action.payload.isForm,
				caIsEdit: action.payload.isEdit,
				caBtnActivar: action.payload.btnActivar
			}
		case DATA_ADD_CA:
			return{
				...state,
				caListado: action.payload.listado,
				caIsForm: action.payload.isForm
			}
		case DATA_EDIT_CA:
			return {
				...state,
				caListado: action.payload.listado,
				caDatos: action.payload.datos,
				caIsForm: action.payload.isForm,
				caBtnActivar: action.payload.btnActivar
			}
		case SELECTED_ROWS_CA:
			return {
				...state,
				caIsSelectedRows: action.payload.rows,
				caBtnActivar: action.payload.btnActivar
			}
		case DATA_ACTIVAR_DESACTIVAR_CA:
			return {
				...state,
				caIsSelectedRows: action.payload.rows,
				caBtnActivar: action.payload.btnActivar,
				caListado: action.payload.listado
			}
		case DATA_DELETE_CA:
			return {
				...state,
				caListado: action.payload
			}
		default: return state
	}
}

export default camionReducer