import API from "../config/api"
import {
	LISTADO_DATA_SU,
	SELECTED_ROWS_SU,
	IS_VISIBLE_FORMULARIO_SU,
	SEARCH_SU,
	DATA_ADD_SU,
	DATA_EDIT_SU,
	DATA_ACTIVAR_DESACTIVAR_SU,
	DATA_DELETE_SU
} from "../types/sucursalType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const listadoSucursal = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/sucursals")
			dispatch(requestSuccess(LISTADO_DATA_SU, response.sucursals))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const getSelectedRowsSucursal = (rows) => (dispatch, getState) => {
	let estatus = ""
	let title = ""

	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].is_activo===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
	}
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount>0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}
	dispatch(requestSuccess(SELECTED_ROWS_SU, payload))
}

export const isVisibleFormularioSucursal = (visible, isOficina) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		},
		visibleOficina: isOficina
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_SU, payload))
	  	 
	}
}

export const searchPorIdSucursal = (id, visible, oficina) => (dispatch, getState) => {
	const {suListado} = getState().sucursales
	
    const payload = {
		seletected: suListado.find(sucursal => sucursal.id===id),
		isForm: visible,
		isEdit: visible,
		visibleOficina: oficina
	}


	if(payload){
	  	dispatch(requestSuccess(SEARCH_SU, payload))
	  	 
	}
}

export const addSucursal = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.post("admin/sucursals", body)

		    if(response.status){
				const cuenta = {
					...body, 
					id: response.id, 
					is_activo: 1,
					name_estatus: "Activado"
				}
				const {suListado} = getState().sucursales
				const data = [...suListado, cuenta]
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_SU, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editSucursal = (body, name_sucursal) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {suListado, suDatos} = getState().sucursales

		  	let response = await API.post(`admin/sucursals/${suDatos.id}`, body)

		    if(response.status){
				
				const data =suListado.map(sucursal => {
					if(sucursal.id === suDatos.id){
						return {
							...body, 
							id: suDatos.id, 
							is_activo: sucursal.is_activo,
							name_estatus: sucursal.name_estatus
						};
					}
					return sucursal
				})  
				const payload = { 
					listado: data, 
					isForm: false, 
					datos: {},
					btnActivar: {
						isVisible: false,
						nameBtn: "Desactivar",
						title: "Desactivado"
					}
				}	
				
		        dispatch(requestSuccess(DATA_EDIT_SU, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changeStatus = (id, opcion, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {suListado, suIsSelectedRows} = getState().sucursales

			const body = {
				type: "estatus",
				opcion: opcion.toLowerCase()
			}

		  	let response = await API.post(`admin/sucursals/${id}`, body)

		    if(response.status){
				
				const listado = suListado.map(sucursal => {
					
					if(sucursal.id === id){
						return {...sucursal, is_activo: status, name_estatus: opcion};
					}
					return sucursal
				})  

				const payload = {
					rows: {
						...suIsSelectedRows,
						selectedRows: suIsSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								is_activo: status,
								name_estatus: opcion
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado
				}

		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_SU, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteSucursal = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {suListado} = getState().sucursales

		  	let response = await API.get(`admin/sucursals/delete/${id}`)

		    if(response.status){
				
				const payload =suListado.filter(sucursal => sucursal.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_SU, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

