import { Fragment } from "react"
import {  
	Row,
	Form,
	Col,
	Button
} from 'react-bootstrap'
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"


const FormAsignar = ({ register, errors, isLoading, estadosRebajar }) => {


	return (
		<Fragment>
			<Row className="bg-soft-blue panel-contenedor--info">
				<Col xs={12}>
					<Form.Row>
					    <Col lg={4} md={4} sm={4}>
					     	<Form.Group as={Col} controlId="estado_id">
						    	<Form.Label>Estado</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="estado_id" 
						          		ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		estadosRebajar.length > 0 && (
							    			estadosRebajar.map(estado => <option key={estado.id} value={estado.id}>{estado.descripcion}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.estado_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.estado_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg={8} md={8} sm={8}>
					      	<Form.Group as={Col} controlId="detalle" >
					      		<Form.Label>Detalle</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="detalle" 
										maxLength={255}
						             	ref={register}
										 onKeyPress={(event) => validateOnlyLetter(event)}
										 onPaste={(event) => onPaste(event)}
								    />
								{errors.detalle && (
							    	<Form.Text className="text-danger">
							      		{errors?.detalle?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col xs={12}>
							<Form.Group as={Col}>
						    	<p className="font-weight-bold fs-12">
									**Antes de rebajar, debe seleccionar los retiros por medio del cuadro de selección
								</p>
						    </Form.Group>
						</Col>
					</Form.Row>
				</Col>
			</Row>
			<Row className="bg-soft-blue">
				<Col>
					<hr />
				</Col>
			</Row>
			<Row className="bg-soft-blue">
				<Col xs={12}>
					<Row>
						<Col>
							<Form.Group as={Col}>
						    	<p className="font-weight-bold fs-12">
									Rebajar retiros seleccionados
								</p>
						    </Form.Group>
						</Col>
						<Col className="text-right">
							<Form.Group as={Col}>
						    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
								    {isLoading ? 'Procesando...' : 'Rebajar'}
								</Button>
						    </Form.Group>
						</Col>
					</Row>
				</Col>
			</Row>

		</Fragment>
	)
}

export default FormAsignar