import { useEffect, useState, forwardRef } from "react"
import {  
	Form,
	Button,
	Col,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import CardRetiro from "../../components/Layouts/CardRetiro"
import TableRetiro from "../../components/Retiros/TableRetiro"
import { getHome } from '../../actions/orderAction'
import { listadoCamionOnlyActive } from '../../actions/camionAction'
import { exportRetiroExcel, getAllRetiroByParams, resetLitRetiro } from '../../actions/retiroAction'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from "react-redux"
import ReactPaginate from 'react-paginate';
import { isAfter, getDateYMD, isBefore, isSame } from '../../utils/formatFecha';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import { useForm } from "react-hook-form"
import "react-datepicker/dist/react-datepicker.css"
import { usePermission } from "../../hooks/Permission/usePermission"
import { validateOnlyNumber, onPaste, validateOnlyLetter } from "../../utils/validationInput"
import { BiCalendar } from "react-icons/bi";
registerLocale('es', es)

const SearchByParameters = () => {

	const dispatch = useDispatch()
	const { retiros, orders, camions } = useSelector((state) => {
		return {
			retiros: state.retiros,
			orders: state.orders,
			camions: state.camions
		}
	})
	const { retirosAll, isLoadingConsulta } = retiros
	const { sucursales, estadoRetiros } = orders
	const { caListado } = camions
	const [ loadingDownload, setLadingDownload ] = useState(false)
	const [pageOffset, setPageOffset] = useState(0)
	const [pageCount, setPageCount] = useState(0)

	const { register, handleSubmit, setValue, getValues } = useForm()
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())
	const [ loading, setLoading ] = useState(false)
	const [ enabledDate, setEnabledDate ] = useState(false)
	const { sucursal_id  } = useSelector((state) => state.users)
	const { validarPermiso } = usePermission()

	const getDataInitial = async () => {
		if(sucursales.length===0){
			await dispatch(getHome())
		}
		if(caListado.length===0){
			await dispatch(listadoCamionOnlyActive())
		}
	}

	const downloadFile = async (event) => {
		event.preventDefault()
		setLadingDownload(true)
		if(retirosAll.length>0){
			await dispatch(exportRetiroExcel())
		}else{
			toast.error("No hay registros para exportar", {position: toast.POSITION.TOP_RIGHT})
		}
		setLadingDownload(false)
	}

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handlePageChange = async (event) => {

		// TODO Only change displayed selected page
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
		const data = getValues();
		getListByFilter(data, event.selected)
	};

	const changeRangeDate = (event) => {
		setEnabledDate(!event.target.checked)
	}

	const onSubmit =  async (data) => {
		getListByFilter(data, 0)
	}

	const getListByFilter =  async (data, page) => {
		setLoading(true)

		dispatch(resetLitRetiro())
		let sucursal_origen = document.getElementById("sucursal_origen_id").value
		let sucursal_destino = document.getElementById("sucursal_destino_id").value
		let client = document.getElementById("type_client").value
		const information = {
			type_assign: "all",
			state_id: data.state_id==="" ? null : data.state_id,
			vehicle_id: data.vehicle_id==="" ? null : data.vehicle_id,
			from_date: !enabledDate ? document.getElementById("from_date").value : null,
			to_date: !enabledDate ? document.getElementById("to_date").value : null,
			sucursal_origen_id: sucursal_origen==="" ? null : sucursal_origen,
			sucursal_destino_id: sucursal_destino==="" ? null : sucursal_destino,
			numero_retiro: data.numero_retiro==="" ? null : data.numero_retiro,
			type_client: client==="" ? null : client,
			name_client: data.name_client==="" ? null : data.name_client
		}

		const response = await dispatch(getAllRetiroByParams(information, page+1))
		setLoading(false)
		if(response?.status){
			setPageCount(response?.countPaginate ?? 0)
			setPageOffset(page)
		}
	}


	useEffect(() => {
		if(sucursal_id!==""){
			setValue("sucursal_origen_id", sucursal_id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales]) 

	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(dayjs(startDate).format('DD/MM/YYYY'))

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			return false
		}

		if (!isSame(dateStart.dateSelected, endDate) && isAfter(dateStart.dateSelected, endDate)) {
			toast.error('La fecha inicial no puede ser superior a la fecha final', {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			setEndDate(new Date())
			return false
		}
	}

	const validateEndDateBlur = () => {
		const dataEnd = getDateYMD(dayjs(endDate).format('DD/MM/YYYY'))

		if(dataEnd.error){
			toast.error(dataEnd.message, {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}

		if (!isSame(dataEnd.dateSelected, dayjs(startDate).format('YYYY-MM-DD')) && isBefore(dataEnd.dateSelected, startDate)) {
			toast.error('La fecha final no puede ser inferior a la fecha inicial', {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}
	}

	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
		<InputGroup>
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<Layout 
	    	titlePanel="Consulta de Retiro"
	    	modulo="consulta-retiros"
	    >
		    <CardRetiro title="CONSULTA HISTORIA DE RETIROS" classBody="pl-0 pr-0">
		    	<div className="row">
					<Col xs={12}>
						<Form onSubmit={handleSubmit(onSubmit)} id="frmBusquedaGlobal" role="form">
							<Form.Row>
								<Col lg={4} md={4} sm={6} xs={4}>
									<Form.Group as={Col} controlId="dateRange" className="mb-0">
										<Form.Check 
											type="checkbox" 
											label="Intervalo de Fecha" 
											className="pl-4 mb-0 pb-0" 
											name="dateRange" 
											onChange={(event)=> changeRangeDate(event)}
											defaultChecked={true}
										/>
									</Form.Group>
									<section className="d-flex">
										<Form.Group as={Col} controlId="from_date" className="pr-0 w-50">
											<Form.Label>Desde</Form.Label>
											<DatePicker 
												type="text" 
												dateFormat="dd/MM/yyyy"
												selected={startDate} 
												onChange={date => setStartDate(date)} 
												className="form-control"
												name="from_date" 
												id="from_date"
												ref={register}
												disabled={enabledDate}
												locale="es"
												onCalendarClose={() => validateStartDateBlur()}
												maxDate={new Date()}
												customInput={<ExampleCustomInputFrom />}
											/>
										</Form.Group>
										<Form.Group as={Col} controlId="to_date" className="pr-0 w-50">
											<Form.Label>Hasta</Form.Label>
											<DatePicker 
												type="text" 
												dateFormat="dd/MM/yyyy"
												selected={endDate} 
												onChange={date => setEndDate(date)} 
												className="form-control"
												name="to_date" 
												id="to_date"
												ref={register}
												disabled={enabledDate}
												locale="es"
												onCalendarClose={() => validateEndDateBlur()}
												maxDate={new Date()}	
												customInput={<ExampleCustomInputTo />}
											/>
										</Form.Group>
									</section>
								</Col>

								<Col lg={5} md={4} sm={6} xs={4}>
									<Form.Label className="pl-3">Sucursales</Form.Label>
									<section className="d-flex">
										<Form.Group as={Col} controlId="sucursal_origen_id" className="pr-0 w-50">
											<Form.Label>Origen</Form.Label>
											<Form.Control 
													as="select"
													name="sucursal_origen_id" 
													disabled={!validarPermiso("cambiar_sucursal")}
													ref={register}
												>
												<option value="">Seleccione</option>
												{
													sucursales.length > 0 && (
														sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
													)
												}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} controlId="sucursal_destino_id" className="pr-0 w-50">
											<Form.Label>Destino</Form.Label>
											<Form.Control 
													as="select"
													name="sucursal_destino_id" 
													//disabled={validarPermiso()}
													ref={register}
												>
												<option value="">Seleccione</option>
												{
													sucursales.length > 0 && (
														sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
													)
												}
											</Form.Control>
										</Form.Group>
									</section>
								</Col>
								<Col lg={3} md={4} sm={6} xs={4}>
									<Form.Label className="pl-3"></Form.Label>
									<Form.Group as={Col} controlId="numero_retiro">
										<Form.Label>N° Retiro</Form.Label>
										<Form.Control 
											type="text" 
											name="numero_retiro" 
											//defaultValue={dataSearch?.numero_retiro || ""}
											ref={register}
											maxLength={30}
											onKeyPress={(event) => validateOnlyNumber(event)}
										 	onPaste={(event) => onPaste(event)}
										/>
									</Form.Group>
								</Col>
							</Form.Row>
							<Form.Row>
								<Col lg={4} md={4} sm={6} xs={4}>
									<Form.Label className="pl-3"></Form.Label>
									<section className="d-flex">
										<Form.Group as={Col} controlId="vehicle_id" className="pr-0 w-50">
											<Form.Label>Vehículo Asignado</Form.Label>
											<Form.Control 
													as="select"
													name="vehicle_id" 
													ref={register}					                
												>
												<option value="">Seleccione</option>
												{
													caListado.length > 0 && (
														caListado.map(camion => <option key={camion.id} value={camion.id}>{camion.numero_interno}</option>)
													)
												}
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} controlId="state_id" className="pr-0 w-50">
											<Form.Label>Estado</Form.Label>
											<Form.Control 
												as="select"
												name="state_id" 
												ref={register}					                
											>
											<option value="">Seleccione</option>
											{
												estadoRetiros.length > 0 && (
													estadoRetiros.map(estado => <option key={estado.id} value={estado.id}>{estado.descripcion}</option>)
												)
											}
										</Form.Control>
										</Form.Group>
									</section>
								</Col>
								<Col lg={5} md={4} sm={6} xs={4}>
									<Form.Label className="pl-3">Cliente</Form.Label>
									<section className="d-flex">
										<Form.Group controlId="type_client" className="pl-3">
											<Form.Label>Tipo</Form.Label>
											<Form.Control 
													as="select"
													name="type_client" 
													ref={register}					                
												>
												<option value="">Seleccione</option>
												<option value="remitente">Remitente</option>
												<option value="destinatario">Destinatario</option>
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} controlId="name_client" className="pr-0 w-50">
											<Form.Label>Nombre</Form.Label>
											<Form.Control 
												type="text" 
												name="name_client" 
												//defaultValue={dataSearch?.numero_retiro || ""}
												ref={register}
												onKeyPress={(event) => validateOnlyLetter(event)}
												onPaste={(event) => onPaste(event)}
											/>
										</Form.Group>
									</section>
								</Col>
							</Form.Row>
							<Form.Row>
							<Col className="text-right">
									<Form.Group as={Col} className="mt-3 pl-0">
										<Button variant="warning" type="submit" className="pl-5 pr-5">
											{loading ? 'Buscando...' :  'Buscar' }
										</Button>
									</Form.Group>
								</Col>
							</Form.Row>
						</Form>
					</Col>
		    	</div>
		    	<div className="row">
		    		<div className="col-12">
		    			<TableRetiro listRetiros={retirosAll} isLink={true} isLoading={isLoadingConsulta} />

						<ReactPaginate
							previousLabel="Anterior"
							nextLabel="Siguiente"
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={handlePageChange}
							containerClassName="pagination"
							activeClassName="active"
							forcePage={pageOffset}

						/>
		    		</div>
		    	</div>
		    	<div className="row">
		    		<div className="col-12 text-right">
		    			<button 
		    				className="pl-5 pr-5 mr-3 mt-4 mb-4 btn btn-warning"
		    				disabled={retirosAll.length>0 ? false : true}
		    				onClick={(event) => downloadFile(event)}
		    			>
		    			{loadingDownload ? "Descargando..." : "Descargar" }
		    			</button>
		    		</div>
		    	</div>
		    	<ToastContainer />
		    </CardRetiro>
	    </Layout>
	)
}

export default SearchByParameters