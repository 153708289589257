import { useEffect, useState } from "react"
import { toogleDocumentPayment, toogleDetailPaymentMethods,
	getDocumentPendientByClient, savePaymentClient,
	searchDetailPayment, loadingPayment } from "../../actions/facturacionAction"
import { listadoCuentaOnlyActive } from "../../actions/cuentaDepositoAction"
import { getListado } from "../../actions/metodoPagoAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { formatNumberCurrency } from "../../utils/validationInput"



export const useCancelationPayment = (payment_id) => {

	const dispatch = useDispatch()
	const [isValid, setIsValid ] = useState(true)
	const [isValidDocument, setIsValidDocument ] = useState(true)

	const { metodoPagos, facturacion, ctaDeposito } = useSelector((state) => {
		return {
			facturacion: state.facturacion,
			metodoPagos: state.metodoPagos,
			ctaDeposito: state.ctaDeposito
		}
	})

	const { metodo_pago } = metodoPagos
	const { cancelationPayment, listDocumentClient, isLoadingPayment } = facturacion
	const { listadoCta } = ctaDeposito
	const { register, setValue, getValues, control, handleSubmit, errors } = useForm()
	const [ isDisable, setIsDisable ] = useState(true)
	const [ loading, setLoading ] = useState(false)
	const [ totalPagar, setTotalPagar ] = useState("")

	const addItemDocumentPayment = (option) => {
		try{
			if(getValues("document_pendient")===""){
				toast.error("Debe seleccionar un documento de pago", {position: toast.POSITION.TOP_RIGHT})
				return false
			}

			const item = { 
				id: getValues("document_pendient"),
			}
			const response = dispatch(toogleDocumentPayment(item, option))
			
			if(response.status){
				setValue("document_pendient", "")
				if(getValues("payment_type")==="Total"){
					setValue("total", formatNumberCurrency(response?.total))
					setValue("remaining_amount", formatNumberCurrency(response?.remaining))
				}
				setIsValid(false)
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}
		}catch(error){
	      toast.error("Hubo un error, intente más tarde", {position: toast.POSITION.TOP_RIGHT})
	    }
	}

	const deleteItemDocumentPayment = (item) => {
		
		if(cancelationPayment?.listDetailPayment?.length>0){
			toast.error("Antes de eliminar un documento de la lista, primero debe eliminar los pagos que ha registrados", {position: toast.POSITION.TOP_RIGHT})
			return false;
		}
		let response = dispatch(toogleDocumentPayment(item, "delete"))
		if(getValues("payment_type")==="Total"){
			setValue("total", response?.total)
			setValue("remaining_amount", response?.remaining)
		}
	
		if(cancelationPayment?.listDocumentPendient?.length-1 ===0){
			setIsValid(true)
		}
		
	}

	const addItemPaymentMethods = (option) => {
		try{
			const totalInput = totalPagar?.lastVal?.startsWith("0") ? parseFloat(totalPagar.lastVal) : totalPagar
	
			if(document.querySelector(".date_payment_to").value!=="" && getValues("deposit_account")!=="" &&
				getValues("payment_methods")!=="" && getValues("monto")!==""){

				if(totalInput > parseFloat(getValues("remaining_amount").toString().replaceAll(".",""))){
					toast.error("El monto debe ser igual o menor al monto restante", {position: toast.POSITION.TOP_RIGHT})
					return false
				}
				let tipo_pago_name = document.querySelector("#payment_methods");
				let cuenta_name = document.querySelector("#deposit_account");

				const item = { 
					fecha: document.querySelector(".date_payment_to").value,
					cuenta_id: getValues("deposit_account"),
					cuenta: cuenta_name?.options[cuenta_name.selectedIndex]?.text,
					tipo_pago_id: getValues("payment_methods"),
					tipo_pago: tipo_pago_name?.options[tipo_pago_name.selectedIndex]?.text,
					monto: totalInput
				}

				const response = dispatch(toogleDetailPaymentMethods(item, option))
				
				if(response.status){
	
					setValue("date_payment", "")
					setValue("deposit_account", "")
					setValue("payment_methods", "")
					setValue("monto", "")
					setTotalPagar("")
					setValue("remaining_amount", response?.remaining===0 ? 0 : formatNumberCurrency(response?.remaining) )
		
					if(response?.remaining===0){
						setValue("payment_type", "Total")
					}else{
						setValue("payment_type", "Parcial")
					}
					setValue("date_payment", new Date())
				}else{
					toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
				}
			}else{
				toast.error("Debe completar los datos de la forma de pago", {position: toast.POSITION.TOP_RIGHT})
			}
		}catch(error){
	      toast.error("Hubo un error, intente más tarde", {position: toast.POSITION.TOP_RIGHT})
	    }
	}

	const deleteItemPayment = (item) => {
	
		let response = dispatch(toogleDetailPaymentMethods(item, "delete"))
	
		let list = cancelationPayment?.listDetailPayment?.length -  1;
		setValue("remaining_amount", formatNumberCurrency(response?.remaining))
		if(response?.remaining==="0.000"){
			setValue("payment_type", "Total")
		}else if(list===0){
			setValue("payment_type", "Total")
		}else{
			setValue("payment_type", "Parcial")
		}
	}

	const getDocumentClient = async (event) => {
		event.preventDefault()
		let internal_code = getValues("internal_code")
	
		if(internal_code!==""){
			dispatch(loadingPayment(true))
			let response = await dispatch(getDocumentPendientByClient(internal_code))
			if(!response?.status){
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
				setIsValidDocument(true)
			}else{
				setIsValidDocument(false)
			}
			setValue("total", "")
			setValue("remaining_amount", "")
			setValue("date_payment", new Date())
			setValue("payment_type", "Total")
			dispatch(loadingPayment(false))
			
		}else{
			toast.error("Debe ingresar el código interno del cliente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const getDataInitial = async (payment_id) => {
		if(payment_id!==null){
			dispatch(loadingPayment(true))
		}
		setValue("cancellation_date", new Date())

		await dispatch(getListado())

		if(payment_id!==null){
			dispatch(loadingPayment(true))
			let response = await dispatch(searchDetailPayment(payment_id))
			if(response.status){
				setValue("rut", response.data.rut)
				setValue("full_name", response.data.full_name)
				setValue("internal_code", response.data.internal_code)
				setValue("telephone", response.data.telephone)
				setValue("total", formatNumberCurrency(response.data.total))
				//setValue("remaining_amount", formatMonto(response.data.remaining_amount))
				//setValue("payment_type", response.data.payment_type)

				let fecha = Date.parse(response.data.cancellation_date)
			    setValue("cancellation_date", new Date(fecha))
			}			
		    dispatch(loadingPayment(false))
		}

		await dispatch(listadoCuentaOnlyActive())

	}

	const changeTypePayment = (event) => {
		setIsDisable(event.target.value==="Total" ? true : false)
		setValue("total", event.target.value==="Total" ? cancelationPayment?.total : "")
	}

	const onSubmit = async (data) => {
		try {
			setLoading(true)
			if(cancelationPayment?.listDocumentPendient.length===0){
				toast.error("Debe seleccionar los documentos de pagos", {position: toast.POSITION.TOP_RIGHT})
				setLoading(false)
				return false
			}

			if(cancelationPayment?.listDetailPayment.length===0){
				toast.error("Debe seleccionar las formas de pago", {position: toast.POSITION.TOP_RIGHT})
				setLoading(false)
				return false
			}

			if(document.querySelector(".fecha_cancelation").value===""){
				toast.error("Debe seleccionar la fecha", {position: toast.POSITION.TOP_RIGHT})
				setLoading(false)
				return false
			}

			let body = {
				...data,
				cancellation_date: document.querySelector(".fecha_cancelation").value
			}

			let response = await dispatch(savePaymentClient(body))

			if(response.status){
				toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
				document.getElementById("frmPayment").reset()
				setValue('cancellation_date', new Date())
				setValue('date_payment', new Date())
				setIsValidDocument(true)
				setIsValid(true)
			}else{
				toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
			}
			setLoading(false)
		}catch(error){
			setLoading(false)
	      	toast.error("Hubo un error, intente más tarde", {position: toast.POSITION.TOP_RIGHT})
	    }
	}

	useEffect(() => {
		getDataInitial(payment_id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payment_id])

	const state = {
		cancelationPayment,
		listDocumentClient,
		metodo_pago,
		isDisable,
		listadoCta,
		loading,
		isLoadingPayment,
		isValid,
		isValidDocument,
		totalPagar
	}

	const actions = {
		addItemDocumentPayment,
		deleteItemDocumentPayment,
		addItemPaymentMethods,
		deleteItemPayment,
		getDocumentClient,
		changeTypePayment,
		setTotalPagar
	}

	const formElement = {
		register,
		control,
		handleSubmit,
		onSubmit,
		errors
	}

	return {
		state,
		actions,
		formElement	
	}
}