import API from "../config/api"
import {
	LISTADO_DATA_ROLE,
	DATA_DELETE_ROLE,
	IS_VISIBLE_FORMULARIO_ROLE,
	SELECTED_ROWS_ROLE,
	SEARCH_ROLE,
	DATA_ADD_ROLE,
	DATA_EDIT_ROLE
} from "../types/roleType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

const generateItems = (listItems) => {
	let objPermisos = {}

	for(let index in listItems){
		let item = listItems[index]
		if(!objPermisos.hasOwnProperty(item?.key_category)){
			objPermisos[item?.key_category] = { title: item?.category, options: [] }
		}
		objPermisos[item?.key_category] = {
			...objPermisos[item?.key_category],
			options: [...objPermisos[item?.key_category]?.options, item]
		}
	}

	return objPermisos
}

export const listadoRole = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/role")

			let listPermisosMenu = response.permisos.filter(item => item.options===true)
			let listPermisosOptions = response.permisos.filter(item => item.options===false)

			listPermisosMenu = [...listPermisosMenu, ...listPermisosOptions]
			
			 const payload = {
			 	roles: response.roles,
			 	permisos: generateItems(listPermisosMenu),
			 }
			 
			dispatch(requestSuccess(LISTADO_DATA_ROLE, payload))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const searchPorIdRole = (id) => (dispatch, getState) => {
	const {listRole} = getState().roles
	const rol = listRole.find(ret => ret.id===id)
    const payload = {
		seletected: {...rol, nombre_rol: rol.name},
		isForm: true,
		isEdit: true
	}

	if(payload){
	  	dispatch(requestSuccess(SEARCH_ROLE, payload))
	  	 
	}
}

export const deleteRole = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listRole} = getState().roles

		  	let response = await API.get(`admin/role/delete/${id}`)

		    if(response.status){
				
				const payload =listRole.filter(rol => rol.id!==id) 
				
		        dispatch(requestSuccess(DATA_DELETE_ROLE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const isVisibleFormularioRole = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_ROLE, payload))
	  	 
	}
}

export const getSelectedRowsRole = (rows) => (dispatch, getState) => {
	dispatch(requestSuccess(SELECTED_ROWS_ROLE, rows))
}


export const addRole = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.post("admin/role", body)

		    if(response.status){
		    	const permisos = body.permisos.map((permiso, indice) => {
		    		return { id: indice, name: permiso }
		    	})
				const rol = {id: response.id, name: body.nombre_rol, permissions:permisos}
				const {listRole} = getState().roles
				const data = [...listRole, rol]
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_ROLE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editRole = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listRole, rDatos} = getState().roles

		  	let response = await API.post(`admin/role/${rDatos.id}`, body)

		    if(response.status){
				
				const permisos = body.permisos.map((permiso, indice) => {
		    		return { id: indice, name: permiso }
		    	})

				const data =listRole.map(rol => {
					if(rol.id === rDatos.id){
						return {id: rDatos.id, permissions:permisos, name: body.nombre_rol};
					}
					return rol
				})  
				const payload = { listado: data, isForm: false, datos: {} }	
		        dispatch(requestSuccess(DATA_EDIT_ROLE, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}
