export const ordenInitial = {
    sucursal_origen_id: "",
    numero_orden: "",
    oficina_origen_id: "",
    sucursal_destino_id: "",
    oficina_destino_id: "",
    fecha_orden: new Date(),
    tipo_envio_id: "",
    cliente_rut: "",
    razon_social: "",
    rut_remitente: "",
    razon_social_remitente: "",
    rut_destinatario: "",
    razon_social_destinatario: "",
    direccion_destinatario: "",
    telefono_avisas: "",
    tipo_cliente: "remitente",
    codigo_interno_nro: null,
    codigo_interno: "no",
    comentarios: "",
    documentacion_id: "",
    numero_documento_carga: "",
    valor_declarado_carga: "",
    forma_pago_id: "",
    documentacion_id_pago: "",
    numero_documento: "",
    url_cedible: "",
    total_bultos: "",
    total_kilos: "",
    total_metro_cubico: "",
    subtotal_orden: "",
    descuento: "No",
    valor_neto: "",
    valor_iva: "",
    total: "",
    user_name: "",
    estado_orden: "",
    listEstado: [],
    nro_nomina: "",
    rendicion: false,
    list_nota_credito: [],
    fecha_orden_format: "",
    email: null,
    isGenerateDocument: false,
    fecha_entrega_orden: "",
    ordenFleteId: null
}