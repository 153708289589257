import React from "react"
import {  
	Form,
	Col,
	Row,
	Table
} from 'react-bootstrap'
import TitlePrincipal from "../Layouts/TitlePrincipal"
import TbodyOrden from "./TbodyOrden"
import { formatNumberCurrency } from "../../utils/validationInput"
import PanelDetalle from "../Layouts/PanelDetalle"

const DetalleSucursal = ({ title, listado, clase, rendirCtdo, rendirPorPagar, rendirTotal, aplica, register }) => {

	return (
		<>
			<Col md={6} sm={12} xs={12} className={clase}>
				<TitlePrincipal title={title} />
				<Row>
					<Col className="bg-soft-blue">
						<Form.Row className="mb-5">
							<Table bordered size="sm" responsive className="table-global mt-4">
								  <thead className="bg-warn-blue">
								    <tr>
								      <th>O. Flete</th>
								      <th>F. Pago</th>
								      <th>Monto</th>
								      <th>CTDO</th>
								      <th>Por pagar</th>
								      <th>Cta. Cte.</th>
								      <th>Doc</th>
								    </tr>
								  </thead>
								  <tbody>
								  	{
								  		listado?.tbody?.length  > 0 ? (
								  			listado?.tbody?.map((orden, index) => {
								  				return (
								  					<TbodyOrden 
								  						key={index}
								  						index={index}
								  						{...orden} 
								  					/>
								  				)
								  			})
								  		) : (
								  			<tr className="tr-vacia">
								  				<td></td>
								  				<td></td>
								  				<td></td>
								  				<td></td>
								  				<td></td>
								  				<td></td>
								  				<td></td>
								  			</tr>
								  		)
								  	}
								  </tbody>
								  <tfoot>
								  	<tr className="tr-vacia fw-600">
						  				<td></td>
						  				<td className="text-right">Total:</td>
						  				<td>{formatNumberCurrency(listado?.tfoot?.totalMonto ?? 0)}</td>
						  				<td>{formatNumberCurrency(listado?.tfoot?.totalCtdo ?? 0)}</td>
						  				<td>{formatNumberCurrency(listado?.tfoot?.totalPorPagar ?? 0)}</td>
						  				<td>{formatNumberCurrency(listado?.tfoot?.totalCtaCte ?? 0)}</td>
						  				<td></td>
						  			</tr>
								  </tfoot>
							</Table>
						</Form.Row>
					</Col>
				</Row>
				{
					aplica && (
						<Row>
							<Col>
								<PanelDetalle title="Montos a rendir" subtitle="" check={false}>
									<Form.Row className="pt-5">
										<Col lg md={6} sm={4}>
									     	<Form.Group as={Col} controlId="total_contado"className="pr-1 pl-0">
										    	<Form.Label>Contado</Form.Label>
										    	<Form.Control 
										    		type="text" 
										    		name="total_contado" 
										    		value={rendirCtdo} 
										    		ref={register}
										    		readOnly 
										    	/>
										    </Form.Group>
									    </Col>
										<Col lg md={6} sm={4}>
									     	<Form.Group as={Col} controlId="total_por_pagar" className="pr-1 pl-0">
										    	<Form.Label>Por Pagar</Form.Label>
										    	<Form.Control 
										    		type="text" 
										    		name="total_por_pagar" 
										    		value={rendirPorPagar} 
										    		ref={register}
										    		readOnly 
										    	/>
										    </Form.Group>
									    </Col>
									    <Col lg md={6} sm={4}>
									     	<Form.Group as={Col} controlId="totales" className="pr-1 pl-0">
										    	<Form.Label>Total a Rendir</Form.Label>
										    	<Form.Control 
										    		type="text" 
										    		name="totales" 
										    		value={rendirTotal} 
										    		ref={register}
										    		readOnly 
										    	/>
										    </Form.Group>
									    </Col>
									</Form.Row>
								</PanelDetalle>
							</Col>
						</Row>
					)
				}
				
			</Col>
		</>
	)
}

export default DetalleSucursal