import { useState } from "react"
import {  
	Form,
	Col,
	Button,
	Table,
	Row,
	OverlayTrigger, 
	Tooltip
} from 'react-bootstrap'
import BodyCarga from './BodyCarga'
import { toast } from 'react-toastify'
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format";
import { onPaste, validateOnlyNumber } from "../../../../utils/validationInput";
import { saveCargas } from "../../../../actions/retiroAction"
import { useDispatch } from "react-redux"


const DetalleCarga = ({ errors, cargas, register, deleteRow, editRow, setValue, getValues, control }) =>{

	const dispatch = useDispatch()
	const [ nroPeso, setNroPeso ] = useState("");
	const [ nroLargo, setNroLargo] = useState("");
	const [ nroAncho, setNroAncho ] = useState("");
	const [ nroAlto, setNroAlto ] = useState("");
	const [ nroM3, setNroM3 ] = useState("");

	const calculateMCubico = () => {
		let total = 0
		if(nroAlto!==""){
			total = nroAlto
		}
		if(nroAncho!==""){
			total = total * nroAncho
		}
		if(nroLargo!==""){
			total = total * nroLargo
		}

		if(total!==""){
			total = parseFloat(total).toFixed(2)
		}

		setNroM3(total)
	}

	const validateBulto = (event, name) => {
	
		if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
		  toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
		  document.getElementById("cantidad").value = ""
		  return false
		}
	  }

	  const saveCarga = (event) => {
		event.preventDefault()
		const data = getValues()

		if(data.alto!=="" && data.ancho!=="" && data.largo!=="" && data.cantidad!=="" && data.peso!==""){
			const infoCarga = { 
				alto: data.alto,
				ancho: data.ancho,
				largo: data.largo,
				cantidad: data.cantidad,
				peso: data.peso,
				visible: false,
				metraje_cubico: data?.metraje_cubico
			}
	
			dispatch(saveCargas(infoCarga, "add"))
			setNroAlto("")
			setNroAncho("")
			setNroLargo("")
			setValue("cantidad", "")
			setNroPeso("")
			setNroM3("")
		}else {
			toast.error("Debe de ingresar el detalle de la carga", {position: toast.POSITION.TOP_RIGHT})
		}
	}


	return (
		<>
			<Form.Row>
				<Col>
			    	<Form.Label>Ingresar detalle de la carga</Form.Label>
				</Col>
			</Form.Row>
			<Row>
		     	<Col>
				 	<OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
						<Form.Group controlId="alto" className="pr-0">
								<Controller
									control={control}
									name="alto"  
									render={(props) => (
										<NumericFormat
											allowNegative={false}
											className="form-control"
											decimalScale={2}
											name={props.name}
											id={props.name}
											fixedDecimalScale={false}
											placeholder="Alto (m)"
											isAllowed={({floatValue}) => {
												// Define your validation rules here
												return true;
											}}
											onPaste={(e) => e.preventDefault()}
											decimalSeparator={','}
											value={nroAlto}
											//defaultValue={ordenInfo.valor_declarado_carga}
											onValueChange={(values) => {
												props.onChange(values.floatValue);
												setNroAlto(values.floatValue);
											}}
											onBlur={() => calculateMCubico()}
											maxLength={15}
										/>
									)}
									autoComplete="off"
								/>
						</Form.Group>
					</OverlayTrigger>
			    </Col>
			    <Col>
				<OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
			     	<Form.Group controlId="ancho" className="pr-1 pl-0">
					 		<Controller
									control={control}
									name="ancho"  
									render={(props) => (
										<NumericFormat
											allowNegative={false}
											className="form-control"
											decimalScale={2}
											name={props.name}
											placeholder="Ancho (m)"
											id={props.name}
											fixedDecimalScale={false}
											isAllowed={({floatValue}) => {
												// Define your validation rules here
												return true;
											}}
											onPaste={(e) => e.preventDefault()}
											decimalSeparator={','}
											value={nroAncho}
											//defaultValue={ordenInfo.valor_declarado_carga}
											onValueChange={(values) => {
												props.onChange(values.floatValue);
												setNroAncho(values.floatValue);
											}}
											onBlur={() => calculateMCubico()}
											maxLength={15}
										/>
									)}
									autoComplete="off"
								/>
			    	</Form.Group>
					</OverlayTrigger>
			    </Col>
			     <Col>
				 <OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
			     	<Form.Group controlId="largo" className="pr-1 pl-0">
					 	<Controller
							control={control}
							name="largo"  
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control"
									placeholder="Largo (m)"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroLargo}
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroLargo(values.floatValue);
									}}
									onBlur={() => calculateMCubico()}
									maxLength={15}
								/>
							)}
							autoComplete="off"
						/>
			    	</Form.Group>
					</OverlayTrigger>
			    </Col>
				<Col>
			     	<Form.Group controlId="metraje_cubico" className="pr-1 pl-0">
					 <Controller
							control={control}
							name="metraje_cubico"  
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroM3}
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
									}}
									readOnly
								/>
							)}
							autoComplete="off"
						/>
			    	</Form.Group>
			    </Col>
			    <Col>
			     	<Form.Group controlId="cantidad" className="pr-1 pl-0">
					    <Form.Control 
			                type="text" 
					    	name="cantidad"  
					    	placeholder="Cantidad"
			                className="form-control"
							maxLength={2}
			                ref={register}
							onKeyPress={(event) => validateOnlyNumber(event)}
							onBlur={(event) => validateBulto(event)}
							onPaste={(event) => onPaste(event)}
			              />
			    	</Form.Group>
			    </Col>
			     <Col>
			     	<Form.Group controlId="peso" className="pr-1 pl-0">
					 	<Controller
							control={control}
							name="peso"  
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									value={nroPeso}
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setNroPeso(values.floatValue);
									}}
									maxLength={15}
								/>
							)}
							autoComplete="off"
						/>
			    	</Form.Group>
			    </Col>
			    <Col>
			     	<Form.Group controlId="btnSaveCarga" className="pr-1 pl-0">
				    	<Button variant="warning" type="button" block onClick={(e)=>saveCarga(e)} size="sm">
						    Ok
						</Button>
				    </Form.Group>
			    </Col>
			</Row>
			<Row >
				<Col >
					<Form.Group className="mb-1">
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th className="text-center">Alto (m)</th>
							      <th className="text-center">Ancho (m)</th>
							      <th className="text-center">Largo (m)</th>
								  <th className="text-center">M3</th>
							      <th className="text-center">Cantidad</th>
							      <th className="text-center">Peso total (kg)</th>
							      <th className="detalle-opciones"></th>
							    </tr>
							  </thead>
							  <tbody>
							  	{
							  		cargas?.length  > 0 ? (
							  			cargas?.map((carga, index) => {
							  				return (
							  					<BodyCarga 
							  						key={index}
							  						index={index}
							  						{...carga} 
							  						register={register}
							  						editRow={editRow}
							  						deleteRow={deleteRow}
													setValue={setValue}
													getValues={getValues}
													control={control}
							  					/>
							  				)
							  			})
							  		) : (
							  			<tr className="tr-vacia">
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
											  <td></td>
							  			</tr>
							  		)
							  	}
							  </tbody>
						</Table>
						{errors.cargas && (
					    	<Form.Text className="text-danger">
					      		{errors?.cargas?.message}
					    	</Form.Text>
					    )}
					</Form.Group>	
				</Col>
			</Row>	
		</>
	)
}

export default DetalleCarga