import { 
	LISTADO_DATA_CO,
	SELECTED_ROWS_CO,
	IS_VISIBLE_FORMULARIO_CO,
	SEARCH_CO,
	DATA_EDIT_CO,
	DATA_ADD_CO,
	DATA_ACTIVAR_DESACTIVAR_CO,
	DATA_DELETE_CO
} from "../types/conductorType"

const INITIAL_STATE = {
	coListado: [],
	coIsCargando: true,
	coIsForm: false,
	coIsEdit: false,
	coDatos: {},
	coIsSelectedRows: [],
	coBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	}
}

const conductorReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_CO:
			return {
				...state,
				coListado: action.payload,
				coIsCargando: false
			}
		case IS_VISIBLE_FORMULARIO_CO:
			return {
				...state,
				coIsForm: action.payload.isForm,
				coIsEdit: action.payload.isEdit,
				coDatos: action.payload.datos,
				coBtnActivar: action.payload.btnActivar,
				coIsSelectedRows: []
			}
		case SEARCH_CO:
			return {
				...state,
				coDatos: action.payload.seletected,
				coIsForm: action.payload.isForm,
				coIsEdit: action.payload.isEdit,
				coBtnActivar: action.payload.btnActivar
			}
		case DATA_ADD_CO:
			return{
				...state,
				coListado: action.payload.listado,
				coIsForm: action.payload.isForm
			}
		case DATA_EDIT_CO:
			return {
				...state,
				coListado: action.payload.listado,
				coDatos: action.payload.datos,
				coIsForm: action.payload.isForm,
				coBtnActivar: action.payload.btnActivar
			}
		case SELECTED_ROWS_CO:
			return {
				...state,
				coIsSelectedRows: action.payload.rows,
				coBtnActivar: action.payload.btnActivar
			}
		case DATA_ACTIVAR_DESACTIVAR_CO:
			return {
				...state,
				coIsSelectedRows: action.payload.rows,
				coBtnActivar: action.payload.btnActivar,
				coListado: action.payload.listado
			}
		case DATA_DELETE_CO:
			return {
				...state,
				coListado: action.payload
			}
		default: return state
	}
}

export default conductorReducer