import React, { useEffect } from "react"
import {  
	Col,
	Row,
	Card
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import SearchOrden from "../../components/Home/SearchOrden"
import Information from "../../components/Home/Information"

import icono1 from '../../assets/images/icono3.png'
import icono2 from '../../assets/images/icono2.png'
import icono3 from '../../assets/images/icono1.png'

import { useDispatch, useSelector } from "react-redux"
import { getCountRetiro } from "../../actions/homeAction"
import { useHistory } from 'react-router-dom'
import { usePermission } from "../../hooks/Permission/usePermission"

const Home = () => {
	
	const dispatch = useDispatch()
	const { validarPermiso } = usePermission()

	const { pendiente, realizado, count } = useSelector((state) => state.home)

	const history = useHistory()
	
	const getData = async () => {
		await dispatch(getCountRetiro())
	}
	
	useEffect(() => {
		if(JSON.parse(localStorage.getItem('user'))===null){
			history.push("/")
		} 
		getData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <Layout 
	    	titlePanel="Módulo de Operaciones"
	    	modulo="dashboard"
	    >
	    <div className="pl-4 pr-4">
	    	<Row className="justify-content-md-center p-5">
	    		<Col xs={12}>
	    			<Card body className="card-retiro shadow-sm">
			    		<SearchOrden />
			    	</Card>
	    		</Col>
	    	</Row>
	    	{!validarPermiso("consultar_retiro") && (
	    		<Row className="pl-5 pr-5">
					<Information title="Retiros Pendientes" total={pendiente} icono={icono1} />
					<Information title="Retiros Retirados" total={realizado} icono={icono2} />
					<Information title="Total de retiros" total={count} icono={icono3} />
	    		</Row>
	    	)}
	    </div>
	    </Layout>
	)
}

export default Home
