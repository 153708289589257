import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Camion/Listado"
import Formulario from "../../components/Admin/Camion/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoCamion } from "../../actions/camionAction"


const Camion = ({toast}) => {
	
	const dispatch = useDispatch()
	const { caListado, caIsCargando, caDatos, caIsForm } = useSelector((state) => state.camions)

	useEffect(() => {
		dispatch(listadoCamion())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
	    <>
			<CardRetiro title={!caIsForm ? "Listado de Camiones" : "Camión" }>
				{!caIsForm ? (
					<Listado data={caListado} isCargando={caIsCargando} toast={toast} />
				): (
					<Formulario toast={toast} datos={caDatos} />	
				)}
		    				    
			</CardRetiro>
	    </>
	)
}

export default Camion