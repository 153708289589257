const Cuenta = { 
	columnas: [
		{
			name: 'Sucursal',
			selector: (row, i) => row.sucursal.nombre,
			sortable: true
		},
		{
			name: 'Banco',
			selector: (row, i) => row.nombre_banco,
			sortable: true
		},
		{
			name: 'Nro cuenta',
			selector: (row, i) => row.numero_cuenta,
			sortable: true
		},
		{
			name: 'Activo',
			selector: (row, i) => row.name_estatus,
			sortable: true
		}
	]
}

export default Cuenta