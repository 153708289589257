import React, { Fragment, useState } from "react"
import {  
	Form,
	Col,
	Button
} from 'react-bootstrap'
import TableOrden from "./TableOrden"
import { useDispatch, useSelector } from "react-redux"
import { searchOrdenesInd } from "../../../actions/orderAction"
import MetodoPago from "./MetodoPago"
import PanelDetalle from "../../Layouts/PanelDetalle"
import TableSuccess from '../TableSuccess'
import { onPaste, validateOnlyOrden } from '../../../utils/validationInput';


const DetalleOrden = ({ register, errors, getValues, setValue, toast, control }) => {

	const dispatch = useDispatch()

	const { disabledBtnPago, listEntregada, visibleMetodoPago  } = useSelector((state) => state.orders)
	const [ loading, setLoading ] = useState(false)

	const addOrden = async (event) => {
		event.preventDefault()
		setLoading(true)
		if(getValues("numero_orden").length === 0){
			toast.error("Ingrese el número de orden", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		let ordenFormat = getValues("numero_orden")?.replace("'", "-")
		setValue("numero_orden", ordenFormat)

		const response = await dispatch(searchOrdenesInd(ordenFormat, "entrega"))
		if(!response.status){
			toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
		}else{
			setValue("numero_orden", "")
		}
		setLoading(false)
	}

	const onKeyOrdenInd = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			addOrden(event)
		}
	}

	const validateBulto = (event) => {
	
		if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
			toast.error("Debe ingresar el número de orden", {position: toast.POSITION.TOP_RIGHT})
			setValue("numero_orden", "")
			return false
		}
	}

	return (
		<Fragment>
			<PanelDetalle title="DETALLE DE LA ORDENES" subtitle="INGRESO DE DATOS" check={true}>
				<Form.Row>
				    <Col md={3} sm={6}>
				     	<Form.Group as={Col} controlId="numero_orden">
					    	<Form.Label>N° de Orden</Form.Label>
						    <Form.Control 
							    	type="text" 
							    	name="numero_orden" 
							    	ref={register}
							    	readOnly={disabledBtnPago}
									maxLength={30}
							    	onKeyDown={(event) => onKeyOrdenInd(event)}
									onKeyPress={(event) => validateOnlyOrden(event)}
									onPaste={(event) => onPaste(event)}
									onBlur={(event) => validateBulto(event)}
							    />
					    </Form.Group>
				    </Col>
				    <Col md={3} sm={6}>
				     	<Form.Group as={Col} className="mt-4">
					    	<Button 
								//disabled={disabledBtnPago} 
								variant="warning" 
								type="button" 
								size="sm" 
								block 
								onClick={(event) => addOrden(event)}
								disabled={disabledBtnPago || loading}
							>	
							   { loading ? 'BUSCANDO' : 'AGREGAR' }  
							</Button>
					    </Form.Group>
				    </Col>
				</Form.Row>
				<TableOrden register={register} />

				

		    	{
		    		visibleMetodoPago && (
		    			<>
		    				<Form.Row className="ml-3">
								<Col>
									<p className="fw-600">SELECCIONA EL MÉTODO DE PAGO</p>
						  		</Col>
					    	</Form.Row>
		    				<MetodoPago register={register} errors={errors} index={0} title="Opción 1" control={control} />
		    				<MetodoPago register={register} errors={errors} index={1} title="Opción 2 (Opcional)" control={control} />
		    			</>
		    		)
		    	}

		    	<Form.Row className="m-3">
					<Col className="text-right">
						<Button variant="warning" type="submit" disabled={disabledBtnPago}>
				    		{loading ? 'PROCESANDO...' : 'ENTREGAR'}
				  		</Button>
			  		</Col>
		    	</Form.Row>
		    	{listEntregada.length>0 && <TableSuccess />}
			</PanelDetalle>
			
		</Fragment>

	)
}

export default DetalleOrden
