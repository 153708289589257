import React, { useEffect, useState } from "react"
import {  
	Button,
	Modal,
	Row,
	Col,
	Form
} from 'react-bootstrap'
import Progress from '../Layouts/Progress'
import { searchCliente } from "../../actions/clienteAction"
import { validarRut } from "../../utils/rut"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { onPaste, validateOnlyLetter, validateOnlyNumber } from "../../utils/validationInput";
import { Controller } from "react-hook-form"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON

const ModalFactura = ({ setHidden, hidden, data, funcFactura, title, isVisibleFactura, nameModulo, setLoading, isLoading=null, updateData }) => {

	const dispatch = useDispatch()
	const { sucursales  } = useSelector((state) => state.orders)
	
	const [ bloquearInput, setBloquearInput ] = useState(true)
	const [ isActivoRut, setIsActivoRut ] = useState(true)
	const [ciudades, setCiudades ] = useState([])
	const [ isProcesando, setIsProcesando ] = useState(false)
	const [ isSpinner, setIsSpinner ] = useState(false)

	const { register, handleSubmit, errors, setValue, control } = useForm()

	const handleClose = () => {
		setHidden(false)
		if(isLoading!==null){
			setLoading(false)
		}		
	}

	const searchClienteFactura = async (event) => {
		if(event.target.value.length>0){
			await dataCliente(event.target.value)
		}
	}

	const onKeyDownFactura = async (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			await searchClienteFactura(event)
		}
	}

	const selectCliente = async (event) => {
		const { value } = event.target
		let rut_factura = ""
		resetInput()
	
		if(value ==="otros"){
			rut_factura = ""
			setIsActivoRut(false)
			setBloquearInput(false)
			setValue("rut_factura", "")
		}else{
			rut_factura = (value ==="destinatario") ? data.rut_destinatario : data.rut_remitente
			setValue("rut_factura", rut_factura)
			setIsActivoRut(true)
			await dataCliente(rut_factura, value)
		}
	}

	const dataCliente = async (rut_factura, typeValue) => {
		if(rut_factura.length>0)
		{

			const isValid = validarRut(rut_factura)
			if(!isValid.status){
				toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			if(rut_factura===RUT_MESON){
				toast.error("No se puede procesar facturas con ese RUT", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			const response = await dispatch(searchCliente({search_type : "rut_cliente", value: rut_factura }))
			if(response.status){
				cargarCiudades(undefined, response.cliente.sucursal_id)
				/**if(rut_factura===RUT_MESON){
					if(getValues("type_cliente_factura")==="remitente"){
						setValue("razon_social_factura", data.razon_social_remitente)
					}else{
						setValue("razon_social_factura", data.razon_social_destinatario)
					}
				}else{
					setValue("razon_social_factura", response.cliente.nombre_completo)
				}**/
				setValue("razon_social_factura", response.cliente.nombre_completo)
				setValue("giro_factura", response.cliente.rubro)
				setValue("contacto_factura", response.cliente.telefono)
				setValue("email_factura", response.cliente.email)
				setValue("direccion_factura", response.cliente.direccion)
				setValue("comuna_factura", response.cliente.comuna_id)
				setValue("ciudad_factura", response.cliente.sucursal_id)
				setValue("rut_factura", response?.cliente?.rut)
			}else{
				if(typeValue==="destinatario"){
					setValue("razon_social_factura", data?.razon_social_destinatario)
					setValue("contacto_factura", data?.telefono_avisas)
					setValue("direccion_factura", data?.direccion_destinatario)
				} else if(typeValue==="remitente"){
					setValue("razon_social_factura", data?.razon_social_remitente)
					setValue("contacto_factura", "")
					setValue("direccion_factura", "")
				}
			}
			setBloquearInput(false)
		}
	}

	const resetInput = () => {
		setBloquearInput(true)
		setValue("razon_social_factura", "")
		setValue("giro_factura", "")
		setValue("contacto_factura", "")
		setValue("email_factura", "")
		setValue("direccion_factura", "")
		setValue("comuna_factura", "")
		setValue("ciudad_factura", "")
	}

	const onSubmit = async (facturacion, e) => {
    	e.preventDefault()
    	let mensaje =''
    	if(nameModulo==="nota"){
    		mensaje = 'La nota de crédito será procesada y generada con estos datos ¿está seguro que desea continuar?.'
    	}else if(nameModulo==="anular"){
    		if(data?.cliente_rut && data.numero_documento.length > 0){
    			mensaje = 'Se va generar una nota de crédito, ¿Seguro desea anular la orden?'
    		}else{
    			mensaje = '¿Seguro desea anular la orden?'
    		}
    	}else{
    		mensaje = 'La factura será procesada y generada con estos datos ¿está seguro que desea continuar?.'
    	}
    	
    	Swal.fire({
		  title: mensaje,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  		setIsSpinner(true)
		  		setIsProcesando(true)
		    	let information = { ...data, factura: facturacion }
		    	const response = await funcFactura(information, e)
		    	setIsProcesando(false)
		    	setIsSpinner(false)
		    	if(response){
		    		setHidden(false)
		    	}	    
		  }
		})
    }

    const cargarCiudades = (e, valor) => {

		const id =  (e !==undefined ) ? e.target.value : valor
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)

			}

			setCiudades(detComuna) 
		}
	}

	useEffect(() => {
		if(data?.rut_factura && (nameModulo==="anular" || nameModulo==="nota" )){
			setIsSpinner(true)
			const getData = async () => {
				await dataCliente(data?.rut_factura)
				setValue("rut_factura", data?.rut_factura)
				setIsSpinner(false)
			}
			getData()
		}else{
			setIsSpinner(false)
		}

		return () => { 
			setIsSpinner(false) 
			//setHidden(false)
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateData])

	return (
		
		    <Modal show={hidden} onHide={handleClose} centered size="lg" >
		        <Modal.Header closeButton>
		          <h6 className="mb-0">{title}</h6>
		        </Modal.Header>
		        <Modal.Body>
		        <Form id="frmFactura" onSubmit={handleSubmit(onSubmit)}>
		        	
			        	<div className="pb-4">
				        	{isVisibleFactura && (
					        	<>
					        	{(nameModulo!=="anular" && nameModulo!=="nota" ) && (
					        		<Row>
							            <Col md={6} sm={12}>
											<Row>
												<Col xs={12} className="mb-1">
											     	<Form.Group as={Col} controlId="type_cliente_factura" className="mb-0 pl-0 pr-0">
												    	<Form.Label>Cliente</Form.Label>
												    	<Form.Control 
													    	as="select"
													    	onChange={(event) => selectCliente(event)}
													    	name="type_cliente_factura" 
											                ref={register({required: {value: true, message: 'Requerido'}})}
													    >
												    	<option value="">Seleccione</option>
												    	<option value="remitente">Remitente</option>
												    	<option value="destinatario">Destinatario</option>
												    	<option value="otros">Otros</option>
												 	</Form.Control>							
												    </Form.Group>
											    </Col>
											</Row>
										</Col>
						          	</Row>
						         )}
					        		<Row className="mt-3 pb-3">
							            <Col sm={12}>
											<h6>Datos básicos</h6>
										</Col>
						          	</Row>
						        	<Row>
							            <Col md={6} sm={12}>
											<Row>
												<Col xs={12} className="mb-1">
											     	<Form.Group as={Col} controlId="rut_factura" className="mb-0 pl-0 pr-0">
												    	<Form.Label>RUT</Form.Label>
														<Controller
															autoComplete="off"
															render={(props) => (
																<MaskedInput
																	mask={RutTextMask}
																	className="form-control"
																	id="rut_factura" 
																	name="rut_factura"
																	value={props.value}
																	maxLength={12}
																	onChange={(value)=>{
																		props.onChange(value)
																	}}
																	//onBlur={(event) => handleBlurRut(event, setError)}
																	readOnly={isActivoRut}
																	onBlur={(event) => searchClienteFactura(event)}
																	onKeyDown={(event) => onKeyDownFactura(event)}
																	onPaste={(event) => onPaste(event)}
																/>
															)}
															control={control}
															name="rut_factura"  
															rules={{required: {value: true, message: 'Requerido'}}}
														/>
													     {errors.rut_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.rut_factura?.message}
													    	</Form.Text>
													    )}							
												    </Form.Group>
											    </Col>
											</Row>
										</Col>
										<Col md={6} sm={12}>
											<Row>
											    <Col xs={12}>
											      	<Form.Group as={Col} controlId="razon_social_factura" className="mb-0 pl-0 pr-0">
											      		<Form.Label>Razón Social</Form.Label>
											      		<Form.Control 
													    	type="text" 
													    	name="razon_social_factura" 
													    	readOnly={bloquearInput}
											                ref={register({required: {value: true, message: 'Requerido'}})}
															onKeyPress={(event) => validateOnlyLetter(event)}
															maxLength={150}
															onPaste={(event) => onPaste(event)}
													    />
													    {errors.razon_social_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.razon_social_factura?.message}
													    	</Form.Text>
													    )}	
											    	</Form.Group>
											    </Col>
											</Row>
										</Col>
						          	</Row>
						          	<Row>
							            <Col md={6} sm={12}>
											<Row>
												<Col xs={12} className="mb-1">
											     	<Form.Group as={Col} controlId="giro_factura" className="mb-0 pl-0 pr-0">
												    	<Form.Label>Giro</Form.Label>
												    	<Form.Control 
													    	type="text" 
													    	name="giro_factura" 
													    	readOnly={bloquearInput}
													    	maxLength="70"
											                ref={register({required: {value: true, message: 'Requerido'}})}
													    />	
													     {errors.giro_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.giro_factura?.message}
													    	</Form.Text>
													    )}								
												    </Form.Group>
											    </Col>
											</Row>
										</Col>
										<Col md={6} sm={12}>
											<Row>
											    <Col xs={12}>
											      	<Form.Group as={Col} controlId="contacto_factura" className="mb-0 pl-0 pr-0">
											      		<Form.Label>Contacto</Form.Label>
											      		<Form.Control 
													    	type="text" 
													    	name="contacto_factura" 
													    	readOnly={bloquearInput}
															maxLength={9}
											                ref={register({
																minLength: {value: 9, message: 'Mínimo 9 dígitos'},
																required: {value: true, message: 'Requerido'},
																//eslint-disable-next-line
																pattern: { value: /^([0-9]+)$/, message: 'Teléfono inválido'},
															})}
															autoComplete="off"
															onKeyPress={(event) => validateOnlyNumber(event)}
															onPaste={(event) => onPaste(event)}
													    />
													     {errors.contacto_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.contacto_factura?.message}
													    	</Form.Text>
													    )}
											    	</Form.Group>
											    </Col>
											</Row>
										</Col>
						          	</Row>
						          	<Row>
							            <Col md={6} sm={12}>
											<Row>
												<Col xs={12} className="mb-1">
											     	<Form.Group as={Col} controlId="email_factura" className="mb-0 pl-0 pr-0">
												    	<Form.Label>E-mail</Form.Label>
												    	<Form.Control 
													    	type="text" 
													    	name="email_factura" 
													    	readOnly={bloquearInput}
											                ref={register({
																//eslint-disable-next-line
																pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'},
																required: {value: true, message: 'Requerido'}
															})} 
															maxLength={70}
													    />	
													    {errors.email_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.email_factura?.message}
													    	</Form.Text>
													    )}								
												    </Form.Group>
											    </Col>
											</Row>
										</Col>
										<Col md={6} sm={12}>
											<Row>
											    <Col xs={12}>
											      	<Form.Group as={Col} controlId="direccion_factura" className="mb-0 pl-0 pr-0">
											      		<Form.Label>Dirección</Form.Label>
											      		<Form.Control 
													    	type="text" 
													    	name="direccion_factura" 
													    	readOnly={bloquearInput}
											                ref={register({required: {value: true, message: 'Requerido'}})}
													    />
													    {errors.direccion_factura && (
													    	<Form.Text className="text-danger">
													      		{errors?.direccion_factura?.message}
													    	</Form.Text>
													    )}	
											    	</Form.Group>
											    </Col>
											</Row>
										</Col>
						          	</Row>
						          	<Row>
							            <Col md={6} sm={12}>
											<Row>
												<Col xs={12} className="mb-1">
											     	<Form.Group as={Col} controlId="ciudad_factura" className="mb-0 pl-0 pr-0">
												    	<Form.Label>Ciudad</Form.Label>
												    	<Form.Control 
													    	as="select"
													    	disabled={bloquearInput}
													    	onChange={(event) => cargarCiudades(event)}
													    	name="ciudad_factura" 
											                ref={register({required: {value: true, message: 'Requerido'}})}
													    >
												    	<option value="">Seleccione</option>
												    	{
												    		sucursales.length > 0 && (
												    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
												    		)
												    	}
												 	</Form.Control>	
												 	{errors.ciudad_factura && (
												    	<Form.Text className="text-danger">
												      		{errors?.ciudad_factura?.message}
												    	</Form.Text>
												    )}							
												    </Form.Group>
											    </Col>
											</Row>
										</Col>
										<Col md={6} sm={12}>
											<Row>
											    <Col xs={12}>
											      	<Form.Group as={Col} controlId="comuna_factura" className="mb-0 pl-0 pr-0">
											      		<Form.Label>Comuna</Form.Label>
											      		<Form.Control 
													    	as="select"
													    	disabled={bloquearInput}
													    	name="comuna_factura" 
											                ref={register({required: {value: true, message: 'Requerido'}})}
													    >
												    	<option value="">Seleccione</option>
												    	{
												    		ciudades.length > 0 && (
												    			ciudades.map(ciudad => <option key={ciudad.id} value={ciudad.id}>{ciudad.nombre}</option>)
												    		)
												    	}
												 	</Form.Control>	
												 	{errors.comuna_factura && (
												    	<Form.Text className="text-danger">
												      		{errors?.comuna_factura?.message}
												    	</Form.Text>
												    )}
											    	</Form.Group>
											    </Col>
											</Row>
										</Col>
						          	</Row>
					          	</>
				          	)}
							{nameModulo==="anular" && (
								<Row className="pt-4">
						            <Col sm={12}>
										<Form.Group controlId="motivo_anular">
										    <Form.Label >Motivo</Form.Label>
										    <Form.Control 
										    	as="textarea" 
										    	name="motivo_anular" 
										    	rows={4} 
										    	ref={register({required: {value: true, message: 'Requerido'}})} 
										   />
										   {errors.motivo_anular && (
										    	<Form.Text className="text-danger">
										      		{errors?.motivo_anular?.message}
										    	</Form.Text>
										    )}
										  </Form.Group>
									</Col>
					          	</Row>
							)}
						</div>
						{isSpinner && (
							<div className="pb-4 text-center">
								<Progress />
								<p className="font-italic">Procesando la información, espere unos minutos</p>
							</div>
						)}
				 	<Modal.Footer>
			          <Button type="submit" className="pl-5 pr-5" variant="warning" disabled={isProcesando}>
			            {isProcesando ? 'Procesando...' :  'Procesar' }
			          </Button>
			          <Button className="pl-5 pr-5" variant="warning" onClick={() => handleClose()}>
			            Cerrar
			          </Button>
			        </Modal.Footer>
			    </Form>
		        </Modal.Body>
		      </Modal>
		

	)
}

export default ModalFactura