import React, { useState } from "react"
import {  
	Form,
	Button
} from 'react-bootstrap'
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { login } from "../../actions/userAction"
import { withRouter } from 'react-router-dom'
import MessageDanger from "../Utilidades/MessageDanger"
import { Link } from 'react-router-dom'
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";

const Forms = (props) => {

	const [ dangerMessage, setDangerMessage ] = useState({status: false, message: ""})
	const { register, handleSubmit, errors } = useForm()
	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const [ typeInput, setTypeInput ] = useState(false)

    const onSubmit = async (data, e) => {
    	e.preventDefault()
    	setLoading(true)
    	const result =  await dispatch(login(data))
    	if(result.status){
            e.target.reset()
            setLoading(false)
            props.history.push('/home')
            //window.location.reload()
         }else{
         	setLoading(false)
            setDangerMessage({ status: true, message: result.message})
         }
         
    }

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
		  { (dangerMessage && dangerMessage.status) && <MessageDanger color="danger" message={dangerMessage.message} /> }
		  <Form.Group controlId="email">
		    <Form.Label>Email</Form.Label>
		    <Form.Control 
		    	type="email" 
		    	name="email" 
                autoFocus
                ref={register({required: {value: true, message: 'Requerido'}})}
		    />
		    {errors.email && (
		    	<Form.Text className="text-danger">
		      		{errors?.email?.message}
		    	</Form.Text>
		    )}
		    
		  </Form.Group>

		  <Form.Group controlId="password">
		    <Form.Label>Contraseña</Form.Label>
		    
			<div className="mb-3 p-relative">
				<Form.Control 
					type={!typeInput ? "password" : "text" } 
					name="password" 
					ref={register({required: {value: true, message: 'Requerido'}})}
					maxLength={20}
				/>
				<div
					className="visible-password" 
					onClick={() => setTypeInput(!typeInput) }
				>
					{ !typeInput ? <BiHide style={{fontSize: '20px'}} /> : <BiShow  style={{fontSize: '20px'}} /> }
				</div>
			</div>
		    {errors.password && (
		    	<Form.Text className="text-danger">
		      		{errors?.password?.message}
		    	</Form.Text>
		    )}
		    <Link to="/home" className="text-secondary d-none">¿Olvidaste tu contraseña?</Link>
		  </Form.Group>
		  <Form.Group className="mt-5">
		  <Button variant="warning" type="submit" block disabled={loading}>
		    {loading ? 'PROCESANDO...' : 'INGRESAR' }
		  </Button>
		  </Form.Group>
		</Form>
	)
}

export default withRouter(Forms)