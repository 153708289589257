import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { getHome, createdOrden, cleanOrden, editDetalleCargaWithPrice } from "../../actions/orderAction"
import { getFechaFormat } from '../../utils/formatFecha'
import { validarRut } from "../../utils/rut"

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify' 

const defaultValues = {
    fecha_orden: new Date()
};

export const useCreateOrden =  () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [ validateForm, setValidateForm] = useState(true)
    const [ isLoading, setLoading]  = useState(false)
    const [ titleModulo, setTitleModulo ] = useState("")
    const [ infoData, setInfoData ] = useState({})
    const [ hidden, setHidden ] = useState(false)
	const [ isVisible, setIsVisible ] = useState(true)
    const [ openForm, setOpenForm ] = useState(false)
    const [ statusValidate, setStatusValidate ] = useState(validateForm)
    const [ bloquear, setBloquear ] = useState(false)

    const { register, handleSubmit, errors, reset, getValues, setValue, control } = useForm({defaultValues})

    const { users, orders  } = useSelector((state) => {
		return {
			users: state.users,
			orders: state.orders
		}
	})
    const { dataOrders, initialOrden, disabledBtnCrear, visibleCarga  } = orders
	const { sucursal_id, comuna_id  } = users

    const onSubmit = async (data, e) => {
    	e.preventDefault()
        try {
            setLoading(true)

            if(data?.type_price==="with_price"){
                if(parseInt(document.getElementById("forma_pago_id").value)===2 && document.getElementById("documentacion_id_pago").value===""){
                    toast.error("Seleccione el tipo de documento de pago", {position: toast.POSITION.TOP_RIGHT})
                    setLoading(false)
                    return false
                }
            }
     
            const listCarga = dataOrders?.cargas ?? [] 
            const findVisible = listCarga?.findIndex(item  => item.visible);
       
            if(findVisible!==-1){
                toast.error(`La carga ${findVisible+1} esta abierta para editar, debe guardar el cambio para continuar`, {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }

            let isValid = validarRut(data?.cliente_rut)
            if(!isValid.status){
                toast.error(`${isValid.message} del cliente`, {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }

            isValid = validarRut(data?.rut_remitente)
            if(!isValid.status){
                toast.error(`${isValid.message} del remitente`, {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }

            isValid = validarRut(data?.rut_destinatario)
            if(!isValid.status){
                toast.error(`${isValid.message} del destinatario`, {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }
      
            if(!data.hasOwnProperty("cargas")){
                toast.error("Debe de completar el detalle de la carga", {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }

            if(typeof data?.cargas === "undefined" || data?.cargas?.length === 0){
                toast.error("Mínimo 1 detalle de la carga", {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }

            let pago_descripcion = document.getElementById("forma_pago_id")

            const information = {
                ...data, 
                fecha_orden: getFechaFormat(getValues("fecha_orden")),
                sucursal_origen_id: document.getElementById("sucursal_origen_id").value,
                oficina_origen_id: document.getElementById("oficina_origen_id").value,
                forma_pago_id: getValues("forma_pago_id"),
                documentacion_id_pago:getValues("documentacion_id_pago"),
                numero_documento: getValues("numero_documento"),
                forma_pago_descripcion: pago_descripcion.options[pago_descripcion.selectedIndex].text,
                numero_orden: data.numero_orden.toString().replaceAll(" ", "").trim()
            }

            setTitleModulo("FACTURA A NOMBRE DE:")

            if(parseInt(data?.documentacion_id_pago)===1 && parseInt(data?.forma_pago_id)===2){
                setInfoData(information)
                setHidden(true)
                setIsVisible(true)
            }else{
                await addOrdenAction(information)
            }
            setLoading(false)
        }catch(err){
            toast.error("Hubo un error al guardar la orden de flete", {position: toast.POSITION.TOP_RIGHT})
        }
    }

    const addOrdenAction = async (information) => {
   
        let response = await dispatch(createdOrden(information))
        setLoading(false)
        if(response.status){
            history.push(`/orders/${information.numero_orden}/edit`)
            return true
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            return false
        }
    }


    const handleblockPaidInput = (event) => {
		const { value } = event.target
        let checked = value==="no_price" ? true : false

		if(value==="no_price"){
            setValue("forma_pago_id", "")
            setValue("documentacion_id_pago", "")
            setValue("forma_pagodescuento_id", "No")
            setValue("total_carga", "No") 

            setValue("valor_neto", "")  
            setValue("valor_iva", "")  
            setValue("total", "")  
            setValue("total_bultos", "")  
            setValue("total_kilos", "")  
            setValue("total_metro_cubico", "")  
            setValue("subtotal_orden", "") 
        	setValidateForm(false)
            /**Verifica que el cliente no sea cuenta corriente */
            if(document.querySelector("#codigo_interno_nro").value===""){
                document.querySelector("#forma_pago_id").value = 1
            }else{
                document.querySelector("#forma_pago_id").value = 3
            }
            setBloquear(true)
            
		}else{
			setValidateForm(true)
		}
        if(dataOrders?.cargas?.length >0){
            dispatch(editDetalleCargaWithPrice(checked))
        }

        /**if(document.querySelector("#codigo_interno_nro").value===""){
            document.querySelector("#forma_pago_id").disabled = checked
        }**/
        
        
        // document.querySelector("#documentacion_id_pago").disabled = checked
        // document.querySelector("#descuento").disabled = checked
        // document.querySelector("#total_carga").readOnly = checked 
        // document.querySelector("#largo").disabled = checked
        // document.querySelector("#ancho").disabled = checked
        // document.querySelector("#alto").disabled = checked
        // document.querySelector("#nro_kilos").readOnly = checked 
	}
    

    const getDatInitial = async () => {
    	await dispatch(getHome())

    	dispatch(cleanOrden())
        setValue("codigo_interno", "no")
        setValue("tipo_cliente", "remitente")
        setValue("sucursal_origen_id", sucursal_id)
        setValue("oficina_origen_id", comuna_id)
    }

    const handleCloseModalPayment = () => {
		setOpenForm(false)
	}

    const handleTipoDoc = (event) => {
		let pago_descripcion = document.querySelector(".change_documentacion_id")
       
		pago_descripcion = pago_descripcion.options[pago_descripcion.selectedIndex].text
    
		if(pago_descripcion.toLowerCase()==="sin documento"){
			setStatusValidate(statusValidate => false)
            setValue("numero_documento_carga", "")
		}else{
			setStatusValidate(statusValidate => true)
		}
	}
    

    (function () {
        if ( typeof window.CustomEvent === "function" ) return false;
  
        function CustomEvent ( event, params ) {
          params = params || { bubbles: true, cancelable: true, detail: undefined };
          var evt = document.createEvent('submit');
          evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
          return evt;
         }
  
        CustomEvent.prototype = window.Event.prototype;
  
        window.CustomEvent = CustomEvent;
      })();
    
    
    const formElement = {
        register, 
        handleSubmit, 
        errors, 
        reset, 
        getValues, 
        setValue, 
        control
    }

    const state = {
        validateForm,
        isLoading,
        titleModulo,
        infoData,
        hidden,
        isVisible,
        dataOrders, 
        initialOrden, 
        disabledBtnCrear,
        openForm,
        statusValidate,
        visibleCarga,
        bloquear, 
        setBloquear 
    }
    
    const actions = {
        handleblockPaidInput,
        setLoading,
        onSubmit,
        setHidden, 
        addOrdenAction,
        getDatInitial,
        handleCloseModalPayment,
        setOpenForm,
        handleTipoDoc
    }

    return {
        actions,
        formElement,
        state
    }
}