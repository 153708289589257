export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const LISTADO_DATA_USER = "LISTADO_DATA_USER"
export const SEARCH_USER = "SEARCH_USER"
export const DATA_DELETE_USER = "DATA_DELETE_USER"
export const IS_VISIBLE_FORMULARIO_USER = "IS_VISIBLE_FORMULARIO_USER"
export const SELECTED_ROWS_USER = "SELECTED_ROWS_USER"
export const DATA_ADD_USER = "DATA_ADD_USER"
export const DATA_ACTIVAR_DESACTIVAR_USER = "DATA_ACTIVAR_DESACTIVAR_USER"
export const CHANGE_CLAVE_USER = "CHANGE_CLAVE_USER"
export const DATA_EDIT_USER = "DATA_EDIT_USER"
export const DATA_ADD_USER_VEHICLE = "DATA_ADD_USER_VEHICLE"