import { useState } from "react"
import {  resultTotalesIncomplete, updateOrdenIncomplete } from "../../actions/orderAction"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

export const useOrdenIncomplete = (toast, setShow) => {

	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const { register, handleSubmit, errors, getValues, setValue, control } = useForm()
	const [ statusValidate, setStatusValidate ] = useState(false)

	const onSubmit = async (data, numero_orden) => {
		try{
			setLoading(true)
	
			if(data?.total==="" || data?.valor_neto==="" || data?.valor_iva==="" || data?.subtotal_orden===""){
				setLoading(false)
				toast.error("Debe ingresar los montos de cada carga", {position: toast.POSITION.TOP_RIGHT})
				return false;
			}else {
				applyDescuento()	
				const response = await dispatch(updateOrdenIncomplete(data, numero_orden))
				
				if(response.status){
					setShow(false)
					toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
				}else{
					toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
				}
				setLoading(false)
			}		
			
		}catch(error){
			toast.error("Hubo un error al actualizar los datos de la orden", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleChangeTotal = (event, idCarga, idOrdenFlete) => {
		if(event.target.value!==""){
			const descuento = getValues("descuento")

			const carga = { id: idCarga, valor_neto: event.target.value.replaceAll(".", "").replaceAll(",", ".") }

			const result = dispatch(resultTotalesIncomplete(carga, descuento))
			
			if(Object.keys(result).length>0){
				setValue("total", result?.total)
				setValue("total_bultos", result?.total_bultos)
				setValue("total_kilos", result?.total_kilos)
				setValue("total_metro_cubico", result?.total_metro_cubico)
				setValue("subtotal_orden", result?.subtotal_orden)
				setValue("valor_neto", result?.valor_neto)
				setValue("valor_iva", result?.valor_iva)
				setValue("valor_descuento", result?.montoDiferencia)
				
			}
		}
	}

	const applyDescuento = () => {
        let dataOrden = getValues()
	
		const result = dispatch(resultTotalesIncomplete(dataOrden?.cargas, getValues("descuento")))

		if(Object.keys(result).length>0){
			setValue("total", result?.total)
			setValue("total_bultos", result?.total_bultos)
			setValue("total_kilos", result?.total_kilos)
			setValue("total_metro_cubico", result?.total_metro_cubico)
			setValue("subtotal_orden", result?.subtotal_orden)
			setValue("valor_neto", result?.valor_neto)
			setValue("valor_iva", result?.valor_iva)
			setValue("valor_descuento", result?.montoDiferencia)
			
		}
	}

	const handleTipoDoc = (event) => {
		let pago_descripcion = document.querySelector(".change_documentacion_id")
	
		pago_descripcion = pago_descripcion.options[pago_descripcion.selectedIndex].text

		if(pago_descripcion.toLowerCase()==="sin documento"){
			setStatusValidate(statusValidate => false)
            setValue("numero_documento_carga", "")
		}else{
			setStatusValidate(statusValidate => true)
		}
	}

	return {
		onSubmit,
		register,
		handleSubmit,
		errors,
		loading,
		handleChangeTotal,
		applyDescuento,
		setValue,
		control,
		getValues,
		handleTipoDoc,
		statusValidate
	}
}
