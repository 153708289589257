import API from "../config/api"
import {
	INICIO_DATA
} from "../types/homeType"

export const requestSuccess = (type, payload) => {

    return { type, payload }
}

export const getCountRetiro = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  let response = await API.get("home")

		   dispatch(requestSuccess(INICIO_DATA, response))
	    }catch(error){
	      resolve({status: false, message:"Hubo un error, intente más tarde"})
	    }
	})
}