import React, { useState } from "react"
import {  
	Form,
	Col,
	InputGroup
} from 'react-bootstrap'
import { useSelector } from "react-redux"
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format";
import { onPaste, validateOnlyNumber } from '../../../utils/validationInput';


const MetodoPago = ({ register, errors, index, title, control }) => {

	const [ totalPagar, setTotalPagar ] = useState("")
	const { metodoPagos, orders } = useSelector((state) => {
		return {
			metodoPagos: state.metodoPagos,
			orders: state.orders
		}
	})

	const { metodo_pago } = metodoPagos
	const { disabledBtnPago } = orders

	const handleRemoveEnterPago = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
		}
	}

	return (
		<Col className="mb-3">
			<Form.Row>
				<Col>
					<Form.Group as={Col} className="mb-1">
						<p className="text-orange mb-1 fw-600">{title}</p>
					</Form.Group>
				</Col>
			</Form.Row>
			<Form.Row>
				<Col md sm={6}>
			     	<Form.Group as={Col} controlId={`pagos[${index}].metodo_pago_id`}>
				    	<Form.Label>Método de Pago</Form.Label>
				    	<Form.Control 
						    	as="select"
						    	name={`pagos[${index}].metodo_pago_id`}
						    	ref={register}
						    	disabled={disabledBtnPago}
						    >
					    	<option value="">Seleccione</option>
					    	{
					    		metodo_pago.length > 0 && (
					    			metodo_pago.map(pago => <option key={pago.id} value={pago.id}>{pago.descripcion}</option>)
					    		)
					    	}
					 	</Form.Control>
				    </Form.Group>
			    </Col>
			    <Col md sm={6}>
				   <Form.Group as={Col} controlId={`pagos[${index}].nro_operacion`}>
			      		<Form.Label>N° de Operación</Form.Label>
			      		<Form.Control 
					    	type="text" 
					    	name={`pagos[${index}].nro_operacion`}
					    	ref={register}
					    	readOnly={disabledBtnPago}
					    	onKeyDown={(event) => handleRemoveEnterPago(event)}
							onKeyPress={(event) => validateOnlyNumber(event)}
							onPaste={(event) => onPaste(event)}
					    />
			    	</Form.Group>
			    </Col>
			   <Col md sm={6}>
			      	<Form.Group as={Col} controlId={`pagos[${index}].monto`}>
			      		<Form.Label>Monto</Form.Label>
			      		<InputGroup >
					    <InputGroup.Prepend>
					      <InputGroup.Text>$</InputGroup.Text>
					    </InputGroup.Prepend>
						<Controller
							control={control}
							name={`pagos[${index}].monto`}
							defaultValue={totalPagar}
							render={(props) => (
								<NumericFormat
									allowNegative={false}
									className="form-control formatNumber"
									decimalScale={2}
									name={props.name}
									id={props.name}
									fixedDecimalScale={false}
									isAllowed={({floatValue}) => {
										// Define your validation rules here
										return true;
									}}
									onPaste={(e) => e.preventDefault()}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={props.value}
									//defaultValue={ordenInfo.valor_declarado_carga}
									onValueChange={(values) => {
										props.onChange(values.floatValue);
										setTotalPagar(values.floatValue);
									}}
									readOnly={disabledBtnPago}
					    			onKeyDown={(event) => handleRemoveEnterPago(event)}
									maxLength={15}
								/>
							)}
							autoComplete="off"
						/>
					   {/** <Form.Control 
					    	type="text" 
					    	name={`pagos[${index}].monto`}
					    	className="formatNumber"
					    	ref={register}
					    	readOnly={disabledBtnPago}
					    	onKeyDown={(event) => handleRemoveEnterPago(event)}
					    /> */}
					  </InputGroup>
			    	</Form.Group>
			    </Col>
			</Form.Row>
		</Col>
		
	)
}

export default MetodoPago