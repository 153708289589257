import {  
	Modal,
	Table,
	Button
} from 'react-bootstrap'
import TbodyHistorico from "./TbodyHistorico"

const ModalHistorico = ({ showIsModal, setShowIsModal, handleCloseModal, listado }) => {

	return (
		<>
		<Modal show={showIsModal} onHide={handleCloseModal} aria-labelledby="example-modal-sizes-title-lg" size="lg">
	        <Modal.Body className="pl-5 pr-5 pt-5">
	        	<h5>Historial de Observaciones</h5>
	        	<Table  bordered size="sm" className="table-global mt-4">
				  <thead>
				    <tr>
				      <th>#</th>
				      <th className="th-description">Descripción</th>
				      <th>Usuario</th>
				      <th>Fecha</th>
				    </tr>
				  </thead>
				  <tbody>
				    {listado?.length  > 0 && ( listado.map((list, index) => {
				    		return <TbodyHistorico 
				    			key={index}
				    			{...list}
				    			indice={index}
							/>
						})
				    )}
				  </tbody>
				</Table>
				<Modal.Footer>
		          <Button variant="outline-warning"  onClick={handleCloseModal}>
		            Cerrar
		          </Button>
		        </Modal.Footer>
	        </Modal.Body>
	        
	      </Modal>
	    </>
	)
}

export default ModalHistorico