export const formatMonto = (totalMonto) => {
    let inicial = "";

    if ((totalMonto.toString().indexOf("-") === 0)) {
        totalMonto = totalMonto.toString().replace("-", "");
        inicial = "-";
    }
 
    let totalMontoFloat = parseFloat(totalMonto).toFixed(2);
    let ingresoTotal = totalMontoFloat.toString().split(".");

    let viewTotal=0;
   
    if(ingresoTotal[1]==="00"){
        viewTotal = inicial + decimales(ingresoTotal, formatMontoDecimal(ingresoTotal[0]));
    }else{
        viewTotal = totalMonto;
    }

    return viewTotal;
}

export const formatMontoGuia = (totalMonto) => {
    let inicial = "";

    if ((totalMonto.toString().indexOf("-") === 0)) {
        totalMonto = totalMonto.toString().replace("-", "");
        inicial = "-";
    }
 
    let totalMontoFloat = parseFloat(totalMonto).toFixed(2);
    let ingresoTotal = totalMontoFloat.toString().split(".");

    let viewTotal=0;

    viewTotal = inicial + decimales(ingresoTotal, formatMontoDecimal(ingresoTotal[0]));

    return viewTotal;
}


export const formatMontoCarga = (totalMonto) => {
    let inicial = "";
   
    if(totalMonto===null){
        return 0;
    }

    if ((totalMonto?.toString().indexOf("-") === 0)) {
        totalMonto = totalMonto.toString().replace("-", "");
        inicial = "-";
    }
 
    let totalMontoFloat = parseFloat(totalMonto).toFixed(2);
    let ingresoTotal = totalMontoFloat.toString().split(".");

    let viewTotal=0;
   
    if(ingresoTotal[1]!=="00"){
        viewTotal = inicial + decimales(ingresoTotal, formatMontoDecimal(ingresoTotal[0]));
    }else{
       
        viewTotal = ingresoTotal[0];
    }

    return viewTotal;
}

const decimales = (resultado, numero) => {
    
    let decimal = "00";
    if (resultado.length === 2) {
        decimal = resultado[1];
    }
    if(parseInt(decimal)===0){
        return numero
    }
    return numero + "," + decimal;
}

const formatMontoDecimal = (num) => {
    if (!isNaN(num)) {
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.')
        num = num.split('').reverse().join('').replace(/^[\.]/, '') //eslint-disable-line
        return num;
    }
}



export const formatFloat = (valor) => {
    while (valor?.toString().indexOf(".") !== -1)
        valor = valor?.toString().replace(".", "");

    return valor?.toString().replace(",", ".");
}

export const totales = (listado) => {
    let totalMonto = 0
    let totalCtdo = 0
    let totalPorPagar = 0
    let totalCtaCte = 0

    if(listado.length > 0){
        totalMonto = listado.reduce((acumulado, data) => acumulado + parseFloat(data.monto), 0)
        totalCtdo = listado.reduce((acumulado, data) => acumulado + parseFloat(data.contado), 0)
        totalPorPagar = listado.reduce((acumulado, data) => acumulado + parseFloat(data.porPagar), 0)
        totalCtaCte = listado.reduce((acumulado, data) => acumulado + parseFloat(data.cuentaCorriente), 0)
    }

    return {
        totalMonto,
        totalCtdo,
        totalPorPagar,
        totalCtaCte
    }
    
}


export const fomartDate = (valor) => {
    while (valor.indexOf("/") !== -1){
        valor = valor.replace("/", "C")
    }
    return valor
}


