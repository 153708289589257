import React, { Fragment, useEffect, useState } from "react"
import {  
	Form,
	Col,
	Row,
	InputGroup,
	Button
} from 'react-bootstrap'

import { useDispatch, useSelector } from "react-redux"
import { resultTotales } from "../../actions/orderAction"
import TitlePrincipal from "../Layouts/TitlePrincipal"
import { downloadDocumento } from '../../utils/downloadFile'
import download from '../../assets/images/download_small.png'
import { searchDocument } from '../../actions/facturacionAction'
import { useDocumentBoleta } from '../../hooks/MetodoPago/useDocumentBoleta'
import { NumericFormat } from "react-number-format";


const Pagos = ({ register, errors, setValue, ordenInfo, getValues, toast, setOpenForm, loadingPago, setLoadingPago, validateForm,
	bloquear, setBloquear }) => {
		
	const dispatch = useDispatch()
	const { forma_pago, documentacionsPago, totales, isBloqueCta } = useSelector((state) => state.orders)
	
	const [ descargar, setDescargar ]= useState(false)

	const { generateDocumento } = useDocumentBoleta(toast, loadingPago, setLoadingPago)

	const applyDiscount = (event) => {
		dispatch(resultTotales(event.target.value))
	}


	useEffect(() => {
		if(Object.keys(totales).length>0){
		
			setValue("total_bultos", totales.total_bultos)
			setValue("total_kilos", totales.total_kilos)
			setValue("total_metro_cubico", totales.total_metro_cubico)
			setValue("subtotal_orden", totales.subtotal_orden)
			setValue("valor_neto", totales.valor_neto)
			setValue("valor_iva", totales.valor_iva)
			setValue("total", totales.total)

			if(parseInt(getValues("forma_pago_id"))===1){
				setBloquear(true)
			}
		}		
		setValue("documentacion_id_pago", ordenInfo?.documentacion_id_pago)
		setValue("numero_documento", ordenInfo.numero_documento ?? "")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totales]) 

	useEffect(() => {
	
		if(isBloqueCta){
			setValue("forma_pago_id", 3)
			setBloquear(true)
		}else{
			if(parseInt(getValues("forma_pago_id"))===3){
				setValue("forma_pago_id", "")
			}
			setBloquear(isBloqueCta)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isBloqueCta])


	const cambiarFormaPago = (event) => {
		if(parseInt(event.target.value)===3){
			if(getValues("codigo_interno")==="no" || parseInt(getValues("codigo_interno_nro"))===111){
				toast.error("El cliente no es cuenta correntista", {position: toast.POSITION.TOP_RIGHT})
				setValue("forma_pago_id", "")
				return false
			}
		}
		if(parseInt(event.target.value)===2){
			if(getValues("type_price")==="no_price"){
				toast.error("La orden es sin precio, seleccione otra forma de pago", {position: toast.POSITION.TOP_RIGHT})
				setValue("forma_pago_id", "")
				return false
			}else{
				
				if(getValues("codigo_interno")==="si"  || getValues("codigo_interno_nro")!==""){
					toast.error("El cliente tiene un código interno debe seleccionar  la forma de pago: Por pagar o Cuenta corriente", {position: toast.POSITION.TOP_RIGHT})
					setValue("forma_pago_id", "")
					return false
				}
			}
		}
		if(parseInt(event.target.value)===1 || parseInt(event.target.value)===3){

			setBloquear(true)
			setValue("documentacion_id_pago", "")
			setValue("numero_documento", "")
		}else{
			setBloquear(false)
		}
	}

	const downloadDoc = async (url_cedible, numero_documento) => {
		setDescargar(true)

		if(url_cedible.length===0){
			const tipo = (parseInt(getValues('documentacion_id_pago'))===1) ? 'factura' : 'boleta'
			const body = { tipo_documento: tipo, numero_documento }
			await dispatch(searchDocument(body))
		}else{
			let data = {
				url: url_cedible,
				numero_documento: numero_documento
			}
			downloadDocumento(data, "_blank")
		}
		setDescargar(false)
	}		

	const checkBoleta1 = (event) => {
		if(parseInt(event.target.value)===8){
			document.getElementById('numero_documento').value='B-1'
			if(document.querySelector("#generar-documento")){
				document.querySelector("#generar-documento").style.display = 'none'
			}
		}else{
			if(document.getElementById('numero_documento').value==='B-1'){
				document.getElementById('numero_documento').value=''
			}
			
			if(document.querySelector("#generar-documento")){
				document.querySelector("#generar-documento").style.display = 'block'
			}
		}
	}

	const validateDocument = (event) => {
		event.preventDefault()
		const data = getValues()
	
		if(data.documentacion_id_pago===""){
			toast.error("Seleccione el documento de pago", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
		if(parseInt(data.documentacion_id_pago)===2){
			generateDocumento(event, data, setValue)			
		}else {
			setOpenForm(true)
		}
	}


	return (
		<Fragment>
			<Row className="mt-1 mb-1">
				<Col md={6} sm={12} className="pr-4">
					<TitlePrincipal title="TOTALES" classTitle="bg-gray-wran" />
					<Row className="bg-soft-blue">
						<Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="total_bultos" className="pr-0 mb-1">
						    	<Form.Label>Bulto</Form.Label>
						    	<Form.Control 
							    	type="text" 
							    	name="total_bultos" 
							    	defaultValue={totales.total_bultos}
							    	ref={register} 
					                readOnly
							    />
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="total_kilos" className="pr-0 pl-0 mb-1">
						    	<Form.Label>Kilo</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="total_kilos" 
							    	defaultValue={totales.total_kilos}
							    	ref={register} 
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.total_kilos}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="total_metro_cubico" className="pr-0 pl-0 mb-1">
						    	<Form.Label>M3</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="total_metro_cubico" 
							    	defaultValue={totales.total_metro_cubico}
							    	ref={register} 
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.total_metro_cubico}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="subtotal_orden" className="pr-0 pl-0 mb-1">
						    	<Form.Label>Subtotal</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="subtotal_orden" 
							    	defaultValue={totales.subtotal_orden}
							    	ref={register} 
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.subtotal_orden}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="descuento" className="pl-0 pr-0 mb-1">
						    	<Form.Label>Dsct 10%</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="descuento"
								    	onChange={(event) => applyDiscount(event)} 
						                ref={register} 
								    >
							    	<option value="No">No</option>
							    	<option value="Si">Si</option>
							 	</Form.Control>
						    </Form.Group>
					    </Col>
					</Row>
					<Row className="bg-soft-blue ">
						<Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="valor_neto" className="pr-0">
						    	<Form.Label>Neto</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="valor_neto" 
							    	defaultValue={totales.valor_neto}
							    	ref={register}
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.valor_neto}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="valor_iva" className="pr-0 pl-0">
						    	<Form.Label>IVA $</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="valor_iva" 
							    	defaultValue={totales.valor_iva}
							    	ref={register}
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.valor_iva}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="total" className="pl-0 pr-0">
						    	<Form.Label>Bruto $</Form.Label>
						    	<Form.Control 
							    	type="hidden" 
							    	name="total" 
							    	defaultValue={totales.total}
							    	ref={register}
					                readOnly
							    />
								<NumericFormat
									displayType="text"
									className="form-control text-padding"
									decimalScale={2}
									fixedDecimalScale={false}
									decimalSeparator={','}
									thousandSeparator={"."}
									value={totales.total}
									readOnly
								/>
						    </Form.Group>
					    </Col>
					</Row>
				</Col>
				<Col md={6} sm={12}>
					<TitlePrincipal title="DOCUMENTO DE FORMA DE PAGO" />
					<Row className="bg-soft-blue">
						<Col lg md={6} sm={6}>
					     	<Form.Group as={Col} controlId="forma_pago_id" className="pr-0 pl-0 mb-1">
						    	<Form.Label>Forma de Pago</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="forma_pago_id" 
								    	ref={register({required: {value: validateForm, message: 'Requerido'}})}
								    	onChange={(event) => cambiarFormaPago(event)}
								    	disabled={isBloqueCta}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		forma_pago.length > 0 && (
							    			forma_pago.map(forma => <option key={forma.id} value={forma.id}>{forma.descripcion}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.forma_pago_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.forma_pago_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={6} sm={6}>
					     	<Form.Group as={Col} controlId="documentacion_id_pago" className="pr-0 pl-0 mb-1">
						    	<Form.Label>Documento</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="documentacion_id_pago" 
								    	ref={register}
								    	disabled={bloquear}
								    	onChange={(event) => checkBoleta1(event)}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		documentacionsPago.length > 0 && (
							    			documentacionsPago.map(documents => <option key={documents.id} value={documents.id}>{documents.descripcion}</option>)
							    		)
							    	}
							 	</Form.Control>
						    </Form.Group>
					    </Col>
					    <Col md sm={12}>
					     	<Form.Group as={Col} controlId="numero_documento" className="pr-0 pl-0 mb-1">
						    	<Form.Label>N° Documento</Form.Label>
						    	<InputGroup className="mb-3">
								    <Form.Control 
								    	type="text" 
								    	name="numero_documento" 
								    	defaultValue={ordenInfo.numero_documento}
								    	ref={register}
								    	readOnly
								    />
								    {
								    	(ordenInfo.url_cedible!=="" && ordenInfo.url_cedible!==undefined && 
										ordenInfo.numero_documento !==null && ordenInfo.numero_documento!=="" ) && (
								    		<InputGroup.Append>
										      <Button variant="warning" type="button" 
										      		onClick={()=>downloadDoc(ordenInfo.url_cedible, ordenInfo.numero_documento)}
										      		disabled={descargar}
										      		className="btn-sm"
										      		>
										      	<img src={download} alt="Descargar documento" className="btn-download" />
										      </Button>
										    </InputGroup.Append>
								    	)
								    }								    
								</InputGroup>
						    </Form.Group>
					    </Col>
					</Row>
					<Row className="bg-soft-blue ">
						<Col lg md={6} sm={6}>
					     	<Form.Group as={Col} controlId="rut_factura" className="pr-0 pl-0 mb-1">
						    	<Form.Label>RUT</Form.Label>
						    	<Form.Control 
							    	type="text" 
							    	name="rut_factura" 
							    	defaultValue={ordenInfo.rut_factura}
							    	ref={register}
							    	readOnly
							    />
						    </Form.Group>
					    </Col>
					    <Col lg sm={6}>
					     	<Form.Group as={Col} controlId="razon_social_factura" className="pr-0 pl-0 mb-1">
						    	<Form.Label>Nombre y Apellido</Form.Label>
						    	<Form.Control 
							    	type="text" 
							    	name="razon_social_factura" 
							    	defaultValue={ordenInfo.razon_social_factura}
							    	ref={register}
							    	readOnly
							    />
						    </Form.Group>
					    </Col>
					    { ordenInfo.isGenerateDocument && 
					    	<Col md sm={12}>
						     	<Button variant="warning" type="button" 
						      		onClick={(event)=>validateDocument(event)}
						      		className="btn-sm mt-4"
						      		disabled={loadingPago}
									id="generar-documento"
						      		>
						      	{loadingPago ? 'Generando...' : 'Generar Documento' }
						      </Button>
						    </Col>
						}
					</Row>
				</Col>
			</Row>	
		</Fragment>
	)
}

export default Pagos