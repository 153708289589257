import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addOficina, editOficina, isVisibleFormularioOficina } from "../../../actions/oficinaAction"
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"

const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	
	const { ofIsEdit  } = useSelector((state) => state.oficinas)

    
    const onSubmit = async (data, e) => {
		setIsLoading(true)
		
		let response 

		if(ofIsEdit){
			response = await dispatch(editOficina(data))
		}else{
			response = await dispatch(addOficina(data))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

    const handleRegresar = () => {
		dispatch(isVisibleFormularioOficina(false))
    }

	return (
    	<Form id="frmOficina" onSubmit={handleSubmit(onSubmit)}>
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!ofIsEdit ? 'CREAR OFICINA' : 'EDITAR OFICINA' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
			     	<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="nombre" >
					      		<Form.Label>Nombre</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="nombre" 
						                autoFocus
						                defaultValue={datos?.nombre || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
										 onKeyPress={(event) => validateOnlyLetter(event)}
										 onPaste={(event) => onPaste(event)}
										 maxLength={30}
								    />
								{errors.nombre && (
							    	<Form.Text className="text-danger">
							      		{errors?.nombre?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>					
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
					<Button variant="secondary" type="button"  className="pl-5 pr-5 mr-3" onClick={(event) => handleRegresar(event)}>
					    Regresar
					</Button>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : ofIsEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form>
	)
}

export default Formulario