import React from 'react'
import {  
	Col,
	Form,
	Button
} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { searchPorIdRole, deleteRole, isVisibleFormularioRole } from "../../../actions/roleAction"

import Swal from 'sweetalert2'
import Nuevo from "../../../assets/images/configurar/plus.png"
import Editar from "../../../assets/images/configurar/edit.png"
import Eliminar from "../../../assets/images/configurar/delete.png"
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {

	const dispatch = useDispatch()
	const { rIsSelectedRows } = useSelector((state) => state.roles)


	const handleClick = () => {
		if(rIsSelectedRows && rIsSelectedRows.selectedCount===1){
            let id = rIsSelectedRows.selectedRows[0].id
            dispatch(searchPorIdRole(id))
		}else if(rIsSelectedRows && rIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleDelete = () => {
		if(rIsSelectedRows && rIsSelectedRows.selectedCount===1){
            
            Swal.fire({
			  title: '¿Desea eliminar el rol?',
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Sí',
			  cancelButtonText: 'No'
			}).then(async (result) => {
			  if (result.isConfirmed) {
			  	let id = rIsSelectedRows.selectedRows[0].id
            	const response = await dispatch(deleteRole(id))
            	if(response.status){
            		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            		setClearTable(true)
            	}else{
            		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            	}			    
			  }
			  setClearTable(false)
			})
			
		}else if(rIsSelectedRows && rIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleAdd = () => {
		dispatch(isVisibleFormularioRole(true))
	}

	  
	return (
		<>
		<Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
			<Col>
				<Col lg={6} className="pl-0 pr-0">
			
			      		<Form.Control 
						    	id="search" 
						    	type="text" 
						    	placeholder="Ingresa el nombre del rol" 
						    	aria-label="Ingresa el nombre del rol" 
						    	value={filterText} 
						    	onChange={onFilter}
								onKeyPress={(event) => validateOnlyLetter(event)}
								onPaste={(event) => onPaste(event)}
						    />

		    	</Col>
		    	<span className="fs-12">**Nombre del rol se utiliza para la búsqueda y modificación de los datos</span>	
			</Col>
	    	<Col className="text-right">
		    	<Form.Group>
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleAdd(event)}>
					   <img src={Nuevo} alt="Nuevo" className="img-conf" />
					</Button>	
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleClick(event)}>
					    <img src={Editar} alt="Editar" className="img-conf" />
					</Button>	
                    <Button variant="warning" type="button"  onClick={(event) => handleDelete(event)}>
					    <img src={Eliminar} alt="Eliminar" className="img-conf" />
					</Button>	
			    </Form.Group>
		    </Col>
	    </Col>

	</>
	)
} 

export default Filter