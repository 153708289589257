import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getHome,
  searchOrden,
  editedOrden,
  resultTotales,
  cancelOrden,
  generarNotaCredito,
} from "../../actions/orderAction";

import { getFechaFormat } from "../../utils/formatFecha";
import { validarRut } from "../../utils/rut";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

const defaultValues = {
  fecha_orden: new Date(),
};

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useEditOrden = () => {
  const dispatch = useDispatch();
  const [validateForm, setValidateForm] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [titleModulo, setTitleModulo] = useState("");
  const [infoData, setInfoData] = useState({});
  const [hidden, setHidden] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [nameModulo, setNameModulo] = useState("");
  const [showIsModal, setShowIsModal] = useState(false);
  const [showModalPrint, setShowModalPrint] = useState(false);

  const [updateData, setUpdateData] = useState(false);
  const [statusValidate, setStatusValidate] = useState(validateForm);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [bloquear, setBloquear] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
  } = useForm({ defaultValues });

  const {
    dataOrders,
    initialOrden,
    disabledBtnCrear,
    disabledBtnAnular,
    disabledImprimir,
    visibleCarga,
  } = useSelector((state) => state.orders);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (data?.type_price === "with_price") {
        if (
          parseInt(data?.forma_pago_id) === 2 &&
          data?.documentacion_id_pago === ""
        ) {
          toast.error("Seleccione el tipo de documento de pago", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }
      }

      let isValid = validarRut(data?.cliente_rut);
      if (!isValid.status) {
        toast.error(`${isValid.message} del cliente`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      isValid = validarRut(data?.rut_remitente);
      if (!isValid.status) {
        toast.error(`${isValid.message} del remitente`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      isValid = validarRut(data?.rut_destinatario);
      if (!isValid.status) {
        toast.error(`${isValid.message} del destinatario`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (!data.hasOwnProperty("cargas")) {
        toast.error("Debe de completar el detalle de la carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      if (typeof data?.cargas === "undefined" || data?.cargas?.length === 0) {
        toast.error("Mínimo 1 detalle de la carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      let pago_descripcion = document.getElementById("forma_pago_id");

      const information = {
        ...data,
        fecha_orden: getFechaFormat(getValues("fecha_orden")),
        sucursal_origen_id: document.getElementById("sucursal_origen_id").value,
        oficina_origen_id: document.getElementById("oficina_origen_id").value,
        forma_pago_id: getValues("forma_pago_id"),
        documentacion_id_pago: getValues("documentacion_id_pago"),
        numero_documento: getValues("numero_documento"),
        forma_pago_descripcion:
          pago_descripcion.options[pago_descripcion.selectedIndex].text,
      };

      setTitleModulo("FACTURA A NOMBRE DE:");
      console.log("data", information);
      //Caso de editar o agregar, verificando la edición
      const response = switchEdit(information);
      if (response.status === 1) {
        setInfoData({ ...data, cliente_cod: dataOrders.cliente_cod });
        Swal.fire({
          title: response.message,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
        setLoading(false);
      } else if (response.status === 2) {
        Swal.fire({
          title: response.message,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await editOrdenAction(information);
          }
        });
        setLoading(false);
      } else if (response.status === 0) {
        await editOrdenAction(information);
      } else if (response.status === 3) {
        Swal.fire({
          title: response.message,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
        });
        setLoading(false);
      }

      setLoading(false);
    } catch (err) {
      toast.error("Hubo un error al editar la orden de flete", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const switchEdit = (data) => {
    if (dataOrders?.estado_orden === "Pendiente tarifario") {
      return {
        status: 3,
        message:
          "La orden se encuentra Pendiente tarifario, debe dirigirse con el administrador",
      };
    }

    if (dataOrders.codigo_interno === "si") {
      if (
        dataOrders.codigo_interno_nro !== data.codigo_interno_nro &&
        (dataOrders.numero_documento !== undefined ||
          dataOrders.numero_documento !== "")
      ) {
        //getDatInitial(getValues("numero_orden"), false);
        return {
          status: 1,
          message:
            "Estás cambiando el código interno del cliente y ya fue facturada la orden, debe generar una nota de crédito",
        };
      }
    }

    if (dataOrders.total !== data.total) {
      if (
        dataOrders.numero_documento !== undefined &&
        dataOrders.numero_documento.length > 0
      ) {
        //getDatInitial(getValues("numero_orden"), false);
        return {
          status: 1,
          message:
            "Estás cambiando el monto y ya fue facturada la orden, debe generar una nota de crédito",
        };
      }

      if (dataOrders.rendicion === true) {
        return {
          status: 2,
          message:
            "La orden está relacionada con una rendición de caja, ¿Seguro desea modificar el monto?",
        };
      }
    }

    if (dataOrders.fecha_orden_format !== data.fecha_orden) {
      if (dataOrders.rendicion === true) {
        return {
          status: 2,
          message:
            "La orden está relacionada con una rendición de caja, ¿Seguro desea modificar la fecha?",
        };
      }
    }

    return { status: 0, message: "" };
  };

  const editOrdenAction = async (information) => {
    const ordenId = getValues("numero_orden");

    let sucursal_origen = document.querySelector("#sucursal_origen_id");

    let dataOrden = {
      ...information,
      sucursal_name:
        sucursal_origen?.options[sucursal_origen.selectedIndex]?.text,
    };

    const response = await dispatch(editedOrden(ordenId, dataOrden));
    setLoading(false);
    if (response.status) {
      //setInitial(false)
      if (!showIsModal) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      }

      await dispatch(searchOrden({ numero_orden: ordenId }));
      // setInitial(true)
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleblockPaidInput = (event, valor) => {
    const value = event !== null ? event.target.value : valor;
    let checked = value === "no_price" ? true : false;
    if (value === "no_price") {
      setValue("documentacion_id_pago", "");
      setValue("forma_pagodescuento_id", "No");
      setValue("total_carga", "No");
      setValidateForm(false);
      //setStatusValidate(statusValidate => false)
      setBloquear(true);
    } else {
      //setValidateForm(true)
    }
    document.querySelector("#documentacion_id_pago").disabled = checked;
    document.querySelector("#descuento").disabled = checked;
    document.querySelector("#total_carga").readOnly = checked;
  };

  const getDatInitial = async (numero, isVisibleLoading = true) => {
    try {
      if (isVisibleLoading) {
        setIsLoadingInitial(true);
      }

      await dispatch(getHome());

      let response = await dispatch(searchOrden({ numero_orden: numero }));
      if (response.status) {
        //reset({ defaultValues: response?.data })
        setValue("numero_orden", numero);
        setValue("forma_pago_id", response?.data?.forma_pago_id);
        setValue("documentacion_id", response?.data?.documentacion_id);
        setValue("sucursal_origen_id", response?.data?.sucursal_origen_id);
        setValue("oficina_origen_id", response?.data?.oficina_origen_id);
        setValue("sucursal_destino_id", response?.data?.sucursal_destino_id);
        setValue("oficina_destino_id", response?.data?.oficina_destino_id);
        setValue("tipo_envio_id", response?.data?.tipo_envio_id);
        setValue("cliente_rut", response?.data?.cliente_rut);
        setValue("rut_remitente", response?.data?.rut_remitente);
        setValue("rut_destinatario", response?.data?.rut_destinatario);

        dispatch(resultTotales(response?.data?.descuento));
        //setInitial(true)
        if (response?.data?.tipo_cliente === "remitente") {
          setValue("tipo_cliente", "remitente");
        } else {
          setValue("tipo_cliente", "destinatario");
        }
        if (response?.data?.codigo_interno === "si") {
          setValue("codigo_interno", "si");
          document.getElementById("codigo_interno_nro").readOnly = false;
        } else {
          setValue("codigo_interno", "no");
          document.getElementById("codigo_interno_nro").readOnly = true;
        }

        if (getValues("rut_remitente") === RUT_MESON) {
          document.getElementById("razon_social_remitente").readOnly = false;
        }
        if (getValues("rut_destinatario") === RUT_MESON) {
          document.getElementById("razon_social_destinatario").readOnly = false;
        }

        if (
          typeof response?.data?.fecha_orden !== "undefined" &&
          response?.data?.fecha_orden !== ""
        ) {
          let fecha = Date.parse(response?.data?.fecha_orden);

          setValue("fecha_orden", new Date(fecha));
        }

        if (
          typeof dataOrders?.messageSuccess !== "undefined" &&
          dataOrders?.messageSuccess?.status === true
        ) {
          toast.success(dataOrders?.messageSuccess?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        if (response?.data?.estado_orden === "Pendiente tarifario") {
          handleblockPaidInput(null, "no_price");
        }

        if (document.querySelector("#generar-documento")) {
          document.querySelector("#documentacion_id_pago").disabled = false;
        }

        handleTipoDoc();
      }

      if (isVisibleLoading) {
        setIsLoadingInitial(false);
      }
    } catch (err) {
      toast.error("Hubo un error al obtener los datos de la orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoadingInitial(false);
    }
  };

  const handleShow = () => {
    setIsVisible(false);

    if (dataOrders.numero_documento) {
      setIsVisible(true);
    }

    setInfoData((infoData) => dataOrders);
    setNameModulo("anular");
    setHidden(true);
    setTitleModulo("ANULAR ORDEN");
    setUpdateData((updateData) => !updateData);
  };

  const anularOrden = async (information, e) => {
    let response;
    console.log("information", information);
    if (nameModulo === "anular") {
      response = await dispatch(cancelOrden(information));
    } else {
      response = await dispatch(generarNotaCredito(information));
      setValue("numero_documento", "");
    }

    if (response.status) {
      if (nameModulo === "nota") {
        let evt = new CustomEvent("submit", {
          bubbles: true,
          cancelable: true,
        });

        let standardEvt = new Event("build");

        document
          .getElementById("frmOrders")
          .addEventListener("build", function () {
            // Código que se ejecutará después de que se complete el evento
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        document.getElementById("frmOrders").dispatchEvent(evt);
        // Disparar el evento estándar
        document.getElementById("frmOrders").dispatchEvent(standardEvt);
      } else if (nameModulo === "anular") {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        await getDatInitial(getValues("numero_orden"), false);
      }
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleNotaCredito = () => {
    setIsVisible(true);
    const info = {
      ...dataOrders,
      codigo_interno_nro: getValues("codigo_interno_nro"),
      rut_cliente_nuevo: getValues("cliente_rut"),
      total: getValues("total"),
    };

    setInfoData({ ...info });
    console.log("info", info);
    setNameModulo("nota");
    setHidden(true);
    setTitleModulo("NOTA DE CREDITO A NOMBRE DE:");
    setUpdateData((updateData) => !updateData);
  };

  const handleCloseModal = () => {
    setShowIsModal(!showIsModal);
    getDatInitial(getValues("numero_orden"), false);
  };

  const handleGenerateModalNota = () => {
    setShowIsModal(!showIsModal);
    //getDatInitial(getValues("numero_orden"), false);
  };

  const handleCloseModalPayment = () => {
    setOpenForm(false);
  };

  const handleClosePrint = () => {
    //event.preventDefault()
    setShowModalPrint(!showModalPrint);
  };

  const handleTipoDoc = (event) => {
    let pago_descripcion = document.getElementById("documentacion_id");

    pago_descripcion =
      pago_descripcion.options[pago_descripcion.selectedIndex].text;

    if (pago_descripcion.toLowerCase() === "sin documento") {
      setStatusValidate((statusValidate) => false);
    } else {
      setStatusValidate((statusValidate) => true);
    }
  };

  (function () {
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
      params = params || { bubbles: true, cancelable: true, detail: undefined };
      var evt = document.createEvent("submit");
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();

  const formElement = {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
  };

  const state = {
    validateForm,
    isLoading,
    titleModulo,
    infoData,
    hidden,
    isVisible,
    dataOrders,
    initialOrden,
    disabledBtnCrear,
    openForm,
    nameModulo,
    disabledBtnAnular,
    disabledImprimir,
    showModalPrint,
    updateData,
    showIsModal,
    statusValidate,
    visibleCarga,
    isLoadingInitial,
    bloquear,
    setBloquear,
  };

  const actions = {
    handleblockPaidInput,
    setLoading,
    onSubmit,
    setHidden,
    editOrdenAction,
    getDatInitial,
    handleCloseModalPayment,
    setOpenForm,
    handleCloseModal,
    handleNotaCredito,
    anularOrden,
    handleClosePrint,
    setShowIsModal,
    handleShow,
    handleTipoDoc,
    handleGenerateModalNota,
  };

  return {
    actions,
    formElement,
    state,
  };
};
