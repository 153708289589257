import React, { Fragment } from "react"
import {  
	Form,
	Col
} from 'react-bootstrap'
import PanelDetalle from "../Layouts/PanelDetalle"

const Observaciones = ({ register, errors, ordenInfo }) => {


	return (
		<Fragment>
			<div className="mb-2">
				<PanelDetalle title="E-MAIL" subtitle="" check={false}>
					<Form.Row className="pt-1 mb-0 ">
						<Col>
					      	<Form.Group as={Col} controlId="email" className="mb-0">
					      		<Form.Control 
									defaultValue={ordenInfo.email} 
									as="input" 
									name="email" 
									placeholder="Escribe e-mail de notificaciones" 
									ref={register({
										//eslint-disable-next-line
										pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
									})} 
									maxLength={70}
								/>
					    	</Form.Group>
							{errors.email && (
								<Form.Text className="text-danger pl-3">
									{errors?.email?.message}
								</Form.Text>
							)}
					    </Col>
					</Form.Row>
				</PanelDetalle>
			</div>
			<PanelDetalle title="OBSERVACIONES" subtitle="" check={false}>
				<Form.Row className="pt-1 mb-0 ">
			     	<Col>
				      	<Form.Group as={Col} controlId="comentarios" className="mb-0">
				      		<Form.Control defaultValue={ordenInfo.comentarios} as="textarea" name="comentarios" rows={2} placeholder="Escribe aquí tus observaciones" ref={register} />
				    	</Form.Group>
				    </Col>
				</Form.Row>
			</PanelDetalle>
		</Fragment>

	)
}

export default Observaciones
