import React, { Fragment } from "react"
import {  
	Form,
	Col,
	Table
} from 'react-bootstrap'
import TbodyOrdens from "./TbodyOrdens"
import { useSelector, useDispatch } from "react-redux"
import { repetirMotivo } from "../../../actions/orderAction"

const TablaOrden = ({ register, errors }) => {

	const dispatch = useDispatch()

	const { listOrdenEntregar  } = useSelector((state) => state.orders)

	const handleChange = (event) => {
		if(event.target.checked){
		 	dispatch(repetirMotivo())
		}
	}

	return (
		<Fragment>
			<Form.Row>
				<Col xs={12} className="text-right">
			     	<Form.Group as={Col} controlId="repetir_motivo">
			     		<Form.Check 
			     			type="checkbox" 
					    	name="repetir_motivo" 
					    	defaultValue="si"
					    	onChange={(event) => handleChange(event)}
	             			ref={register}
					      	label="Repetir motivo"
					    />
				    </Form.Group>
			    </Col>
				<Col xs={12}>
					<Form.Group as={Col}>
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th>N° de Orden</th>
							      <th>Forma de Pago</th>
							      <th>Total</th>
							      <th>Motivo</th>
							      <th>Estado</th>
							    </tr>
							  </thead>
							  <tbody>
							  	{
							  		listOrdenEntregar?.length  > 0 ? (
							  			listOrdenEntregar?.map((orden, index) => {
							  				return (
							  					<TbodyOrdens 
							  						key={index}
							  						index={index}
							  						{...orden} 
							  						register={register}
							  					/>
							  				)
							  			})
							  		) : (
							  			<tr className="tr-vacia">
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  			</tr>
							  		)
							  	}
							  </tbody>
						</Table>
					</Form.Group>
				</Col>
			</Form.Row>				
		</Fragment>

	)
}

export default TablaOrden

