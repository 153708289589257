import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Cliente/Listado"
import Formulario from "../../components/Admin/Cliente/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoCliente } from "../../actions/clienteAction"
import { getHome } from "../../actions/orderAction"

const Cliente = ({toast}) => {
	
	const dispatch = useDispatch()
	const { listado, isCargando, datos, isForm } = useSelector((state) => state.cliente)

	useEffect(() => {
		dispatch(listadoCliente())
		dispatch(getHome())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!isForm ? "Listado de Clientes" : "Clientes" }>
				{!isForm ? (
					<Listado data={listado} isCargando={isCargando} toast={toast} />
				): (
					<Formulario toast={toast} datos={datos} />	
				)}
		    				    
			</CardRetiro>
	    </>
	)
}

export default Cliente