import React, { useState } from "react"
import {  
	Button,
	Modal,
	Row,
	Col,
	Form
} from 'react-bootstrap'
import Progress from '../Layouts/Progress'
import { useForm } from "react-hook-form"
import Swal from 'sweetalert2'


const ModalAnular = ({ setHidden, hidden, data, funcFactura, title, isVisibleFactura, nameModulo, setLoading, isLoading=null }) => {

	const [ isProcesando, setIsProcesando ] = useState(false)
	const [ isSpinner, setIsSpinner ] = useState(false)

	const { register, handleSubmit, errors } = useForm()

	const handleClose = () => {
		setHidden(false)
		if(isLoading!==null){
			setLoading(false)
		}		
	}

	const onSubmit = async (facturacion, e) => {
    	e.preventDefault()
    	let mensaje =''
    	if(data?.cliente_rut && data?.numero_documento?.length > 0 && data?.numero_documento!=="B-1"){
            mensaje = 'Se va generar una nota de crédito, ¿Seguro desea anular la orden?'
        }else{
            mensaje = '¿Seguro desea anular la orden?'
        }
    	
    	Swal.fire({
		  title: mensaje,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  		setIsSpinner(true)
		  		setIsProcesando(true)
		    	let information = { ...data, factura: facturacion }
		    	const response = await funcFactura(information, e)
		    	setIsProcesando(false)
		    	setIsSpinner(false)
		    	if(response){
		    		setHidden(false)
		    	}	    
		  }
		})
    }


	return (
		
		    <Modal show={hidden} onHide={handleClose} centered size="lg" >
		        <Modal.Header closeButton>
		          <h6 className="mb-0">{title}</h6>
		        </Modal.Header>
		        <Modal.Body>
		        <Form id="frmFactura" onSubmit={handleSubmit(onSubmit)}>
			        	<div className="pb-4">
                            <Row className="pt-4">
                                <Col sm={12}>
                                    <Form.Group controlId="motivo_anular">
                                        <Form.Label >Motivo</Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            name="motivo_anular" 
                                            rows={4} 
                                            ref={register({required: {value: true, message: 'Requerido'}})} 
                                    />
                                    {errors.motivo_anular && (
                                            <Form.Text className="text-danger">
                                                {errors?.motivo_anular?.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
						</div>
						{isSpinner && (
							<div className="pb-4 text-center">
								<Progress />
								<p className="font-italic">Procesando la información, espere unos minutos</p>
							</div>
						)}
				 	<Modal.Footer>
			          <Button type="submit" className="pl-5 pr-5" variant="warning" disabled={isProcesando}>
			            {isProcesando ? 'Procesando...' :  'Procesar' }
			          </Button>
			          <Button className="pl-5 pr-5" variant="warning" onClick={() => handleClose()}>
			            Cerrar
			          </Button>
			        </Modal.Footer>
			    </Form>
		        </Modal.Body>
		      </Modal>
		

	)
}

export default ModalAnular