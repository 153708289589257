import React from "react"
import {  
	Button,
	Modal,
	Row,
	Col
} from 'react-bootstrap'

const ModalMessage = ({ setHidden, hidden, message }) => {
	  	
	const handleClose = () => setHidden(false)

	return (
		
		    <Modal show={hidden} onHide={handleClose} centered>
		        <Modal.Header closeButton>
		          <h6>CLIENTE NO CREADO</h6>
		        </Modal.Header>
		        <Modal.Body>
		        	<Row>
			            <Col xs={{ span: 10, offset: 1 }}>
			              <p>{message}</p>
			            </Col>
			          </Row>
					  <Modal.Footer>
				          <Button className="pl-5 pr-5" variant="warning" onClick={() => handleClose()}>
				            Ok
				          </Button>
				        </Modal.Footer>
		        </Modal.Body>
		      </Modal>
		

	)
}

export default ModalMessage