import React, { useState } from 'react'
import DataTable from 'react-data-table-component'

import Filter from './Filter'
import ordenes from './Columnas/Ordenes'
import Progress from '../../Layouts/Progress'
import { hiddenBotones } from "../../../actions/facturacionAction"
import { useDispatch } from "react-redux"

const Listado = ({data, isCargando }) => {

	const columnas = ordenes.columnas
	const dispatch = useDispatch()
	const [clearTable, setClearTable ] = useState(false)

	const paginationOptions = {
		rowsPerPageText: 'Filas por Página',
		rangeSeparatorText:'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	}

	const [filterText, setFilterText] = React.useState('')
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
	const filteredItems = data.filter(item => item.numero_folio && item.numero_folio.toString().toLowerCase().includes(filterText.toLowerCase()));


  	const subHeaderComponentMemo = React.useMemo(() => {

  		const busquedaText = (texto) => {
			setFilterText(texto)
			dispatch(hiddenBotones(filteredItems, texto))
		}

    	const handleClear = () => {
	      	if (filterText) {
	        	setResetPaginationToggle(!resetPaginationToggle);
	        	setFilterText('');
	      	}
	    }
		return <Filter onFilter={e => busquedaText(e.target.value)}  onClear={handleClear} filterText={filterText} setClearTable={setClearTable} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, resetPaginationToggle])


	return (
		<div className="table-historico">
			<DataTable 
				columns={columnas}
				data={filteredItems}
			    pagination
			    paginationResetDefaultPage={resetPaginationToggle}
			    subHeader
			    paginationComponentOptions={paginationOptions}
      			subHeaderComponent={subHeaderComponentMemo}
      			persistTableHead
      			dense
      			progressPending={isCargando}
      			progressComponent={<Progress />}
				noDataComponent={<div>No hay datos registros disponible.</div>}
          		clearSelectedRows={clearTable}
			/>

		</div>
	)
}

export default Listado