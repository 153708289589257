import { useEffect } from "react"
import CardRetiro from "../../components/Layouts/CardRetiro"
import { useDispatch, useSelector } from "react-redux"
import { listadoUsuario, loadUser } from "../../actions/userAction"
import { getHome } from "../../actions/orderAction"
import Listado from "../../components/Admin/Usuarios/Listado"
import Formulario from "../../components/Admin/Usuarios/Formulario"
import Clave from "../../components/Admin/Usuarios/Clave"
import AssignVehicle from "../../components/Admin/Usuarios/AssignVehicle"
import { listadoCamionOnlyActive } from "../../actions/camionAction"

const Usuario = ({toast}) => {
	
	const dispatch = useDispatch()
	const { allListUsuario, uIsCargando, uDatos, uIsForm, uIsSelectedRows, uIsClave, uIsVehicle } = useSelector((state) => state.users)
	
	const getDataInitial = () => {
		dispatch(listadoUsuario())
		dispatch(listadoCamionOnlyActive())
		dispatch(getHome())
		dispatch(loadUser())
	}


	useEffect(() => {
		getDataInitial()	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!uIsForm ? "Listado de Usuarios" : "Usuario" }>
				{!uIsForm && (
					<Listado dataUser={allListUsuario} isCargando={uIsCargando} toast={toast} isSelectedRows={uIsSelectedRows} />
				)}
				{(uIsClave===true) && <Clave toast={toast} datos={uDatos} />	}
				{(uIsClave===false && uIsForm && uIsVehicle===false) && <Formulario toast={toast} datos={uDatos} />}
				{uIsVehicle && <AssignVehicle toast={toast} datos={uDatos} /> }
			</CardRetiro>
	    </>
	)
}

export default Usuario