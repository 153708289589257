export const validarRut = (rut) => {
    let valor = rut.toString()
    const cantidadCaracteres = valor.split(".").length - 1
    
    if(!rut.includes(".") || !rut.includes("-") || cantidadCaracteres!==2){
        return { message: "Rut debe contener punto y guión", status: false }
    }

    // Despejar Puntos
    while (valor.indexOf(".") !== -1){
        valor = valor.replace(".", "")
    }
    // Despejar Guión
    valor = valor.replace('-','')
    
    // Aislar Cuerpo y Dígito Verificador
    let cuerpo = valor.slice(0,-1)

    let dv = valor.slice(-1).toUpperCase()
        
    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if(cuerpo.length < 7) { 
        return { message: "Rut Incompleto", status: false }
    }
    
    // Calcular Dígito Verificador
    let suma = 0
    let multiplo = 2
    let index = 0
    // Para cada dígito del Cuerpo
    for(let i=1;i<=cuerpo.length;i++) {
    
        // Obtener su Producto con el Múltiplo Correspondiente
        index = multiplo * valor.charAt(cuerpo.length - i)

        // Sumar al Contador General
        suma = suma + index

        // Consolidar Múltiplo dentro del rango [2,7]
        if(multiplo < 7) { 
            multiplo = multiplo + 1 
        } else { 
            multiplo = 2
        }
  
    }
    
    // Calcular Dígito Verificador en base al Módulo 11
    let dvEsperado = 11 - (suma % 11)

    // Casos Especiales (0 y K)
    dv = (dv.toUpperCase() === 'K') ? 10 : dv
    dv = (parseInt(dv) === 0) ? 11 : dv

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if(dvEsperado.toString() !== dv.toString()) { 
        return { message: "Rut Inválido", status: false }
    }
    return { message: "Rut Correcto", status: true }
}