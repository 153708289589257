import React, { useState } from "react"
import {  
	Form,
	Col,
	Button
} from 'react-bootstrap'

import { useDispatch } from "react-redux"
import { searchClienteOrdenes, resetListadoOrdenes } from "../../../actions/orderAction"
import { validarRut } from "../../../utils/rut"

import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { Controller } from "react-hook-form"

const Cliente = ({ register, getValues, setValue, toast, disabledBtnPago, control }) => {

	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)

	const searchClienteRUT = async (event) => {
		try{
			event.preventDefault()
			setLoading(true)

			dispatch(resetListadoOrdenes())
			setValue("razon_social_destinatario", "")
			setValue("cliente_id", "")

			const rut = getValues("rut_destinatario")

			const isValid = validarRut(rut)

			if(isValid.status){
				const data = await dispatch(searchClienteOrdenes(rut))
				
				setLoading(false)
				if(data.status){
					setValue("razon_social_destinatario", data.nombre_destinatario)
					setValue("cliente_id", data.id)

					if(data.cantidad === 0){
						toast.error("No tiene ordenes asignadas por retirar", {position: toast.POSITION.TOP_RIGHT})
						return false
					}
				}else {
					toast.error(data.message, {position: toast.POSITION.TOP_RIGHT})
					return false
				}
			}else{
				toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
				setLoading(false)
				return false
			}	
		}catch(error){
			toast.error("El destinatario no existe en el sistema", {position: toast.POSITION.TOP_RIGHT})
			setLoading(false)
			return false
		}
	}

	const onKeyCliente = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			searchClienteRUT(event)
		}
	}


	return (
		<Col>
			<Form.Row className="pr-5 pl-5 pt-3 pb-4">
				<Col lg={3} md={6}>
			     	<Form.Group as={Col} controlId="rut_destinatario">
				    	<Form.Label>Rut del destinatario</Form.Label>
						<Controller
							render={(props) => (
								<MaskedInput
									mask={RutTextMask}
									className="form-control"
									id="rut_destinatario" 
									name="rut_destinatario"
									value={props.value}
									maxLength={12}
									onChange={(value)=>{
										props.onChange(value)
									}}
									//onBlur={(event) => handleBlurRut(event, setError)}
									onKeyDown={(event) => onKeyCliente(event)}
									onKeyPress={(event) => {
										if (!/[0-9Kk.-]/.test(event.key)) {
										  event.preventDefault();
										}
									}}
								/>
							)}
							control={control}
							name="rut_destinatario"  
							ref={register}
						/>
				    	{/**<Form.Control 
					    	type="text" 
					    	name="rut_destinatario" 
					    	ref={register}
					    	onKeyDown={(event) => onKeyCliente(event)}
					    /> */}
				    </Form.Group>
			    </Col>
			    <Col lg={2} md={3}>
			     	<Form.Group as={Col} className="mt-4">
				    	<Button variant="warning" type="button" size="sm" block onClick={(event) => searchClienteRUT(event)} disabled={loading}>
						    { loading ? "BUSCANDO..." : "BUSCAR"}
						</Button>
				    </Form.Group>
			    </Col>
			    <Col md={7}>
			      	<Form.Group as={Col} controlId="cliente_id">
			      		<Form.Label>Nombre destinatario</Form.Label>
			      		<Form.Control 
					    	type="hidden" 
					    	name="cliente_id" 
					    	ref={register}
					    />
			      		<Form.Control 
					    	type="text" 
					    	name="razon_social_destinatario" 
					    	ref={register}
					    	readOnly
					    />
			    	</Form.Group>
			    </Col>
			</Form.Row>
		</Col>
		
	)
}

export default Cliente