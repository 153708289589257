import { useEffect, useState } from "react"
import {  Form, Card, Row, Col, Button } from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import CardRetiro from "../../components/Layouts/CardRetiro"
import FormRebajar from '../../components/Retiros/Nomina/FormRebajar'
import ReportListByNominate from "../../components/Retiros/PDF/ReportListByNominate"
import { useDispatch, useSelector } from "react-redux"
import { getRetiroByUser, saveUpdateState, getEstadoInitial, filtradoMovil } from "../../actions/retiroAction"
import { useForm } from "react-hook-form"
import { ToastContainer, toast } from 'react-toastify'
import {  Document, PDFDownloadLink } from "@react-pdf/renderer";
import ReactPaginate from 'react-paginate';
import { validateOnlyNumber, onPaste } from "../../utils/validationInput"


const Nomina = () => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors } = useForm()
	const [isLoading, setLoading] = useState(false)
	const { rowsNomina, isLoadingNomina, estadosRebajar } = useSelector((state) => state.retiros)
	const [pageOffset, setPageOffset] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [ filterText, setFilterText ] = useState("")


	const getDataInitial = async (page) => {
		
		const response = await dispatch(getRetiroByUser({type_assign: "paysheet"}, page+1))
		if(response?.status){
			setPageCount(response?.countPaginate ?? 0)
			setPageOffset(page)
		} 

		if(estadosRebajar?.length===0){
			await dispatch(getEstadoInitial())
		}
	}

	useEffect(() => {
		getDataInitial(pageOffset)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageOffset])

	const onSubmit = async (data, e) => {
		setLoading(true)

		let listadoRetiro = data?.ordenes?.filter(ret => ret!==false)

		if(listadoRetiro.length>0){
			let estado_text = document.querySelector("#estado_id");

			const information = {
				camion_id: null,
				observation: data.detalle,
				type_assign: "rebajar",
				id_estado: data.estado_id,
				numero_retiros: listadoRetiro,
				estado: estado_text.options[estado_text.selectedIndex].text
			}

			let response = await dispatch(saveUpdateState(information))
	    	if(response.status){
	    		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
	    		e.target.reset()
	    		getDataInitial(0)
	    	}else{
	    		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
	    	}
	    }else{
	    	toast.error("Debe seleccionar los retiros", {position: toast.POSITION.TOP_RIGHT})
	    }
    	setLoading(false)
	}

	const handlePageChange = async (event) => {

		// TODO Only change displayed selected page
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
	};

	const onFilter = (e)  => {
		setFilterText(e.target.value)

		dispatch(filtradoMovil(e.target.value))
	}



	return (
	    <Layout 
			titlePanel="Nominar"
			modulo="nominar-retiros"
		>
			<div className="pl-3 pr-3">
				<Row>
					<Col xs={12}>
						<Form id="frmAsignar" onSubmit={handleSubmit(onSubmit)}>
							<CardRetiro title="NÓMINA DE RETIROS">
								<FormRebajar register={register} errors={errors} isLoading={isLoading} estadosRebajar={estadosRebajar} />	 		    
							</CardRetiro>
							<Card body className="card-retiro shadow-sm">
							<div className="row fsearch-retiro mt-5">
									<Col lg={4} md={4} sm={6} className="text-right">
										<Form.Group >
											<Form.Control 
													id="search" 
													type="text" 
													placeholder="Ingresa N° del móvil"
													aria-label="Ingresa N° de móvil"
													value={filterText} 
													onChange={onFilter}
													autoComplete="off"
													onKeyPress={(event) => validateOnlyNumber(event)}
										 			onPaste={(event) => onPaste(event)}
												/>
										</Form.Group>
									</Col>
								</div>
								<div className="table-responsive table-retiro-responsive">
									<table className="table table-bordered table-sm bg-white table-retiro">
										<thead className="bg-warn-blue">
											<tr>
												<th className="text-center"></th>
												<th className="text-center">N° Retiro</th>
												<th className="text-center">Estado</th>
												<th className="text-center">Rut Remitente</th>
												<th className="text-center" style={{width: "300px"}}>Remitente</th>
												<th className="text-center">Móvil</th>
												<th className="text-center">Dirección Retiro</th>
												<th className="text-center">Ciudad Ret.</th>
												<th className="text-center">Detalle</th>
											</tr>
										</thead>
										<tbody>
										{ 
											rowsNomina.length>0 &&
											rowsNomina.map((row, key) => 
												<tr key={key}>
													<td>
														<Form.Group id="formGridCheckbox">
															<Form.Check 
																type="checkbox" 
																name={`ordenes[${key}]`} 
																defaultValue={row.number_withdrawal_order}
																defaultChecked={false} 
																ref={register}
															/>
														</Form.Group>
													</td>
													<td className="text-center">{row.number_withdrawal_order}</td>
													<td className="text-center">{row.state}</td>
													<td className="text-center">{row.remitter?.identity_document}</td>
													<td>{row.remitter?.fullname}</td>
													<td>{row.mobile_internal_number}</td>
													<td>{row.remitter?.address}</td>
													<td>{row.remitter?.city}</td>
													<td>{row.observation}</td>
												</tr>
											)
										}
										{(!isLoadingNomina && rowsNomina.length===0) && <tr><td colSpan={9}>No hay registros disponibles</td></tr>}
										{(isLoadingNomina && rowsNomina.length===0) && <tr><td colSpan={9}>Buscando...</td></tr>}
										</tbody>
									</table>
								</div>

								<ReactPaginate
									previousLabel="Anterior"
									nextLabel="Siguiente"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handlePageChange}
									containerClassName="pagination"
									activeClassName="active"
									forcePage={pageOffset}

								/>
								{/**<TableRebajar retirosAsignados={rowsNomina} setSelectRows={setSelectRows} isCargando={isLoadingNomina} clearTable={clearTable} /> */}
								{rowsNomina.length>0 && 
									<Row className="mt-5 mb-5 text-right">
										<Col md sm={12}>
											<PDFDownloadLink
												document={
													<Document >
														<ReportListByNominate listRetiros={rowsNomina} />
													</Document>
												}
												fileName="nomina-retiro.pdf"
											>
												<Button variant="outline-primary" className="mb-2 pl-5 pr-5" type="button"> 
													Descargar PDF
												</Button>
											</PDFDownloadLink>
										</Col>
									</Row>
								}
							</Card>      		
						</Form>
					</Col>		
				</Row>
			</div>
			<ToastContainer />
	    </Layout>
	)
}

export default Nomina