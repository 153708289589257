import { useEffect } from 'react'
import {  
	Jumbotron,
	Button
} from 'react-bootstrap'
import Layout from "../components/Layouts/Layout"
import { Link, useHistory } from 'react-router-dom'

const NotFound = () => {
	
	const history = useHistory()

	useEffect(() => {
		if(JSON.parse(localStorage.getItem('user'))===null){
			history.push("/")
		} 
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return (
	    <Layout 
	    	titlePanel=""
	    >
	    	<Jumbotron className="mt-5">
			  <h1>Página No Encontrada</h1>
			  <p>
			    La página que quiere acceder no se encuentra.
			  </p>
			  <p>
			    
			    <Link to="/home"> 
		  			<Button variant="warning">Ir al Home</Button>
		  		</Link>
			  </p>
			</Jumbotron>
	    	
	    </Layout>
	)
}

export default NotFound