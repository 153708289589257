import { useEffect } from "react"
import {  
	Form,
	Col,
	Row,
	OverlayTrigger, 
	Tooltip
} from 'react-bootstrap'
import { useDispatch } from "react-redux"

import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { Controller } from "react-hook-form"
import { validateOnlyRUT, validateOnlyLetter, onPaste, validateOnlyNumber } from "../../../../utils/validationInput"
const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON	

const ClienteDestinatario = ({ register, errors, setValue, searchcliente, validarrut, toast, 
	sucursales, infoDestino, oficinadestino, cargaroficinas, control, tipo_envio }) => {

	const dispatch = useDispatch()

	const onKeyDownDestinatario = async (event) => {
		if (event.keyCode === 13) {
			event.preventDefault()
			await searchClienteDestinatario()
		}
	}

	const onBlurHandleDes = async (event) => {
		if(event.target.value.length!==0){
			await searchClienteDestinatario()
		}
	}

	const searchClienteDestinatario = async () => {
		let search
		
		const rut = document.getElementById("rut").value
		const isValid = validarrut(rut)
		if(isValid.status){
			search = {search_type : "rut_cliente", value: rut }
		}else{
			toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
			return false
		}	
		
		const data = await dispatch(searchcliente(search))

		if(data.status){

			if(rut===RUT_MESON){
				setValue("razon_social", "")
				document.getElementById("razon_social").readOnly=false
				setValue("direccion", "")
				setValue("telefono", "")
				setValue("email", "")
			}else{
				setValue("razon_social", data.cliente.nombre_completo)
				document.getElementById("razon_social").readOnly=true
				setValue("direccion", data?.cliente?.direccion)
				setValue("telefono", data?.cliente?.telefono)
				setValue("email", data?.cliente?.email)
				setValue("sucursal_id", data?.cliente?.sucursal_id)
				cargaroficinas(undefined, "destino", data?.cliente?.sucursal_id)
				if(Object.keys(infoDestino).length>0){
					setValue("comuna_id", data?.cliente?.comuna_id)
				}
				
			}
	      	setValue("telefono", data.cliente.telefono)
		}else{
			setValue("razon_social", "")
			setValue("telefono", "")
			document.getElementById("razon_social").readOnly=false
			setValue("direccion", "")
			setValue("email", "")
		}
	}

	useEffect(() => {
		cargaroficinas(undefined, "destino", infoDestino.sucursal_id)
		if(Object.keys(infoDestino).length>0){
			setValue("sucursal_id", infoDestino.sucursal_id)
			setValue("comuna_id", infoDestino.comuna)
			setValue("tipo_envio_id", infoDestino.tipo_envio_id ?? 1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales])


	return (
		<Row>
     		<Col lg={6} md={6} sm={12}>
     			<h6 className="mb-4">Datos del destinatario</h6>
     			<Row>
     				<Col lg={6} md={6} sm={12}>
     					<Form.Group controlId="rut">
					    	<Form.Label>Rut</Form.Label>
							<OverlayTrigger overlay={<Tooltip id="tooltip-largo">Presione <strong>enter</strong> para realizar la búsqueda</Tooltip>}>
								<Controller
									render={(props) => (
										<MaskedInput
											mask={RutTextMask}
											className="form-control"
											id="rut" 
											name="rut"
											value={props.value}
											maxLength={12}
											defaultValue={infoDestino?.rut || ""}
											onChange={(value)=>{
												props.onChange(value)
											}}
											//onBlur={(event) => handleBlurRut(event, setError)}
											onBlur={(event) => onBlurHandleDes(event)}
						    				onKeyDown={(event) => onKeyDownDestinatario(event)}
											onKeyPress={(event) => validateOnlyRUT(event)}
										/>
									)}
									control={control}
									name="rut"  
									rules={{required: {value: true, message: 'Requerido'}}}
								/>
							{/**<Form.Control 
						    	type="text" 
						    	name="rut"
								maxLength={14} 
						    	onBlur={(event) => onBlurHandleDes(event)}
						    	onKeyDown={(event) => onKeyDownDestinatario(event)}
						    	defaultValue={infoDestino?.rut || ""}
				                ref={register({required: {value: true, message: 'Requerido'}})}
						    /> */}
							</OverlayTrigger>
						    {errors.rut && (
						    	<Form.Text className="text-danger">
						      		{errors?.rut?.message}
						    	</Form.Text>
						    )}
					    </Form.Group>
     				</Col>
     				<Col lg={6} md={6} sm={12}>
     					<Form.Group controlId="razon_social">
				      		<Form.Label>Destinatario</Form.Label>
				      		<Form.Control 
						    	type="text" 
						    	name="razon_social" 
								maxLength={160} 
						    	defaultValue={infoDestino?.razon_social || ""}
				                ref={register({required: {value: true, message: 'Requerido'}})}
								onKeyPress={(event) => validateOnlyLetter(event)}
								onPaste={(event) => onPaste(event)}
						    />
						    {errors.razon_social && (
						    	<Form.Text className="text-danger">
						      		{errors?.razon_social?.message}
						    	</Form.Text>
						    )}
				    	</Form.Group>
     				</Col>
     			</Row>
     			<Row>
     				<Col lg={6} md={6} sm={12}>
     					<Form.Group controlId="telefono">
				      		<Form.Label>Contacto (Teléfono)</Form.Label>
				      		<Form.Control 
						    	type="text" 
						    	name="telefono" 
								maxLength={9} 
						    	defaultValue={infoDestino?.telefono || ""}
				                ref={register({
									required: {value: true, message: 'Requerido'},
									minLength: {value: 9, message: 'Mínimo 9 dígitos'},
									//eslint-disable-next-line
									pattern: { value: /^([0-9]+)$/, message: 'Teléfono inválido'},
								})}
								autoComplete="off"
								onKeyPress={(event) => validateOnlyNumber(event)}
								onPaste={(event) => onPaste(event)}
						    />
						    {errors.telefono && (
						    	<Form.Text className="text-danger">
						      		{errors?.telefono?.message}
						    	</Form.Text>
						    )}
				    	</Form.Group>
     				</Col>
     				<Col lg={6} md={6} sm={12}>
     					<Form.Group controlId="email">
				      		<Form.Label>E-mail</Form.Label>
				      		<Form.Control 
						    	type="text" 
						    	name="email" 
						    	defaultValue={infoDestino?.email || ""}
				                ref={register({
									//eslint-disable-next-line
									pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
								})} 
								onPaste={(event) => onPaste(event)}
						    />
							{errors.email && (
								<Form.Text className="text-danger">
									{errors?.email?.message}
								</Form.Text>
							)}
				    	</Form.Group>
     				</Col>
     			</Row>
     		</Col>
     		<Col lg={6} md={6} sm={12}>
     			<h6 className="mb-4">Dirección del destinatario</h6>
     			<Row>
     				<Col lg={6} md={6} sm={12}>
     					<Form.Group controlId="sucursal_id" >
					    	<Form.Label>Sucursal destino</Form.Label>
					    	<Form.Control 
							    	as="select"
							    	name="sucursal_id" 
							    	onChange={(event) => cargaroficinas(event, "destino", "")}
					        		ref={register({required: {value: true, message: 'Requerido'}})}
							    >
						    	<option value="">Seleccione</option>
						    	{
						    		sucursales.length > 0 && (
						    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
						    		)
						    	}
						 	</Form.Control>
						 	{errors.sucursal_id && (
						    	<Form.Text className="text-danger">
						      		{errors?.sucursal_id?.message}
						    	</Form.Text>
						    )}
					    </Form.Group>
     				</Col>
     				<Col lg={6} md={6} sm={12}>
		     			<Form.Group controlId="comuna_id" >
						    	<Form.Label>Comuna destino</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="comuna_id" 
						        		ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		oficinadestino.length > 0 && (
							    			oficinadestino.map(comuna => <option key={comuna.id} value={comuna.id}>{comuna.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.comuna_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.comuna_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
		     		</Col>
		     		<Col lg={12} md={12} sm={12}>
				      	<Form.Group controlId="direccion">
				      		<Form.Label>Dirección destino</Form.Label>
				      		<Form.Control 
							    	type="text" 
							    	name="direccion" 
									maxLength={255} 
					                ref={register({required: {value: true, message: 'Requerido'}})}
					                defaultValue={infoDestino?.direccion || ""}
							    />
							{errors.direccion && (
						    	<Form.Text className="text-danger">
						      		{errors?.direccion?.message}
						    	</Form.Text>
						    )}
				    	</Form.Group>
				    </Col>
     			</Row>
     			
     		</Col>
     		<Col xs={3}  className="mb-4">
     			<Form.Group controlId="tipo_envio_id">
				 <Form.Label>Entrega en</Form.Label>
		      		<Form.Control 
							as="select"
							name="tipo_envio_id" 
							ref={register({required: {value: true, message: 'Requerido'}})}
						>
						{
							tipo_envio.length > 0 && (
								tipo_envio.map(entrega => <option key={entrega.id} value={entrega.id}>{entrega.nombre}</option>)
							)
						}
					</Form.Control>
					{errors.tipo_envio_id && (
						<Form.Text className="text-danger">
							{errors?.tipo_envio_id?.message}
						</Form.Text>
					)}
		    	</Form.Group>
     		</Col>
			 <Col xs={9}  className="mb-4">
     			<Form.Group controlId="observacion">
		      		<Form.Label>Observación</Form.Label>
		      		<Form.Control 
				    	type="text" 
				    	name="observacion" 
						maxLength={255} 
				    	defaultValue={infoDestino?.observacion || ""}
		                ref={register}
				    />
		    	</Form.Group>
     		</Col>
     		
     	</Row>		
	)
}

export default ClienteDestinatario