import React, { Fragment, useState } from "react"
import {  
	Form,
	Col,
	Row,
	OverlayTrigger,
	Popover
} from 'react-bootstrap'
import Remitente from "./Remitente"

import { useDispatch } from "react-redux"
import { searchCliente, habilitarNuevoCliente } from "../../../actions/clienteAction"
import { clienteCtaCorriente } from "../../../actions/orderAction"
import { validarRut } from "../../../utils/rut"
import ModalMessage from "./ModalMessage"
import PanelDetalle from "../../Layouts/PanelDetalle"
import ModalSelectNroCuenta from "./ModalSelectNroCuenta";

import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { Controller } from "react-hook-form"
import { onPaste, validateOnlyLetter, validateOnlyNumber, validateOnlyRUT } from '../../../utils/validationInput';

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON

const Principal = ({ register, errors, setValue, ordenInfo, disabledBtnCrear, toast, control, getValues }) => {

	const [ codigo, setCodigo ] = useState(false)
	const [ dataCodigo, setDataCodigo ] = useState("")
	const dispatch = useDispatch()
	const [ direccionC, setDireccionC ] = useState("")
	const [ message, setMessage ] = useState("")
	const [ hidden, setHidden ] = useState(false)
	const [ habCliente, setHabCliente ] = useState(true)
	const [ disabledRemitente, setDisabledRemitente ] = useState(true)
	const [ disabledDestinatario, setDisabledDestinatario ] = useState(true)
	const [ openCloseCta, setOpenCloseCta ] = useState(false)
	const [ clienteSelected, setClienteSelected] = useState(false)
	const [ dataClientSearch, setDataClientSearch ]= useState(null)

	const onKeyDownHandler = async (event) => {

		if (event.keyCode === 13) {
			event.preventDefault()
			if(event.target.value.length>0){
				
	      		//setDataCodigo("")
	      		//setValue("codigo_interno_nro", "")
				
				await searchClientePrincipal("codigo")
			}
		}else{
			setValue("codigo_interno", "si")
	      	setCodigo(true)
			setValue("cliente_rut", "")
			setValue("razon_social", "")
			if(getValues("tipo_cliente")==="remitente"){
				setValue("rut_remitente", "")
				setValue("razon_social_remitente", "")
				setDisabledRemitente(true)
			}else{
				setValue("rut_destinatario", "")
				setValue("razon_social_destinatario", "")
				setValue("direccion_destinatario", "")
				setValue("telefono_avisas", "")
				setDisabledDestinatario(true)
			}			
		}
	}

	const onKeyDownHandlerInput = async (event) => {
	
		if (event.keyCode === 13) {
			event.preventDefault()
			
			if(event.target.value.length>0){
				setValue("codigo_interno", "no")
	      		setCodigo(false)
	      		setDataCodigo("")
	      		setValue("codigo_interno_nro", "")
				setValue("razon_social", "")
				if(getValues("tipo_cliente")==="remitente"){
					setValue("rut_remitente", "")
					setValue("razon_social_remitente", "")
					setDisabledRemitente(true)
				}else{
					setValue("rut_destinatario", "")
					setValue("razon_social_destinatario", "")
					setValue("direccion_destinatario", "")
					setValue("telefono_avisas", "")
					setDisabledDestinatario(true)
				}

				await searchClientePrincipal("input")
			}
		}else {
			handleBlurRut("")
		}
	}

	const onBlur = async (event) => {
		
		if(dataCodigo.length>0){
			await searchClientePrincipal("codigo")
		}else if(event.target.value===""){
			setHidden(false)
			setValue("razon_social", "")
			setValue("cliente_existe", "creado")
			setHabCliente(true)
			//setValue("cliente_rut", "")
			//dispatch(clienteCtaCorriente(false))
			if(getValues("tipo_cliente")==="remitente"){
				setValue("rut_remitente", "")
				setValue("razon_social_remitente", "")
				setDisabledRemitente(false)
			}else{
				setValue("rut_destinatario", "")
				setValue("razon_social_destinatario", "")
				setValue("direccion_destinatario", "")
				setValue("telefono_avisas", "")
			}
			setValue("codigo_interno", "si")
			//setCodigo(false)
			//setValue("codigo_interno_nro", "")
		}
	}

	const handleOpenCloseCta = () => {
		setOpenCloseCta(!openCloseCta)
	}	

	const handleBlurRut = (event) => {
		
		if(event===""){
			setHidden(false)
			setValue("razon_social", "")
			setValue("cliente_existe", "creado")
			setHabCliente(true)
			//setValue("cliente_rut", "")
			//dispatch(clienteCtaCorriente(false))
			if(getValues("tipo_cliente")==="remitente"){
				setValue("rut_remitente", "")
				setValue("razon_social_remitente", "")
				setDisabledRemitente(false)
			}else{
				setValue("rut_destinatario", "")
				setValue("razon_social_destinatario", "")
				setValue("direccion_destinatario", "")
				setValue("telefono_avisas", "")
			}
			setValue("codigo_interno", "no")
			setCodigo(false)
			setValue("codigo_interno_nro", "")
		}		
	}

	const searchClientePrincipal = async (type) => {
		let search
		
		if(type==="codigo"){
			search = { search_type: "codigo_interno", value: dataCodigo }
		}else{
			const rut = document.getElementById("cliente_rut").value.toString().toUpperCase()
			const isValid = validarRut(rut)
			if(isValid.status){
				search = {search_type : "rut_cliente", value: rut }
			}else{
				toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
				return false
			}			
		}

		const data = await dispatch(searchCliente(search))
	
		if(data.status){
			setDataClientSearch(data.cliente)
			if(type==="codigo"){
				const isValid = validarRut(data.cliente.rut.toString().toUpperCase())
				if(!isValid.status){
					toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
					return false
				}
			}

			if(type!=="codigo"){
				if(data?.cliente?.numero_cuentas?.length===0){
					setValue("razon_social", data.cliente.nombre_completo)
					setValue("cliente_rut", data.cliente.rut)
					setDireccionC(data.cliente.direccion)
					if(document.getElementById("tipo_cliente_remitente").checked){
						dataTypeCliente(document.getElementById("tipo_cliente_remitente").value, data.cliente.direccion)
					}else{
						dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value, data.cliente.direccion)
						setValue("telefono_avisas", data.cliente.telefono)
					}
					setValue("cliente_existe", "creado")
					setHabCliente(true)			
	
					setValue("codigo_interno", "no")
					setCodigo(false)
					 setValue("codigo_interno_nro", "")
	
					//dispatch(clienteCtaCorriente(false))
					if(document.querySelector("#forma_pago_id").disabled){
						document.querySelector("#forma_pago_id").value=1
					}
				}else if(data?.cliente?.numero_cuentas?.length===1){
					setValue("razon_social", data.cliente.nombre_completo)
					setValue("cliente_rut", data.cliente.rut)
					setDireccionC(data.cliente.direccion)
					if(document.getElementById("tipo_cliente_remitente").checked){
						dataTypeCliente(document.getElementById("tipo_cliente_remitente").value, data.cliente.direccion)
					}else{
						dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value, data.cliente.direccion)
						setValue("telefono_avisas", data.cliente.telefono)
					}
					setValue("cliente_existe", "creado")
					setHabCliente(true)
						

					setValue("codigo_interno", "si")
					setCodigo(true)
					setValue("codigo_interno_nro", data?.cliente?.numero_cuentas[0]?.numero_cuenta)
					setDataCodigo(data?.cliente?.numero_cuentas[0]?.numero_cuenta)
					//dispatch(clienteCtaCorriente(true))
				}else{
					setClienteSelected(data.cliente)
					setOpenCloseCta(true)
				}
			}else{
				
				if(data?.cliente?.numero_cuentas?.length===1){
					setValue("razon_social", data.cliente.nombre_completo)
					setValue("cliente_rut", data.cliente.rut)
					setDireccionC(data.cliente.direccion)
					if(document.getElementById("tipo_cliente_remitente").checked){
						dataTypeCliente(document.getElementById("tipo_cliente_remitente").value, data.cliente.direccion)
					}else{
						dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value, data.cliente.direccion)
						setValue("telefono_avisas", data.cliente.telefono)
					}
					setValue("cliente_existe", "creado")
					setHabCliente(true)
						

					setValue("codigo_interno", "si")
					setCodigo(true)
					setValue("codigo_interno_nro", data?.cliente?.numero_cuentas[0]?.numero_cuenta)
					setDataCodigo(data?.cliente?.numero_cuentas[0]?.numero_cuenta)
					//dispatch(clienteCtaCorriente(true))
				}else{
					setValue("razon_social", data.cliente.nombre_completo)
					setValue("cliente_rut", data.cliente.rut)
					setDireccionC(data.cliente.direccion)
					if(document.getElementById("tipo_cliente_remitente").checked){
						dataTypeCliente(document.getElementById("tipo_cliente_remitente").value, data.cliente.direccion)
					}else{
						dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value, data.cliente.direccion)
						setValue("telefono_avisas", data.cliente.telefono)
					}
					setValue("cliente_existe", "creado")
					setHabCliente(true)			

					//dispatch(clienteCtaCorriente(false))
					if(document.querySelector("#forma_pago_id").disabled){
						document.querySelector("#forma_pago_id").value=1
					}
				}				
			}
			
			/**if(data.cliente.codigo_interno!==""){
	      		setValue("codigo_interno", "si")
	      		setCodigo(true)
	      		setValue("codigo_interno_nro", data.cliente.codigo_interno)
	      		setDataCodigo(data.cliente.codigo_interno)
	      	}else{
	      		setValue("codigo_interno", "no")
	      		setCodigo(false)
	      		setValue("codigo_interno_nro", "")
	
	      	}

	      	if(data.cliente.codigo_interno!==""){
	      		dispatch(clienteCtaCorriente(true))
	      	}else{
	      		dispatch(clienteCtaCorriente(false))
				if(document.querySelector("#forma_pago_id").disabled){
					document.querySelector("#forma_pago_id").value=1
				}
	      	}**/
		}else{
			if(data.status===false){
				setMessage(data.message)
				setHidden(true)
				setValue("razon_social", "")
				setValue("cliente_existe", "nuevo")
				setHabCliente(false)
				//setValue("cliente_rut", "")
				//dispatch(clienteCtaCorriente(false))
				if(getValues("tipo_cliente")==="remitente"){
					setValue("rut_remitente", "")
					setValue("razon_social_remitente", "")
					setDisabledRemitente(false)
				}else{
					setValue("rut_destinatario", "")
					setValue("razon_social_destinatario", "")
					setValue("direccion_destinatario", "")
					setValue("telefono_avisas", "")
				}
			}else{
				setValue("razon_social", "")
				setValue("cliente_existe", "nuevo")
				toast.error(data.message, {position: toast.POSITION.TOP_RIGHT})
				return false
			}
			
		}
	}

	const selectCodeClient = (codigo) => {
		
		setValue("razon_social", clienteSelected.nombre_completo)
		setValue("cliente_rut", clienteSelected.rut)
		setDireccionC(clienteSelected.direccion)
		if(document.getElementById("tipo_cliente_remitente").checked){
			dataTypeCliente(document.getElementById("tipo_cliente_remitente").value, clienteSelected.direccion)
		}else{
			dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value, clienteSelected.direccion)
			setValue("telefono_avisas", clienteSelected.telefono)
		}
		setValue("cliente_existe", "creado")
		setHabCliente(true)
			

		setValue("codigo_interno", "si")
		setCodigo(true)
		setValue("codigo_interno_nro", codigo)
		setDataCodigo(codigo)
		//dispatch(clienteCtaCorriente(true))

		handleOpenCloseCta();
	}

	const changeTypeCliente = (event) => {
		const type_cliente = event.target.value
		
		if(type_cliente==="destinatario"){
			setValue("rut_remitente", "")
			setValue("razon_social_remitente", "")
		}else{
			setValue("rut_destinatario", "")
			setValue("razon_social_destinatario", "")
			setValue("direccion_destinatario", "")
			setValue("telefono_avisas", "")
		}
		dataTypeCliente(type_cliente, direccionC)
	}

	const dataTypeCliente = (type_cliente, direccion) => {
	
		const name_cliente = document.getElementById("razon_social").value
		const cliente_rut = document.getElementById("cliente_rut").value

		if(type_cliente==="remitente"){
			setValue("rut_remitente", cliente_rut)
			if(cliente_rut===RUT_MESON){
				setValue("razon_social_remitente", "")
				setDisabledRemitente(false)
			}else{
				setValue("razon_social_remitente", name_cliente)
				setDisabledRemitente(true)
			}			
		}else if(type_cliente==="destinatario"){
			setValue("rut_destinatario", cliente_rut)
			if(cliente_rut===RUT_MESON){
				setValue("razon_social_destinatario", "")
				setValue("direccion_destinatario", "")
				setDisabledDestinatario(false)
			}else{
				setValue("razon_social_destinatario", cliente_rut!=="" ? name_cliente : "")
				setValue("direccion_destinatario", cliente_rut!=="" ? direccion : "")
				setValue("telefono_avisas", cliente_rut!=="" ? dataClientSearch?.telefono : "")
				setDisabledDestinatario(true)
			}	
		}
	}

	const changeValueCI = (event) => {
		setDataCodigo("")
		if(event.target.value==="no"){
			setCodigo(false)
			setDataCodigo("")
			setValue("codigo_interno_nro", "")
		}else{
			setCodigo(true)
		}
		setValue("razon_social", "")
		setValue("cliente_rut", "")
      	setDireccionC("")

		if(document.getElementById("tipo_cliente_remitente").checked){
			setValue("rut_remitente", "")
			setValue("razon_social_remitente", "")
		}else{
			setValue("rut_destinatario", "")
			setValue("razon_social_destinatario", "")
			setValue("direccion_destinatario", "")
		}

		dispatch(clienteCtaCorriente(false))
		return false
	}

	const habilitarInputCliente = (event) => {
	
		if(event.target.value==="nuevo"){
			dispatch(habilitarNuevoCliente(false))
		}else{
			dispatch(habilitarNuevoCliente(true))
		}
		setDataCodigo("")	
		setValue("razon_social", "")
		setValue("cliente_rut", "")
		setCodigo(false)
		setValue("codigo_interno", "no")
		setValue("codigo_interno_nro", "")	
			
		if(document.getElementById("tipo_cliente_remitente").checked){
			setValue("rut_remitente", "")
			setValue("razon_social_remitente", "")
			setDisabledRemitente(true)
			
		}else{
			setValue("rut_destinatario", "")
			setValue("razon_social_destinatario", "")
			setValue("direccion_destinatario", "")
		}
	}


	const handleBlurCliente= (event) => {
		
		if(document.getElementById("cliente_rut").value.length>0){
			if(document.getElementById("tipo_cliente_remitente").checked){
	      		dataTypeCliente(document.getElementById("tipo_cliente_remitente").value)
	      	}else{
				dataTypeCliente(document.getElementById("tipo_cliente_destinatario").value)
			}
	    }		
	}

	const popover = (
	  <Popover id="popover-basic">
	    <Popover.Content>
	      Presione <strong>enter</strong> para realizar la búsqueda
	    </Popover.Content>
	  </Popover>
	);


	return (
		<Fragment>
			<PanelDetalle title="CLIENTE" subtitle="" check={false}>
				<Form.Row className="pt-1 mb-0">
			    	<Col xs={5} className="mb-0">
			    		<Form.Group as={Col} className="mb-0">
			    			<p className="fw-600 font-s-10 mb-0">SELECCIONA EL CLIENTE QUE VAS INGRESAR</p>
			    		</Form.Group>
			    	</Col>
			    	<Col className="mb-0">
			     		<Form.Group as={Col} className="mb-0">
					    	<div>
						      <Form.Check 
						      		onChange={(event)=>habilitarInputCliente(event)}
						      		inline label="Cliente Creado" type="radio" value="creado" id="cliente_creado" name="cliente_existe"
						      		ref={register}
						      		defaultChecked
						      	 />
						      <Form.Check 
						      		onChange={(event)=>habilitarInputCliente(event)}
						      		inline label="Cliente Nuevo" type="radio" value="nuevo" id="cliente_nuevo" name="cliente_existe" 
						      		ref={register}
						      	/>
						    </div>
					    </Form.Group>
			     	</Col>
			     	<Col xs={12} >
		    			<Form.Group as={Col} className="mb-1">
		    				<hr className="line-division" />
		    			</Form.Group>
		    		</Col>
		    	</Form.Row>
			    <Form.Row className="pb-1 mb-0">
			     	<Col lg md={4} className="mb-0">
			     		<Form.Group as={Col} className="mb-0">
					    	<Form.Label>Cod. Interno</Form.Label>
					    	<div className="mb-2">
						      <Form.Check 
						      		onChange={(event)=>changeValueCI(event)}
						      		inline label="Si" type="radio" value="si" id="codigo_intern_si" name="codigo_interno"
						      		ref={register}
						      	 />
						      <Form.Check 
						      		onChange={(event)=>changeValueCI(event)}
						      		inline label="No" type="radio" value="no" id="codigo_intern_no" name="codigo_interno" 
						      		ref={register}
						      	/>
						    </div>
						    <OverlayTrigger placement="right" overlay={popover}>
							    <Form.Control 
							    	type="text" 
									autoComplete="off"
							    	defaultValue={ordenInfo.codigo_interno_nro}
							    	name="codigo_interno_nro" 
							    	id="codigo_interno_nro"
					                placeholder="Ingresa código"
					                ref={register}
					                readOnly={!codigo}
					                onChange={(event) => setDataCodigo(event.target.value)}
					                onKeyDown={(event) => onKeyDownHandler(event)}
					                onBlur={(event) => onBlur(event)}
									maxLength={4}
									onPaste={(event) => onPaste(event)}
									onKeyPress={(event) => validateOnlyNumber(event)}
							    />
							</OverlayTrigger>
					    </Form.Group>
			     	</Col>
			     	<Col md={6} xs={12} className="mb-0">
			     		<Form.Group controlId="cliente_rut" className="mb-0">
					    	<Form.Label className="pl-3">RUT</Form.Label>
					    	<Col sm={6} >
					    		<OverlayTrigger placement="right" overlay={popover}>
									<Controller
										autoComplete="off"
										render={(props) => (
											<MaskedInput
												mask={RutTextMask}
												className="form-control"
												id="cliente_rut" 
												name="cliente_rut"
												value={props.value}
												maxLength={12}
												defaultValue={ordenInfo.cliente_rut}
												onChange={(value)=>{
													props.onChange(value)
												}}
												onBlur={(event) => handleBlurRut(event.target.value)}
												onKeyDown={(event) => onKeyDownHandlerInput(event)}
												onKeyPress={(event) => validateOnlyRUT(event)}
											/>
										)}
										control={control}
										name="cliente_rut"  
										rules={{required: {value: true, message: 'Requerido'}}}
									/>
								    {/**<Form.Control 
								    	type="text" 
								    	name="cliente_rut" 
								    	defaultValue={ordenInfo.cliente_rut}
								    	onKeyDown={(event) => onKeyDownHandlerInput(event)}
						                ref={register({required: {value: true, message: 'Requerido'}})}
										maxLength={12}
								    /> */}
								</OverlayTrigger>
							    {errors.cliente_rut && (
							    	<Form.Text className="text-danger">
							      		{errors?.cliente_rut?.message}
							    	</Form.Text>
							    )}
						    </Col>
					    </Form.Group>
				      	<Form.Group as={Col} controlId="razon_social" className="mb-0">
				      		<Form.Label>Razón Social</Form.Label>
				      		<Form.Control 
						    	type="text" 
						    	name="razon_social" 
						    	defaultValue={ordenInfo.razon_social}
								ref={register({required: {value: true, message: 'Requerido'}})}
						    	readOnly={habCliente}
						    	onBlur={(event)=>handleBlurCliente(event)}
								onKeyPress={(event) => validateOnlyLetter(event)}
								maxLength={150}
								onPaste={(event) => onPaste(event)}
						    />
							{errors.razon_social && (
								<Form.Text className="text-danger">
									{errors?.razon_social?.message}
								</Form.Text>
							)}
				    	</Form.Group>
				    </Col>
				    <Col>
				     	<Form.Group as={Col} controlId="tipo_cliente_remitente">
				     		<Form.Label>Tipo de cliente</Form.Label>
					    	<div >
						      <Form.Check 
						        type="radio"
						        id="tipo_cliente_remitente"
						        label="Remitente"
						        name="tipo_cliente"
						        value="remitente"
						        ref={register}
						        onChange={(event)=>changeTypeCliente(event)}
						        className="pb-2 pt-3"
						      />

						      <Form.Check
						        type="radio"
						        id="tipo_cliente_destinatario"
						        label="Destinatario"
						        name="tipo_cliente"
						        ref={register}
						        value="destinatario"
						        onChange={(event)=>changeTypeCliente(event)}
						      />
						    </div>
					    </Form.Group>
				    </Col>
				</Form.Row>
			</PanelDetalle>
			<Row className="mb-1 mt-1">
				<Remitente 
					register={register} 
					errors={errors}  
					setValue={setValue} 
					ordenInfo={ordenInfo} 
					disabledBtnCrear={disabledBtnCrear} 
					validarRut={validarRut} 
					toast={toast}  
					setMessage={setMessage}
					disabledRemitente={disabledRemitente}
					setDisabledRemitente={setDisabledRemitente}
					setHidden={setHidden}
					disabledDestinatario={disabledDestinatario}
					setDisabledDestinatario={setDisabledDestinatario}
					popover={popover}
					control={control}
				/>
			</Row>
			<ModalMessage message={message} setHidden={setHidden} hidden={hidden}  />
			{openCloseCta && 
					<ModalSelectNroCuenta 
						handleOpenCloseCta={handleOpenCloseCta} 
						openCloseCta={openCloseCta}  
						cliente={clienteSelected}
						selectCodeClient={selectCodeClient}
					/>}			
		</Fragment>

	)
}

export default Principal

