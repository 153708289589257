import { Form, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { usePermission } from "../../../hooks/Permission/usePermission";
import { useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  validateOnlyRUT,
  validateOnlyLetter,
  onPaste,
  validateOnlyNumber,
} from "../../../utils/validationInput";
import ModalSelectNroCuenta from "../../Orders/Clientes/ModalSelectNroCuenta";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

const ClienteRemitente = ({
  register,
  errors,
  setValue,
  searchCliente,
  validarRut,
  toast,
  sucursales,
  dataRetiro,
  oficinaOrigen,
  cargarOficinas,
  forma_pago,
  comuna_id,
  control,
  getValues,
}) => {
  const dispatch = useDispatch();
  const { validarPermiso } = usePermission();
  const [openCloseCta, setOpenCloseCta] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(false);
  const [isValidate, setIsValidate] = useState(true);

  const onKeyDownRemitente = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente();
    }
  };

  const searchClienteRemitente = async () => {
    let search;
    const rut = document.getElementById("rut_remitente").value;
    const isValid = validarRut(rut);

    if (isValid.status) {
      search = { search_type: "rut_cliente", value: rut };
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    const data = await dispatch(searchCliente(search));

    if (data.status) {
      setIsValidate(false);
      if (rut === RUT_MESON) {
        setValue("razon_social_remitente", "");
        document.getElementById("razon_social_remitente").readOnly = false;
        setValue("codigo_interno", "");
        setValue("direccion_retiro", data?.cliente.direccion);
      } else if (data?.cliente?.numero_cuentas?.length === 1) {
        setValue("rut_remitente", data?.cliente.rut);
        setValue("razon_social_remitente", data?.cliente.nombre_completo);
        document.getElementById("razon_social_remitente").readOnly = true;
        setValue("telefono_remitente", data?.cliente?.telefono);
        setValue("email_remitente", data?.cliente?.email);
        setValue(
          "codigo_interno",
          data?.cliente?.numero_cuentas[0]?.numero_cuenta
        );
        setValue("direccion_retiro", data?.cliente.direccion);
        setIsValidate(true);
      } else if (data?.cliente?.numero_cuentas?.length > 0) {
        setClienteSelected(data.cliente);
        setOpenCloseCta(true);
        setIsValidate(true);
        //setValue("razon_social_remitente", data.cliente.nombre_completo)
        //document.getElementById("razon_social_remitente").readOnly=true
      } else {
        setValue("razon_social_remitente", data.cliente.nombre_completo);
        document.getElementById("razon_social_remitente").readOnly = true;
        setValue("codigo_interno", "");
        setValue("telefono_remitente", data?.cliente?.telefono);
        setValue("email_remitente", data?.cliente?.email);
        setValue("direccion_retiro", data?.cliente.direccion);
      }
    } else {
      setIsValidate(false);
      setValue("razon_social_remitente", "");
      setValue("telefono_remitente", "");
      document.getElementById("razon_social_remitente").readOnly = false;
      setValue("codigo_interno", "");
      setValue("email_remitente", "");
      setValue("direccion_retiro", "");
    }
  };

  useEffect(() => {
    setValue("comuna_origen_id", comuna_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oficinaOrigen?.length]);

  const handleOpenCloseCta = () => {
    setOpenCloseCta(!openCloseCta);
    setValue("razon_social_remitente", "");
    document.getElementById("razon_social_remitente").readOnly = true;
    setValue("telefono_remitente", "");
    setValue("email_remitente", "");
    setValue("codigo_interno", "");
  };

  const selectCodeClient = (codigo) => {
    setValue("rut_remitente", clienteSelected.rut);
    setValue("razon_social_remitente", clienteSelected.nombre_completo);
    document.getElementById("razon_social_remitente").readOnly = true;
    setValue("telefono_remitente", clienteSelected?.telefono);
    setValue("email_remitente", clienteSelected?.email);
    setValue("direccion_retiro", clienteSelected.direccion);
    //setHabCliente(true)

    //setValue("codigo_interno", "si")
    //setCodigo(true)
    setValue("codigo_interno", codigo);
    setOpenCloseCta(!openCloseCta);
  };

  const cambiarFormaPago = (event) => {
    if (parseInt(event.target.value) === 3) {
      if (getValues("codigo_interno") === "") {
        toast.error("El cliente no es cuenta correntista", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setValue("forma_pago_id", "");
        return false;
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <h6 className="mb-4">Datos del remitente</h6>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="rut_remitente">
                <Form.Label>Rut</Form.Label>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-largo">
                      Presione <strong>enter</strong> para realizar la búsqueda
                    </Tooltip>
                  }
                >
                  <Controller
                    render={(props) => (
                      <MaskedInput
                        mask={RutTextMask}
                        className="form-control"
                        id="rut_remitente"
                        name="rut_remitente"
                        value={props.value}
                        maxLength={12}
                        defaultValue={dataRetiro?.rut_remitente || ""}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        //onBlur={(event) => handleBlurRut(event, setError)}
                        onKeyDown={(event) => onKeyDownRemitente(event)}
                        //onBlur={(event) => onBlurHandle(event)}
                        onKeyPress={(event) => validateOnlyRUT(event)}
                      />
                    )}
                    control={control}
                    name="rut_remitente"
                    rules={{ required: { value: true, message: "Requerido" } }}
                  />
                  {/**<Form.Control 
									type="text" 
									name="rut_remitente"
									maxLength={14}
									onKeyDown={(event) => onKeyDownRemitente(event)}
									defaultValue={dataRetiro?.rut_remitente || ""}
									ref={register({required: {value: true, message: 'Requerido'}})}
									onBlur={(event) => onBlurHandle(event)}
								/> */}
                </OverlayTrigger>
                {errors.rut_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.rut_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="codigo_interno">
                <Form.Label>Cod. interno</Form.Label>
                <Form.Control
                  type="text"
                  name="codigo_interno"
                  readOnly={true}
                  //maxLength={160}
                  //defaultValue={dataRetiro?.razon_social_remitente || ""}
                  ref={register({
                    required: { value: isValidate, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.codigo_interno && (
                  <Form.Text className="text-danger">
                    {errors?.codigo_interno?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="razon_social_remitente">
                <Form.Label>Remitente</Form.Label>
                <Form.Control
                  type="text"
                  name="razon_social_remitente"
                  maxLength={160}
                  defaultValue={dataRetiro?.razon_social_remitente || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.razon_social_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.razon_social_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="telefono_remitente">
                <Form.Label>Contacto (Teléfono)</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono_remitente"
                  maxLength={9}
                  defaultValue={dataRetiro?.telefono_remitente || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 9, message: "Mínimo 9 dígitos" },
                  })}
                  autoComplete="off"
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.telefono_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.telefono_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email_remitente">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email_remitente"
                  maxLength={150}
                  className="mb-0"
                  defaultValue={dataRetiro?.email_remitente || ""}
                  ref={register({
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.email_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.email_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <h6 className="mb-4">Dirección del remitente</h6>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="sucursal_origen_id">
                <Form.Label>Sucursal retiro</Form.Label>
                <Form.Control
                  as="select"
                  name="sucursal_origen_id"
                  onChange={(event) => cargarOficinas(event, "origen", "")}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  disabled={!validarPermiso("cambiar_sucursal")}
                >
                  <option value="">Seleccione</option>
                  {sucursales.length > 0 &&
                    sucursales.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.nombre}
                      </option>
                    ))}
                </Form.Control>
                {errors.sucursal_origen_id && (
                  <Form.Text className="text-danger">
                    {errors?.sucursal_origen_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="comuna_origen_id">
                <Form.Label>Comuna retiro</Form.Label>
                <Form.Control
                  as="select"
                  name="comuna_origen_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  disabled={!validarPermiso("cambiar_sucursal")}
                >
                  <option value="">Seleccione</option>
                  {oficinaOrigen.length > 0 &&
                    oficinaOrigen.map((comuna) => (
                      <option key={comuna.id} value={comuna.id}>
                        {comuna.nombre}
                      </option>
                    ))}
                </Form.Control>
                {errors.comuna_origen_id && (
                  <Form.Text className="text-danger">
                    {errors?.comuna_origen_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="direccion_retiro">
                <Form.Label>Dirección retiro</Form.Label>
                <Form.Control
                  type="text"
                  name="direccion_retiro"
                  maxLength={255}
                  defaultValue={dataRetiro?.direccion_retiro || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                />
                {errors.direccion_retiro && (
                  <Form.Text className="text-danger">
                    {errors?.direccion_retiro?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="forma_pago_id">
                <Form.Label>Forma de pago</Form.Label>
                <Form.Control
                  as="select"
                  name="forma_pago_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onChange={(event) => cambiarFormaPago(event)}
                >
                  <option value="">Seleccione</option>
                  {forma_pago.length > 0 &&
                    forma_pago
                      ?.filter((item) => item.descripcion !== "Contado")
                      .map((pago) => (
                        <option key={pago.id} value={pago.id}>
                          {pago.descripcion}
                        </option>
                      ))}
                </Form.Control>
                {errors.forma_pago_id && (
                  <Form.Text className="text-danger">
                    {errors?.forma_pago_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      {openCloseCta && (
        <ModalSelectNroCuenta
          handleOpenCloseCta={handleOpenCloseCta}
          openCloseCta={openCloseCta}
          cliente={clienteSelected}
          selectCodeClient={selectCodeClient}
        />
      )}
    </Fragment>
  );
};

export default ClienteRemitente;
