export const formatDataRetiro = (remitente, listDestino) => {

	let dataDestino = []
	
	for(let index in listDestino){
		let infoRetiro = {
			comuna_origen_id: remitente?.comuna_origen_id,
			comuna_destino_id: listDestino[index]?.comuna,
			remitente: {
				rut: remitente?.rut_remitente,
				razon_social: remitente?.razon_social_remitente,
				direccion: remitente?.direccion_retiro,
				telefono: remitente?.telefono_remitente,
				email: remitente?.email_remitente,
				internal_code_number: remitente.codigo_interno
			},
			destinatario: {
				rut: listDestino[index]?.rut,
				razon_social: listDestino[index]?.razon_social,
				direccion: listDestino[index]?.direccion,
				telefono: listDestino[index]?.telefono,
				email: listDestino[index]?.email,
				internal_code_number: ""
			},
			cargas: listDestino[index]?.cargas,
			observacion: listDestino[index]?.observacion,
			tipo_envio_id: listDestino[index]?.tipo_envio_id ?? 1,
			forma_pago_id: remitente.forma_pago_id
		}
		
		dataDestino[index] = formatDataCreated(infoRetiro)
	}
	
	let cliente = {
		rut: remitente?.rut_remitente,
		razon_social: remitente?.razon_social_remitente,
		direccion: remitente?.direccion_retiro,
		telefono: remitente?.telefono_remitente,
		email: typeof remitente?.email_remitente !=="undefined" ? remitente?.email_remitente : "",
		comuna_id: remitente?.comuna_origen_id,
		sucursal_id: remitente?.sucursal_origen_id,
		codigo_interno: remitente.codigo_interno ?? ""
	}
	
	return { dataDestino, cliente }
}

export const formatDataCreated = (data) => {
	
	let comunas = [
		{
			type: "origen",
			oficina_id: data?.comuna_origen_id,
		},
		{
			type: "destino",
			oficina_id: data?.comuna_destino_id
		}
	]

	let clientes = [
		{
            type: "cliente_principal",
            rut: data?.remitente?.rut,
            isPrincipal: true,
            razon_social: data?.remitente?.razon_social,
            codigo_interno: data?.remitente?.internal_code_number,
			
        },
		{
            type: "remitente",
            rut: data?.remitente?.rut,
            isPrincipal: false,
            direccion: data?.remitente?.direccion,
            telefono: data?.remitente?.telefono,
            razon_social: data?.remitente?.razon_social,
            codigo_interno: data?.remitente?.internal_code_number,
        },
        {
            type: "destinatario",
            rut: data?.destinatario?.rut,
            isPrincipal: false,
            direccion: data?.destinatario?.direccion,
            telefono: data?.destinatario?.telefono,
            razon_social: data?.destinatario?.razon_social,
            codigo_interno: data?.destinatario?.internal_code_number,
			email: data?.destinatario?.email,
        },
	]

	let cargas = data?.cargas.map(item => {
		let total = 0

		if(typeof item?.alto !=="undefined" && item?.alto!==""){
			total = item?.alto
		}

		if(typeof item?.ancho !=="undefined" && item?.ancho!==""){
			total = total * item?.ancho
		}
	
		if(typeof item?.largo !=="undefined" && item?.largo!==""){
			total = total * item?.largo
		}
		
		return {
			alto: typeof item?.alto ==="undefined" ? "" : item?.alto,
			ancho: typeof item?.ancho ==="undefined" ? "" : item?.ancho,
			largo: typeof item?.largo ==="undefined" ? "" : item?.largo,
			bulto: typeof item?.cantidad ==="undefined" ? "" : item?.cantidad,
			peso: typeof item?.peso ==="undefined" ? 0 : item?.peso,
			metraje_cubico: total,
			valor_neto: typeof item?.valor_neto ==="undefined" ? 0 : item?.valor_neto
		}
	})
	let info = {
		observacion: typeof data?.observacion === "undefined" ? "" : data?.observacion,
		comunas,
		clientes,
		cargas,
		tipo_envio_id: typeof data?.tipo_envio_id === "undefined" ? 1 : data?.tipo_envio_id,
		forma_pago_id: typeof data?.forma_pago_id === "undefined" ? 1 : parseInt(data?.forma_pago_id)
	}

	return info
}

export const formatDataPreOrdenEdit = (data, numero_retiro) => {
	let infoPreOrden = {
		numero_retiro,
		cliente: {
	        rut: data?.rut_destinatario,
	        razon_social: data?.razon_social_destinatario,
	        direccion: data?.direccion_destinatario,
	        telefono: data?.telefono_destinatario,
	        email: data?.email_destinatario,
	        codigo_interno: "", 
			type: "destinatario"
	    },
	    comuna_id: data?.comuna_destino_id,
	    observacion: data?.observacion,
	    cargas: data.cargas.map(item => {
			return {
				id: item.id,
				alto: item.alto, 
				ancho: item.ancho, 
				largo: item.largo, 
				bulto: item.cantidad, 
				peso: item.peso,
				total: item?.valor_neto,
				metraje_cubico: item?.metraje_cubico
			}
		}),
		documentacion_id: data?.documentacion_id,
		forma_pago_id: data?.forma_pago_id,
	    numero_documento_carga: data?.numero_documento_carga,
	    valor_declarado_carga: data?.valor_declarado_carga,
	    subtotal_orden: data?.subtotal_orden,
	    descuento: data?.descuento,
	    valor_neto: data?.valor_neto,
	    valor_iva: data?.valor_iva,
	    monto_descuento: data?.valor_descuento==="" ? 0 : data?.valor_descuento,
	    total: data?.total,
		tipo_envio_id: data?.tipo_envio_id
	}

	return infoPreOrden
}