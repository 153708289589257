import { useState } from "react"
import {  
	Form,
	Col,
	Card,
	Row,
	OverlayTrigger,
	Popover
} from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';

import { useDispatch } from "react-redux"
import { searchCliente } from '../../actions/clienteAction'
import { validarRut } from '../../utils/rut'
import { getDateYMD } from '../../utils/formatFecha';
import { onPaste, validateOnlyLetter } from '../../utils/validationInput';
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'

registerLocale('es', es)
const Encabezado = ({ register, errors, control, forma_pagos, toast, setValue }) => {

	const dispatch = useDispatch()
	const [ bloquear, setBloquear ] = useState(true)
	const [ fechaBloquear, setFechaBloquear ] = useState(true)
	const [ dateStart, setDateStart ] = useState(new Date())
	const [ dateVenc, setDateVenc ] = useState(null)

	const onKeyDownCliente = async (event) => {

		if (event.keyCode === 13) {
			event.preventDefault()
			let search
			const rut = document.getElementById("rut").value
			const isValid = validarRut(rut)
			if(isValid.status){
				search = {search_type : "rut_cliente", value: rut }
			}else{
				toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
				setValue("razon_social", "")
				setValue("direccion", "")
				return false
			}	
			
			const data = await dispatch(searchCliente(search))

			if(data.status){
				setValue("razon_social", data.cliente.nombre_completo)
		      	setValue("direccion", data.cliente.direccion)
				if(data.cliente.nombre_completo!=="" && data.cliente.direccion!==""){
					setBloquear(true)
				}else{
					setBloquear(false)
				}	
			}else{
				setValue("razon_social", "")
				setValue("direccion", "")
				setBloquear(false)
			}
		}
	}

	const changeFechaVenc = (event) => {
		setFechaBloquear(!fechaBloquear)
		setValue('fecha_vencimiento', '')
		setDateVenc(null)
	}

	const popover = (
	  <Popover id="popover-basic">
	    <Popover.Content>
	      Presione <strong>enter</strong> para realizar la búsqueda
	    </Popover.Content>
	  </Popover>
	);

	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(document.querySelector("#fecha_documento").value)

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setDateStart(new Date())
			return false
		}
	}


	const validateEndDateBlur = (props) => {
		const dateStart = getDateYMD(document.querySelector("#fecha_vencimiento").value)

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setDateVenc(new Date())
			return false
		}
	}


	return (
		<Card border="Light">
	    <Card.Header as="h6" className="bg-warn-blue text-dark">ENCABEZADO</Card.Header>
	    <Card.Body className="bg-soft-blue height-encabezado">
	     	<Row>
	     		<Col lg={6} md={12} sm={12}>
			      	<Form.Group controlId="rut">
				    	<Form.Label>Rut</Form.Label>
				    	<OverlayTrigger placement="right" overlay={popover}>
							<Controller
								render={(props) => (
									<MaskedInput
										mask={RutTextMask}
										className="form-control"
										id="rut" 
										name="rut"
										value={props.value}
										maxLength={12}
										//defaultValue={ordenInfo.cliente_rut}
										onChange={(value)=>{
											props.onChange(value)
										}}
										//onBlur={(event) => handleBlurRut(event, setError)}
										onKeyDown={(event) => onKeyDownCliente(event)}
										onKeyPress={(event) => {
											if (!/[0-9Kk.-]/.test(event.key)) {
											  event.preventDefault();
											}
										}}
									/>
								)}
								control={control}
								name="rut"  
								rules={{required: {value: true, message: 'Requerido'}}}
							/>
						    {/**<Form.Control 
						    	type="text" 
						    	name="rut"
						    	onKeyDown={(event) => onKeyDownCliente(event)}
				                ref={register({required: {value: true, message: 'Requerido'}})}

						    /> */}
						</OverlayTrigger>
				    	
					    {errors.rut && (
					    	<Form.Text className="text-danger">
					      		{errors?.rut?.message}
					    	</Form.Text>
					    )}
				    </Form.Group>
			    </Col>
			    <Col lg={6} md={12} sm={12}>
			     	<Form.Group controlId="razon_social">
			      		<Form.Label>Razón Social</Form.Label>
			      		<Form.Control 
					    	type="text" 
					    	name="razon_social" 
					    	readOnly={bloquear}
			                ref={register({required: {value: true, message: 'Requerido'}})}
							onKeyPress={(event) => validateOnlyLetter(event)}
							maxLength={150}
							onPaste={(event) => onPaste(event)}
					    />
					    {errors.razon_social && (
					    	<Form.Text className="text-danger">
					      		{errors?.razon_social?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    </Col>
	     		<Col lg={12} md={12} sm={12}>
			      	<Form.Group controlId="direccion">
			      		<Form.Label>Dirección</Form.Label>
			      		<Form.Control 
						    	type="text" 
						    	name="direccion"
						    	readOnly={bloquear} 
				                ref={register}
						    />
			    	</Form.Group>
			    </Col>
			    <Col lg={6} md={12} sm={12}>
			      	<Form.Group controlId="fecha_documento">
				    	<Form.Label>Fecha documento</Form.Label>
				    	<Controller
				            control={control}
				            name="fecha_documento"
				            render={(props) => (
				              <DatePicker
				                dateFormat="dd/MM/yyyy"
				                onChange={(e) => {
									setDateStart(e)
									props.onChange(e)
								}}
				                selected={dateStart}
				                className="form-control"
				                id="fecha_documento"
				                maxDate={new Date()}
				                autoComplete="off"
								locale="es"
								onCalendarClose={() => validateStartDateBlur()}
				              />
				            )}
				          />
					    {errors.fecha_documento && (
					    	<Form.Text className="text-danger">
					      		{errors?.fecha_documento?.message}
					    	</Form.Text>
					    )}
				    </Form.Group>
			    </Col>
			    <Col lg={6} md={12} sm={12}>
			     	<Form.Group controlId="fecha_vencimiento">
			      		<Form.Label>Fecha vencimiento</Form.Label>
			      		<Controller
				            control={control}
				            name="fecha_vencimiento"
				            render={(props) => (
				              <DatePicker
				                dateFormat="dd/MM/yyyy"
				                onChange={(e) => {
									setDateVenc(e)
									props.onChange(e)
								}}
				                selected={dateVenc}
				                className="form-control"
				                id="fecha_vencimiento"
				                autoComplete="off"
				                readOnly={fechaBloquear}
								locale="es"
								minDate={new Date()}
								onCalendarClose={() => validateEndDateBlur()}
				              />
				            )}
				          />
				         
					    {errors.fecha_vencimiento && (
					    	<Form.Text className="text-danger">
					      		{errors?.fecha_vencimiento?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    	<Form.Group as={Col} controlId="sin_fecha" className="pt-0 text-md-right">
			     		 <Form.Check 
			     			type="checkbox" 
					    	name="sin_fecha" 
					    	onChange = { (event) => changeFechaVenc(event)}
					    	defaultValue="si"
	             			ref={register}
					      	label="Sin fecha de vencimiento"
					      	defaultChecked
					    />
				    </Form.Group>
			    </Col>
	     		<Col lg={12} md={12} sm={12}>
			      	<Form.Group controlId="forma_pago_id">
			      		<Form.Label>Forma de pago</Form.Label>
			      		<Form.Control 
							   as="select"
						    	name="forma_pago_id" 
				                ref={register({required: {value: true, message: 'Requerido'}})}
						    >
					    	<option value="">Seleccione</option>
					    	{
					    		forma_pagos.length > 0 && (
					    			forma_pagos.map(forma => <option key={forma.id} value={forma.id}>{forma.descripcion}</option>)
					    		)
					    	}
					 	</Form.Control>
						{errors.forma_pago_id && (
					    	<Form.Text className="text-danger">
					      		{errors?.forma_pago_id?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    </Col>

			</Row>
	    </Card.Body>
	  </Card>
		
	)
}

export default Encabezado