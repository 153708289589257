import API from "../config/api"
import {
	LISTAR_DATA_PAGO,
	DISABLED_METODO_PAGO
} from "../types/metodoPagoType"

export const requestSuccess = (type, payload) => {

    return { type, payload }
}

export const getListado = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("pagos")

		    if(response.status){
		       dispatch(requestSuccess(LISTAR_DATA_PAGO, response))
		    }
	      resolve(response)
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const updateDisabledBtn = (estado) => (dispatch, getState) => {
    dispatch(requestSuccess(DISABLED_METODO_PAGO, estado))
}