import { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import { isAfter, getDateYMD, isBefore, isSame } from '../../../utils/formatFecha';
import { toast } from 'react-toastify'
import dayjs from 'dayjs';
import { InputGroup, FormControl, Button } from "react-bootstrap"
import { BiCalendar } from "react-icons/bi";

registerLocale('es', es)

const FormSearch = ({ register, handleSubmit, onSubmit, startDate,
		endDate, loading, enabledDate, setStartDate, setEndDate,
		changeRangeDate, onChangeViewColumn }) => {
	
	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(dayjs(startDate).format('DD/MM/YYYY'))

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			return false
		}

		if (!isSame(dateStart.dateSelected, endDate) && isAfter(dateStart.dateSelected, endDate)) {
			toast.error('La fecha inicial no puede ser superior a la fecha final', {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			setEndDate(new Date())
			return false
		}
	}

	const validateEndDateBlur = () => {
		const dataEnd = getDateYMD(dayjs(endDate).format('DD/MM/YYYY'))

		if(dataEnd.error){
			toast.error(dataEnd.message, {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}

		if (!isSame(dataEnd.dateSelected, dayjs(startDate).format('YYYY-MM-DD')) && isBefore(dataEnd.dateSelected, startDate)) {
			toast.error('La fecha final no puede ser inferior a la fecha inicial', {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}
	}

	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				className="fecha_orden_from"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
		<InputGroup>
			<FormControl
				className="fecha_orden_to"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="mb-4">
			<div className="row mt-4">
				<div className="col-lg-4 col-md-4 col-sm-6 col-4">
			    	<div className="pl-4 mb-0 pb-0 form-check">
					    <input 
					    	type="checkbox" 
					    	className="form-check-input" 
					    	name="dateRange" 
					    	onChange={(event)=> changeRangeDate(event)}
					    	defaultChecked={true}
					    />
					    <label htmlFor="dateRange" className="form-check-label">Intervalo de Fecha</label>
					</div>
			     	<section className="d-flex">
			     		<div className="pr-2 w-50 form-group">
					    	<label htmlFor="from_date">Desde</label>
							<DatePicker 
								type="text" 
								dateFormat="dd/MM/yyyy"
								selected={startDate} 
								onChange={date => setStartDate(date)} 
								className="form-control"
								name="from_date" 
								id="from_date"
								ref={register}
								disabled={enabledDate}
								locale="es"
								onCalendarClose={() => validateStartDateBlur()}
								maxDate={new Date()}
								customInput={<ExampleCustomInputFrom />}
							/>
					    </div>
					    <div className="pl-2 w-50 form-group">
					    	<label htmlFor="to_date">Hasta</label>
							<DatePicker 
								type="text" 
								dateFormat="dd/MM/yyyy"
								selected={endDate} 
								onChange={date => setEndDate(date)} 
								className="form-control"
								name="to_date" 
								id="to_date"
								ref={register}
								disabled={enabledDate}
								locale="es"
								onCalendarClose={() => validateEndDateBlur()}
								maxDate={new Date()}
								customInput={<ExampleCustomInputTo/>}
							/>
						</div>
			     	</section>
			    </div>
			    <div className="col-3">
					<div className="form-group mt-4">
					    <label htmlFor="estatus_id">Estado</label>
					    <select 
					    	className="form-control" 
					    	id="estatus_id"
					    	name="estatus_id"
					    	ref={register}
					    	onChange={(event)=>onChangeViewColumn(event)}
					    >
					      <option value="24">Factura Pendiente</option>
					      <option value="25">Factura Pagada</option>
					      <option value="26">Factura Abono</option>
					    </select>
					</div>
				</div>
			    <div className="col-3">
			    	<div className="mt-4 pl-0 form-group">
			    		<button 
			    			type="submit" 
			    			className="pl-5 pr-5 btn btn-warning mtop-1"
			    		>{loading ? 'Buscando...' : 'Buscar' }</button>
			    	</div>
			    </div>
			</div>
		</form>
	)
}

export default FormSearch