import {
	CONFIG_INICIAL
} from "../types/configType"

export const getPermissionsUser = (type, payload) => {

	const permissions = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).permissions : []

    return { type: CONFIG_INICIAL, payload: permissions }
}

