import React, { Fragment, useEffect, useState } from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import TablaCarga from "./TablaCarga";
import { useSelector } from "react-redux";
import PanelDetalle from "../../Layouts/PanelDetalle";
import { NumericFormat } from "react-number-format";
import { onPaste, validateOnlyNumber } from "../../../utils/validationInput";
import { Controller } from "react-hook-form";

const DetalleCarga = ({
  register,
  errors,
  getValues,
  ordenInfo,
  toast,
  validateForm,
  handleblockPaidInput,
  statusValidate,
  handleTipoDoc,
  addOrden,
  visibleCarga,
  setValue,
  control,
}) => {
  const { documentacionsCarga, disabledBtnCrear } = useSelector(
    (state) => state.orders
  );
  const [totalPagar, setTotalPagar] = useState("");

  const allowOnlyNumberBlur = (e) => {
    setValue("numero_documento_carga", e.target.value.trim());
  };

  useEffect(() => {
    setTotalPagar(ordenInfo?.valor_declarado_carga ?? "");
  }, [ordenInfo?.valor_declarado_carga]);

  return (
    <Fragment>
      <PanelDetalle
        title="DETALLE DE LA CARGA"
        subtitle=""
        check={false}
        classRow="mt-5"
      >
        {addOrden && (
          <Form.Row className="pt-1 mb-0">
            <Col xs={5} className="mb-0">
              <Form.Group as={Col} className="mb-0">
                <p className="fw-600 font-s-10 mb-0">
                  ¿DESEA GENERAR LA ORDEN DE FLETE?
                </p>
              </Form.Group>
            </Col>
            <Col className="mb-0">
              <Form.Group as={Col} className="mb-0">
                <div>
                  <Form.Check
                    onChange={(event) => handleblockPaidInput(event)}
                    inline
                    label="Con Precio"
                    type="radio"
                    value="with_price"
                    id="with_price"
                    name="type_price"
                    ref={register}
                    defaultChecked
                  />
                  <Form.Check
                    onChange={(event) => handleblockPaidInput(event)}
                    inline
                    label="Sin Precio"
                    type="radio"
                    value="no_price"
                    id="no_price"
                    name="type_price"
                    ref={register}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group as={Col} className="mb-1">
                <hr className="line-division" />
              </Form.Group>
            </Col>
          </Form.Row>
        )}

        <Form.Row className="pt-2 mb-0">
          <Col md sm={4} className="mb-0">
            <Form.Group as={Col} controlId="documentacion_id" className="mb-1">
              <Form.Label>Tipo de Documento</Form.Label>
              <Form.Control
                as="select"
                name="documentacion_id"
                className="change_documentacion_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onChange={(event) => handleTipoDoc(event)}
              >
                <option value="">Seleccione</option>
                {documentacionsCarga.length > 0 &&
                  documentacionsCarga.map((documents) => (
                    <option key={documents.id} value={documents.id}>
                      {documents.descripcion}
                    </option>
                  ))}
              </Form.Control>
              {errors.documentacion_id && (
                <Form.Text className="text-danger">
                  {errors?.documentacion_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md sm={4} className="mb-0">
            <Form.Group
              as={Col}
              controlId="numero_documento_carga"
              className="mb-1"
            >
              <Form.Label>Nro Documento(s)</Form.Label>
              <Form.Control
                type="text"
                name="numero_documento_carga"
                defaultValue={ordenInfo.numero_documento_carga}
                onKeyPress={(event) => validateOnlyNumber(event)}
                onBlur={(text) => allowOnlyNumberBlur(text)}
                autoComplete="off"
                maxLength={15}
                ref={register({
                  required: { value: statusValidate, message: "Requerido" },
                })}
                onPaste={(event) => onPaste(event)}
                readOnly={!statusValidate}
              />
              {errors.numero_documento_carga && (
                <Form.Text className="text-danger">
                  {errors?.numero_documento_carga?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md sm={4} className="mb-0">
            <Form.Group
              as={Col}
              controlId="valor_declarado_carga"
              className="mb-1"
            >
              <Form.Label>Valor declarado</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Controller
                  control={control}
                  name="valor_declarado_carga"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => (
                    <NumericFormat
                      allowNegative={false}
                      className="form-control"
                      decimalScale={2}
                      name={props.name}
                      id={props.name}
                      fixedDecimalScale={false}
                      isAllowed={({ floatValue }) => {
                        // Define your validation rules here
                        return true;
                      }}
                      onPaste={(e) => e.preventDefault()}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      value={totalPagar}
                      //defaultValue={ordenInfo.valor_declarado_carga}
                      onValueChange={(values) => {
                        props.onChange(values.floatValue);
                        setTotalPagar(values.floatValue);
                      }}
                      maxLength={15}
                    />
                  )}
                  autoComplete="off"
                />
              </InputGroup>
              {errors.valor_declarado_carga && (
                <Form.Text className="text-danger">
                  {errors?.valor_declarado_carga?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Form.Row>
        <TablaCarga
          register={register}
          errors={errors}
          getValues={getValues}
          ordenInfo={ordenInfo}
          toast={toast}
          disabledBtnCrear={disabledBtnCrear}
          validateForm={validateForm}
          visibleCarga={visibleCarga}
          setValue={setValue}
        />
      </PanelDetalle>
    </Fragment>
  );
};

export default DetalleCarga;
