import Descargar from '../Descargar.jsx'
import { formatNumberCurrency } from '../../../../utils/validationInput'

const Ordenes = { 
	columnas: [
		{
			name: 'Número de Folio',
			selector: (row, i) => row.numero_folio,
			sortable: true
		},
		{
			name: 'Fecha Orden',
			selector: (row, i) => row.fecha_orden,
			sortable: true
		},
		{
			name: 'Fecha Entrega',
			selector: (row, i) => row.fecha_entrega,
			sortable: true
		},
		{
			name: 'Fecha Factura',
			selector: (row, i) => row.fecha_factura,
			sortable: true
		},
		{
			name: 'Monto',
			selector: (row, i) => formatNumberCurrency(row.total),
			sortable: true,
			right: true
		},
		{
			name: 'N° Ordenes',
			selector: (row, i) => row.numero_orden,
			sortable: true
		},
		{
			name: '',
			sortable: true,
			center: true,
			cell: row =>  <Descargar {...row} />,
			width: "80px"
		},
	]
}

export default Ordenes