import { BiX, BiEditAlt } from "react-icons/bi"


const TableDestination = ({ openCloseModal, detRetiro, handleSelectedPreOrden, 
	handleCompletedPreOrden, isAdmin, handleAnular }) => {

	return (
		<div className="row mt-4">
			<div className="col-12">
				<div className="table-responsive table-retiro-responsive">
					<table className="table table-bordered table-sm bg-white table-retiro">
						<thead className="bg-warn-blue">
						    <tr>
						    	<th className="text-center">Preorden</th>
						    	<th className="text-center">Estado</th>
						      	<th className="text-center">RUT</th>
						      	<th className="text-center">Nombre y Apellido</th>
						      	<th className="text-center detalle-carga-th">Teléfono</th>
						      	<th className="text-center">Sucursal</th>
						      	<th className="text-center">Oficina</th>
						      	<th className="text-center detalle-opciones">Cant Bultos</th>
						      	<th className="detalle-opciones"></th>
						    </tr>
						 </thead>
						 <tbody>
						 	{
						 		detRetiro?.preorden?.length > 0 && 
						 		detRetiro?.preorden?.map(((item, key) => 
						 			<tr key={key} className="text-center">
									 	<td>{item.numero_preorden}</td>
									 	<td>{item.ultimo_estado}</td>
							 			<td>{item.rut_destinatario}</td>
							 			<td>{item.razon_social_destinatario}</td>
							 			<td>{item.telefono_avisas}</td>
							 			<td>{item.ciudad_destino_nombre}</td>
							 			<td>{item.oficina_destino_nombre}</td>
							 			<td>{item.total_bultos}</td>
							 			<td>
							 				{(detRetiro.estado!=="Anulado" && detRetiro.estado!=="Retiro Completado") && (
							 					<button 
								 					className="btn btn-warning mr-1 pl-1 pr-1" 
								 					type="button" 
								 					onClick={()=>handleSelectedPreOrden(item)}
								 				>
								    			    <BiEditAlt style={{fontSize: '20px'}} />
								    			</button>
							 				)}	
							 				{(item.ultimo_estado!=="Nulas" && item?.is_edit) && 
							 					<button 
								 					className="btn btn-danger pl-1 pr-1" 
								 					type="button" 
								 					onClick={(event)=>handleAnular(event,handleAnular(event, { type: "preorden", numero: item?.numero_preorden}))}
								 				>
								    			    <BiX style={{fontSize: '20px'}} />
								    			</button>
							 				}
							 			</td>
							 		</tr>
						 		))
						 	}
							
						 { detRetiro?.preorden?.length === 0 && <tr><td colSpan={9}>No tiene destinatarios</td></tr> }
						 </tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default TableDestination