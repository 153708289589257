import  { useEffect } from 'react'
import {  
	Card,
	Row,
	Col,
	Form
} from 'react-bootstrap'
import { formatMonto } from '../../config/formatInput'
import { useSelector } from "react-redux"

const Totales = ({ register, setValue }) => {

	const { listado_totales, descuento_global, recargo_global } = useSelector((state) => state.guiaDespacho)

	useEffect(() => {
		setValue('subtotal', formatMonto(listado_totales.subtotal))
		setValue('total_neto', formatMonto(listado_totales.total_neto))
		setValue('total_iva', formatMonto(listado_totales.total_iva))
		setValue('totals', formatMonto(listado_totales.totals))
		setValue('descuento_global', formatMonto(descuento_global))
		setValue('recargo_global', formatMonto(recargo_global))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listado_totales])

	return (
		<Card border="Light">
	    <Card.Header as="h6" className="bg-warn-blue text-dark">TOTALES</Card.Header>
	    <Card.Body className="bg-soft-blue pb-3">
	     	<Row>
	     		<Col>
	     			<Form.Group as={Row} controlId="descuento_global">
					    <Form.Label column lg="4">
					      Descuento Global
					    </Form.Label>
					    <Col lg="8" className="pt-3">
					        <Form.Control 
					      		type="text" 
					      		ref={register} 
					      		name="descuento_global"  
					      		readOnly 
					      	/>
					    </Col>
					</Form.Group>
					<Form.Group as={Row} controlId="recargo_global">
					    <Form.Label column lg="4">
					      Recargo Global
					    </Form.Label>
					    <Col lg="8" className="pt-3">
					        <Form.Control 
					      		type="text" 
					      		ref={register} 
					      		name="recargo_global"  
					      		readOnly 
					      	/>
					    </Col>
					</Form.Group>
	     		</Col>
	     		<Col>
	     			<Form.Group as={Row} controlId="subtotal" className="mb-2">
					    <Form.Label column lg="4">
					      Sub Total
					    </Form.Label>
					    <Col lg="8">
					      	<Form.Control 
					      		type="text" 
					      		name="subtotal" 
					      		ref={register} 
					      		readOnly  
					      	/>
					    </Col>
					</Form.Group>
					<Form.Group as={Row} controlId="total_neto" className="mb-2">
					    <Form.Label column lg="4">
					      Total Neto
					    </Form.Label>
					    <Col lg="8">
					      	<Form.Control 
					      		type="text" 
					      		name="total_neto" 
					      		ref={register} 
					      		readOnly 
					      	/>
					    </Col>
					</Form.Group>
					<Form.Group as={Row} controlId="total_iva" className="mb-2">
					    <Form.Label column lg="4">
					      IVA %19
					    </Form.Label>
					    <Col lg="8">
					      	<Form.Control 
					      		type="text" 
					      		name="total_iva"  
					      		ref={register} 
					      		readOnly 
					      	/>
					    </Col>
					</Form.Group>
					<Form.Group as={Row} controlId="totals" className="mb-2">
					    <Form.Label column lg="4">
					      Total
					    </Form.Label>
					    <Col lg="8">
					      	<Form.Control 
					      		type="text" 
					      		name="totals" 
					      		ref={register} 
					      		readOnly 
					      	/>
					    </Col>
					</Form.Group>
	     		</Col>
			</Row>
	    </Card.Body>
	  </Card>
		
	)
}

export default Totales