import { useSelector } from "react-redux"

export const usePermission = () => {

	const { configuracion } = useSelector((state) => state.config)

	const validarPermiso = (permission) => {
		let enabled = false

		if(configuracion.length > 0 && configuracion.indexOf(permission) >= 0)
		{
	    	enabled=true
		}

	    return enabled
	}


	return {
		validarPermiso
	}
}