import { useEffect } from "react"
import { Card, Form, Row, Col } from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import CardRetiro from "../../components/Layouts/CardRetiro"
import ModalDestino from "../../components/Retiros/Nuevo/Destino/ModalDestino"
import ClienteRemitente from "../../components/Retiros/Nuevo/ClienteRemitente"
import TablaDestion from "../../components/Retiros/Nuevo/Destino/TablaDestion"
import { ToastContainer, toast } from 'react-toastify'
import { useRetiroDestino } from "../../hooks/Retiro/useRetiroDestino"
import { useRetiroCreated } from "../../hooks/Retiro/useRetiroCreated"
import { validarRut } from "../../utils/rut"


const Created = () => {
	
	
	const { editDestino, deleteDestino, modalShow, handleModal, handleOpenModalDestino } = useRetiroDestino(toast)
	const { dataRetiro, listDestino, sucursales, getValues,
		 isLoading, oficinaOrigen, oficinaDestino, cargarOficinas, getDataInitial, 
		onSubmit, register, handleSubmit, errors, setValue, searchCliente, sucursal_id, comuna_id, control, 
		tipo_envio, forma_pago } = useRetiroCreated()

	useEffect(() => {
		getDataInitial()
		if(document.getElementById("frmRetiro")){
			document.getElementById("frmRetiro").reset()
			document.getElementById("razon_social_remitente").readOnly=true
    		//document.getElementById("razon_social_destinatario").readOnly=true
		}
		setValue("sucursal_origen_id", sucursal_id)
		setValue("comuna_origen_id", comuna_id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales?.length])

	return (
	    <Layout 
	    	titlePanel="Retiro"
	    	modulo="retiros"
	    >
			<Row>
				<Col xs={12}>
					<CardRetiro title="CREAR UN NUEVO RETIRO">
							<Form id="frmRetiro" onSubmit={handleSubmit(onSubmit)}>
								<Card border="Light">
									<Card.Header as="h6" className="bg-warn-blue text-dark">RETIRO</Card.Header>
									<Card.Body className="bg-soft-blue">
										<Row>
											<Col xs={12}>
												<ClienteRemitente 
													register={register} 
													errors={errors} 
													setValue={setValue} 
													searchCliente={searchCliente} 
													validarRut={validarRut} 
													toast={toast} 
													sucursales={sucursales}
													dataRetiro={dataRetiro}
													oficinaOrigen={oficinaOrigen}
													cargarOficinas={cargarOficinas}
													sucursal_id={sucursal_id} 
													comuna_id={comuna_id} 
													control={control}
													forma_pago={forma_pago}
													getValues={getValues}
												/>
												<hr />
											</Col>
											<Col xs={12} className="mt-4">
												<div className="panel-destino">
													<h6 className="mb-0">Detalle destinatario(s)</h6>
													<button 
														type="button" 
														className="btn btn-warning btn-sm"
														onClick={() => handleOpenModalDestino(getValues())}
													>Agregar destinatario</button>
												</div>
											</Col>
											<Col xs={12}>
												<TablaDestion 
													listDestino={listDestino} 
													editDestino={editDestino}
													deleteDestino={deleteDestino}
												/>
											</Col>
											<Col xs={12} className="text-right">
												<Form.Group as={Col} className="mt-3 pr-0">
													<button type="submit" className="btn btn-warning pl-5 pr-5" disabled={isLoading}>
														{ isLoading ? 'Procesando...' : 'Guardar' }
													</button>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Form>	
						
					</CardRetiro>
				</Col>
			</Row>
	    	{modalShow &&
	    		<ModalDestino
			        show={modalShow}
			        onHide={() => handleModal(false)}
			        searchcliente={searchCliente} 
					validarrut={validarRut} 
					sucursales={sucursales}
					toast={toast}
					oficinadestino={oficinaDestino}
					cargaroficinas={cargarOficinas}
					tipo_envio={tipo_envio}
			    />
			}
			<ToastContainer />
	    </Layout>
	)
}

export default Created