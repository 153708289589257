import React, { useState } from "react"
import {  
	Col,
	Row,
	Form
} from 'react-bootstrap'
import ModalFactura from '../../components/Factura/ModalFactura'
import Cliente from "../../components/Encomienda/Entrega/Cliente"
import DetalleOrden from "../../components/Encomienda/Entrega/DetalleOrden"
import { useDispatch, useSelector } from "react-redux"
import { entregarOrden, assignOrdenCaja } from "../../actions/orderAction"
import { useForm } from "react-hook-form"
import {  formatFloat } from "../../config/formatInput"

const EntregaDirecto = ({ toast }) => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors, getValues, setValue, control } = useForm()
    const { totalMonto, disabledBtnPago  } = useSelector((state) => state.orders)
    const [ hidden, setHidden ] = useState(false)
    const [ infoData, setInfoData ] = useState({})
    const [ loading, setLoading ] = useState(false)
   

	const onSubmit = async (data, event) => {
    	event.preventDefault()
   
        setLoading(true)
        if(data.no_aplica===false){
            if(data.rut_destinatario==="" || data.razon_social_destinatario===""){
                toast.error("Debe ingresar los datos del cliente", {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
                return false
            }
        }

        let listOrdenes
    	if(!data.hasOwnProperty("ordenes")){
    		toast.error("No tiene ordenes por retirar", {position: toast.POSITION.TOP_RIGHT})
            setLoading(false)
    		return false
    	}else{
    		listOrdenes = data.ordenes.filter(orden => orden.seleccion==="true")
    		if(listOrdenes.length===0){
    			toast.error("No hay orden seleccionadas", {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
    			return false
    		}
    	}

    	const listadoDocumentacion = listOrdenes.filter(orden => orden.documentacion_id==="")
    	const listadoNro = listOrdenes.filter(orden => orden.documentacion_id==="")

    	if(listadoDocumentacion.length > 0 || listadoNro.length > 0){
    		toast.error("Debe de completar el detalle de las ordenes", {position: toast.POSITION.TOP_RIGHT})
            setLoading(false)
    		return false
    	}
        let dataPago = typeof data.pagos!=="undefined" ? data.pagos : []
    	const formaPago = listOrdenes.filter(orden => orden.forma_pago==="Por pagar")
    	if(formaPago.length > 0){

            dataPago = data.pagos.map(pago => {
                return {
                    ...pago,
                    monto: formatFloat(pago.monto)
                }
            }) 

    		const cantPago = dataPago.filter(pago => pago.metodo_pago_id.length===0 || pago.monto.length===0 || pago.nro_operacion.length===0)
    		if(cantPago.length===2){
    			toast.error("Debe ingresar un método de pago", {position: toast.POSITION.TOP_RIGHT})
                setLoading(false)
    			return false
    		}else{
                let totals = dataPago.reduce((acumulado, totalPagar) => acumulado + parseFloat(totalPagar.monto || 0), 0)

                if(totals!==totalMonto){
                    toast.error("Los montos no coinciden", {position: toast.POSITION.TOP_RIGHT})
                    setLoading(false)
                    return false
                }
            }
    	}

        const information = {
            ...data,
            ordenes: listOrdenes,
            pagos: dataPago
        }
       
        const porPagar = information.ordenes.filter(orden => orden.forma_pago==="Por pagar" && parseInt(orden.documentacion_id)===1)

        if(porPagar.length>0){
            const datosFactura = {
                information,
                rut_remitente: porPagar[0].rut_remitente,
                rut_destinatario: porPagar[0].rut_destinatario
            }

            setHidden(true)
            setInfoData(datosFactura)
        } else{
            await entregarEncom({information})
        }  
        setLoading(false)
    }

    const entregarEncom = async (information) => {
        setLoading(true)
        const ordenes = information.information.ordenes.map(item => item.orden_flete_id)

        const response = await dispatch(entregarOrden(information, "entrega", ordenes))

        if(response.status){
            const body = {
                fecha_entrega: response.fecha_entrega,
                ordens: ordenes
            }
            await dispatch(assignOrdenCaja(body))
            setLoading(false)
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            document.getElementById("frmEntrega").reset()
            return true
        }else{
            setLoading(false)
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            return false
        }
    }


	return (
		<>
	    	<Form id="frmEntrega" onSubmit={handleSubmit(onSubmit)}>
		    	<Row className="mb-1">
					<Col className="contenedor-cliente bg-soft-blue">
						<Form.Row>
							<Cliente 
                                register={register} 
                                getValues={getValues} 
                                setValue={setValue} 
                                toast={toast} 
                                disabledBtnPago={disabledBtnPago} 
                                control={control}
                            />
						</Form.Row>
					</Col>
				</Row>

				<DetalleOrden 
                    register={register} 
                    errors={errors} 
                    getValues={getValues} 
                    setValue={setValue} 
                    toast={toast}  
                    loading={loading} 
                    control={control}
                />
			</Form>
            {hidden && <ModalFactura 
                        setHidden={setHidden} 
                        hidden={hidden} 
                        data={infoData} 
                        toast={toast} 
                        funcFactura={entregarEncom} 
                        title={"FACTURA A NOMBRE DE:"}  
                        isVisibleFactura={true}
                        nameModulo="entrega"
                        /> }
	</>
	)
}

export default EntregaDirecto