import { 
	LISTADO_DATA_OF,
	SELECTED_ROWS_OF,
	IS_VISIBLE_FORMULARIO_OF,
	SEARCH_OF,
	DATA_EDIT_OF,
	DATA_ADD_OF,
	DATA_ACTIVAR_DESACTIVAR_OF,
	DATA_DELETE_OF
} from "../types/oficinaType"

const INITIAL_STATE = {
	ofListado: [],
	ofIsCargando: true,
	ofIsForm: false,
	ofIsEdit: false,
	ofDatos: {},
	ofIsSelectedRows: [],
	ofBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	}
}

const oficinaReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_OF:
			return {
				...state,
				ofListado: action.payload,
				ofIsCargando: false
			}
		case IS_VISIBLE_FORMULARIO_OF:
			return {
				...state,
				ofIsForm: action.payload.isForm,
				ofIsEdit: action.payload.isEdit,
				ofDatos: action.payload.datos,
				ofBtnActivar: action.payload.btnActivar,
				ofIsSelectedRows: []
			}
		case SEARCH_OF:
			return {
				...state,
				ofDatos: action.payload.seletected,
				ofIsForm: action.payload.isForm,
				ofIsEdit: action.payload.isEdit,
				ofBtnActivar: action.payload.btnActivar
			}
		case DATA_ADD_OF:
			return{
				...state,
				ofListado: action.payload.listado,
				ofIsForm: action.payload.isForm
			}
		case DATA_EDIT_OF:
			return {
				...state,
				ofListado: action.payload.listado,
				ofDatos: action.payload.datos,
				ofIsForm: action.payload.isForm,
				ofBtnActivar: action.payload.btnActivar
			}
		case SELECTED_ROWS_OF:
			return {
				...state,
				ofIsSelectedRows: action.payload.rows,
				ofBtnActivar: action.payload.btnActivar
			}
		case DATA_ACTIVAR_DESACTIVAR_OF:
			return {
				...state,
				ofIsSelectedRows: action.payload.rows,
				ofBtnActivar: action.payload.btnActivar,
				ofListado: action.payload.listado
			}
		case DATA_DELETE_OF:
			return {
				...state,
				ofListado: action.payload
			}
		default: return state
	}
}

export default oficinaReducer