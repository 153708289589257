import React from "react"
import {  
	Form
} from 'react-bootstrap'
import { useDispatch } from "react-redux"
import { updateEntregarOrden } from "../../../actions/orderAction"
import { formatNumberCurrency } from "../../../utils/validationInput"

const TbodyOrden = ({ index, numero_orden, orden_flete_id, total, documentacion_id, nro_documento, check, 
	estado, register, documentacions, forma_pago, changeTotales, rut_remitente, rut_destinatario, forma_pago_id
}) => {

	const dispatch = useDispatch()


	const handleChange = (event, orden_flete_id) => {
		const param = event.target.name.split(".")[1]
		const valor = event.target.value
		dispatch(updateEntregarOrden(orden_flete_id, param, valor))
	}

	return (
		<tr key={index}>
	      <td>
			<input
                type="hidden"
                name={`ordenes[${index}].orden_flete_id`}
                value={orden_flete_id}
                ref={register}
              />
            {numero_orden}
			</td>
			<td>
			<input
                type="hidden"
                name={`ordenes[${index}].forma_pago`}
                value={forma_pago}
                ref={register}
              />
			  <input
                type="hidden"
                name={`ordenes[${index}].forma_pago_id`}
                value={forma_pago_id}
                ref={register}
              />
	      	{forma_pago}
	      </td>
	      <td>
	      	<input
                type="hidden"
                name={`ordenes[${index}].total`}
                value={total}
                ref={register}
              />
              <input
                type="hidden"
                name={`ordenes[${index}].rut_remitente`}
                value={rut_remitente}
                ref={register}
              />
              <input
                type="hidden"
                name={`ordenes[${index}].rut_destinatario`}
                value={rut_destinatario}
                ref={register}
              />
	      	$ {formatNumberCurrency(total)}
	      </td>
	      <td>
	      	{
	      		 !check ? ( 
	      		 	<Form.Control 
					    	as="select"
					    	name={`ordenes[${index}].documentacion_id`}
					    	value={documentacion_id}
					    	onChange = {(event) => handleChange(event, orden_flete_id)}
					    	ref={register}
					    >
				    	<option value="">Seleccione</option>
				    	{
				    		documentacions.length > 0 && (
				    			documentacions.map(documents => <option key={documents.id} value={documents.id}>{documents.descripcion}</option>)
				    		)
				    	}
				 	</Form.Control>

	      		 ) : documentacion_id
	      	}
	      	
	      </td>
	      <td>
	      { nro_documento}
	      </td>
	      <td>
	      	{estado}
	      </td>
	      <td className="text-center">
			<Form.Check 
     			type="checkbox" 
		    	name={`ordenes[${index}].seleccion`}
		    	onChange = {(event) => changeTotales(event, orden_flete_id)}
		    	defaultValue={true}
     			ref={register}
		    />
	      </td>
	    </tr>

	)
}

export default TbodyOrden
