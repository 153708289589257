import { useState, useEffect } from "react"
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from "react-redux"
import { resultTotales, habEditCarga, saveEditDetalleCarga } from "../../../actions/orderAction"
import { BiX, BiEditAlt, BiSave } from "react-icons/bi"
import { toast } from 'react-toastify'
import { NumericFormat } from "react-number-format";
import { onPaste, validateOnlyLetterNumber, validateOnlyNumber } from "../../../utils/validationInput";

const BodyCarga = ({ index, id, bulto, peso, metraje_cubico, contenido, valor_neto, 
	register, descuento, visible, getValues, alto, largo, ancho, visibleCarga, setValue, deleteRow, validateForm }) => {
	
	const dispatch = useDispatch()
	const [ totalPagar, setTotalPagar ] = useState(valor_neto)
	const [ nroPeso, setNroPeso ] = useState(peso);
	const [ nroLargo, setNroLargo] = useState(largo);
	const [ nroAncho, setNroAncho ] = useState(ancho);
	const [ nroAlto, setNroAlto ] = useState(alto);
	const [ nroM3, setNroM3 ] = useState(metraje_cubico);

	useEffect(() => {
		setTotalPagar(valor_neto);
		setNroPeso(peso);
		setNroLargo(largo);
		setNroAncho(ancho);
		setNroAlto(alto);
		setNroM3(metraje_cubico);
	
	}, [valor_neto, peso, largo, ancho, alto, metraje_cubico])
	

	const editRow = (event, index, visible, descuento) => {
	
		if(visible===false){
			dispatch(habEditCarga(index))
		}else{
			const data = getValues()	
	
			let cargaIndex = data.cargas[index]
		
			let total = 0
			if(nroAlto!==""){
				total = nroAlto
			}
	
			if(nroAncho!==""){
				total = total * nroAncho
			}
		
			if(nroLargo!==""){
				total = total * nroLargo
			}
	
			cargaIndex = {
				...cargaIndex,
				metraje_cubico: parseFloat(total).toFixed(2)
			}
	
		
				if(Object.keys(cargaIndex).length>0){
					dispatch(saveEditDetalleCarga({
						...cargaIndex,
						peso: nroPeso,
						largo: nroLargo,
						ancho: nroAncho,
						alto: nroAlto,
						metraje_cubico: nroM3,
						valor_neto: totalPagar
					}, index))
				
					dispatch(resultTotales(descuento))
				}
		
		}
	}

	const editM3 = (index) => {     
        let total = 0
		if(nroAlto!==""){
			total = nroAlto
		}
		if(nroAncho!==""){
			total = total * nroAncho
		}
		if(nroLargo!==""){
			total = total * nroLargo
		}
         total = parseFloat(total).toFixed(2)

        setValue(`cargas[${index}].metraje_cubico`, total)
        setNroM3(total)
      }

	  const validateBulto = (event) => {
   
		if(event.target.value.toString()==="0" || event.target.value.toString()==="00"){
		  toast.error("Debe ingresar la cantidad de bultos", {position: toast.POSITION.TOP_RIGHT})
		  return false
		}
	  }

	
	return (
		<tr key={index}>
	      <td>
	      	<input
                type="hidden"
                name={`cargas[${index}].id`}
                defaultValue={id}
                ref={register}
                className="form-control"
              />
			<input
                type={visible ? "text" : "hidden"}
                name={`cargas[${index}].bulto`}
                defaultValue={bulto}
                ref={register}
                className="form-control"
				maxLength="2"
				onBlur={(event) => validateBulto(event)}
				onPaste={(event) => onPaste(event)}				
				onKeyPress={(event) => validateOnlyNumber(event)}
              />
            {!visible && bulto}
			</td>
	      <td>
	      	
			  {
				visible ? (
					<NumericFormat
						allowNegative={false}
						className="form-control"
						decimalScale={2}
						placeholder="kilos"
						name={`cargas[${index}].peso`}
						id={`cargas[${index}].peso`}
						fixedDecimalScale={false}
						isAllowed={({floatValue}) => {
							// Define your validation rules here
							return true;
						}}
						onPaste={(e) => e.preventDefault()}
						decimalSeparator={','}
						value={nroPeso}
						onValueChange={(values) => setNroPeso(values.floatValue)}
						onBlur={() => editM3(index)}
					/>
				) : (
					<input
						type="hidden"
						name={`cargas[${index}].peso`}
						defaultValue={nroPeso}
						ref={register}
						className="form-control"
						step="0.01" 
						min="0"
					/>
				)
			  }
			  	
	      	{!visible && 
				<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroPeso}
				/>
			}
	      </td>
	      <td>
		  {
				visible ? (
					<OverlayTrigger overlay={<Tooltip id="tooltip-largo">m</Tooltip>}>
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							placeholder="Largo"
							name={`cargas[${index}].largo`}
							id={`cargas[${index}].largo`}
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							defaultValue ={largo}
							value={nroLargo}
							onValueChange={(values) => setNroLargo(values.floatValue)}
							onBlur={() => editM3(index)}
							maxLength={15}
						/>
					</OverlayTrigger>
				) : (
					<input
						type="hidden"
						name={`cargas[${index}].largo`}
						defaultValue={largo}
						ref={register}
						className="form-control"
						step="0.01" 
						min="0"
					/>
				)
			  }
	      	{!visible &&  
				<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroLargo}
				/>
			}
	      </td>
		  <td>
		  {
				visible ? (
					<OverlayTrigger overlay={<Tooltip id="tooltip-ancho">m</Tooltip>}>
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							placeholder="Ancho"
							name={`cargas[${index}].ancho`}
							id={`cargas[${index}].ancho`}
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							defaultValue ={ancho}
							value={nroAncho}
							onValueChange={(values) => setNroAncho(values.floatValue)}
							onBlur={() => editM3(index)}
							maxLength={15}
						/>
					</OverlayTrigger>
				) : (
					<input
						type="hidden"
						name={`cargas[${index}].ancho`}
						defaultValue={ancho}
						ref={register}
						className="form-control"
						step="0.01" 
						min="0"
					/>
				)
			  }
	      	
	      	{!visible && 
				<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroAncho}
				/>
			}
	      </td>
		  <td>
		  {
				visible ? (
					<OverlayTrigger overlay={<Tooltip id="tooltip-alto">m</Tooltip>}>
						<NumericFormat
							allowNegative={false}
							className="form-control"
							decimalScale={2}
							placeholder="Alto"
							name={`cargas[${index}].alto`}
							id={`cargas[${index}].alto`}
							fixedDecimalScale={false}
							isAllowed={({floatValue}) => {
								// Define your validation rules here
								return true;
							}}
							onPaste={(e) => e.preventDefault()}
							decimalSeparator={','}
							defaultValue ={ancho}
							value={nroAlto}
							onValueChange={(values) => setNroAlto(values.floatValue)}
							onBlur={() => editM3(index)}
							maxLength={15}
						/>
					</OverlayTrigger>
				) : (
					<input
						type="hidden"
						name={`cargas[${index}].alto`}
						defaultValue={alto}
						ref={register}
						className="form-control"
						step="0.01" 
						min="0"
					/>
				)
			  }
	      	{!visible && 
				<NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					value={nroAlto}
				/>
			}
	      </td>
		  <td>
	      	<input
                type="hidden"
                name={`cargas[${index}].metraje_cubico`}
                defaultValue={nroM3}
                ref={register}
                className="form-control"
                step="0.01" 
              />
	      	<NumericFormat
				displayType="text"
				className="text-padding"
				decimalScale={2}
				fixedDecimalScale={false}
				decimalSeparator={','}
				thousandSeparator={"."}
				value={nroM3}
			/>
	      </td>
	      <td>
	      	<input
                type={visible ? "text" : "hidden"}
                name={`cargas[${index}].contenido`}
                defaultValue={contenido}
                ref={register}
                className="form-control"
				autoComplete="off"
				onKeyPress={(event) => validateOnlyLetterNumber(event)}
              />
	      	{!visible && contenido}
	      </td>
	      <td>
		  {
				visible ? (
					<NumericFormat
						allowNegative={false}
						className="form-control"
						decimalScale={2}
						placeholder="Total"
						name={`cargas[${index}].valor_neto`}
						id={`cargas[${index}].valor_neto`}
						fixedDecimalScale={false}
						onPaste={(e) => e.preventDefault()}
						decimalSeparator={','}
						thousandSeparator={"."}
						defaultValue ={ancho}
						value={totalPagar}
						onValueChange={(values) => setTotalPagar(values.floatValue)}
						isAllowed={({floatValue}) => {
							// Define your validation rules here
							return true;
						}}
						maxLength={15}
						readOnly={!validateForm}
					/>
				) : (
					<input
						type="hidden"
						name={`cargas[${index}].valor_neto`}
						defaultValue={valor_neto}
						ref={register}
						className="form-control"
					/>
				)
			  }
			  {!visible && 
			   <NumericFormat
					displayType="text"
					className="text-padding"
					decimalScale={2}
					fixedDecimalScale={false}
					decimalSeparator={','}
					thousandSeparator={"."}
					value={totalPagar}
				/>
			  }
	      </td>
		  {
			visibleCarga===true && (
				<td>
					<Button variant={!visible ? "warning" : "success" } type="button" size="sm" onClick={(event)=>editRow(event, index, visible, descuento)} className="mr-1 pl-1 pr-1">
						{!visible ? <BiEditAlt style={{fontSize: '20px'}} /> : <BiSave style={{fontSize: '20px'}} /> }
					</Button>
					<Button variant="danger" type="button" size="sm" onClick={()=>deleteRow(index, descuento)} className="pl-1 pr-1">
						<BiX style={{fontSize: '20px'}} />
					</Button>		
				</td>
			)
		  }	      
	    </tr>

	)
}

export default BodyCarga
