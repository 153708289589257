import { useState } from "react"
import {  
	Button,
	Row,
	Col
} from 'react-bootstrap'

import { useDispatch } from "react-redux"
import Listado from "./Historico/Listado"
import { exportarFilePdf, exportarFileExcel } from "../../actions/facturacionAction"

const Historico = ({data, isCargando, hiddenBtn, cliente}) => {

	const dispatch = useDispatch()
	const [ isLoadingPdf, setIsLoadingPdf ] = useState(false)
	const [ isLoadingExcel, setIsLoadingExcel ] = useState(false)

	const exportToPdf = async (event) => {
		setIsLoadingPdf(true)
		const desde = document.getElementById("fecha_desde").value
		const hasta = document.getElementById("fecha_hasta").value
		await dispatch(exportarFilePdf(desde, hasta))
		setIsLoadingPdf(false)

	}
	
	const exportToExcel = async (event) => {
		setIsLoadingExcel(true)
		const desde = document.getElementById("fecha_desde").value
		const hasta = document.getElementById("fecha_hasta").value
		await dispatch(exportarFileExcel(desde, hasta))
		setIsLoadingExcel(false)
	}

	return (
    	<>	
    		<h6 className="mt-3 mb-5">FACTURACIÓN HISTÓRICA</h6>
    		<Row>
			  <Col lg={3}>
			  	<p className="pl-3 mr-2">
			  		<span className="mr-2 font-weight-bold">RUT:</span>
			  		<span>{cliente.rut}</span>
			  	</p>
			  </Col>
			  <Col lg={9}>
			  	<p className="pl-3 mr-2">
			  		<span className="mr-2 font-weight-bold">Cliente:</span>
			  		<span>{cliente.nombre}</span>
			  	</p>
			  </Col>
			</Row>
    		<Listado data={data} isCargando={isCargando} />
    		{hiddenBtn && (
	    		<Row className="mb-5 text-right">
	    			<Col md sm={12}>
			  			<Button variant="outline-primary" className="mb-2 pl-5 pr-5 mr-3" type="button" onClick={(event)=>exportToExcel(event)} disabled={isLoadingExcel}> 
				    		{isLoadingExcel ? 'Descargando..' : 'Descargar Excel' }
				  		</Button>
				  		<Button variant="outline-primary" className="mb-2 pl-5 pr-5" type="button" onClick={(event)=>exportToPdf(event)} disabled={isLoadingPdf}> 
				    		{isLoadingPdf ? 'Descargando..' : 'Descargar PDF' }
				  		</Button>
			  		</Col>
	    		</Row>
    		)}
    	</>
	)
}

export default Historico