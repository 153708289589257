import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Roles/Listado"
import Formulario from "../../components/Admin/Roles/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoRole } from "../../actions/roleAction"


const Role = ({toast}) => {
	
	const dispatch = useDispatch()
	const { listRole, rIsCargando, rDatos, rIsForm } = useSelector((state) => state.roles)

	useEffect(() => {
		dispatch(listadoRole())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!rIsForm ? "Listado de Roles" : "Roles" }>
		    	{!rIsForm ? (
					<Listado data={listRole} isCargando={rIsCargando} toast={toast} />
				): (
					<Formulario toast={toast} datos={rDatos} />	
				)}			    
			</CardRetiro>
	    </>
	)
}

export default Role