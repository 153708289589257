import API from "../config/api"
import {
	LISTADO_DATA_CO,
	SELECTED_ROWS_CO,
	IS_VISIBLE_FORMULARIO_CO,
	SEARCH_CO,
	DATA_ADD_CO,
	DATA_EDIT_CO,
	DATA_ACTIVAR_DESACTIVAR_CO,
	DATA_DELETE_CO
} from "../types/conductorType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const listadoConductor = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/conductors")
			dispatch(requestSuccess(LISTADO_DATA_CO, response.conductors))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const getSelectedRowsConductor = (rows) => (dispatch, getState) => {
	let estatus = ""
	let title = ""

	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].estatus===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].estatus===1) ? "Desactivado" : "Activado"
	}
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount>0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}
	dispatch(requestSuccess(SELECTED_ROWS_CO, payload))
}

export const isVisibleFormularioConductor = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_CO, payload))
	  	 
	}
}

export const searchPorIdConductor = (id) => (dispatch, getState) => {
	const {coListado} = getState().conductores
	
    const payload = {
		seletected: coListado.find(conductor => conductor.id===id),
		isForm: true,
		isEdit: true
	}


	if(payload){
	  	dispatch(requestSuccess(SEARCH_CO, payload))
	  	 
	}
}

export const addConductor = (body, dataOriginal, fecha) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{

		  	let response = await API.postFile("admin/conductors", body)

		    if(response.status){
		    	const dia = (fecha.getDate()<10) ? `0${fecha.getDate()}` : fecha.getDate()

		    	const mes = ((fecha.getMonth() + 1)<10) ? `0${(fecha.getMonth() + 1)}` : (fecha.getMonth() + 1)
		    	
		    	let fecha_venc = dia + '-' + mes + '-' + fecha.getFullYear()

				const conductor = {
					...dataOriginal, 
					id: response.id, 
					fecha: fecha_venc,
					fecha_vencimiento: fecha,
					estatus: 1,
					name_estatus: "Activado",
					imagen: response.imagen
				}
				const {coListado} = getState().conductores
				const data = [...coListado, conductor]
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_CO, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editConductor = (body, dataOriginal, fecha) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {coListado, coDatos} = getState().conductores

		  	let response = await API.postFile(`admin/conductors/${coDatos.id}`, body)

		    if(response.status){
				const dia = (fecha.getDate()<10) ? `0${fecha.getDate()}` : fecha.getDate()

		    	const mes = ((fecha.getMonth() + 1)<10) ? `0${(fecha.getMonth() + 1)}` : (fecha.getMonth() + 1)
		    	
		    	let fecha_venc = dia + '-' + mes + '-' + fecha.getFullYear()
				
				const data =coListado.map(conductor => {
					if(conductor.id === coDatos.id){
						return {
							...dataOriginal, 
							id: coDatos.id, 
							fecha: fecha_venc,
							fecha_vencimiento: fecha,
							estatus: conductor.estatus,
							name_estatus: conductor.name_estatus,
							imagen: response.imagen
						};
					}
					return conductor
				})  

				const payload = { 
					listado: data, 
					isForm: false, 
					datos: {},
					btnActivar: {
						isVisible: false,
						nameBtn: "Desactivar",
						title: "Desactivado"
					}
				}	

		        dispatch(requestSuccess(DATA_EDIT_CO, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changeStatus = (id, opcion, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {coListado, coIsSelectedRows} = getState().conductores

			const body = {
				type: "estatus",
				opcion: opcion.toLowerCase()
			}

		  	let response = await API.post(`admin/conductors/${id}`, body)

		    if(response.status){
				const listado = coListado.map(conductor => {
					
					if(conductor.id === id){
						return {...conductor, estatus: status, name_estatus: opcion};
					}
					return conductor
				})  

				const payload = {
					rows: {
						...coIsSelectedRows,
						selectedRows: coIsSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								estatus: status,
								name_estatus: opcion
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado
				}


		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_CO, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteConductor = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {coListado} = getState().conductores

		  	let response = await API.get(`admin/conductors/delete/${id}`)

		    if(response.status){
				
				const payload =coListado.filter(conductor => conductor.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_CO, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}