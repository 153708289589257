import React, { useState  } from "react"
import {  
    Col,
    Row,
    Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import Layout from "../../components/Layouts/Layout"
import Guide from "./Guide"
import Historial from "./Historial"
import { resetDataOrdenes } from '../../actions/guiaDespachoAction'
import { useDispatch } from "react-redux"

const Options = () => {

    const dispatch = useDispatch()
    const [ isNuevo, setIsNuevo ] = useState(true)
    const [ isHistorial, setIsHistorial ] = useState(false)

    const changeNewGuide = (event) => {
        setIsNuevo(true)
        setIsHistorial(false)
        if(document.getElementById("frmDevolucion")){
            document.getElementById("frmDevolucion").reset()
        }
        dispatch(resetDataOrdenes())
        
    }

    const changeHistoryGuide = (event) => {
        setIsNuevo(false)
        setIsHistorial(true)
    }

    return (
        <Layout 
            titlePanel="Guía de despacho electrónica"
            modulo="guia_despacho"
        >
            <Row>
                <Col xs={12} className="pt-4 pl-5 pb-4">
                    <Button variant={isNuevo ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2" onClick={(event) => changeNewGuide(event)}>
                        Nueva Guía
                    </Button>
                    <Button variant={isHistorial ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2" onClick={(event) => changeHistoryGuide(event)}>
                        Historial Guía
                    </Button>
                </Col>
            </Row>

            {
                (isNuevo) && (
                        <Guide toast={toast}  />
                )
            }
            {
                (isHistorial) && (
                        <Historial toast={toast} />
                )
            }
            
            <ToastContainer />
        </Layout>
    )
}

export default Options