import {  
	Col,
	Form
} from 'react-bootstrap'

const Permisos = ({listado, clave, register, rol}) => {

	const isCheckPermiso = (namePermiso) => {
		if(rol.hasOwnProperty("permissions")){
			const isExiste = rol.permissions.find(permiso => permiso.name === namePermiso)
			if(isExiste){
				return true
			}
		}
		
		return false
	}

	return (
            <Col lg={4} key={clave} className="mb-3">
            	<Form.Label className="mb-2">{listado?.title}</Form.Label>
				{listado?.options.map((permiso, indice) => (
					<Form.Group key={permiso.id} className="mb-1">
					    <Form.Check 
					    	type="checkbox" 
					    	name={`permisos[${permiso.id}]`} 
					    	id={`permisos[${permiso.id}]`}
					    	label={permiso.name_view}
					    	defaultValue={permiso.name}
					    	defaultChecked={isCheckPermiso(permiso.name)}
	             			ref={register}
					   	/>
					 </Form.Group>
				))}
			</Col>
	)
}

export default Permisos