import {  
	Form,
	Col,
	Row
} from 'react-bootstrap'
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form"

const FormTotales = ({ register, applyDescuento, total_bultos, valor_descuento, total_kilos,
	total_metro_cubico, subtotal_orden, valor_neto, valor_iva, total, control }) => {

	return (
		<>
			<Row>
				<Col>
			    	<Form.Label>Totales</Form.Label>
				</Col>
			</Row>
			<Row>
				<Col lg md={4} sm={4}>
			     	<Form.Group controlId="total_bultos" className="pr-0 mb-1">
				    	<Form.Label>Bulto</Form.Label>
				    	<Form.Control 
					    	type="text" 
					    	name="total_bultos" 
					    	defaultValue={total_bultos}
					    	ref={register}
			                readOnly
					    />
					    <Form.Control 
					    	type="hidden" 
					    	name="valor_descuento"
					    	defaultValue={valor_descuento} 
					    	ref={register}
					    />
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="total_kilos" className="pr-0 pl-0 mb-1">
				    	<Form.Label>Kilo</Form.Label>
						<Controller
							control={control}
							name={"total_kilos"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={total_kilos}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/>
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="total_metro_cubico" className="pr-0 pl-0 mb-1">
				    	<Form.Label>M3</Form.Label>
						<Controller
							control={control}
							name={"total_metro_cubico"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={total_metro_cubico}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/>
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="subtotal_orden" className="pr-0 pl-0 mb-1">
				    	<Form.Label>Subtotal</Form.Label>
						<Controller
							control={control}
							name={"subtotal_orden"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={subtotal_orden}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/> 
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="descuento" className="pl-0 pr-0 mb-1">
				    	<Form.Label>Dsct 10%</Form.Label>
				    	<Form.Control 
						    	as="select"
						    	name="descuento" 
						    	ref={register}
						    	onChange={(event)=>applyDescuento(event)}
						    >
					    	<option value="No">No</option>
					    	<option value="Si">Si</option>
					 	</Form.Control>
				    </Form.Group>
			    </Col>
			</Row>
			<Row>
				<Col lg md={4} sm={4}>
			     	<Form.Group controlId="valor_neto" className="pr-0">
				    	<Form.Label>Neto</Form.Label>
						<Controller
							control={control}
							name={"valor_neto"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={valor_neto}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/> 
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="valor_iva" className="pr-0 pl-0">
				    	<Form.Label>IVA $</Form.Label>
						<Controller
							control={control}
							name={"valor_iva"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={valor_iva}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/> 
				    </Form.Group>
			    </Col>
			    <Col lg md={4} sm={4}>
			     	<Form.Group controlId="total" className="pl-0 pr-0">
				    	<Form.Label>Bruto $</Form.Label>
						<Controller
							control={control}
							name={"total"}
							rules={{required: {value: true, message: 'Requerido'}}}
							defaultValue={total}
							render={(props) => (
							<NumericFormat
								allowNegative={false}
								className="form-control"
								decimalScale={2}
								placeholder=""
								name={props.name}
								id={props.name}
								fixedDecimalScale={false}
								isAllowed={({floatValue}) => {
									// Define your validation rules here
									return true;
								}}
								onPaste={(e) => e.preventDefault()}
								decimalSeparator={','}
								thousandSeparator={"."}
								value={props.value}
								onValueChange={(values) => {
									props.onChange(values.floatValue);
								}}
								readOnly={true}
								maxLength={15}
							/>
							)}
							autoComplete="off"
						/> 
				    </Form.Group>
			    </Col>
			</Row>	
		</>
	)
}

export default FormTotales