import { useState, useEffect } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card,
	InputGroup
} from 'react-bootstrap'

import { useForm, useFieldArray } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addCliente, editCliente, listadoCliente, deleteCodeByClient } from "../../../actions/clienteAction"
import { validarRut } from "../../../utils/rut"

import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { Controller } from "react-hook-form"
import { validateOnlyNumber, onPaste, validateOnlyLetter, validateOnlyLetterNumber } from "../../../utils/validationInput"
import Swal from 'sweetalert2'


const Formulario = ({toast, datos}) => {
	
    const dispatch = useDispatch()
	const [ isLoading, setIsLoading ] = useState(false)
	const [oficinaOrigen, setOficinaOrigen ] = useState([])
	const [isCodigo, setIsCodigo ] = useState("no")
    const { register, handleSubmit, errors, reset, getValues, control, setValue } = useForm({
		defaultValues: {
			rut: "",
			telefono: "", 
			direccion: "",
			email: "",
			sucursal_id: "",
			comuna_id: "",
			nombre_completo: "",
			numero_cuenta: [{ value: "", idRow: ""}]
		}
	})
	

	const { fields: vNumeroCuenta, append, remove } = useFieldArray({ control, name: "numero_cuenta" })

	const { cliente, orders  } = useSelector((state) => {
		return {
			cliente: state.cliente,
			orders: state.orders
		}
	})

	const { isEdit } = cliente
	const { sucursales } = orders


	useEffect(() => {
	
		const data = getValues();
		reset({
			...data,
			numero_cuenta: vNumeroCuenta
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vNumeroCuenta?.length])

	useEffect(() => {

		if(Object.keys(datos).length>0){
			cargarOficinas(undefined, datos.sucursal_id)
			const vNumero = datos?.numero_cuentas?.map(item => {
				return {
					value: item?.numero_cuenta,
					idRow: item?.id
				}
			  });
			
			reset({
			  ...getValues(),
			  sucursal_id: datos.sucursal_id,
			  comuna_id: datos.comuna_id,
			  rut: datos?.rut,
			  numero_cuenta: vNumero
			})	
			setIsCodigo(vNumero?.length>0 ? "si" : "no")	
		}else{
			reset({
				rut: "",
				telefono: "", 
				direccion: "",
				email: "",
				sucursal_id: "",
				comuna_id: "",
				nombre_completo: "",
				numero_cuenta: []
			  })
			  setIsCodigo("no")
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
    const onSubmit = async (data, e) => {
		setIsLoading(true)
		let response 
		
		const rut = data.rut

		const isValid = validarRut(rut)
		if(!isValid.status){
			toast.error(isValid.message, {position: toast.POSITION.TOP_RIGHT})
			setIsLoading(false)
			return false
		}

		let text_sucursal = ""
		const sucursalSelected = document.getElementById("sucursal_id")
		if(sucursalSelected){
			text_sucursal = sucursalSelected.options[sucursalSelected.selectedIndex].text	
		}

		let text_comuna = ""
		const comunaSelected = document.getElementById("comuna_id")
		if(comunaSelected){
			text_comuna = comunaSelected.options[comunaSelected.selectedIndex].text	
		}
		

		const information = {
			...data,
			sucursal_id: document.getElementById("sucursal_id").value,
			comuna_id: document.getElementById("comuna_id").value,
			sucursal: text_sucursal,
			comuna: text_comuna,
			numero_cuenta: data?.numero_cuenta?.map(item => {
				return {
					idRow: item?.idRow,
					value: item?.value
				}
			})
		}
		
		if(isEdit){
			response = await dispatch(editCliente(information))
		}else{
			response = await dispatch(addCliente(information))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
			dispatch(listadoCliente())
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

    const cargarOficinas = (e, valor) => {

		const id =  (e !==undefined ) ? e.target.value : valor
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)

			}

			setOficinaOrigen(detComuna) 
		}
			
	}

	const removeCodeInternal = (index, value, id) => {
		Swal.fire({
			title: '¿Seguro desea eliminar el código interno del cliente?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		  }).then(async (result) => {
			if (result.isConfirmed) {
				if(id!=="")	{
					const response = await dispatch(deleteCodeByClient(value))	
					if(response.status){
						toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
						remove(index)  
					}else{
						toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
					} 
				}else{
					remove(index)  
				}				
			}
		  })
		
			
	}

	

	return (
		<Form id="frmCliente" onSubmit={handleSubmit(onSubmit)}>
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!isEdit ? 'CREAR CLIENTE' : 'EDITAR CLIENTE' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
					<Row>
						<Col lg={12} md={12} sm={12}>
							<Form.Group className="row-radio" >
								<Form.Label>El cliente tiene código interno:</Form.Label>
								<div className="ml-3">
									<Form.Check inline label="Si" name="group1" type="radio" id={`inline-si`} checked={isCodigo==="si" ? true : false} onChange={() => setIsCodigo("si")} />
									<Form.Check inline label="No" name="group1" type="radio" id={`inline-no`} checked={isCodigo==="no" ? true : false} onChange={() => setIsCodigo("no")} />
								</div>
								{
									isCodigo==="si" && (
										<Button variant="warning" type="button" disabled={isLoading} className="ml-5 btn-sm pl-3 pr-3"
											onClick={() => append({ value: "", idRow: "" })}
										>
											Agregar un nuevo código interno
										</Button>
									)
								}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						{
							vNumeroCuenta?.length>0 &&
							vNumeroCuenta?.map((item, index) => 
								<Col key={index} lg={6} md={6} sm={12}>
									<Form.Group controlId="codigo_interno">
										<Form.Label>Código interno {index+1}</Form.Label>
										<InputGroup>
											<Form.Control 
												type="text" 
												name={`numero_cuenta[${index}].value`}												
												ref={register}
												maxLength={5}
												defaultValue={item?.value}
												onKeyPress={(event) => validateOnlyNumber(event)}
												onPaste={(event) => onPaste(event)}
											/>
											<Form.Control 
												type="hidden" 
												name={`numero_cuenta[${index}].idRow`}												
												ref={register}
												maxLength={5}
												defaultValue={item?.idRow}
											/>
											<InputGroup.Append>
												<Button variant="danger" type="button" className="btn-sm pl-3 pr-3"
													onClick={() => removeCodeInternal(index, item?.value, item?.idRow)}
												>-</Button>
											</InputGroup.Append>
										</InputGroup>
										
										{errors.codigo_interno && (
											<Form.Text className="text-danger">
													{errors?.codigo_interno?.message}
											</Form.Text>
										)}
									</Form.Group>
								</Col>
							)
						}
					</Row>
					<Row>
						<Col lg={12}>
							<div className="line-bottom" />
						</Col>
					</Row>
			     	<Row>
						
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="rut" >
						    	<Form.Label>Rut</Form.Label>
								<Controller
									render={(props) => (
										<MaskedInput
											mask={RutTextMask}
											className="form-control"
											id="rut" 
											name="rut"
											value={props.value}
											maxLength={12}
											defaultValue={datos.rut || ''}
											//readOnly={isEdit ? true : false}
											onChange={(value)=>{
												props.onChange(value)
												setValue(value.target.value ?? "")
											}}
											onKeyPress={(event) => {
												if (!/[0-9Kk.-]/.test(event.key)) {
												  event.preventDefault();
												}
											}}
											//onBlur={(event) => handleBlurRut(event, setError)}
										/>
									)}
									control={control}
									name="rut"  
									rules={{required: {value: true, message: 'Requerido'}}}
								/>
						    	{/**<Form.Control 
								    	type="text" 
								    	name="rut" 
                                        ref={register}
										defaultValue={datos.rut || ''}
										readOnly={isEdit ? true : false}
								    /> */}
								{errors.rut && (
							    	<Form.Text className="text-danger">
							      		{errors?.rut?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
						<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="nombre_completo" >
					      		<Form.Label>Nombre y Apellido</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="nombre_completo" 
                                        ref={register({required: {value: true, message: 'Requerido'}})}
                                        defaultValue={datos.nombre_completo || ''}
										onKeyPress={(event) => validateOnlyLetter(event)}
										onPaste={(event) => onPaste(event)}
										maxLength={100}
								    />
								{errors.nombre_completo && (
							    	<Form.Text className="text-danger">
							      		{errors?.nombre_completo?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>	
					<Row>
					    <Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="telefono">
						    	<Form.Label>Teléfono</Form.Label>
						    	<Form.Control 
								    	type="text" 
								    	name="telefono" 
                                         defaultValue={datos.telefono || ''}
										 maxLength={9}
										ref={register({
											minLength: {value: 9, message: 'Mínimo 9 dígitos'},
											required: {value: true, message: 'Requerido'},
											//eslint-disable-next-line
											pattern: { value: /^([0-9]+)$/, message: 'Teléfono inválido'},
										})}
										autoComplete="off"
										onKeyPress={(event) => validateOnlyNumber(event)}
										onPaste={(event) => onPaste(event)}
								    />
								{errors.telefono && (
							    	<Form.Text className="text-danger">
							      		{errors?.telefono?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
						<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="email">
						    	<Form.Label>E-mail</Form.Label>
						    	<Form.Control 
								    	type="text" 
								    	name="email" 
										ref={register({
											//eslint-disable-next-line
											pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'},
											required: {value: true, message: 'Requerido'}
										})} 
                                         defaultValue={datos.email || ''}
										 onPaste={(event) => onPaste(event)}
								    />
								{errors.email && (
							    	<Form.Text className="text-danger">
							      		{errors?.email?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					</Row>		
					<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="sucursal_id">
						    	<Form.Label>Sucursal</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="sucursal_id" 
						                ref={register}
						                onChange={(event) => cargarOficinas(event, "")}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		sucursales.length > 0 && (
							    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
								{errors.sucursal_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.sucursal_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="comuna_id">
						    	<Form.Label>Comuna</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="comuna_id" 
						                ref={register}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		oficinaOrigen.length > 0 && (
							    			oficinaOrigen.map(origen => <option key={origen.id} value={origen.id}>{origen.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
								{errors.comuna_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.comuna_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					</Row>	
					<Row>
						<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="direccion" >
						    	<Form.Label>Dirección</Form.Label>
						    	<Form.Control 
								    	type="text" 
								    	name="direccion" 
                                        defaultValue={datos.direccion || ''}
										ref={register({required: {value: true, message: 'Requerido'}})}
								    />
								{errors.direccion && (
							    	<Form.Text className="text-danger">
							      		{errors?.direccion?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
						<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="rubro" >
					      		<Form.Label>Rubro</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="rubro" 
                                        ref={register}
                                        defaultValue={datos.rubro || ''}
										onKeyPress={(event) => validateOnlyLetterNumber(event)}
										onPaste={(event) => onPaste(event)}
										maxLength={50}
								    />
					    	</Form.Group>
					    </Col>					    
					</Row>
										
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : isEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form>
	)
}

export default Formulario