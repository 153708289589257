import API from "../config/api"
import {
	LISTADO_DATA_CA,
	SELECTED_ROWS_CA,
	IS_VISIBLE_FORMULARIO_CA,
	SEARCH_CA,
	DATA_ADD_CA,
	DATA_EDIT_CA,
	DATA_ACTIVAR_DESACTIVAR_CA,
	DATA_DELETE_CA
} from "../types/camionType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const listadoCamion = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/camions")
			dispatch(requestSuccess(LISTADO_DATA_CA, response.camions))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const listadoCamionOnlyActive = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.get("admin/camions/enabled")
	
			dispatch(requestSuccess(LISTADO_DATA_CA, response.camions))
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const getSelectedRowsCamion = (rows) => (dispatch, getState) => {
	let estatus = ""
	let title = ""

	if(rows.selectedCount>0){
		estatus = (rows.selectedRows[0].estatus===1) ? "Desactivar" : "Activar"
		title = (rows.selectedRows[0].estatus===1) ? "Desactivado" : "Activado"
	}
	const payload = {
		rows,
		btnActivar: {
			isVisible: rows.selectedCount>0 ? true : false,
			nameBtn: estatus,
			title 
		}
	}
	dispatch(requestSuccess(SELECTED_ROWS_CA, payload))
}

export const isVisibleFormularioCamion = (visible) => (dispatch, getState) => {

    const payload = {
		isForm: visible,
		isEdit: false,
		datos: {},
		btnActivar: {
			isVisible: false,
			nameBtn: "Desactivar",
			title: "Desactivado"
		}
	}

	if(payload){
	  	dispatch(requestSuccess(IS_VISIBLE_FORMULARIO_CA, payload))
	  	 
	}
}

export const searchPorIdCamion = (id) => (dispatch, getState) => {
	const {caListado} = getState().camions
	
    const payload = {
		seletected: caListado.find(ret => ret.id===id),
		isForm: true,
		isEdit: true
	}


	if(payload){
	  	dispatch(requestSuccess(SEARCH_CA, payload))
	  	 
	}
}

export const addCamion = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		  	let response = await API.post("admin/camions", body)

		    if(response.status){
				const cuenta = {
					...body, 
					id: response.id, 
					estatus: 1,
					name_estatus: "Activado"
				}
				const {caListado} = getState().camions
				let data = caListado;
				data.unshift(cuenta)
				const payload = { listado: data, isForm: false}

		        dispatch(requestSuccess(DATA_ADD_CA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const editCamion = (body, name_sucursal) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {caListado, caDatos} = getState().camions

		  	let response = await API.post(`admin/camions/${caDatos.id}`, body)

		    if(response.status){
				
				const data =caListado.map(camion => {
					if(camion.id === caDatos.id){
						return {
							...body, 
							id: caDatos.id, 
							estatus: camion.estatus,
							name_estatus: camion.name_estatus
						};
					}
					return camion
				})  
				const payload = { 
					listado: data, 
					isForm: false, 
					datos: {},
					btnActivar: {
						isVisible: false,
						nameBtn: "Desactivar",
						title: "Desactivado"
					}
				}	
				
		        dispatch(requestSuccess(DATA_EDIT_CA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const changeStatus = (id, opcion, status) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {caListado, caIsSelectedRows} = getState().camions

			const body = {
				type: "estatus",
				opcion: opcion.toLowerCase()
			}

		  	let response = await API.post(`admin/camions/${id}`, body)

		    if(response.status){
				
				const listado = caListado.map(camion => {
					
					if(camion.id === id){
						return {...camion, estatus: status, name_estatus: opcion};
					}
					return camion
				})  

				const payload = {
					rows: {
						...caIsSelectedRows,
						selectedRows: caIsSelectedRows.selectedRows.map(rows=> {
							return {
								...rows,
								estatus: status,
								name_estatus: opcion
							}
						})
					},
					btnActivar: {
						isVisible: true,
						nameBtn: (status===1) ? "Desactivar" : "Activar"
					},
					listado
				}

		        dispatch(requestSuccess(DATA_ACTIVAR_DESACTIVAR_CA, payload))
			}

	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const deleteCamion = (id) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {caListado} = getState().camions

		  	let response = await API.get(`admin/camions/delete/${id}`)

		    if(response.status){
				
				const payload =caListado.filter(rol => rol.id!==id) 

		        dispatch(requestSuccess(DATA_DELETE_CA, payload))
			}
	      resolve({ status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}