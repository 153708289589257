const User = { 
	columnas: [
		{
			name: 'Nombre y Apellido',
			selector: (row, i) => row.fullName,
			sortable: true
		},
		{
			name: 'Comuna',
			selector: (row, i) => row.comuna,
			sortable: true
		},
		{
			name: 'Sucursal',
			selector: (row, i) => row.sucursal,
			sortable: true
		},
		{
			name: 'Rol',
			selector: (row, i) => row.nombre_rol,
			sortable: true
		},
		{
			name: 'E-mail',
			selector: (row, i) => row.email,
			sortable: true
		},
		{
			name: 'Estatus',
			selector: (row, i) => row.name_status,
			sortable: false
		}
	]
}

export default User