import { 
	CONFIG_INICIAL
} from "../types/configType"

const INITIAL_STATE = {
	configuracion: []
}

const configReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case CONFIG_INICIAL:
			return {
				...state,
				configuracion: action.payload
			}

		default: return state
	}
}

export default configReducer