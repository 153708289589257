import React, { useState } from "react"
import {  
	Form,
	Button,
	Col,
	Row
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { searchOrden } from "../../actions/orderAction"
import { useHistory } from 'react-router-dom'
import { onPaste, validateOnlyOrden } from '../../utils/validationInput';
import { ToastContainer, toast } from 'react-toastify'

const SearchOrden = () => {

	const { register, handleSubmit, errors, setValue } = useForm()
	const dispatch = useDispatch()
	const [isLoading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = async (data, e) => {
    	e.preventDefault()
    	setLoading(true)
		
		if(data.numero_orden!==""){
			let ordenFormat = data.numero_orden?.replace("'", "-")
			setValue("numero_orden", ordenFormat)
		}

    	const response = await dispatch(searchOrden(data))

    	if(response.status){
    		setLoading(false)
    		history.push(`/orders/${response.numero}/edit`)
    	}else{
    		setLoading(false)
    		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
    	}
    }

	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
			  <Form.Group as={Row} controlId="numero_orden" className="pt-2">
			    <Form.Label column sm="2" className="fs-18">Consultar OT</Form.Label>
			    <Col sm="7">
				      <Form.Control 
					    	type="text" 
					    	name="numero_orden" 
			                autoFocus
			                className="form-control-search"
			                ref={register({required: {value: true, message: 'Requerido'}})}
			                placeholder="Ingresa el número de orden"
							onKeyPress={(event) => validateOnlyOrden(event)}
							onPaste={(event) => onPaste(event)}
							maxLength={15}
					    />
					    {errors.numero_orden && (
					    	<Form.Text className="text-danger">
					      		{errors?.numero_orden?.message}
					    	</Form.Text>
					    )}
			    </Col>
			    <Col sm="3">
			      <Button variant="warning" block type="submit">
			      	{isLoading ? 'Buscando...' : 'VER OT'}
			      </Button>
			    </Col>
			  </Form.Group>

			</Form>

			<ToastContainer />
		</>
	)
}

export default SearchOrden