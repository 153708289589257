import { useState, useEffect } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addCuenta, editCuenta } from "../../../actions/cuentaDepositoAction"
import { validateOnlyOrdenPackage, onPaste, validateOnlyLetter } from "../../../utils/validationInput"

const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors, setValue } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	
	const { ctaDeposito, orders  } = useSelector((state) => {
		return {
			ctaDeposito: state.ctaDeposito,
			orders: state.orders
		}
	})

	const { cIsEdit } = ctaDeposito
	const { sucursales } = orders

	useEffect(() => {
		if(Object.keys(datos).length>0){
			setValue("sucursal_id", datos.sucursal_id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
    const onSubmit = async (data, e) => {
		setIsLoading(true)
		
		let response 

		const sucursalSelected = document.getElementById("sucursal_id")
		const text_sucursal = sucursalSelected.options[sucursalSelected.selectedIndex].text

		if(cIsEdit){
			response = await dispatch(editCuenta(data, text_sucursal))
		}else{
			response = await dispatch(addCuenta(data, text_sucursal))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

	return (
		<Form id="frmCuenta" onSubmit={handleSubmit(onSubmit)}>
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!cIsEdit ? 'CREAR CUENTA' : 'EDITAR CUENTA' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
			     	<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="sucursal_id">
						    	<Form.Label>Sucursal</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="sucursal_id" 
						                ref={register}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		sucursales.length > 0 && (
							    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
								{errors.sucursal_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.sucursal_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="nombre_banco" >
						    	<Form.Label>Banco</Form.Label>
						    	<Form.Control 
								    	type="text" 
								    	name="nombre_banco" 
                                        ref={register}
										maxLength={100}
										defaultValue={datos.nombre_banco || ''}
										onKeyPress={(event) => validateOnlyLetter(event)}
										onPaste={(event) => onPaste(event)}
								    />
								{errors.nombre_banco && (
							    	<Form.Text className="text-danger">
							      		{errors?.nombre_banco?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					</Row>	
					<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="numero_cuenta" >
					      		<Form.Label>Nro de cuenta</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="numero_cuenta" 
                                        ref={register}
										maxLength={32}
                                        defaultValue={datos.numero_cuenta || ''}
										onKeyPress={(event) => validateOnlyOrdenPackage(event)}
										onPaste={(event) => onPaste(event)}
								    />
								{errors.numero_cuenta && (
							    	<Form.Text className="text-danger">
							      		{errors?.numero_cuenta?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    
					</Row>					
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : cIsEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form> 
	)
}

export default Formulario