import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { changePassword, isVisibleFormularioUser } from "../../../actions/userAction"
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";


const Clave = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors, getValues } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ typeInput, setTypeInput ] = useState(false)
	const [ typeInputRep, setTypeInputRep ] = useState(false)
    
    const onSubmit = async (data, e) => {
		setIsLoading(true)

		let response = await dispatch(changePassword(data))
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            dispatch(isVisibleFormularioUser(false))
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }


	return (
            <Form id="frmClave" onSubmit={handleSubmit(onSubmit)}>
            	<Card border="Light">
				    <Card.Header as="h6" className="bg-warn-blue text-dark">CAMBIAR CONTRASEÑA</Card.Header>
				    <Card.Body className="bg-soft-blue padding-user">
				    	<Row>
				     		<Col lg={6} md={6} sm={12}>
						      	<Form.Group controlId="fullName" >
						      		<Form.Label>Nombre y Apellido</Form.Label>
						      		<Form.Control 
									    	type="text" 
									    	name="fullName" 
							                defaultValue={datos?.fullName || ""}
							             	disabled
									    />
						    	</Form.Group>
						    </Col>
						    <Col lg={6} md={6} sm={12}>
						    	<Form.Group controlId="email" >
						      		<Form.Label>E-mail</Form.Label>
						      		<Form.Control 
									    	type="text" 
									    	name="email" 
							                defaultValue={datos?.email || ""}
							             	disabled
									    />
						    	</Form.Group>
						    </Col>
						</Row>
						<Row>
				     		<Col lg={6} md={6} sm={12}>
						      	<Form.Group controlId="password" >
						      		<Form.Label>Nueva Contraseña</Form.Label>
										<div className="mb-3 p-relative">
										<Form.Control 
											type={!typeInput ? "password" : "text" } 
											name="password" 
											ref={register({ required: { value: true, message: 'Requerido' } })}
											maxLength={20}
										/>
										<div
											className="visible-password" 
											onClick={() => setTypeInput(!typeInput) }
										>
											{ !typeInput ? <BiHide style={{fontSize: '20px'}} /> : <BiShow  style={{fontSize: '20px'}} /> }
										</div>
									</div>
									{errors.password && (
								    	<Form.Text className="text-danger">
								      		{errors?.password?.message}
								    	</Form.Text>
								    )}
						    	</Form.Group>
						    </Col>
						    <Col lg={6} md={6} sm={12}>
						    	<Form.Group controlId="repPassword" >
						      		<Form.Label>Repetir Contraseña</Form.Label>
									  <div className="mb-3 p-relative">
										<Form.Control 
											type={!typeInputRep ? "password" : "text" } 
											name="repPassword" 
							             	ref={register({
							             		required: {value: true, message: 'Requerido'},
							             		validate: value => {
										            if (value === getValues()["password"]) {
										              return true;
										            } else {
										              return "Las claves no coinciden";
										            }
										          }
							             	})}
											maxLength={20}
										/>
										<div
											className="visible-password" 
											onClick={() => setTypeInputRep(!typeInputRep) }
										>
											{ !typeInputRep ? <BiHide style={{fontSize: '20px'}} /> : <BiShow  style={{fontSize: '20px'}} /> }
										</div>
									</div>
									{errors.repPassword && (
								    	<Form.Text className="text-danger">
								      		{errors?.repPassword?.message}
								    	</Form.Text>
								    )}
						    	</Form.Group>
						    </Col>
						</Row>
				    </Card.Body>
				</Card>
			   <Row>
			   	<Col xs={12} className="text-right mt-3">
					<Form.Group as={Col}>
				    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
						    {isLoading ? 'Procesando...' : 'Modificar'  }
						</Button>
				    </Form.Group>
				</Col>
			   </Row>
            </Form>
	)
}

export default Clave