import { useEffect } from 'react'
import {  
	Card
} from 'react-bootstrap'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Oficinas/Listado"
import Formulario from "../../components/Admin/Oficinas/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoOficina } from "../../actions/oficinaAction"


const Oficina = ({toast}) => {
	
	const dispatch = useDispatch()
	const { oficinas, sucursales } = useSelector((state) => {
		return {
			oficinas: state.oficinas,
			sucursales: state.sucursales
		}
	})

	const { ofListado, ofIsCargando, ofDatos, ofIsForm } = oficinas
	const { suDatos } = sucursales

	useEffect(() => {
		dispatch(listadoOficina())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
	    	<Card className="mt-3 card-retiro shadow-sm">
			  <Card.Header as="h6" className="card-header-white">{`SUCURSAL: ${suDatos.nombre}` }</Card.Header>
			</Card>

			<CardRetiro title={!ofIsForm ? "Listado de Oficinas" : "Oficina" }>
				{!ofIsForm ? (
					<Listado data={ofListado} isCargando={ofIsCargando} toast={toast} />
				): (
					<Formulario toast={toast} datos={ofDatos} />	
				)}
		    				    
			</CardRetiro>
	    </>
	)
}

export default Oficina