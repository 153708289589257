import React, { useState } from 'react'
import {  
	Col,
	Form,
	Button
} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { searchPorId, deleteCliente, isVisibleFormularioCliente, changeStatus } from "../../../actions/clienteAction"

import Swal from 'sweetalert2'
import Nuevo from "../../../assets/images/configurar/plus.png"
import Editar from "../../../assets/images/configurar/edit.png"
import Eliminar from "../../../assets/images/configurar/delete.png"
import SwitchToogle from "../../Layouts/SwitchToogle"
import { validateOnlyLetter , onPaste } from "../../../utils/validationInput"

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {

	const dispatch = useDispatch()
	const { isSelectedRows, cliBtnActivar } = useSelector((state) => state.cliente)
	const [ checked, setChecked ] = useState(false)

	const handleClick = () => {
		if(isSelectedRows.selectedCount===1){
            let rut = isSelectedRows.selectedRows[0].rut
            dispatch(searchPorId(rut))
		}else if(isSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleDelete = () => {
		if(isSelectedRows.selectedCount===1){
            
            Swal.fire({
			  title: '¿Desea eliminar el cliente?',
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Sí',
			  cancelButtonText: 'No'
			}).then(async (result) => {
			  if (result.isConfirmed) {
			  	let id = isSelectedRows.selectedRows[0].id
            	const response = await dispatch(deleteCliente(id))
            	if(response.status){
            		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            	}else{
            		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            	}			    
			  }
			})
			setClearTable(true)
		}else if(isSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleAdd = () => {
		dispatch(isVisibleFormularioCliente(true))
	}

	const handleActivarDes = () => {
		if(isSelectedRows && isSelectedRows.selectedCount===1){
            const nombre = cliBtnActivar.nameBtn.toLowerCase()
            const status = (isSelectedRows.selectedRows[0].is_activo===1) ? 0 : 1
            const statusName = (isSelectedRows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
            Swal.fire({
			  title: `¿Desea ${nombre} el cliente?`,
			  icon: 'warning',
			  showCancelButton: true,
			  confirmButtonColor: '#3085d6',
			  cancelButtonColor: '#d33',
			  confirmButtonText: 'Sí',
			  cancelButtonText: 'No'
			}).then(async (result) => {
			  if (result.isConfirmed) {
			  	let id = isSelectedRows.selectedRows[0].id
            	const response = await dispatch(changeStatus(id, statusName, status))
            	setChecked(response.status)
            	if(response.status){
            		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            		//setClearTable(true)
            	}else{
            		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            	}			    
			  }
			  setClearTable(false)
			})
			
		}else if(isSelectedRows && isSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}

	}


	return (
		<>
		<Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
			<Col>
				<Col lg={6} className="pl-0 pr-0">
			
			      		<Form.Control 
						    	id="search" 
						    	type="text" 
						    	placeholder="Ingresa el Nombre del Cliente" 
						    	aria-label="Ingresa el Nombre del Cliente" 
						    	value={filterText} 
						    	onChange={onFilter}
								onKeyPress={(event) => validateOnlyLetter(event)}
								onPaste={(event) => onPaste(event)}
						    />

		    	</Col>
		    	<span className="fs-12">**Nombre del cliente se utiliza para la búsqueda y modificación de los datos</span>	
			</Col>
	    	<Col className="text-right">
		    	<Form.Group>
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleAdd(event)}>
					    <img src={Nuevo} alt="Nuevo" className="img-conf" />
					</Button>	
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleClick(event)}>
					    <img src={Editar} alt="Editar" className="img-conf" />
					</Button>	
                    <Button variant="warning" type="button" onClick={(event) => handleDelete(event)}>
					    <img src={Eliminar} alt="Eliminar" className="img-conf" />
					</Button>	
					{cliBtnActivar.isVisible && (
						<SwitchToogle handleActivarDes={handleActivarDes} checked={checked} coBtnActivar={cliBtnActivar} setChecked={setChecked} />	
						)
					}	
			    </Form.Group>
		    </Col>
	    </Col>

	</>
	)
} 

export default Filter