import Descargar from './Descargar.jsx'
import { formatMonto } from "../../../config/formatInput"

const Columns = { 
	columnas: [
		{
			name: 'Número documento',
			selector: 'numero_documento',
			sortable: true
		},
		{
			name: 'Cliente',
			selector: 'cliente',
			sortable: true
		},
		{
			name: 'Fecha Documento',
			selector: 'fecha_documento',
			sortable: true
		},
		{
			name: 'Traslado',
			selector: 'traslado',
			sortable: true
		},
		{
			name: 'Despacho',
			selector: 'despacho',
			sortable: true
		},
		{
			name: 'Total',
			selector: 'total',
			sortable: true,
			cell: row =>  <span>{formatMonto(row.total)}</span>,
			right: true
		},
		{
			name: '',
			sortable: true,
			center: true,
			cell: row =>  <Descargar {...row} />,
			width: "60px"
		},
	]
}

export default Columns