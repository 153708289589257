import React, { useEffect } from "react"
import {  
	Row,
	Button,
	Col
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import { Link, withRouter } from 'react-router-dom'
import { useSelector } from "react-redux"


const baseURL = process.env.REACT_APP_API_URL 

const PreviewRendir = (props) => {

	const { busqueda  } = useSelector((state) => state.rendir)
	const { desde, hasta, sucursal_id } = busqueda


	useEffect(() => {
		if(Object.keys(busqueda).length===0){
			props.history.push("/rendir/sucursal")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (

	    <Layout 
			titlePanel="Rendición de caja"
	    	modulo="cerrar_caja"
	    >
	    	 <Row>
				<Col xs={12} className="mb-3 mt-3 text-right">
					<Link to="/rendir/sucursal"> 
			  			<Button variant="warning" type="button">
				    		Regresar
				  		</Button>
			  		</Link>					
				</Col>
				<Col xs={12}>
			    <div className="embed-responsive embed-responsive-16by9">
				  <iframe title="Preview Caja" className="embed-responsive-item" src={`${baseURL}/rendir/print/${desde}/${hasta}/${sucursal_id}`} width="100%" height="300px"></iframe>
				</div>
				</Col>
			</Row>
					    	
	    </Layout>
	)
}

export default withRouter(PreviewRendir)