import React, { useState } from 'react'
import {  
	Col,
	Form,
	Button
} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { searchPorId, deleteUser, isVisibleFormularioUser, changeStatus } from "../../../actions/userAction"

import Swal from 'sweetalert2'
import Nuevo from "../../../assets/images/configurar/plus.png"
import Editar from "../../../assets/images/configurar/edit.png"
import Eliminar from "../../../assets/images/configurar/delete.png"
import Padlock from "../../../assets/images/configurar/padlock.png"
import VehicleIcon from "../../../assets/images/configurar/vehicle.png"
import SwitchToogle from "../../Layouts/SwitchToogle"
import { validateOnlyLetter, onPaste } from "../../../utils/validationInput"


const Filter = ({ filterText, onFilter, toast, setClearTable }) => {

	const dispatch = useDispatch()
	const { uIsSelectedRows, uBtnActivar, email } = useSelector((state) => state.users)
	const [ checked, setChecked ] = useState(false)

	const handleClick = () => {
		if(uIsSelectedRows.selectedCount===1){
            let id = uIsSelectedRows.selectedRows[0].id
			const statusArr = {
				isForm: true,
				isEdit: true,
				isClave: false,
				isVehicle: false
			}
            dispatch(searchPorId(id, statusArr))
		}else if(uIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleDelete = () => {
		if(uIsSelectedRows.selectedCount===1){
            if(email!==uIsSelectedRows.selectedRows[0].email){
            	Swal.fire({
				  title: '¿Desea eliminar el usuario?',
				  icon: 'warning',
				  showCancelButton: true,
				  confirmButtonColor: '#3085d6',
				  cancelButtonColor: '#d33',
				  confirmButtonText: 'Sí',
				  cancelButtonText: 'No'
				}).then(async (result) => {
				  if (result.isConfirmed) {
				  	let id = uIsSelectedRows.selectedRows[0].id
	            	const response = await dispatch(deleteUser(id))
	            	if(response.status){
	            		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
	            		setClearTable(true)
	            	}else{
	            		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
	            	}			    
				  }
				})
            }else{
            	toast.error('El mismo usuario no se puede eliminar', {position: toast.POSITION.TOP_RIGHT})
				return false
            }           
		}else if(uIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleAdd = () => {
		dispatch(isVisibleFormularioUser(true))
	}

	const handleClave = () => {
		if(uIsSelectedRows.selectedCount===1){
            let id = uIsSelectedRows.selectedRows[0].id
			const statusArr = {
				isForm: true,
				isEdit: true,
				isClave: true,
				isVehicle: false
			}
            dispatch(searchPorId(id, statusArr))
		}else if(uIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleActivarDes = (event) => {
		if(uIsSelectedRows.selectedCount===1){
			if(email!==uIsSelectedRows.selectedRows[0].email){
	            const nombre = uBtnActivar.nameBtn.toLowerCase()
	            const status = (uIsSelectedRows.selectedRows[0].is_activo===1) ? 0 : 1
	            const statusName = (uIsSelectedRows.selectedRows[0].is_activo===1) ? "Desactivado" : "Activado"
	            Swal.fire({
				  title: `¿Desea ${nombre} el usuario?`,
				  icon: 'warning',
				  showCancelButton: true,
				  confirmButtonColor: '#3085d6',
				  cancelButtonColor: '#d33',
				  confirmButtonText: 'Sí',
				  cancelButtonText: 'No'
				}).then(async (result) => {
				  if (result.isConfirmed) {
				  	let id = uIsSelectedRows.selectedRows[0].id
	            	const response = await dispatch(changeStatus(id, statusName, status))
	            	setChecked(response.status)
	            	if(response.status){
	            		toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
	            		//setClearTable(true)
	            	}else{
	            		toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
	            	}	
				  }
				  setClearTable(false)
				})
			}else{
				toast.error('El mismo usuario no se puede eliminar', {position: toast.POSITION.TOP_RIGHT})
				return false
			}			
		}else if(uIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}

	const handleAssignVehicle = (event) => {
		if(uIsSelectedRows.selectedCount===1){
            let id = uIsSelectedRows.selectedRows[0].id
			if(uIsSelectedRows.selectedRows[0].nombre_rol!=="Conductor"){
				toast.error('Solo se puede asignar vehículo a usuario con rol Conductor', {position: toast.POSITION.TOP_RIGHT})
				return false
			}else{
				const statusArr = {
					isForm: true,
					isEdit: true,
					isClave: false,
					isVehicle: true
				}
				dispatch(searchPorId(id, statusArr))
			}
		}else if(uIsSelectedRows.selectedCount>0){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}else{
			toast.error('Debe seleccionar una fila', {position: toast.POSITION.TOP_RIGHT})
			return false
		}
	}


	return (
		<>
		<Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
			<Col>
				<Col lg={6} className="pl-0 pr-0">
			
			      		<Form.Control 
						    	id="search" 
						    	type="text" 
						    	placeholder="Ingresa el nombre o apellido del usuario" 
						    	aria-label="Ingresa el nombre o apellido del usuario" 
						    	value={filterText} 
						    	onChange={onFilter}
								onKeyPress={(event) => validateOnlyLetter(event)}
								onPaste={(event) => onPaste(event)}
						    />

		    	</Col>
		    	<span className="fs-12">**Nombre o apellido del usuario se utiliza para la búsqueda y modificación de los datos</span>	
			</Col>
	    	<Col className="text-right">
		    	<Form.Group>
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleAdd(event)}>
					    <img src={Nuevo} alt="Nuevo" className="img-conf" />
					</Button>	
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleClick(event)}>
					    <img src={Editar} alt="Editar" className="img-conf" />
					</Button>	
                    <Button variant="warning" type="button" className="mr-2" onClick={(event) => handleDelete(event)}>
					    <img src={Eliminar} alt="Eliminar" className="img-conf" />
					</Button>
					<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleClave(event)}>
					    <img src={Padlock} alt="Cambiar Clave" className="img-conf" /> 
					</Button>	
					<Button variant="warning" className="btn-assign" type="button" onClick={(event) => handleAssignVehicle(event)}>
					    <img src={VehicleIcon} alt="Asignar Vehículo" className="img-assign" /> 
					</Button>	
					{uBtnActivar.isVisible && (
						<SwitchToogle handleActivarDes={handleActivarDes} checked={checked} coBtnActivar={uBtnActivar} setChecked={setChecked} />	
						)
					}
			    </Form.Group>
		    </Col>
	    </Col>

	</>
	)
} 

export default Filter