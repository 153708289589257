import { useState, useMemo } from 'react'
import DataTable from 'react-data-table-component'

import Filter from './Filter'
import correlativo from '../Columnas/Correlativo'
import Progress from '../../Layouts/Progress'
import { useDispatch } from "react-redux"
import { getSelectedRowsCorrelativo } from "../../../actions/correlativoAction"

const Listado = ({ data, isCargando, toast, corIsSelectedRows }) => {

	const columnas = correlativo.columnas
	const dispatch = useDispatch()
	const [clearTable, setClearTable ] = useState(false)

	const paginationOptions = {
		rowsPerPageText: 'Filas por Página',
		rangeSeparatorText:'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	}

	const [filterText, setFilterText] = useState('')
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
	const filteredItems = data.filter(item => item.title_modulo && item.title_modulo.toLowerCase().includes(filterText.toString()?.toLowerCase()));

	const subHeaderComponentMemo = useMemo(() => {
    	const handleClear = () => {
	      	if (filterText) {
	        	setResetPaginationToggle(!resetPaginationToggle);
	        	setFilterText('');
	      	}
	    }

		return <Filter onFilter={e => setFilterText(e.target.value)}  onClear={handleClear} filterText={filterText} toast={toast} setClearTable={setClearTable} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, resetPaginationToggle])

	const handleChange = state => {

		if(state.selectedCount===1){
			dispatch(getSelectedRowsCorrelativo(state))
		}
		if(state.selectedCount>1){
			dispatch(getSelectedRowsCorrelativo({selectedCount:0}))
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
		}
   }


	return (
		<div className="table-component table-correlativo">
			<DataTable 
				columns={columnas}
				data={filteredItems}
			    pagination
			    paginationResetDefaultPage={resetPaginationToggle}
			    subHeader
			    paginationComponentOptions={paginationOptions}
      			subHeaderComponent={subHeaderComponentMemo}
      			persistTableHead
      			dense
      			progressPending={isCargando}
      			progressComponent={<Progress />}
				noDataComponent={<div>No hay datos registros disponible.</div>}
				selectableRows
          		onSelectedRowsChange={handleChange}
          		clearSelectedRows={clearTable || corIsSelectedRows?.selectedCount===0 ? true : false}
          		selectableRowsHighlight={true}
			/>
		</div>
	)
}

export default Listado