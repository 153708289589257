import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Conductores/Listado"
import Formulario from "../../components/Admin/Conductores/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoConductor } from "../../actions/conductorAction"


const Conductor = ({toast}) => {
	
	const dispatch = useDispatch()
	const { coListado, coIsCargando, coDatos, coIsForm } = useSelector((state) => state.conductores)

	useEffect(() => {
		dispatch(listadoConductor())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!coIsForm ? "Listado de Conductores" : "Conductor" }>
				{!coIsForm ? (
					<Listado datos={coListado} isCargando={coIsCargando} toast={toast} />
				): (
					<Formulario toast={toast} datos={coDatos} />	
				)}
		    				    
			</CardRetiro>
	    </>
	)
}

export default Conductor