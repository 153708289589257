import { 
	INICIO_DATA
} from "../types/homeType"

const INITIAL_STATE = {
	pendiente: 0,
	realizado: 0,
	count: 0
}

const homeReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case INICIO_DATA:
			return {
				...state,
				pendiente: action.payload.countPendiente,
				realizado: action.payload.countRetirados,
				count: action.payload.count
			}

		default: return state
	}
}

export default homeReducer