import { useState, useEffect } from "react"
import { getOrdenReceive, getHome } from "../../actions/orderAction"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { getFechaFormat } from "../../utils/formatFecha"
import { toast } from 'react-toastify'
const defaultValues = {
  fecha_recepcion: new Date(),
  sucursal_search_id: ""
};

export const useReceiveOrder =  () => {

	const dispatch = useDispatch()
	const [ isBuscar, setIsBuscar ] = useState(false)
	const { handleSubmit, control, register, setValue, getValues } = useForm({defaultValues})
	const { isLoadingReceive, listOrdensReceive, infoReceive, sucursales } = useSelector((state) => state.orders)
	const [pageOffset, setPageOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0)

	const onSubmit = async (data, e) => {
		await getListOrdenByFilter(data, 0)
	}

	const getListOrdenByFilter = async (data, page) => {
		setIsBuscar(true)

		if(data?.sucursal_search_id===""){
			setIsBuscar(false)
			toast.error("Debe seleccionar la sucursal", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		if(data?.fecha_recepcion===null){
			setIsBuscar(false)
			toast.error("Debe seleccionar la fecha", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		const body = {
			fecha_recepcion: getFechaFormat(data?.fecha_recepcion),
			sucursal_id: data?.sucursal_search_id
		}

		const response = await dispatch(getOrdenReceive(body, page+1))
		if(!response.status){
			toast.error("No hay registros disponibles", {position: toast.POSITION.TOP_RIGHT})
		}else{
			setPageCount(response?.countPaginate ?? 0)
			setPageOffset(page)
		}
		setIsBuscar(false)
	}

	const getDataInitial = async () => {
		await dispatch(getHome())
	}

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handlePageChange = async (event) => {
	
		// TODO Only change displayed selected page
		// when its content is loaded in useEffect.
		setPageOffset(event.selected);
  
		const data = getValues()
		await getListOrdenByFilter(data, event.selected);
	  };

	return {
		listOrdensReceive,
		isLoadingReceive,
		onSubmit,
		handleSubmit,
		control,
		isBuscar,
		infoReceive,
		sucursales,
		register,
		setValue,
		handlePageChange,
		pageCount,
		pageOffset
	}
}