import React, { useState, Fragment, useEffect, forwardRef } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import { useSelector } from "react-redux"
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import ModalListado from "./Estados/ModalListado"
import { usePermission } from "../../hooks/Permission/usePermission"

import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"
import dayjs from 'dayjs';
import { toast } from 'react-toastify'
import { isAfter, getDateYMD } from '../../utils/formatFecha';
import { onPaste, validateOnlyOrden } from '../../utils/validationInput';
import { BiCalendar } from "react-icons/bi";

registerLocale('es', es)

const Sucursales = ({ register, errors, reset, initial, ordenInfo, getValues, control,editOrden, setValue }) => {

	const { validarPermiso } = usePermission()
	const [oficinaOrigen, setOficinaOrigen ] = useState([])
	const [oficinaDestino, setOficinaDestino ] = useState([])

	

	const { users, orders  } = useSelector((state) => {
		return {
			users: state.users,
			orders: state.orders
		}
	})
	const { sucursales, tipo_envio, disabledTracking  } = orders
	const { sucursal_id  } = users


	const [showIsModal, setShowIsModal ] = useState(false)

	const cargarOficinas = (e, type, valor) => {

		const id =  (e !==undefined ) ? e.target.value : valor
		if(id!==""){
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			let comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []

			let detComuna = comunas
			if(comunas.length>0){
				detComuna = comunas.filter(c=>c.is_activo!==0)

			}

			if(type === "origen") {
		    	setOficinaOrigen(detComuna) 
		    }else if(type === "destino") {
		    	setOficinaDestino(detComuna)
		    }
		}
			
	}

	useEffect(() => {
	
		if(Object.keys(ordenInfo).length > 0 && initial===false && ordenInfo.sucursal_origen_id!=="" && ordenInfo.sucursal_destino_id!==""){

			cargarOficinas(undefined, "origen", ordenInfo.sucursal_origen_id)
			cargarOficinas(undefined, "destino", ordenInfo.sucursal_destino_id)
			reset({
			  ...getValues(),
			  sucursal_origen_id: ordenInfo.sucursal_origen_id,
			  oficina_origen_id: ordenInfo.oficina_origen_id,
			  sucursal_destino_id: ordenInfo.sucursal_destino_id,
			  oficina_destino_id: ordenInfo.oficina_destino_id,
			  forma_pago_id: ordenInfo.forma_pago_id,
			  documentacion_id: ordenInfo.documentacion_id,
			  tipo_envio_id: ordenInfo.tipo_envio_id,
			  documentacion_id_pago: ordenInfo?.documentacion_id_pago,
			  descuento: ordenInfo.descuento || "No"
			})
		}else{
			if(sucursal_id){
				
				cargarOficinas(undefined, "origen", sucursal_id)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales])

	const handleCloseModal = () => {
		setShowIsModal(false)
	}

	const showEstado = (event) => {
		event.preventDefault()
		setShowIsModal(true)
	}

	const handleBlur= (event) => {
		setValue("numero_orden", event.target.value.trim().replaceAll(" ", ""))
	}
	
	const validateDateBlur = (props) => {

		const { message, error, dateSelected } = getDateYMD(document.querySelector("#fecha_orden").value)

		if(error){
			toast.error(message, {position: toast.POSITION.TOP_RIGHT})
			props.onChange(new Date())
			return false
		}

		if (isAfter(dateSelected, dayjs())) {
			toast.error('La fecha ingresada no puede ser superior a la fecha actual', {position: toast.POSITION.TOP_RIGHT})
			props.onChange(new Date())
			return false
        }

		const yearLast = dayjs(dateSelected).year()
		const yearCurrent = dayjs().year()

		if(yearCurrent!==yearLast){
			toast.error('No puede seleccionar fechas anteriores', {position: toast.POSITION.TOP_RIGHT})
			props.onChange(new Date())
			return false
		}
	}

	const restMounth = () => {
		let d = new Date();
		d.setMonth(d.getMonth() - 1);
		return d;
	}

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<Fragment>
			<Row className="bg-gray panel-contenedor--info mb-0 pt-1">
				<Col xs={12}>
					<Form.Row>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="sucursal_origen_id" className="pr-0 mb-0">
						    	<Form.Label>Ciudad de Origen</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	onChange={(event) => cargarOficinas(event, "origen", "")}
								    	name="sucursal_origen_id" 
						                ref={register({required: {value: true, message: 'Requerido'}})}
						                disabled={!validarPermiso("cambiar_sucursal")}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		sucursales.length > 0 && (
							    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.sucursal_origen_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.sucursal_origen_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					      	<Form.Group as={Col} controlId="numero_orden" className="pr-1 pl-0 mb-0">
					      		<Form.Label>O. Manual N°</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="numero_orden" 
						                autoFocus
						                defaultValue={ordenInfo.numero_orden}
						                autoComplete="off"
										readOnly={editOrden}
										maxLength={20}
						                ref={register({required: {value: true, message: 'Requerido'}})}
										onBlur={(event)=>handleBlur(event)}
										onKeyPress={(event) => validateOnlyOrden(event)}
										onPaste={(event) => onPaste(event)}
								    />
							    {errors.numero_orden && (
							    	<Form.Text className="text-danger">
							      		{errors?.numero_orden?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="oficina_origen_id" className="pr-1 pl-0 mb-0">
						    	<Form.Label>Oficina de Origen</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="oficina_origen_id" 
						                ref={register({required: {value: true, message: 'Requerido'}})}
						                disabled={!validarPermiso("cambiar_sucursal")}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		oficinaOrigen.length > 0 && (
							    			oficinaOrigen.map(origen => <option key={origen.id} value={origen.id}>{origen.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.oficina_origen_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.oficina_origen_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="sucursal_destino_id" className="pr-1 pl-0 mb-0">
						    	<Form.Label>Sucursal destino</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="sucursal_destino_id"
								    	onChange={(event) => cargarOficinas(event, "destino", "")} 
						                ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		sucursales.length > 0 && (
							    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.sucursal_destino_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.sucursal_destino_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="oficina_destino_id" className="pl-0 mb-0">
						    	<Form.Label>Comuna destino</Form.Label>
						    	<Form.Control 
								    	as="select"
								    	name="oficina_destino_id" 
						                ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		oficinaDestino.length > 0 && (
							    			oficinaDestino.map(destino => <option key={destino.id} value={destino.id}>{destino.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.oficina_destino_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.oficina_destino_id?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					</Form.Row>

					<Form.Row className="mt-1">
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="fecha_orden" className="pr-0 mb-0">
						    	<Form.Label>Fecha Orden</Form.Label>
								<Controller
						            control={control}
						            name="fecha_orden"
						            render={(props) => {
										return (
											<DatePicker
											  dateFormat="dd/MM/yyyy"
											  placeholderText="Select date"
											  onChange={(event) => {
												  props.onChange(event)
											  }}
											  selected={props.value}
											  className="form-control"
											  readOnly={!validarPermiso("cambiar_sucursal")}
											  id="fecha_orden"
											  maxDate={new Date()}
											  minDate={restMounth()}
											  locale="es"
											  onCalendarClose={() => validateDateBlur(props, props.value)}
											  autoComplete="off"
											  customInput={<ExampleCustomInput />}
											/>
										  )
									}}
						          />
								{errors.fecha_orden && (
							    	<Form.Text className="text-danger">
							      		{errors?.fecha_orden?.message}
							    	</Form.Text>
							    )}
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					      	<Form.Group as={Col} controlId="tipo_envio_id" className="pr-1 pl-0 mb-0">
					      		<Form.Label>Entrega en</Form.Label>
					      		<Form.Control 
								    	as="select"
								    	name="tipo_envio_id" 
						                ref={register({required: {value: true, message: 'Requerido'}})}
								    >
							    	<option value="">Seleccione</option>
							    	{
							    		tipo_envio.length > 0 && (
							    			tipo_envio.map(entrega => <option key={entrega.id} value={entrega.id}>{entrega.nombre}</option>)
							    		)
							    	}
							 	</Form.Control>
							 	{errors.tipo_envio_id && (
							    	<Form.Text className="text-danger">
							      		{errors?.tipo_envio_id?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="user_name" className="pr-1 pl-0 mb-0">
						    	<Form.Label>Usuario</Form.Label>
						    	<Form.Control type="text" name="user_name" defaultValue={ordenInfo.user_name} readOnly />
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="estado_orden" className="pr-1 pl-0 mb-0">
						    	<Form.Label>Estado</Form.Label>
						    	<Form.Control type="text" name="estado_orden" defaultValue={ordenInfo.estado_orden}  readOnly />
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="nro_nomina" className="pr-1 pl-0 mb-0">
						    	<Form.Label>Nómina</Form.Label>
						    	<Form.Control type="text" name="nro_nomina" defaultValue={ordenInfo.nro_nomina} readOnly />
						    </Form.Group>
					    </Col>
					    <Col lg md={4} sm={4}>
					     	<Form.Group as={Col} controlId="formGridEmail" className="mt-4 mb-0">
						    	<Button variant="warning" type="button" block onClick={(event) => showEstado(event)} disabled={disabledTracking}>
								    Tracking
								</Button>
						    </Form.Group>
					    </Col>
					</Form.Row>
				</Col>
			</Row>
			<ModalListado showIsModal={showIsModal} setShowIsModal={setShowIsModal} handleCloseModal={handleCloseModal} listado={ordenInfo.listEstado} />
		</Fragment>
	)
}

export default Sucursales