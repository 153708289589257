import React from "react"
import {  
	Form
} from 'react-bootstrap'
import { useDispatch } from "react-redux"
import { updateEntregarOrden } from "../../../actions/orderAction"
import { validateOnlyLetterNumber } from '../../../utils/validationInput';

const TbodyOrden = ({ index, numero_orden, orden_flete_id, total, delivery_failure_detail, forma_pago, estado, register }) => {

	const dispatch = useDispatch()


	const handleChange = (event, orden_flete_id) => {
		const param = event.target.name.split(".")[1]
		const valor = event.target.value
		dispatch(updateEntregarOrden(orden_flete_id, param, valor))
	}

	return (
		<tr key={index}>
	      <td>
			<input
                type="hidden"
                name={`ordenes[${index}].orden_flete_id`}
                value={orden_flete_id}
                ref={register}
              />
            {numero_orden}
			</td>
			<td>
	      	{forma_pago}
	      </td>
	      <td>
	      	<input
                type="hidden"
                name={`ordenes[${index}].total`}
                value={total}
                ref={register}
              />
	      	$ {total}
	      </td>
	      <td>
	      {
      		<Form.Control 
		    	type="text"
                name={`ordenes[${index}].delivery_failure_detail`}
                value={delivery_failure_detail || ''}
                onChange = {(event) => handleChange(event, orden_flete_id)}
                ref={register}
				onKeyPress={(event) => validateOnlyLetterNumber(event)}
				maxLength={150}
		    />
	      }
	      </td>
	      <td>
	      	{estado}
	      </td>
	    </tr>

	)
}

export default TbodyOrden
