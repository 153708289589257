import {  
	Modal,
	Table,
	Button,
	Row,
	Col
} from 'react-bootstrap'
import descargar from "../../../assets/images/descargar.png"
import { useDispatch } from "react-redux"
import { searchDocument } from '../../../actions/facturacionAction'
import { downloadDocumento } from '../../../utils/downloadFile'

const ModalNota = ({ showIsModal, setShowIsModal, handleCloseModal, listado, handleNotaCredito, documento, disabledBtnAnular }) => {

	const dispatch = useDispatch()
	
	const obtenerDocumento = async (event, row) => {
		event.preventDefault()
	
		if(row.url_cedible && row.url_cedible.length===0){
			
			const body = { tipo_documento: 'nota_credito', numero_documento: row.nro_documento}
			await dispatch(searchDocument(body))
		}else{
			let data = {
				url: row.url_cedible,
				numero_documento: row.nro_documento
			}

			downloadDocumento(data)
		}

	}

	return (
		<>
		<Modal show={showIsModal} onHide={handleCloseModal} aria-labelledby="example-modal-sizes-title-lg">
	        <Modal.Body className="pl-4 pr-4 pt-5">
	        	<h5>Notas de créditos</h5>
	        	<Row>
	        		{
	        			(documento!=="" && documento!==undefined && !disabledBtnAnular) && (
	        				<Col xs={12} className="text-right">
			        			<Button variant="warning" className="mb-2" type="button" onClick={()=>handleNotaCredito()}>
					    			CREAR NOTA DE CRÉDITO
					  			</Button>
			        		</Col>
	        			)
	        		}
	        		
	        		<Col xs={12}>
	        			<Table  bordered size="sm" className="table-global mt-4">
						  <thead>
						    <tr>
						      <th>Número documento</th>
						      <th>Fecha</th>
						      <th></th>
						    </tr>
						  </thead>
						  <tbody>
						    {listado?.length  > 0 ? ( listado.map((list, index) => (
						    	<tr key={index}>
							      <td>{list.nro_documento}</td>
							      <td>{list.created_at}</td>
							      <td>
							      	<button 
											style={{"height": "37px"}} 
											className="btn btn-xs pb-0 pt-1 mb-0" 
											key={list.nro_documento}
											onClick={(event)=>obtenerDocumento(event, list)}
									>
										<img src={descargar} height="30" alt="Descargar Factura"  />
									</button>
							      </td>
							    </tr>
						    ))
					    ) : <tr><td colSpan ='3'>No hay registros disponible</td></tr>}
						  </tbody>
						</Table>
	        		</Col>
	        	</Row>
	        	
	        	
				<Modal.Footer>
		          <Button variant="outline-warning"  onClick={handleCloseModal}>
		            Cerrar
		          </Button>
		        </Modal.Footer>
	        </Modal.Body>
	        
	      </Modal>
	    </>
	)
}

export default ModalNota