import {  
	Modal,
	Button
} from 'react-bootstrap'
import FormDestinatario from "../FormDestinatario"
import DetalleCarga from "./DetalleCarga"
import FormTotales from "./FormTotales"

import { useRetiroUpdatePreOrden } from '../../../../hooks/Retiro/useRetiroUpdatePreOrden'

const ModalPreOrdenAdmin = ({ completedModal, openCloseModalCompleted, preordenSelected, 
	toast, setCompletedModal, documentacionsCarga, forma_pago, isAdmin, searchCliente, 
	validarRut, detRetiro }) => {

	const { onSubmit, register, handleSubmit, loading, handleChangeTotal, 
			applyDescuento, setValue, control, errors, getValues, statusValidate,
			handleTipoDoc }= useRetiroUpdatePreOrden(toast, setCompletedModal)

	return (
		<Modal
			 size="lg"
	        show={completedModal}
	        onHide={() => openCloseModalCompleted(false)}
	        dialogClassName="modal-90w"
	        aria-labelledby="example-custom-modal-styling-title"
	      >
	        <Modal.Header closeButton>
	          <Modal.Title id="example-custom-modal-styling-title">
	            Pre Orden - { preordenSelected.numero_preorden}
	          </Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<form 
	        		id="frmRetiro" 
	        		onSubmit={handleSubmit(data => onSubmit(data, preordenSelected?.numero_preorden))}	
	        	>
		        	<FormDestinatario 
		        		preordenSelected={preordenSelected}  
		        		register={register}
		        		control={control}
		        		isAdmin={preordenSelected?.ultimo_estado!=="Nulas" ? isAdmin : false}
		        		setValue={setValue}
		        		searchCliente={searchCliente}
		        		validarRut={validarRut}
		        		toast={toast}
						errors={errors}
		        	/>
		        	<DetalleCarga 
		        		preordenSelected={preordenSelected} 
		        		register={register} 
		        		documentacionsCarga={documentacionsCarga} 
		        		handleChangeTotal={handleChangeTotal}
		        		forma_pago={forma_pago}
		        		setValue={setValue}
						errors={errors}
		        		isAdmin={preordenSelected?.ultimo_estado!=="Nulas" ? isAdmin : false}
						getValues={getValues}
						control={control}
						statusValidate={statusValidate}
						handleTipoDoc={handleTipoDoc}
		        	/>
		          	<FormTotales 
						register={register} 
						applyDescuento={applyDescuento} 
						{...preordenSelected} 
						control={control} 
					/>
		          	<div className="modal-preorden">
		          		{(preordenSelected?.ultimo_estado!=="Nulas" && preordenSelected?.is_edit) && (
							(isAdmin && detRetiro?.ultimo_estado!=="Finalizado") && (
									<Button 
										type="submit" 
										className="btn btn-warning btn-w-95"
										disabled={loading}
									>
										{loading ? "Actualizando..." : "Completar datos" }
									</Button>
								)
						)}					
		          		<Button className="btn btn-secondary ml-2 btn-w-95" onClick={()=>openCloseModalCompleted(false)}>Cerrar</Button>
		          	</div>
	          	</form>
	        </Modal.Body>
	      </Modal>
	)
}

export default ModalPreOrdenAdmin