import { 
	BUSCAR_CLIENTE_CODIGO_INTERNO,
	LISTADO_CLIENTE,
	DATA_ADD_CLIENTE, 
	SELECTED_ROWS_CLI,
	SEARCH_CLIENTE,
	DATA_EDIT_CLIENTE,
	DATA_DELETE_CLIENTE,
	IS_VISIBLE_FORMULARIO,
	DATA_ACTIVAR_DESACTIVAR_CLI
} from "../types/clienteType"

const INITIAL_STATE = {
	disabledCrearCliente: true,
	listado: [],
	isCargando: true,
	datos: {},
	isSelectedRows:{},
	isForm: false,
	isEdit: false,
	cliBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	}
}

const clienteReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case BUSCAR_CLIENTE_CODIGO_INTERNO:
			return {
				...state,
				disabledCrearCliente: action.payload.status
			}
		case LISTADO_CLIENTE:
			return {
				...state,
				listado: action.payload,
				isCargando: false
			}
		case DATA_ADD_CLIENTE:
			return{
				...state,
				listado: action.payload.listado,
				isForm: action.payload.isForm
			}
		case SELECTED_ROWS_CLI:
			return {
				...state,
				isSelectedRows: action.payload.rows,
				cliBtnActivar: action.payload.btnActivar
			}
		case SEARCH_CLIENTE:
			return {
				...state,
				datos: action.payload.seletected,
				isForm: action.payload.isForm,
				isEdit: action.payload.isEdit,
				cliBtnActivar: action.payload.btnActivar
			}
		case DATA_EDIT_CLIENTE:
			return {
				...state,
				listado: action.payload.listado,
				datos: action.payload.datos,
				isForm: action.payload.isForm,
				cliBtnActivar: action.payload.btnActivar
			}
		case DATA_DELETE_CLIENTE:
			return {
				...state,
				listado: action.payload
			}
		case IS_VISIBLE_FORMULARIO:
			return {
				...state,
				isForm: action.payload.isForm,
				isEdit: action.payload.isEdit,
				datos: action.payload.datos,
				cliBtnActivar: action.payload.btnActivar,
			}
		case DATA_ACTIVAR_DESACTIVAR_CLI:
			return {
				...state,
				cliBtnActivar: action.payload.btnActivar,
				listado: action.payload.listado,
				isSelectedRows: action.payload.rows
			}
		default: return state
	}
}

export default clienteReducer