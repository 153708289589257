import Layout from "../../components/Layouts/Layout"
import { Card } from 'react-bootstrap'
import TableFacturacion from "../../components/Configuracion/TableFacturacion"
import { ToastContainer } from 'react-toastify'

import { useDataBasicFacturacion } from "../../hooks/DataBasic/useDataBasicFacturacion"

const ConfiguracionFactura = () => {
	
	const { state, formElement, actions } = useDataBasicFacturacion()

	const { dataBasic, listDataBasicFacturacion, loading, bloquearInput } = state


	const { register, handleSubmit, onSubmit, errors } = formElement
    const { saveDataEdit, onReset } = actions

	return (
	    <Layout 
	    	titlePanel="Datos básicos de la factura"
	    	modulo="crear_pagos_factura"
	    >
	    	<div className="row">
	    		<div className="col-12 col-lg-10 mx-auto">
	    			<Card className="mb-5 mt-3 card-retiro shadow-sm">
						<Card.Header as="h6" className="card-header-white">Configuración de Facturación</Card.Header>
						<Card.Body className="">
                            <form onSubmit={handleSubmit(onSubmit)} id="frmInvoice">
                                <div className="row"> 
                                    <div className="col-12 col-sm-4 col-md-5 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="rut_emisor">RUT</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="rut_emisor"
                                                name="rut_emisor"
                                                ref={register({required: {value: true, message: 'Requerido'}})}
                                                defaultValue={dataBasic?.rut_emisor}
                                                readOnly={bloquearInput}
                                            />
                                            {errors.rut_emisor && (
                                                <span className="text-danger">
                                                    {errors?.rut_emisor?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> 
                                    <div className="col-12 col-md-7 col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="razon_social_emisor">Razón Social</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="razon_social_emisor"
                                                name="razon_social_emisor"
                                                ref={register({required: {value: true, message: 'Requerido'}})}
                                                defaultValue={dataBasic?.razon_social_emisor}
                                                readOnly={bloquearInput}
                                            />
                                            {errors.razon_social_emisor && (
                                                <span className="text-danger">
                                                    {errors?.razon_social_emisor?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> 
                                    <div className="col-12 col-md-12 col-lg-5">
                                        <div className="form-group">
                                            <label htmlFor="giro_emisor">Giro</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="giro_emisor"
                                                name="giro_emisor"
                                                ref={register({required: {value: true, message: 'Requerido'}})}
                                                defaultValue={dataBasic?.giro_emisor}
                                                readOnly={bloquearInput}
                                            />
                                            {errors.giro_emisor && (
                                                <span className="text-danger">
                                                    {errors?.giro_emisor?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> 
                                </div> 
                                <div className="row"> 
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="form-group">
                                                <label htmlFor="ciudad_emisor">Ciudad</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="ciudad_emisor"
                                                    name="ciudad_emisor"
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                    defaultValue={dataBasic?.ciudad_emisor}
                                                    readOnly={bloquearInput}
                                                />
                                                {errors.ciudad_emisor && (
                                                    <span className="text-danger">
                                                        {errors?.ciudad_emisor?.message}
                                                    </span>
                                                )}
                                        </div> 
                                    </div> 
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="comuna_emisor">Comuna</label>
                                            <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="comuna_emisor"
                                                    name="comuna_emisor"
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                    defaultValue={dataBasic?.comuna_emisor}
                                                    readOnly={bloquearInput}
                                                />
                                                {errors.comuna_emisor && (
                                                    <span className="text-danger">
                                                        {errors?.comuna_emisor?.message}
                                                    </span>
                                                )}
                                        </div>
                                    </div> 
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="direccion_emisor">Dirección</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="direccion_emisor"
                                                name="direccion_emisor"
                                                ref={register({required: {value: true, message: 'Requerido'}})}
                                                defaultValue={dataBasic?.direccion_emisor}
                                                readOnly={bloquearInput}
                                            />
                                            {errors.direccion_emisor && (
                                                <span className="text-danger">
                                                    {errors?.direccion_emisor?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> 
                                </div> 
                                <div className="row">
                                    <div className="col-12">
                                        <p>Acceso a SOAP:</p>
                                    </div> 
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="form-group">
                                                <label htmlFor="usuario_soap">Usuario</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="usuario_soap"
                                                    name="usuario_soap"
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                    defaultValue={dataBasic?.usuario_soap}
                                                    readOnly={bloquearInput}
                                                />
                                                {errors.usuario_soap && (
                                                    <span className="text-danger">
                                                        {errors?.usuario_soap?.message}
                                                    </span>
                                                )}
                                        </div> 
                                    </div> 
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="clave_soap">Contraseña</label>
                                            <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="clave_soap"
                                                    name="clave_soap"
                                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                                    defaultValue={dataBasic?.clave_soap}
                                                    readOnly={bloquearInput}
                                                />
                                                {errors.clave_soap && (
                                                    <span className="text-danger">
                                                        {errors?.clave_soap?.message}
                                                    </span>
                                                )}
                                        </div>
                                    </div> 
                                </div>
                                <div className="row"> 
                                    <div className="col-12 text-right">
                                        <button 
                                            type="button" 
                                            className="btn btn-outline-warning btn-sm pl-5 pr-5 pt-2 pb-2 mr-3"
                                            onClick={()=>onReset()}
                                            disabled={loading}
                                        >
                                            Cancelar
                                        </button>
                                        <button 
                                            type="submit" 
                                            className="btn btn-warning btn-sm pl-5 pr-5 pt-2 pb-2"
                                            disabled={loading}
                                        >
                                            {loading ? 'Actualizando...' : 'Actualizar' }
                                        </button>
                                    </div> 
                                </div> 
                            </form>


                            <TableFacturacion 
                                listDataBasicFacturacion={listDataBasicFacturacion} 
                                saveDataEdit={saveDataEdit}
                            /> 
                            
						</Card.Body>
					</Card>
	    		</div>
	    	</div>
	    	
	    	<ToastContainer />


	    </Layout>
	)
}

export default ConfiguracionFactura