import React from 'react'
import DataTable from 'react-data-table-component'

import guias from './Columns'
import Progress from '../../Layouts/Progress'

const Table = ({data, isCargando }) => {

	const columnas = guias.columnas

	const paginationOptions = {
		rowsPerPageText: 'Filas por Página',
		rangeSeparatorText:'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	}


	return (
		<div>
			<DataTable 
				columns={columnas}
				data={data}
			    pagination
			    paginationComponentOptions={paginationOptions}
      			persistTableHead
      			dense
      			progressPending={isCargando}
      			progressComponent={<Progress />}
				noDataComponent={<div>No hay datos registros disponible.</div>}
			/>

		</div>
	)
}

export default Table