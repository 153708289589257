import React from "react"

import { formatNumberCurrency } from "../../utils/validationInput"

const TbodyOrden = ({ index, numero_orden, forma_pago, monto, contado, porPagar, cuentaCorriente, 
	documento
}) => {

	return (
		<tr key={index}>
	      <td>
            {numero_orden}
			</td>
			<td>
	      	{forma_pago}
	      </td>
	      <td>
	      	{formatNumberCurrency(monto)}
	      </td>
	      <td>
	      	{formatNumberCurrency(contado)}
	      </td>
	      <td>
	      {formatNumberCurrency(porPagar)}
	      </td>
	      <td>
	      	{formatNumberCurrency(cuentaCorriente)}
	      </td>
	      <td>
			{documento}
	      </td>
	    </tr>

	)
}

export default TbodyOrden
