import API from "../config/api"
import {
    LIST_DATA_BASIC_FACTURACION,
    SELECTED_CONFIG_DATA
} from "../types/basicFacturaType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const addDataBasicFactura = (body) => async (dispatch, getState) => {
	try{
        const { dataBasic, listDataBasicFacturacion } = getState().dataBasicInvoice

        let dataForm = {
            ...body,
            id: dataBasic.id
        }
        let response = await API.post("basic/form", dataForm)
        let { status, message } = response

        if(status){
            let listado = listDataBasicFacturacion
            const index = listDataBasicFacturacion.findIndex(item => item.id===dataForm.id)
            if(index!==-1){
 
                listado[index] = {...listado[index], ...dataForm }
            }

            let payload = { listData: listado }
            dispatch(requestSuccess(LIST_DATA_BASIC_FACTURACION, payload))
        }       

        return { status, message }
    }catch(error){
        return {status: false, message:error}
    }
}

export const listDataBasicFactura = (body) => async (dispatch, getState) => {
	try{
        let response = await API.get("basic/invoice")

        let { status, message, data } = response

        if(status){
            let dataBasic = data.map(item => {
                return {
                    ...item,
                    estatus_name: item.estatus==="1" ? "Activo" : "Inactivo"
                }
            })
            let payload = { listData: dataBasic }
            dispatch(requestSuccess(LIST_DATA_BASIC_FACTURACION, payload))
        }       

        return { status, message }
    }catch(error){
        return {status: false, message:error}
    }
}



export const getDataEditFactura = (item) => (dispatch, getState) => {
	try{

        let payload = { data: item }
        dispatch(requestSuccess(SELECTED_CONFIG_DATA, payload))      
    }catch(error){
        return {status: false, message:error}
    }
}