import React from "react"
import {  
	Form,
	Alert
} from 'react-bootstrap'

const MessageDanger = ({ color, message}) => {
	return (
		<Form.Group>
			<Alert variant={color}>
			 	 {message}
			</Alert>
		</Form.Group>
	)
}

export default MessageDanger