import { 
	LISTADO_DATA_SU,
	SELECTED_ROWS_SU,
	IS_VISIBLE_FORMULARIO_SU,
	SEARCH_SU,
	DATA_EDIT_SU,
	DATA_ADD_SU,
	DATA_ACTIVAR_DESACTIVAR_SU,
	DATA_DELETE_SU
} from "../types/sucursalType"

const INITIAL_STATE = {
	suListado: [],
	suIsCargando: true,
	suIsForm: false,
	suIsEdit: false,
	suDatos: {},
	suIsSelectedRows: [],
	suBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	},
	suIsVisibleOficina: false
}

const sucursalReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_SU:
			return {
				...state,
				suListado: action.payload,
				suIsCargando: false
			}
		case IS_VISIBLE_FORMULARIO_SU:
			return {
				...state,
				suIsForm: action.payload.isForm,
				suIsEdit: action.payload.isEdit,
				suDatos: action.payload.datos,
				suBtnActivar: action.payload.btnActivar,
				suIsSelectedRows: [],
				suIsVisibleOficina: action.payload.visibleOficina
			}
		case SEARCH_SU:
			return {
				...state,
				suDatos: action.payload.seletected,
				suIsForm: action.payload.isForm,
				suIsEdit: action.payload.isEdit,
				suBtnActivar: action.payload.btnActivar,
				suIsVisibleOficina: action.payload.visibleOficina
			}
		case DATA_ADD_SU:
			return{
				...state,
				suListado: action.payload.listado,
				suIsForm: action.payload.isForm
			}
		case DATA_EDIT_SU:
			return {
				...state,
				suListado: action.payload.listado,
				suDatos: action.payload.datos,
				suIsForm: action.payload.isForm,
				suBtnActivar: action.payload.btnActivar
			}
		case SELECTED_ROWS_SU:
			return {
				...state,
				suIsSelectedRows: action.payload.rows,
				suBtnActivar: action.payload.btnActivar
			}
		case DATA_ACTIVAR_DESACTIVAR_SU:
			return {
				...state,
				suIsSelectedRows: action.payload.rows,
				suBtnActivar: action.payload.btnActivar,
				suListado: action.payload.listado
			}
		case DATA_DELETE_SU:
			return {
				...state,
				suListado: action.payload
			}
		default: return state
	}
}

export default sucursalReducer