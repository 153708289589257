import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm, useFieldArray } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addVehicleUser } from "../../../actions/userAction"

const AssignVehicle = ({toast, datos}) => {

    const dispatch = useDispatch()
    const { register, handleSubmit, getValues, control } = useForm({defaultValues: datos})
    const { fields, append, remove } = useFieldArray({ control, name: "camions" })
	const [ isLoading, setIsLoading ] = useState(false)
    const { caListado } = useSelector((state) => state.camions)

    const addVehicleInput = () => {

        if(getValues("listPlaca")!=="-Seleccione-"){
            const idVehicleP = getValues("listPlaca").split("|")
            
            const isExist = fields.find(item => item.vehicle===idVehicleP[1])
            if(!isExist){
                append({ placa: idVehicleP[1], idVehiculo: idVehicleP[0] })
            }else{
                toast.error("Ya existe en la lista",{position: toast.POSITION.TOP_RIGHT})
            }            
        }else{
            toast.error("Debe seleccionar un vehículo de la lista",{position: toast.POSITION.TOP_RIGHT})
        }        
    }

    
    const onSubmit = async (data, e) => {
		setIsLoading(true)
        if(fields.length>0){
            const body= { camions: fields}
            const response = await dispatch(addVehicleUser(body))
           
            if(response.status){
                toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            }else{
                toast.error(response.message,{position: toast.POSITION.TOP_RIGHT})
            }
        }else{
            toast.error("Debe asignar vehículo al usuario",{position: toast.POSITION.TOP_RIGHT})
        }
        
        setIsLoading(false)
    }

    return (
        <Form id="frmClave" onSubmit={handleSubmit(onSubmit)}>
            	<Card border="Light">
				    <Card.Header as="h6" className="bg-warn-blue text-dark">ASIGNAR VEHÍCULOS</Card.Header>
				    <Card.Body className="bg-soft-blue padding-user">
				    	<Row>
				     		<Col lg={6} md={6} sm={12}>
						      	<Form.Group controlId="fullName" >
						      		<Form.Label>Nombre y Apellido</Form.Label>
						      		<Form.Control 
									    	type="text" 
									    	name="fullName" 
							                defaultValue={datos?.fullName || ""}
							             	disabled
									    />
						    	</Form.Group>
						    </Col>
						    <Col lg={6} md={6} sm={12}>
						    	<Form.Group controlId="email" >
						      		<Form.Label>E-mail</Form.Label>
						      		<Form.Control 
									    	type="text" 
									    	name="email" 
							                defaultValue={datos?.email || ""}
							             	disabled
									    />
						    	</Form.Group>
						    </Col>
						</Row>
                        <Row>
				     		<Col lg={6} md={6} sm={12}>
						      	<Form.Group controlId="fullName" >
						      		<Form.Label>Asigne el vehículo al usuario:</Form.Label>
						      		<select className="form-control" id="listPlaca" name="listPlaca" ref={register}>
                                        <option>-Seleccione-</option>
                                        {
                                            caListado.length>0 && caListado.map((item, key) =>
                                            <option key={key} value={`${item.id}|${item.placa}`}>{item.placa}</option>)
                                        }
                                    </select>
                                    
						    	</Form.Group>
						    </Col>
						    <Col lg={6} md={6} sm={12}>
						    	<Form.Group controlId="email" >
                                    <Button variant="warning" type="button" className="mt-4 btn-sm" onClick={()=>addVehicleInput()}>
                                        Asignar
                                    </Button>
						    	</Form.Group>
						    </Col>
						</Row>
                        <Row>
				     		<Col md={8} sm={12}>
						      	<Form.Group controlId="fullName" >
						      		<Form.Label>Listado de Vehículos asignados al usuario:</Form.Label>
						      		<table className="table table-bordered table-sm bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col">Placa</th>
                                                <th scope="col" style={{width: "10px"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                fields.length>0 ? (fields.map((item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.placa}
                                                            <input type="hidden" 
                                                                control={control} 
                                                                name={`camions[${index}].placa`} 
                                                                id={`camions[${index}].placa`} 
                                                                defaultValue={item.placa}
                                                                ref={register}
                                                            />
                                                            <input type="hidden" 
                                                                control={control} 
                                                                name={`camions[${index}].idVehiculo`} 
                                                                id={`camions[${index}].idVehiculo`} 
                                                                defaultValue={item.idVehiculo}
                                                                ref={register}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button 
                                                                variant="danger" 
                                                                type="button" 
                                                                className="btn-sm"
                                                                onClick={() => remove(index)}
                                                            >
                                                                Eliminar
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )})) : (
                                                    <tr>
                                                        <td colSpan={2}>
                                                            No hay vehículo asignado
                                                        </td>
                                                    </tr> 
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    
						    	</Form.Group>
						    </Col>
						</Row>
				    </Card.Body>
				</Card>
			   <Row>
			   	<Col xs={12} className="text-right mt-3">
					<Form.Group as={Col}>
				    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
						    {isLoading ? 'Procesando...' : 'Actualizar'  }
						</Button>
				    </Form.Group>
				</Col>
			   </Row>
            </Form>
    )
}

export default AssignVehicle