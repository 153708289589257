import Layout from "../../components/Layouts/Layout"
import CardRetiro from "../../components/Layouts/CardRetiro"
import Busqueda from "../../components/Facturacion/Busqueda"
import Resultado from "../../components/Facturacion/Resultado"
import { ToastContainer, toast } from 'react-toastify'

const Panel = () => {
	

	
	return (
	    <Layout 
	    	titlePanel="Facturación cliente"
	    	modulo="facturacion_cliente"
	    >
	    	<CardRetiro>
		    	<Busqueda toast={toast} />
		    	<Resultado toast={toast} />	    
			</CardRetiro>
	    	<ToastContainer />
	    	
	    </Layout>
	)
}

export default Panel