import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Correlativo/Listado"
import Formulario from "../../components/Admin/Correlativo/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadosCorrelativo } from "../../actions/correlativoAction"


const Correlativo = ({toast}) => {
	
	const dispatch = useDispatch()
	const { listCorrelativo, corIsCargando, corDatos, corIsForm, corIsSelectedRows } = useSelector((state) => state.correlativo)

	useEffect(() => {
		dispatch(listadosCorrelativo())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!corIsForm ? "Listado de Correlativos" : "Correlativo" }>
		    	{!corIsForm ? (
					<Listado data={listCorrelativo} isCargando={corIsCargando} toast={toast} corIsSelectedRows={corIsSelectedRows} />
				): (
					<Formulario toast={toast} datos={corDatos} />	
				)}			    
			</CardRetiro>
	    </>
	)
}

export default Correlativo