import { useState } from "react"
import { updateDataPreOrden, resultTotales, applyResultTotales } from "../../actions/retiroAction"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"

export const useRetiroUpdatePreOrden = (toast, setShow) => {

	const dispatch = useDispatch()
	const [ loading, setLoading ] = useState(false)
	const [ statusValidate, setStatusValidate ] = useState(true)
	const { register, handleSubmit, errors, getValues, setValue, control } = useForm()

	const onSubmit = async (data, numero_preorden) => {
		try{

		setLoading(true)

		if(data?.valor_declarado_carga==="0"){
			setLoading(false)
			toast.error("Debe de ingresar el valor de la carga", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		for(let index in data?.cargas){
			if(data?.cargas[index].valor_neto==="0"){
				setLoading(false)
				toast.error("Debe de ingresar el valor de la carga", {position: toast.POSITION.TOP_RIGHT})
				return false
			}
		}

		if(data?.valor_neto==="0"){
			setLoading(false)
			toast.error("Debe de ingresar el total de la preorden", {position: toast.POSITION.TOP_RIGHT})
			return false
		}
		
		let comuna_selected = document.querySelector("#comuna_destino_id")
		let sucursal_selected = document.querySelector("#sucursal_destino_id") 
		

		let body = {
			...data,
			oficina_destino_nombre: comuna_selected.options[comuna_selected.selectedIndex].text,
			ciudad_destino_nombre: sucursal_selected.options[sucursal_selected.selectedIndex].text,
		}

		applyDescuento()	
		
		const response = await dispatch(updateDataPreOrden(body, numero_preorden))
		
		if(response.status){
			setShow(false)
			toast.success(response.message, {position: toast.POSITION.TOP_RIGHT})
		}else{
			toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
		}
		setLoading(false)
		}catch(error){
			setLoading(false)
			toast.error("Hubo un error, inténtelo nuevamente", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const handleChangeTotal = (event, idCarga, idPreoden) => {
		if(event.target.value!==""){
			const descuento = getValues("descuento")

			const carga = { id: idCarga, valor_neto: event.target.value.replaceAll(".", "").replaceAll(",", ".") }
		
			const result = dispatch(resultTotales(carga, descuento, idPreoden))

			if(Object.keys(result).length>0){
				setValue("total", result?.total)
				setValue("total_bultos", result?.total_bultos)
				setValue("total_kilos", result?.total_kilos)
				setValue("total_metro_cubico", result?.total_metro_cubico)
				setValue("subtotal_orden", result?.subtotal_orden)
				setValue("valor_neto", result?.valor_neto)
				setValue("valor_iva", result?.valor_iva)
				setValue("valor_descuento", result?.montoDiferencia)
				
			}
		}
	}

	const applyDescuento = () => {
		const result = dispatch(applyResultTotales(getValues("descuento")))

		if(Object.keys(result).length>0){
			setValue("total", result?.total)
			setValue("total_bultos", result?.total_bultos)
			setValue("total_kilos", result?.total_kilos)
			setValue("total_metro_cubico", result?.total_metro_cubico)
			setValue("subtotal_orden", result?.subtotal_orden)
			setValue("valor_neto", result?.valor_neto)
			setValue("valor_iva", result?.valor_iva)
			setValue("valor_descuento", result?.montoDiferencia)
		}
	}

	const handleTipoDoc = (event) => {
		let pago_descripcion = document.querySelector(".change_documentacion_id")

		pago_descripcion = pago_descripcion.options[pago_descripcion.selectedIndex].text
	
		if(pago_descripcion.toLowerCase()==="sin documento"){
			setStatusValidate(statusValidate => false)
            setValue("numero_documento_carga", "")
		}else{
			setStatusValidate(statusValidate => true)
		}
	}

	return {
		onSubmit,
		register,
		handleSubmit,
		errors,
		loading,
		handleChangeTotal,
		applyDescuento,
		setValue,
		control,
		getValues,
		statusValidate,
		handleTipoDoc
	}
}
