import { 
	SEARCH_ORDEN_FECHA_CREACION,
	SEARCH_ORDEN_FECHA_ENTREGA,
	SEARCH_ORDEN_OFFICE,
	RESET_LISTADO_SOLUTIONS
} from "../types/solutionsOrdenType"

const INITIAL_STATE = {
	listOrdenesFechaCreacion: [],
	listOrdenesFechaEntrega: [],
	listOrdenesOffice: []
}

const solutionsOrdenReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case SEARCH_ORDEN_FECHA_CREACION:
			return {
				...state,
				listOrdenesFechaCreacion: action.payload.listOrdenesFechaCreacion
			}
		case SEARCH_ORDEN_FECHA_ENTREGA:
			return {
				...state,
				listOrdenesFechaEntrega: action.payload.listOrdenesFechaEntrega
			}
		case SEARCH_ORDEN_OFFICE:
			return {
				...state,
				listOrdenesOffice: action.payload.listOrdenesOffice
			}
		case RESET_LISTADO_SOLUTIONS:
			return {
				...state,
				listOrdenesOffice: action.payload.listOrdenesOffice,
				listOrdenesFechaEntrega: action.payload.listOrdenesFechaEntrega,
				listOrdenesFechaCreacion: action.payload.listOrdenesFechaCreacion
			}
		default: return state
	}
}

export default solutionsOrdenReducer