import { useEffect, useState } from "react"
import {  
	Form,
	Col,
	Table,
	Row,
	InputGroup
} from 'react-bootstrap'
import BodyCarga from "./BodyCarga"
import { NumericFormat } from "react-number-format";
import { onPaste } from "../../../../utils/validationInput";
import { Controller } from "react-hook-form"


const DetalleCarga = ({ preordenSelected, register, documentacionsCarga, handleChangeTotal, 
	forma_pago, setValue, isAdmin, errors, getValues, control, statusValidate, handleTipoDoc }) => {
	
	const [ totalPagar, setTotalPagar ] = useState("")
	useEffect(() => {
		setValue("documentacion_id", preordenSelected.documentacion_id)
		setValue("forma_pago_id", preordenSelected.forma_pago_id)
		setValue("valor_declarado_carga", preordenSelected.valor_declarado_carga)
		setTotalPagar(preordenSelected.valor_declarado_carga)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preordenSelected?.documentacion_id])

	return (
		<>
			<Form.Row>
				<Col>
					<hr />
			    	<h6>Detalle de la carga</h6>
				</Col>
			</Form.Row>
			<Row>
				<Col md sm={3} className="mb-0">
			      	<Form.Group controlId="documentacion_id" className="mb-1">
			      		<Form.Label>Tipo de Documento</Form.Label>
			      		<Form.Control 
					    	as="select"
					    	name="documentacion_id" 
							className="change_documentacion_id"
					    	defaultValue={preordenSelected.documentacion_id}
					    	disabled={!isAdmin}
					    	ref={register({required: {value: true, message: 'Requerido'}})}
							onChange={(event) => handleTipoDoc(event)}
					    >
				    	<option value="">Seleccione</option>
				    	
				    		{documentacionsCarga.length > 0 && (
				    			documentacionsCarga.map(documents => <option key={documents.id} value={documents.id}>{documents.descripcion}</option>)
				    		)}
				    	
				 		</Form.Control>
						 {errors.documentacion_id && (
								<Form.Text className="text-danger">
									{errors?.documentacion_id?.message}
								</Form.Text>
							)}
			    	</Form.Group>
			    </Col>
			    <Col md sm={3} className="mb-0">
			     	<Form.Group controlId="numero_documento_carga" className="mb-1">
				    	<Form.Label>Nro Documento(s)</Form.Label>
				    	<Form.Control 
					    	type="text" 
					    	name="numero_documento_carga" 
					    	defaultValue={preordenSelected.numero_documento_carga}
							ref={register({
								required: {value: statusValidate, message: 'Requerido'},
							})}
							onPaste={(event) => onPaste(event)}
							readOnly={!statusValidate}
					    />
						{errors.numero_documento_carga && (
								<Form.Text className="text-danger">
									{errors?.numero_documento_carga?.message}
								</Form.Text>
							)}
				    </Form.Group>
			    </Col>
			    <Col md sm={3} className="mb-0">
			     	<Form.Group controlId="valor_declarado_carga" className="mb-1">
				    	<Form.Label>Valor declarado</Form.Label>
					    <InputGroup >
						    <InputGroup.Prepend>
						      <InputGroup.Text>$</InputGroup.Text>
						    </InputGroup.Prepend>
							<Controller
								control={control}
								name="valor_declarado_carga"  
								defaultValue={preordenSelected.valor_declarado_carga}
								rules={{required: {value: true, message: 'Requerido'}}}
								render={(props) => (
									<NumericFormat
										allowNegative={false}
										className="form-control"
										decimalScale={2}
										name={props.name}
										id={props.name}
										fixedDecimalScale={false}
										isAllowed={({floatValue}) => {
											// Define your validation rules here
											return true;
										}}
										onPaste={(e) => e.preventDefault()}
										decimalSeparator={','}
										thousandSeparator={"."}
										value={totalPagar}
										//defaultValue={ordenInfo.valor_declarado_carga}
										onValueChange={(values) => {
											props.onChange(values.floatValue);
											setTotalPagar(values.floatValue);
										}}
										readOnly={!isAdmin}
										maxLength={15}
									/>
								)}
								autoComplete="off"
							/>
						  </InputGroup>
						  {errors.valor_declarado_carga && (
								<Form.Text className="text-danger">
									{errors?.valor_declarado_carga?.message}
								</Form.Text>
							)}
				    </Form.Group>
			    </Col>
			    <Col md sm={3} className="mb-0">
			      	<Form.Group controlId="forma_pago_id" className="mb-1">
			      		<Form.Label>Forma de pago</Form.Label>
			      		<Form.Control 
					    	as="select"
					    	name="forma_pago_id" 
					    	disabled={!isAdmin}
					    	defaultValue={preordenSelected.forma_pago_id}
					    	ref={register({required: {value: true, message: 'Requerido'}})}
					    >
				    	<option value="">Seleccione</option>
				    	
				    		{forma_pago.length > 0 && (
				    			forma_pago.map(pago => <option key={pago.id} value={pago.id}>{pago.descripcion}</option>)
				    		)}
				    	
				 	</Form.Control>
			    	</Form.Group>
			    </Col>
			</Row>
			<Row >
				<Col >
					<Form.Group className="mb-1">
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th className="text-center">Alto (m)</th>
							      <th className="text-center">Ancho (m)</th>
							      <th className="text-center">Largo (m)</th>
								  <th className="text-center">M3</th>
							      <th className="text-center">Cantidad</th>
							      <th className="text-center">Peso total (kg)</th>
							      <th className="text-center" style={{"width":"150px"}}>Total</th>
							    </tr>
							  </thead>
							  <tbody>
							  	{
							  		preordenSelected.cargas.length>0 &&
							  		preordenSelected.cargas.map((item,key) => 
							  			<BodyCarga 
							  				key={key}
					  						index={key}
					  						{...item}
							  				register={register}
							  				isAdmin={isAdmin}
							  				handleChangeTotal={handleChangeTotal}
							  				idPreoden={preordenSelected.orden_flete_id}
											  setValue={setValue}
											  getValues={getValues}
											  control={control}
											  errors={errors}
					  					/>
							  		)
							  	}
							  </tbody>
						</Table>
					</Form.Group>	
				</Col>
			</Row>	
		</>
	)
}

export default DetalleCarga