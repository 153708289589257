import { combineReducers } from "redux"
import users from "./userReducer"
import orders from "./ordersReducer"
import cliente from "./clienteReducer"
import nominas from "./nominaReducer"
import metodoPagos from "./metodoPagoReducer"
import rendir from "./rendirReducer"
import config from "./configReducer"
import retiros from "./retiroReducer"
import roles from "./roleReducer"
import camions from "./camionReducer"
import sucursales from "./sucursalReducer"
import conductores from "./conductorReducer"
import oficinas from "./oficinaReducer"
import facturacion from "./facturacionReducer"
import guiaDespacho from './guiaDespachoReducer'
//Admin
import ctaDeposito from "./cuentaDepositoReducer"
import correlativo from "./correlativoReducer"

import home from "./homeReducer"
import solutions from "./solutionsOrdenReducer"
import dataBasicInvoice from "./basicFacturaReducer"

export default combineReducers({
	users,
	orders,
	cliente,
	nominas,
	metodoPagos,
	rendir,
	config,
	retiros,
	ctaDeposito,
	home,
	roles,
	camions,
	sucursales,
	conductores,
	oficinas,
	facturacion,
	guiaDespacho,
	correlativo,
	solutions,
	dataBasicInvoice
})