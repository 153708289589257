import {
	LISTA_DATA_SUCURSAL,
	CLEAN_DATA_SUCURSAL,
	LISTADO_DEPOSITO,
	LISTADO_INICIO_DEPOSITO,
	CHANGE_VALUE_INPUT,
	SAVE_LISTADO_DEPOSITO,
	REABRIR_CAJA,
	ADD_OBSERVACIONES
} from "../types/rendirType"

const INITIAL_STATE = {
	listadoVenta: [],
	listadoRecepcion: [],
	rendirCtdo: 0,
	rendirPorPagar: 0,
	rendirTotal: 0,
	diferencia: 0,
	totalDeposito: 0,
	listadoDepositos: [],
	listadoCuentaDeposito: [],
	listadoTipoRendir: [],
	disabledAdd: false,
	busqueda: {},
	isCajaCerrada: 0,
	isPermisoEliminarDeposito: false,
	listadoObservaciones: [],
	idRendirCaja: 0,
	isInitial: true,
	isExiste: false,
	edicionManual: [],
	cajaDescuadrada: [],
	ultimasCajas: [],
	search: { sucursal_id: null, fecha: new Date()},
	cajasAbiertas: [],
	cajasListSucursal: []
}

const rendirReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_INICIO_DEPOSITO:
			return {
				...state,
				listadoCuentaDeposito: action.payload.cuentas_depositos,
				listadoTipoRendir: action.payload.tipo_rendir,
				isCajaCerrada: 1,
				cajaDescuadrada: action.payload.cajas_descuadradas,
				ultimasCajas: action.payload.ultimas_cajas,
				cajasAbiertas: action.payload.cajas_abiertas
			}
		case LISTA_DATA_SUCURSAL:
			return {
				...state,
				listadoVenta: action.payload.listadoVenta,
				listadoRecepcion: action.payload.listadoRecepcion,
				rendirCtdo: action.payload.rendirCtdo,
				rendirPorPagar: action.payload.rendirPorPagar,
				rendirTotal: action.payload.rendirTotal,
				diferencia: action.payload.diferencia,
				listadoDepositos: action.payload.depositos,
				totalDeposito: action.payload.total_depositos,
				disabledAdd: action.payload.disabled,
				isCajaCerrada: action.payload.isCajaCerrada,
				isPermisoEliminarDeposito: action.payload.isPermisoEliminarDeposito,
				listadoObservaciones: action.payload.listadoObservaciones,
				idRendirCaja: action.payload.idRendirCaja,
				isInitial: action.payload.isInitial,
				isExiste: action.payload.isExiste,
				edicionManual: action.payload.edicionManual,
				search: action.payload.search,
				cajasListSucursal: action.payload.cajasListSucursal
			}
		case CLEAN_DATA_SUCURSAL:
			return {
				...state,
				listadoVenta: action.payload.listadoVenta,
				listadoRecepcion: action.payload.listadoRecepcion,
				rendirCtdo: 0,
				rendirPorPagar: 0,
				rendirTotal: 0,
				diferencia: 0,
				totalDeposito: 0,
				listadoDepositos: action.payload.depositos,
				disabledAdd: false,
				isCajaCerrada: 1,
				listadoObservaciones: action.payload.listadoObservaciones,
				edicionManual: action.payload.edicionManual,
				search: action.payload.search
			}
		case LISTADO_DEPOSITO:
			return {
				...state,
				listadoDepositos: action.payload.depositos,
				totalDeposito: action.payload.totalMonto,
				diferencia: action.payload.diferencia,
				disabledAdd: action.payload.disabledAdd
			}
		case CHANGE_VALUE_INPUT:
			return {
				...state,
				busqueda: action.payload
			}
		case SAVE_LISTADO_DEPOSITO:
			return {
				...state,
				listadoDepositos: action.payload.listado,
				totalDeposito: action.payload.total,
				diferencia: action.payload.diferencia,
				disabledAdd: action.payload.disabledAdd
			}
		case REABRIR_CAJA: 
			return {
				...state,
				isPermisoEliminarDeposito: action.payload.isPermisoEliminarDeposito,
				isCajaCerrada: action.payload.isCajaCerrada,
				idRendirCaja: action.payload.idRendirCaja,
				isInitial: action.payload.isInitial,
				isExiste: action.payload.isExiste,
				listadoObservaciones: action.payload.observaciones,
				listadoDepositos: action.payload.listadoDepositos,
				disabledAdd: action.payload.disabledAdd
			}
		case ADD_OBSERVACIONES:
			return {
				...state,
				listadoObservaciones: action.payload
			}
		default: return state
	}
}

export default rendirReducer