import React, { useState } from "react"
import {  
	Form
} from 'react-bootstrap'
import ModalFactura from '../../components/Factura/ModalFactura'
import DetalleTabla from "../../components/Encomienda/Intermediario/DetalleTabla"
import { useDispatch, useSelector } from "react-redux"
import { entregarOrden, assignOrdenCaja } from "../../actions/orderAction"
import { useForm } from "react-hook-form"

import TableSuccess from '../../components/Encomienda/TableSuccess'

const EntregaOtraPersona = ({ toast }) => {

	const dispatch = useDispatch()
    const { listEntregada  } = useSelector((state) => state.orders)
	const { register, handleSubmit, errors, getValues, setValue, control } = useForm()
    const [isLoad, setIsLoad] = useState(false)
    const [ hidden, setHidden ] = useState(false)
    const [ infoData, setInfoData ] = useState({})

	const onSubmit = async (data, event) => {
    	event.preventDefault()

        setIsLoad(true)

    	if(!data.hasOwnProperty("ordenes")){
    		toast.error("No tiene ordenes por retirar", {position: toast.POSITION.TOP_RIGHT})
            setIsLoad(false)
    		return false
    	}

    	let { ordenes } = data

    	const listadoDocumentacion = ordenes.filter(orden => orden.documentacion_id==="" && orden.forma_pago!=="Cuenta Corriente")
    	const listadoNro = ordenes.filter(orden => orden.documentacion_id==="" && orden.forma_pago!=="Cuenta Corriente")
    	const listadoRut = ordenes.filter(orden => orden.rut_recepcion==="")
    	const listadoName = ordenes.filter(orden => orden.nombre_recepcion==="")
    	const listadoPago= ordenes.filter(orden => orden.metodo_pago_id==="" && orden.forma_pago!=="Cuenta Corriente")

    	if(listadoDocumentacion.length > 0 || listadoNro.length > 0 || listadoRut.length > 0 || listadoName.length > 0 || listadoPago.length > 0){
    		toast.error("Debe de completar el detalle de las ordenes", {position: toast.POSITION.TOP_RIGHT})
            setIsLoad(false)
    		return false
    	}

        const porPagar = ordenes.filter(orden => orden.forma_pago==="Por pagar" && parseInt(orden.documentacion_id)===1)

        if(porPagar.length>0){
            const information = {
                ordenes,
                rut_remitente: porPagar[0].rut_remitente,
                rut_destinatario: porPagar[0].rut_destinatario
            }
            setHidden(true)
            setInfoData(information)
        } else{
            await funcEntregar({ordenes})
        }      
    }

    const funcEntregar = async (information) => {

        let response = await dispatch(entregarOrden(information, "otros"))
        setIsLoad(false)
        if(response.status){
            const body = {
                fecha_entrega: response.fecha_entrega,
                ordens: information.ordenes.map(item => item.orden_flete_id)
            }
            await dispatch(assignOrdenCaja(body))

            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
            document.getElementById("frmEntregaIntermedio").reset()
            return true
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
            return false
        }
    }


	return (
		<>
	    	<Form id="frmEntregaIntermedio" onSubmit={handleSubmit(onSubmit)}>

				<DetalleTabla 
                    register={register} 
                    errors={errors} 
                    getValues={getValues} 
                    setValue={setValue} 
                    toast={toast} 
                    isLoad={isLoad}
                    control={control}
                />

                {listEntregada.length>0 && <TableSuccess />}

                {hidden && <ModalFactura 
                            setHidden={setHidden} 
                            hidden={hidden} 
                            data={infoData} 
                            toast={toast} 
                            funcFactura={funcEntregar} 
                            title={"FACTURA A NOMBRE DE:"}  
                            isVisibleFactura={true}
                            nameModulo="entrega"
                            /> }
			</Form>
		</>
	)
}

export default EntregaOtraPersona