import { Modal } from 'react-bootstrap'
import { Link } from "react-router-dom"

const ModalOptionImpresion = ({ showModal, handleClose, numero_orden, isView=true }) => {

	return (
		<Modal show={showModal} onHide={handleClose} centered >
			<Modal.Header closeButton style={{borderBottom: 'none'}} />
	        <Modal.Body>
		        <section>
		        	<h3 className="txt-print">¿Qué desea imprimir?</h3>
		        	<div className="options-btn__print">
		        		<Link to={`/orders/${numero_orden}/ticket`}> 
			        		<button 
			        			className="btn btn-outline-warning mb-4 btn-block btn-print"
			        		>
			        			Orden
			        		</button>
		        		</Link>
		        		
						{
							isView && (
								<Link to={`/orders/${numero_orden}/correlativos`}> 
									<button 
										className="btn btn-outline-warning btn-block btn-print"
									>
										Correlativos
									</button>
								</Link>
							)
						}
		        		
		        	</div>
		        </section>
	        </Modal.Body>
	   	</Modal>
	)
}

export default ModalOptionImpresion