import React, { useState } from 'react'
import DataTable from 'react-data-table-component'

import Filter from './Filter'
import sucursal from '../Columnas/Sucursal'
import Progress from '../../Layouts/Progress'
import { useDispatch } from "react-redux"
import { getSelectedRowsSucursal } from "../../../actions/sucursalAction"

const Listado = ({data, isCargando, toast }) => {

	const columnas = sucursal.columnas
	const dispatch = useDispatch()
	const [clearTable, setClearTable ] = useState(false)

	const paginationOptions = {
		rowsPerPageText: 'Filas por Página',
		rangeSeparatorText:'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos'
	}

	const [filterText, setFilterText] = React.useState('')
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false)
	const filteredItems = data.filter(item => item.nombre && item.nombre.toString()?.toLowerCase().includes(filterText.toString()?.toLowerCase()));

  	const subHeaderComponentMemo = React.useMemo(() => {
    	const handleClear = () => {
	      	if (filterText) {
	        	setResetPaginationToggle(!resetPaginationToggle);
	        	setFilterText('');
	      	}
	    }
		return <Filter onFilter={e => setFilterText(e.target.value)}  onClear={handleClear} filterText={filterText} toast={toast} setClearTable={setClearTable} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, resetPaginationToggle])

	const handleChange = state => {
	    if(state.selectedCount>1){
			toast.error('Debe seleccionar solo una fila', {position: toast.POSITION.TOP_RIGHT})
			dispatch(getSelectedRowsSucursal([]))
			setClearTable(true)
			return false
		}else{
			dispatch(getSelectedRowsSucursal(state))
		}
   }

	return (
		<div className="table-component">
			<DataTable 
				columns={columnas}
				data={filteredItems}
			    pagination
			    paginationResetDefaultPage={resetPaginationToggle}
			    subHeader
			    paginationComponentOptions={paginationOptions}
      			subHeaderComponent={subHeaderComponentMemo}
      			persistTableHead
      			dense
      			progressPending={isCargando}
      			progressComponent={<Progress />}
				noDataComponent={<div>No hay datos registros disponible.</div>}
				selectableRows
          		onSelectedRowsChange={handleChange}
          		clearSelectedRows={clearTable}
          		selectableRowsHighlight={true}
			/>

		</div>
	)
}

export default Listado