import {
    LIST_DATA_BASIC_FACTURACION,
    SELECTED_CONFIG_DATA
} from "../types/basicFacturaType"

const INITIAL_STATE = {
	dataBasic: {
        id: "",
        razon_social_emisor: "",
        giro_emisor: "",
        direccion_emisor: "",
        ciudad_emisor: "",
        comuna_emisor: ""
    },
    listDataBasicFacturacion: []
}

const basicFacturaReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
        case LIST_DATA_BASIC_FACTURACION:
            return {
                ...state,
                listDataBasicFacturacion: action.payload.listData
            }
        case SELECTED_CONFIG_DATA:
            return {
                ...state,
                dataBasic: action.payload.data
            }
		default: return state
	}
}

export default basicFacturaReducer