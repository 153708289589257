import { 
	LISTADO_DATA_CTA,
	SELECTED_ROWS_CTA,
	IS_VISIBLE_FORMULARIO_CTA,
	SEARCH_CUENTA,
	DATA_EDIT_CUENTA,
	DATA_ADD_CUENTA,
	DATA_ACTIVAR_DESACTIVAR_CTA,
	DATA_DELETE_CTA
} from "../types/cuentaDepositoType"

const INITIAL_STATE = {
	cuenta: {},
	listadoCta: [],
	cIsCargando: true,
	cIsForm: false,
	cIsEdit: false,
	cDatos: {},
	cIsSelectedRows: [],
	cBtnActivar: {
		isVisible: false,
		nameBtn: "Desactivar",
		title: "Desactivado"
	}
}

const cuentaDepositoReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_CTA:
			return {
				...state,
				listadoCta: action.payload,
				cIsCargando: false
			}
		case IS_VISIBLE_FORMULARIO_CTA:
			return {
				...state,
				cIsForm: action.payload.isForm,
				cIsEdit: action.payload.isEdit,
				cDatos: action.payload.datos,
				cBtnActivar: action.payload.btnActivar,
				cIsSelectedRows: []
			}
		case SEARCH_CUENTA:
			return {
				...state,
				cDatos: action.payload.seletected,
				cIsForm: action.payload.isForm,
				cIsEdit: action.payload.isEdit,
				cBtnActivar: action.payload.btnActivar
			}
		case DATA_ADD_CUENTA:
			return{
				...state,
				listadoCta: action.payload.listado,
				cIsForm: action.payload.isForm
			}
		case DATA_EDIT_CUENTA:
			return {
				...state,
				listadoCta: action.payload.listado,
				cDatos: action.payload.datos,
				cIsForm: action.payload.isForm,
				cBtnActivar: action.payload.btnActivar
			}
		case SELECTED_ROWS_CTA:
			return {
				...state,
				cIsSelectedRows: action.payload.rows,
				cBtnActivar: action.payload.btnActivar
			}
		case DATA_ACTIVAR_DESACTIVAR_CTA:
			return {
				...state,
				cIsSelectedRows: action.payload.rows,
				cBtnActivar: action.payload.btnActivar,
				listadoCta: action.payload.listado
			}
		case DATA_DELETE_CTA:
			return {
				...state,
				listadoCta: action.payload
			}
		default: return state
	}
}

export default cuentaDepositoReducer