import { useState } from "react"
import {  
	Row,
	Col,
	Button
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import { ToastContainer, toast } from 'react-toastify'
import Usuario from "./Usuario"
import Cliente from "./Cliente"
import Cuenta from "./Cuenta"
import Role from "./Role"
import Camion from "./Camion"
import Sucursal from "./Sucursal"
import Conductor from "./Conductor"
import Correlativo from "./Correlativo"

import { useDispatch } from "react-redux"
import { isVisibleFormularioCliente } from "../../actions/clienteAction"
import { isVisibleFormularioUser } from "../../actions/userAction"
import { isVisibleFormularioCta } from "../../actions/cuentaDepositoAction"
import { isVisibleFormularioRole } from "../../actions/roleAction"
import { isVisibleFormularioCamion } from "../../actions/camionAction"
import { isVisibleFormularioSucursal } from "../../actions/sucursalAction"
import { isVisibleFormularioConductor } from "../../actions/conductorAction"
import { isVisibleFormularioCorrelativo } from "../../actions/correlativoAction"

const CardOpciones = () => {
	
	const dispatch = useDispatch()

	const [ configBtn, setConfigBtn ] = useState([
		{
			id: 1,
			color: "primary",
			text: "Roles",
			active: true
		},
		{
			id: 2,
			color: "secondary",
			text: "Usuarios",
			active: false
		},
		{
			id: 3,
			color: "secondary",
			text: "Sucursales",
			active: false
		},
		{
			id: 4,
			color: "secondary",
			text: "Cliente",
			active: false
		},
		{
			id: 5,
			color: "secondary",
			text: "Placas",
			active: false
		},
		{
			id: 6,
			color: "secondary",
			text: "Cuentas Depósitos",
			active: false
		},
		{
			id: 7,
			color: "secondary",
			text: "Conductores",
			active: false
		},
		{
			id: 8,
			color: "secondary",
			text: "Correlativos",
			active: false
		}
	])

	const changeColor = (event, id) => {
		const filtered =  configBtn.map(btn => {
			let color = "secondary"
			let active = false
			if(btn.id===id){
				color = "primary"
				active = true
			}


			if(btn.text==="Cliente"){
				dispatch(isVisibleFormularioCliente(false))
			}else if(btn.text==="Usuarios"){
				dispatch(isVisibleFormularioUser(false))
			}else if(btn.text==="Cuentas Depósitos"){
				dispatch(isVisibleFormularioCta(false))
			}else if(btn.text==="Roles"){
				dispatch(isVisibleFormularioRole(false))
			}else if(btn.text==="Placas"){
				dispatch(isVisibleFormularioCamion(false))
			}else if(btn.text==="Sucursales"){
				dispatch(isVisibleFormularioSucursal(false, false))
			}else if(btn.text==="Conductores"){
				dispatch(isVisibleFormularioConductor(false))
			}else if(btn.text==="Correlativos"){
				dispatch(isVisibleFormularioCorrelativo(false))
			}

			return {
				...btn, 
				color,
				active
			}
		})
		setConfigBtn(filtered)
	}



	return (
	    <Layout 
	    	titlePanel="Administrar"
	    	modulo="administrar"
	    >
	    	<div className="pl-3 pr-3">
		    	<Row>
		    		<Col xs={12} className="d-flex justify-content-between flex-wrap">
		    			
		    				{configBtn.length>0 && configBtn.map(btn => (
					    		<Button variant={btn.color} className="mt-3 btn-size-opciones" active key={btn.id} onClick={(event)=>changeColor(event, btn.id)}>
							    	{btn.text}
								</Button>
					    	))
					    	}
		    			
		    		</Col>

		    		{(configBtn.length>0 && configBtn[0].active) && (
		    			<Col xs={12}>
			    			<Role toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[1].active) && (
		    			<Col xs={12}>
			    			<Usuario toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[2].active) && (
		    			<Col xs={12}>
			    			<Sucursal toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[3].active) && (
		    			<Col xs={12}>
			    			<Cliente toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[4].active) && (
		    			<Col xs={12}>
			    			<Camion toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[5].active) && (
		    			<Col xs={12}>
			    			<Cuenta toast={toast} />
			    		</Col>
		    		)}

		    		{(configBtn.length>0 && configBtn[6].active) && (
		    			<Col xs={12}>
			    			<Conductor toast={toast} />
			    		</Col>
		    		)}
		    		
					{(configBtn.length>0 && configBtn[7].active) && (
		    			<Col xs={12}>
			    			<Correlativo toast={toast} />
			    		</Col>
		    		)}
		    	</Row>
	    	</div>
	    	<ToastContainer />
	    </Layout>
	)
}

export default CardOpciones
