import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Cuentas/Listado"
import Formulario from "../../components/Admin/Cuentas/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoCuenta } from "../../actions/cuentaDepositoAction"
import { getHome } from "../../actions/orderAction"


const Cliente = ({toast}) => {
	
	const dispatch = useDispatch()
	const { listadoCta, cIsCargando, cDatos, cIsForm, cIsSelectedRows } = useSelector((state) => state.ctaDeposito)

	useEffect(() => {
		dispatch(listadoCuenta())
		dispatch(getHome())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
			<CardRetiro title={!cIsForm ? "Listado de Cuentas" : "Cuentas" }>
				{!cIsForm ? (
					<Listado dataCta={listadoCta} isCargando={cIsCargando} toast={toast} isSelectedRows={cIsSelectedRows}  />
				): (
					<Formulario toast={toast} datos={cDatos} />	
				)}
		    				    
			</CardRetiro>
	    </>
	)
}

export default Cliente