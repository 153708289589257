import { useEffect } from 'react'
import CardRetiro from "../../components/Layouts/CardRetiro"
import Listado from "../../components/Admin/Sucursales/Listado"
import Formulario from "../../components/Admin/Sucursales/Formulario"

import { useDispatch, useSelector } from "react-redux"
import { listadoSucursal } from "../../actions/sucursalAction"

import Oficina from "./Oficina"

const Sucursal = ({toast}) => {
	
	const dispatch = useDispatch()
	const { suListado, suIsCargando, suDatos, suIsForm, suIsVisibleOficina } = useSelector((state) => state.sucursales)

	useEffect(() => {
		dispatch(listadoSucursal())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
	    <>
	    	{!suIsVisibleOficina ? (
	    			<CardRetiro title={!suIsForm ? "Listado de Sucursales" : "Sucursal" }>
						{!suIsForm ? (
							<Listado data={suListado} isCargando={suIsCargando} toast={toast} />
						): (
							<Formulario toast={toast} datos={suDatos} />	
						)}		    				    
					</CardRetiro>
	    		) : (
	    			<Oficina toast={toast} />
	    		)
	    	}			
	    </>
	)
}

export default Sucursal