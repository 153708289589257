import React, { useEffect } from 'react'
import Switch from "react-switch"

const SwitchToogle = ({ handleActivarDes, checked, coBtnActivar, setChecked }) => {


	useEffect(() => {
		const chk = coBtnActivar.nameBtn==="Activar" ? true : false
        setChecked(chk)
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coBtnActivar])

	return (
		<>
			<label htmlFor="small-radius-switch" className="small_radius-switch">
			  <Switch
			    checked={checked}
			    onChange={(event) => handleActivarDes(event)}
			    handleDiameter={30}
			    offColor="#6c757d"
			    onColor="#007bff"
			    offHandleColor="#fff"
			    onHandleColor="#fff"
			    height={38}
			    width={130}
			    borderRadius={5}
			    activeBoxShadow="0px 0px 1px 2px #007bff"
			    uncheckedIcon={
			      <div
			        style={{
			          display: "flex",
			          justifyContent: "center",
			          alignItems: "center",
			          height: "100%",
			          fontSize: 15,
			          color: "white",
			          paddingRight:2 ,
			          width: "30.5px"
			        }}
			      >
			        Desactivar
			      </div>
			    }
			    checkedIcon={
			      <div
			        style={{
			          display: "flex",
			          justifyContent: "center",
			          alignItems: "center",
			          height: "100%",
			          fontSize: 15,
			          color: "white",
			          paddingRight:2,
			          width: "98.5px"
			        }}
			      >
			        Activar
			      </div>
			    }

			    className="react-switch"
			    id="small-radius-switch"
			  />
			</label>
	</>
	)
} 

export default SwitchToogle