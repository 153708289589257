import React from "react"
import {  
	Row,
	Col,
	Spinner
} from 'react-bootstrap'

const LoadingSearch = () => {
	return (
		<Row>
			<Col className="text-center pt-3">
				<Spinner animation="grow" variant="dark" />
				<Spinner animation="grow" variant="dark" />
				<Spinner animation="grow" variant="dark" />
				<Spinner animation="grow" variant="dark" />
			</Col>
		</Row>
	)
}

export default LoadingSearch