import { useState, useEffect } from "react"
import {
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'
import FormAddConductor from "./Conductors/FormAddConductor"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addUserFile, editUserFile } from "../../../actions/userAction"
import { validarRut } from "../../../utils/rut"
import { onPaste, validateOnlyLetter } from "../../../utils/validationInput"
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";

const Formulario = ({ toast, datos }) => {

	const dispatch = useDispatch()
	const { register, handleSubmit, errors, reset, getValues, control, setValue } = useForm()
	const [isLoading, setIsLoading] = useState(false)
	const [oficina, setOficina] = useState([])
	const [typeRol, setTypeRol] = useState("")
	const [file, setFile] = useState("")
	const [ typeInput, setTypeInput ] = useState(false)
	const [ typeInputRep, setTypeInputRep ] = useState(false)
	const [isChangePhoto, setIsChangePhoto] = useState(false)
	const { users, roles, orders } = useSelector((state) => {
		return {
			users: state.users,
			roles: state.roles,
			orders: state.orders
		}
	})

	const { uIsEdit } = users
	const { listRole } = roles
	const { sucursales } = orders

	useEffect(() => {
		if (Object.keys(datos).length > 0) {
			cargarOficinas(undefined, datos.sucursal_id)
			reset({
				...getValues(),
				sucursal_id: datos.sucursal_id,
				comuna_id: datos.comuna_id,
				nombre_rol: datos.nombre_rol
			})
			setTypeRol(datos?.nombre_rol)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const cargarOficinas = (e, valor) => {

		const id = (e !== undefined) ? e.target.value : valor
		if (id !== "") {
			const detOficinas = sucursales.find(s => s.id === parseInt(id))
			const comunas = (detOficinas && detOficinas.comunas) ? detOficinas.comunas : []
			setOficina(comunas)
		}

	}

	const changeRol = (event) => {
		setTypeRol(event.target.value)
	}

	const onSubmit = async (data, e) => {
		try{
			setIsLoading(true)

			const sucursalSelected = document.getElementById("sucursal_id")
			const text_sucursal = sucursalSelected.options[sucursalSelected.selectedIndex].text
			const comunaSelected = document.getElementById("sucursal_id")
			const text_comuna = comunaSelected.options[comunaSelected.selectedIndex].text
	
			let response
			let body = {
				...data,
				comuna: text_comuna,
				sucursal: text_sucursal
			}
	
			let fecha = ""

			if (data.nombre_rol === "Conductor") {
				if (isChangePhoto && data?.documento[0].size > 2000000) {
					toast.error("El tamaño máximo del documento es 2MB", { position: toast.POSITION.TOP_RIGHT })
					setIsLoading(false)
					return false
				}
	
				const rut = data.rut
				const isValid = validarRut(rut)
				if (!isValid.status) {
					toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT })
					setIsLoading(false)
					return false
				}
	
				let fechaD = document.querySelector(".fecha_venc").value?.split("/")
				fecha = fechaD[2] + '-' + fechaD[1]  + '-' + fechaD[0] 

				body = {
					...body,
					fecha_vencimiento: document.querySelector(".fecha_venc").value
				}
	
			}
		
			let formData = new FormData()
			formData.append('fecha_vencimiento', fecha)
			formData.append('licencia_conducir', data.licencia_conducir ? data.licencia_conducir : "")
			formData.append('nombre_completo', data.nombre_completo)
			formData.append('rut', data.rut ? data.rut : "")
			formData.append('cambiar_foto', isChangePhoto)
	
			// if (uIsEdit || isChangePhoto) {
			if (data.nombre_rol === "Conductor" || isChangePhoto) {
				if(data.documento){
					formData.append('documento', data.documento[0])
				}				
			}
	
			formData.append('comuna_id', body.comuna_id)
			formData.append('sucursal_id', body.sucursal_id)
			formData.append('nombre', body.nombre)
			formData.append('surname', body.surname)
			formData.append('nombre_rol', body.nombre_rol)
			formData.append('email', body.email)
			formData.append('password', body.password)
	
			if (uIsEdit) {
				response = await dispatch(editUserFile(formData, body))
			} else {
				response = await dispatch(addUserFile(formData, body))
			}

			if (response.status) {
				toast.success(response.message, { position: toast.POSITION.TOP_RIGHT })
			} else {
				toast.error(response.message, { position: toast.POSITION.TOP_RIGHT })
			}
			setIsLoading(false)
		}catch(error){
			toast.error("Hubo un error con los datos del usuario", { position: toast.POSITION.TOP_RIGHT })
		}		
	}

	const isVisiblePanel = () => {
		setIsChangePhoto(!isChangePhoto)
	}

	const handleFile = (event) => {
		if (!event.target.files || event.target.files.length === 0) {
			setFile(undefined)
			return false
		}
		setFile(URL.createObjectURL(event.target.files[0]))
	}

	return (
		<Form id="frmUsuario" onSubmit={handleSubmit(onSubmit)}>
			<Card border="Light">
				<Card.Header as="h6" className="bg-warn-blue text-dark">{!uIsEdit ? 'CREAR USUARIO' : 'EDITAR USUARIO'}</Card.Header>
				<Card.Body className="bg-soft-blue padding-user">
					<Row>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="nombre" >
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type="text"
									name="nombre"
									autoFocus
									defaultValue={datos?.nombre || ""}
									ref={register({ required: { value: true, message: 'Requerido' } })}
									onKeyPress={(event) => validateOnlyLetter(event)}
									onPaste={(event) => onPaste(event)}
									maxLength={100}
								/>
								{errors.nombre && (
									<Form.Text className="text-danger">
										{errors?.nombre?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="surname" >
								<Form.Label>Apellido</Form.Label>
								<Form.Control
									type="text"
									name="surname"
									defaultValue={datos?.surname || ""}
									ref={register({ required: { value: true, message: 'Requerido' } })}
									onKeyPress={(event) => validateOnlyLetter(event)}
									onPaste={(event) => onPaste(event)}
									maxLength={100}
								/>
								{errors.surname && (
									<Form.Text className="text-danger">
										{errors?.surname?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="sucursal_id" >
								<Form.Label>Sucursal</Form.Label>
								<Form.Control
									as="select"
									onChange={(event) => cargarOficinas(event)}
									name="sucursal_id"
									ref={register({ required: { value: true, message: 'Requerido' } })}
								>
									<option value="">Seleccione</option>
									{
										sucursales.length > 0 && (
											sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
										)
									}
								</Form.Control>
								{errors.sucursal_id && (
									<Form.Text className="text-danger">
										{errors?.sucursal_id?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="comuna_id" >
								<Form.Label>Comuna</Form.Label>
								<Form.Control
									as="select"
									name="comuna_id"
									ref={register({ required: { value: true, message: 'Requerido' } })}
								>
									<option value="">Seleccione</option>
									{
										oficina.length > 0 && (
											oficina.map(oficina => <option key={oficina.id} value={oficina.id}>{oficina.nombre}</option>)
										)
									}
								</Form.Control>
								{errors.comuna_id && (
									<Form.Text className="text-danger">
										{errors?.comuna_id?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="nombre_rol" >
								<Form.Label>Rol</Form.Label>
								<Form.Control
									as="select"
									name="nombre_rol"
									onChange={(event) => changeRol(event)}
									ref={register({ required: { value: true, message: 'Requerido' } })}
								>
									<option value="">Seleccione</option>
									{
										listRole.length > 0 && (
											listRole.map(rol => <option key={rol.id} value={rol.name}>{rol.name}</option>)
										)
									}
								</Form.Control>
								{errors.nombre_rol && (
									<Form.Text className="text-danger">
										{errors?.nombre_rol?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
						<Col lg={6} md={6} sm={12}>
							<Form.Group controlId="email" >
								<Form.Label>E-mail</Form.Label>
								<Form.Control
									type="email"
									name="email"
									defaultValue={datos?.email || ""}
									disabled={uIsEdit ? true : false}
									ref={register({
										required: { value: true, message: 'Requerido' },
										//eslint-disable-next-line
										pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
									})} 
									maxLength={100}
								/>
								{errors.email && (
									<Form.Text className="text-danger">
										{errors?.email?.message}
									</Form.Text>
								)}
							</Form.Group>
						</Col>
					</Row>
					{!uIsEdit && (
						<Row>
							<Col lg={6} md={6} sm={12}>
								<Form.Group controlId="password" >
									
									<Form.Label>Contraseña</Form.Label>
									<div className="mb-3 p-relative">
										<Form.Control 
											type={!typeInput ? "password" : "text" } 
											name="password" 
											ref={register({ required: { value: true, message: 'Requerido' } })}
											maxLength={20}
										/>
										<div
											className="visible-password" 
											onClick={() => setTypeInput(!typeInput) }
										>
											{ !typeInput ? <BiHide style={{fontSize: '20px'}} /> : <BiShow  style={{fontSize: '20px'}} /> }
										</div>
									</div>
									{errors.password && (
										<Form.Text className="text-danger">
											{errors?.password?.message}
										</Form.Text>
									)}
								</Form.Group>
							</Col>
							<Col lg={6} md={6} sm={12}>
								<Form.Group controlId="repPassword" >
									<Form.Label>Repetir Contraseña</Form.Label>
									<div className="mb-3 p-relative">
										<Form.Control 
											type={!typeInputRep ? "password" : "text" } 
											name="repPassword"
											ref={register({
												required: { value: true, message: 'Requerido' },
												validate: value => {
													if (value === getValues()["password"]) {
														return true;
													} else {
														return "Las claves no coinciden";
													}
												}
											})}
											maxLength={20}
										/>
										<div
											className="visible-password" 
											onClick={() => setTypeInputRep(!typeInputRep) }
										>
											{ !typeInputRep ? <BiHide style={{fontSize: '20px'}} /> : <BiShow  style={{fontSize: '20px'}} /> }
										</div>
									</div>
									{errors.repPassword && (
										<Form.Text className="text-danger">
											{errors?.repPassword?.message}
										</Form.Text>
									)}
								</Form.Group>
							</Col>
						</Row>
					)}
					{
						typeRol === "Conductor" && (
							<FormAddConductor
								register={register}
								datos={datos}
								errors={errors}
								control={control}
								setValue={setValue}
								setFile={setFile}
								file={file}
								coIsEdit={uIsEdit}
								isVisiblePanel={isVisiblePanel}
								isChangePhoto={isChangePhoto}
								handleFile={handleFile}
							/>
						)
					}
				</Card.Body>
			</Card>
			<Row>
				<Col xs={12} className="text-right mt-3">
					<Form.Group as={Col}>
						<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
							{isLoading ? 'Procesando...' : uIsEdit ? 'Modificar' : 'Guardar'}
						</Button>
					</Form.Group>
				</Col>
			</Row>
		</Form>
	)
}

export default Formulario