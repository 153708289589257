import Layout from "../../components/Layouts/Layout"
import ModalSpinner from "../../components/Custom/Spinner/ModalSpinner"
import FormRemitente from "../../components/Retiros/CompletedPreOrden/FormRemitente"
import TableDestination from "../../components/Retiros/CompletedPreOrden/TableDestination"
import ModalPreOrdenAdmin from "../../components/Retiros/CompletedPreOrden/Admin/ModalPreOrdenAdmin"
import AnularRetiro  from "../../components/Retiros/AnularRetiro"

import { ToastContainer, toast } from 'react-toastify'

import { useParams, Link } from 'react-router-dom'
import { validarRut } from "../../utils/rut"
import { useCancelRetiro } from "../../hooks/Retiro/useCancelRetiro"
import { useDetailRetiro } from "../../hooks/Retiro/useDetailRetiro"
import { usePermission } from "../../hooks/Permission/usePermission"


const CompletedRetiro = () => {

	const { numero_retiro } = useParams()
	const { validarPermiso } = usePermission()

	const { detRetiro, preordenSelected, isLoadingDetRetiro, documentacionsCarga, 
		 forma_pago, openCloseModal, show, handleSelectedPreOrden, handleCompletedPreOrden,
		  setShow,  titleDetailRetiro, searchCliente } = useDetailRetiro(numero_retiro, toast)
	
	const { anularModal, handleAnular, onSubmit, 
		register, handleSubmit, errors, loading } = useCancelRetiro()


	return (
		<Layout 
	    	titlePanel="Detalle Retiros"
	    	modulo="retiros"
	    >
	    	<div className="card mb-5 mt-3 card-retiro shadow-sm">
	    		<div className="card-header card-header-white card-retiro-completed">
				    <h6 className="mb-0">
				    	{titleDetailRetiro}
				    </h6>
				    <div>
				    	<Link to="/retiros/busqueda">
				    		<button className="btn btn-warning">Regresar</button>
				    	</Link>
				    	{detRetiro?.ultimo_estado!=="Anulado" &&
				    		validarPermiso("anular_retiro")  ?
				    		<button 
					    		className="btn btn-outline-warning pl-4 pr-4 ml-3" 
					    		onClick={(event)=> handleAnular(event, { type: "retiro", numero: numero_retiro})}
					    	>
					    		Anular
					    	</button>
					    	: null
					    }
					    <Link to={`/retiros/${numero_retiro}/preordenes`}>
				    		<button className="btn btn-outline-warning pl-4 pr-4 ml-3">Imprimir</button>
				    	</Link>
				    </div>
				</div>
				<div className="card-body">
				  	<FormRemitente 
				  		detRetiro={detRetiro} 
				  		isAdmin={validarPermiso("editar_retiro")}
				  		searchCliente={searchCliente}
				  		validarRut={validarRut}
				  		toast={toast}
						forma_pago={forma_pago}
				  	/>
					<div className="row">  	
						<div className="col-12">
						  	<p className="bg-warn-blue mb-0 fw-600 text-center p-0 mb-4">Destinatarios</p>
						  	<TableDestination 
						  		openCloseModal={openCloseModal} 
						  		handleSelectedPreOrden={handleSelectedPreOrden}
						  		handleCompletedPreOrden={handleCompletedPreOrden} 
						  		detRetiro={detRetiro} 
						  		isAdmin={validarPermiso("editar_retiro")}
						  		handleAnular={handleAnular}
						  	/>
						</div>
				    </div>
				</div>
	    	</div>
	    	<ModalPreOrdenAdmin
	    		completedModal={show} 
	    		openCloseModalCompleted={openCloseModal} 
	    		preordenSelected={preordenSelected} 
	    		toast={toast}
	    		setCompletedModal={setShow}
	    		documentacionsCarga={documentacionsCarga}
	    		forma_pago={forma_pago}
	    		isAdmin={validarPermiso("editar_retiro")}
	    		searchCliente={searchCliente}
	    		validarRut={validarRut}
	    		detRetiro={detRetiro}
	    	/>
	    	{ anularModal && 
	    		<AnularRetiro 
	    			show={anularModal}
	    			numero_documento={numero_retiro} 
	    			handleAnular={handleAnular}
	    			onSubmit={onSubmit} 
	    			register={register} 
	    			handleSubmit={handleSubmit} 
	    			errors={errors} 
	    			loading={loading}
	    		/>
	    	}
	    	<ToastContainer />

	    	{isLoadingDetRetiro && 
	    		<ModalSpinner 
		    		spinnerShow={isLoadingDetRetiro}
		    		description="Buscando el detalle del retiro..."
		    	/>
		    }

	    </Layout>
	)
}

export default CompletedRetiro