import React, { useState, useEffect, forwardRef } from "react"
import {  
	Form,
	Button,
	Col,
	Row,
	InputGroup,
	FormControl
} from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import PanelDetalle from "../Layouts/PanelDetalle"
import { withRouter} from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux"
import { dataSearch, searchGlobalRendicion } from "../../actions/rendirAction"
import { useForm } from "react-hook-form"

import { usePermission } from "../../hooks/Permission/usePermission"
import dayjs from 'dayjs';
import { isAfter, getDateYMD, isBefore, isSame } from '../../utils/formatFecha';
import { BiCalendar } from "react-icons/bi";

const defaultValues = {
  fecha_desde: new Date(),
  fecha_hasta: new Date(),
  sucursal_search_id: ""
};
registerLocale('es', es)


const SearchGlobal = ({ toast, history, location }) => {

	const dispatch = useDispatch()
	const { validarPermiso } = usePermission()
	const { handleSubmit, register } = useForm({defaultValues})
	const [ isBuscar, setIsBuscar ] = useState(false)
	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(new Date())

	const { users, orders, rendir } = useSelector((state) => {
		return {
			users: state.users,
			orders: state.orders,
			rendir: state.rendir
		}
	})

	const { sucursales  } = orders
	const { sucursal_id  } = users
	const { busqueda } = rendir

	
	const closeStartDate = (type) => {
		let fecha 
		if(type==="desde"){
			fecha = startDate
		}else{
			fecha = endDate
		}
		let fechaActual = fecha.getFullYear() + '-' + (fecha.getMonth() + 1) + '-' + fecha.getDate()

		dispatch(dataSearch(type, fechaActual, true))
	}

	const onSubmit = async (data, e) => {
		setIsBuscar(true)

		const { value } = document.getElementById("sucursal_search_id")
		let sucursal_id = (value!=="") ? parseInt(value) : null
		
		/**let fechaD = getValues("fecha_desde")
		let fechaDesde = fechaD.getFullYear() + '-' + (fechaD.getMonth() + 1) + '-' + fechaD.getDate()

		let fechaH = getValues("fecha_hasta")
		let fechaHasta = fechaH.getFullYear() + '-' + (fechaH.getMonth() + 1) + '-' + fechaH.getDate()**/

		const information = {
			desde:dayjs(startDate).format('YYYY-MM-DD'),
			hasta: dayjs(endDate).format('YYYY-MM-DD'),
			sucursal_id 
		}

		const response = await dispatch(searchGlobalRendicion(information))

		if(response.status){
			setIsBuscar(false)
			history.push("/rendir/sucursal/print")
		}else{
			setIsBuscar(false)
			toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
		}
	}

	useEffect(() => {
		if(Object.keys(busqueda).length===0){
			document.getElementById('sucursal_search_id').value=sucursal_id
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursales])

	useEffect(() => {
		
		if(Object.keys(busqueda).length>2 && busqueda?.desde!=="" && busqueda?.hasta!==""){
			
			let fecha = new Date(busqueda?.desde)
		
			fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
			setStartDate(fecha)

			let fechaEnd = new Date(busqueda?.hasta)
		
			fechaEnd.setMinutes(fechaEnd.getMinutes() + fechaEnd.getTimezoneOffset())
			setEndDate(fechaEnd)

			document.getElementById('sucursal_search_id').value=busqueda?.sucursal_id ?? sucursal_id
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [busqueda])
	
	const validateStartDateBlur = () => {
		const dateStart = getDateYMD(dayjs(startDate).format('DD/MM/YYYY'))

		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			return false
		}

		if (!isSame(dateStart.dateSelected, startDate) && isAfter(dateStart.dateSelected, endDate)) {
			toast.error('La fecha inicial no puede ser superior a la fecha final', {position: toast.POSITION.TOP_RIGHT})
			setStartDate(new Date())
			setEndDate(new Date())
			return false
        }

		closeStartDate("desde")
	}

	const validateEndDateBlur = () => {
		const dataEnd = getDateYMD(dayjs(endDate).format('DD/MM/YYYY'))

		if(dataEnd.error){
			toast.error(dataEnd.message, {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
		}

		if (!isSame(dataEnd.dateSelected, dayjs(startDate).format('YYYY-MM-DD')) && isBefore(dataEnd.dateSelected, startDate)) {
			toast.error('La fecha final no puede ser inferior a la fecha inicial', {position: toast.POSITION.TOP_RIGHT})
			setEndDate(new Date())
			return false
        }

		closeStartDate("hasta")
	}

	const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				className="date_from"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
		<InputGroup>
			<FormControl
				name="fecha_orden_c"
				className="date_to"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<Row>
			<Col className="col-md-11 mx-auto pb-3 pt-3 pad-row">
				<PanelDetalle title="BUSCADOR DE RENDICIONES" subtitle="" check={false}>
					<Form id="frmBusquedaGlobal" role="form" onSubmit={handleSubmit(onSubmit)}>
						<Form.Row className="pl-3 pt-3 pb-4 shadow-sm border mr-3 frmSearch">
							<Col md xs={12}>
							 	<Form.Group as={Col} controlId="sucursal_search_id" className="pr-0">
							    	<Form.Label>Sucursal</Form.Label>
									<Form.Control 
									    	as="select"
									    	name="sucursal_search_id" 
									    	disabled={!validarPermiso("cambiar_sucursal")}
									    >
								    	<option value="">Seleccione</option>
								    	{
								    		sucursales.length > 0 && (
								    			sucursales.map(sucursal => <option key={sucursal.id} value={sucursal.id}>{sucursal.nombre}</option>)
								    		)
								    	}
								 	</Form.Control>
							    </Form.Group>
							</Col>
							 <Col md xs={12}>
							 	<Form.Group as={Col} controlId="fecha_desde" className="pl-0 pr-0">
							    	<Form.Label>Desde</Form.Label>
									<DatePicker 
										type="text" 
										dateFormat="dd/MM/yyyy"
										selected={startDate} 
										onChange={date => setStartDate(date)} 
										className="form-control"
										name="fecha_desde" 
										id="fecha_desde"
										ref={register}
										locale="es"
										maxDate={new Date()}
										onCalendarClose={() => validateStartDateBlur()}
										customInput={<ExampleCustomInputFrom />}
									/>
							    </Form.Group>
							</Col>
							<Col md xs={12}>
							  	<Form.Group as={Col} controlId="fecha_hasta" className="pr-1 pl-0">
							  		<Form.Label>Hasta</Form.Label>
									  <DatePicker 
										type="text" 
										dateFormat="dd/MM/yyyy"
										selected={endDate} 
										onChange={date => setEndDate(date)} 
										className="form-control"
										name="fecha_hasta" 
										id="fecha_hasta"
										ref={register}
										locale="es"
										maxDate={new Date()}
										onCalendarClose={() => validateEndDateBlur()}
										customInput={<ExampleCustomInputTo />}
									/>									
							    </Form.Group>
							</Col>
						    

						    <Col>
						     	<Form.Group as={Col} className="mt-4 pl-0">
						  			<Button variant="warning" type="submit" block disabled={isBuscar}> 
							    		{isBuscar ? 'BUSCANDO...' : 'VER RENDICIÓN' }
							  		</Button>
							    </Form.Group>
						    </Col>
						</Form.Row>
					</Form>
				</PanelDetalle>
			</Col>
		</Row>
	)
}

export default withRouter(SearchGlobal)