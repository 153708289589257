import { onPaste, validateOnlyNumber } from '../../../utils/validationInput';

const DetailClient = ({ register, getDocumentClient, data, isEdit }) => {


	  
	return (
		<>
			<h6 className="mt-4">Detalle del Cliente</h6>
			<div className="row mt-4">
				<div className="col-3">
					<div className="form-group">
					    <label htmlFor="internal_code">Código interno</label>
					    <div className="input-group mb-3">
						  <input 
						  	type="text" 
						  	className="form-control" 
						  	placeholder="Buscar cliente" 
						  	aria-label="Buscar cliente" 
						  	aria-describedby="internal_code_search"
						  	id="internal_code"
						  	name="internal_code"
						  	ref={register}
						  	readOnly={isEdit}
							maxLength={3}
							onKeyPress={(event) => validateOnlyNumber(event)}
							onPaste={(event) => onPaste(event)}
						  />
						  {
						  	!isEdit && (
						  		<div className="input-group-append">
								    <button 
								    	className="btn btn-warning btn-sm" 
								    	type="button" 
								    	id="internal_code_search"
								    	onClick={(event)=>getDocumentClient(event)}
								    >Buscar</button>
								  </div>
						  	)
						  }
						</div>
					</div>
				</div>
				<div className="col-3">
					<div className="form-group">
					    <label htmlFor="rut">RUT</label>
					    <input 
					    	type="text" 
					    	className="form-control"
					    	id="rut" 
					    	name="rut"
					    	ref={register}
					    	defaultValue={data?.rut}
					    	readOnly
					    />
					  </div>
				</div>
				<div className="col-6">
					<div className="form-group">
					    <label htmlFor="full_name">Razón Social</label>
					    <input 
					    	type="text" 
					    	className="form-control" 
					    	id="full_name"
					    	name="full_name"
					    	ref={register}
					    	defaultValue={data?.full_name}
					    	readOnly
					    />
					  </div>
				</div>
			</div>
			<div className="row">
				<div className="col-3">
					<div className="form-group">
					    <label htmlFor="telephone">Contacto</label>
					    <input 
					    	type="text" 
					    	className="form-control" 
					    	id="telephone"
					    	name="telephone"
					    	ref={register}
					    	defaultValue={data?.telephone}
					    	readOnly
					    />
					  </div>
				</div>
				<div className="col-3">
					<div className="form-group">
					    <label htmlFor="email">E-mail</label>
					    <input 
					    	type="email" 
					    	className="form-control" 
					    	id="email"
					    	name="email"
					    	defaultValue={data?.email}
					    	readOnly
					    />
					  </div>
				</div>
			</div>
		</>
	)
}

export default DetailClient