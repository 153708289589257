import React from "react"
import {  
	Row,
	Button,
	Col
} from 'react-bootstrap'
import Layout from "../../components/Layouts/Layout"
import { withRouter} from 'react-router-dom'
import { useSelector } from "react-redux"

const baseURL = process.env.REACT_APP_API_URL 

const PreviewNomina = (props) => {

	const { typeImpresion } = useSelector((state) => state.nominas)
	
	const { 
			match: { params: numero }
		} = props
	
	const redirectPage = () => {
		props.history.push('/nomina/create')
	}

	return (
	    <Layout 
	    	titlePanel="NOMINA DE CARGA"
	    	modulo="crear_nomina"
	    >
	    	 <Row>
				<Col xs={12} className="mb-3 mt-3 text-right"> 
		  			<Button variant="warning" type="button" onClick={(event) => redirectPage(event)}>
			    		Regresar
			  		</Button>				
				</Col>
				<Col xs={12}>
			    <div className="embed-responsive embed-responsive-16by9">
				  <iframe className="embed-responsive-item" title="Carga Nomina" src={`${baseURL}/nominas/print/${numero.numero}/${typeImpresion}`} width="100%" height="300px"></iframe>
				</div>
				</Col>
			</Row>
					    	
	    </Layout>
	)
}

export default withRouter(PreviewNomina)