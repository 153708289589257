import React from "react"
import {  
	Button
} from 'react-bootstrap'
import { formatNumberCurrency } from "../../../utils/validationInput"

const TbodyDeposito = ({ index, fecha, cuenta_deposito_text, tipo_rendir_text, monto, formatMonto, isPermisoEliminarDeposito, deleteRow }) => {


	return (
		<tr key={index}>
	      <td>
            {fecha}
			</td>
			<td>
	      	{cuenta_deposito_text}
	      </td>
	      <td>
	      	{tipo_rendir_text}
	      </td>
	      <td>
	      	$ {formatNumberCurrency(monto)}
	      </td>
	      {isPermisoEliminarDeposito && (
      		<td>
		      	<Button variant="danger" type="button" size="sm" onClick={(event)=>deleteRow(event, index)}>
				    X
				</Button>
		      </td>
	      )}
	      
	    </tr>

	)
}

export default TbodyDeposito
