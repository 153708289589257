import { 
	LISTADO_DATA_ROLE,
	DATA_DELETE_ROLE,
	IS_VISIBLE_FORMULARIO_ROLE,
	SELECTED_ROWS_ROLE,
	SEARCH_ROLE,
	DATA_ADD_ROLE,
	DATA_EDIT_ROLE
} from "../types/roleType"

const INITIAL_STATE = {
	rol: {},
	listRole: [],
	rIsCargando: true,
	rIsForm: false,
	rIsEdit: false,
	rDatos: {},
	rIsSelectedRows: [],
	listPermisos: []
}

const roleReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LISTADO_DATA_ROLE:
			return {
				...state,
				listRole: action.payload.roles,
				rIsCargando: false,
				listPermisos: action.payload.permisos
			}
		case SELECTED_ROWS_ROLE:
			return {
				...state,
				rIsSelectedRows: action.payload
			}
		case IS_VISIBLE_FORMULARIO_ROLE:
			return {
				...state,
				rIsForm: action.payload.isForm,
				rIsEdit: action.payload.isEdit,
				rDatos: action.payload.datos
			}
		case DATA_DELETE_ROLE:
			return {
				...state,
				listRole: action.payload
			}
		case SEARCH_ROLE:
			return {
				...state,
				rDatos: action.payload.seletected,
				rIsForm: action.payload.isForm,
				rIsEdit: action.payload.isEdit
			}
		case DATA_ADD_ROLE:
			return{
				...state,
				listRole: action.payload.listado,
				rIsForm: action.payload.isForm
			}
		case DATA_EDIT_ROLE:
			return {
				...state,
				listRole: action.payload.listado,
				rDatos: action.payload.datos,
				rIsForm: action.payload.isForm
			}
		default: return state
	}
}

export default roleReducer