import Editar from "../../assets/images/configurar/edit.png"



const TableFacturacion = ({ listDataBasicFacturacion, saveDataEdit}) => {
	

	return (
	    <div className="row mt-4"> 
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-bordered table-config-fact">
                        <thead>
                            <tr>
                            <th scope="col">Razón Social</th>
                            <th scope="col">Giro</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Ciudad</th>
                            <th scope="col">Comuna</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listDataBasicFacturacion.length > 0 &&
                                listDataBasicFacturacion.map((item, key) => (
                                    <tr key={key}>
                                        <td>{item.razon_social_emisor}</td>
                                        <td>{item.giro_emisor}</td>
                                        <td>{item.direccion_emisor}</td>
                                        <td>{item.ciudad_emisor}</td>
                                        <td>{item.comuna_emisor}</td>
                                        <td>{item.estatus_name}</td>        
                                        <td className="text-center">
                                            <button 
                                                type="button" 
                                                className="btn btn-info btn-sm"
                                                onClick={()=>saveDataEdit(item)}
                                            >
                                                <img src={Editar} alt="Editar" className="img-conf" />
                                            </button> 
                                            
                                        </td>                                                    
                                    </tr>
                                ))
                            }  
                            {
                                listDataBasicFacturacion.length===0 && (
                                    <tr>
                                        <td colSpan={7}>No hay registros disponibles</td>                                                
                                    </tr>
                                )
                            }                                              
                        </tbody>
                    </table>
                </div>
            </div> 
        </div> 
	)
}

export default TableFacturacion