import {  
	Modal,
	Button
} from 'react-bootstrap'
import ClienteDestinatario from "./ClienteDestinatario"
import DetalleCarga from "./DetalleCarga"

import { useRetiroDestino } from '../../../../hooks/Retiro/useRetiroDestino'

const ModalDestino = (props) => {

	const { register, handleSubmit, errors, onSubmit, setValue, saveCarga,
		deleteRow, editRow, infoDestino, getValues, control } = useRetiroDestino(props.toast)

	return (
		 <Modal
		 	show={props.show} 
			onHide={props.onHide}
		      size="lg"
		      aria-labelledby="contained-modal-title-vcenter"
		      centered
		    >
		      <Modal.Header closeButton>
		        <Modal.Title id="contained-modal-title-vcenter">
		          Destinatario
		        </Modal.Title>
		      </Modal.Header>
		      <form id="frmDestino" onSubmit={handleSubmit(onSubmit)}>
		      <Modal.Body>
		        <ClienteDestinatario 
					register={register} 
					errors={errors} 
					setValue={setValue} 
					searchcliente={props.searchcliente} 
					validarrut={props.validarrut} 
					toast={props.toast} 
					sucursales={props.sucursales}
					oficinadestino={props.oficinadestino}
					cargaroficinas={props.cargaroficinas}
					infoDestino={infoDestino}
					control={control}
					tipo_envio={props.tipo_envio}
				/>
				<DetalleCarga 
					saveCarga={saveCarga}
					errors={errors}
					cargas={infoDestino?.cargas}
					register={register}
					deleteRow={deleteRow}
					editRow={editRow}
					setValue={setValue}
					getValues={getValues}
					control={control}
				/>
		      </Modal.Body>
		      <Modal.Footer>
		        <Button className="btn btn-secondary" onClick={props.onHide}>Cerrar</Button>
		        <Button type="submit" className="btn btn-warning">Guardar</Button>
		      </Modal.Footer>
		      </form>
		    </Modal>
	)
}

export default ModalDestino