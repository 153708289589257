import React, { useEffect, useState } from "react"
import {  
	Row,
	Col,
	Nav,
	Navbar,
	NavDropdown
} from 'react-bootstrap'
import logo from "../../assets/images/logo.png"
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { loadUser, logout } from "../../actions/userAction"
import { getPermissionsUser } from "../../actions/configAction"

import user from "../../assets/images/user.png"
import truck from "../../assets/images/truck.svg"
import shoppingList from "../../assets/images/menu/shopping_list.svg"
import calculator from "../../assets/images/menu/calculator.svg"
import packageList from "../../assets/images/menu/package.svg"
import delivery from "../../assets/images/menu/delivery.svg"
import question from "../../assets/images/menu/question.svg"
import settings from "../../assets/images/settings.png"
import factura from "../../assets/images/menu/document.svg"
import guiaDespacho from "../../assets/images/menu/delivery_note.svg"
import dashboardImg from "../../assets/images/menu/dashboard.svg"
import { FaChevronLeft } from 'react-icons/fa'

import { usePermission } from "../../hooks/Permission/usePermission"

const version = process.env.REACT_APP_VERSION

const Layout = ({ titlePanel, titleUser, children, history, location, isIcon=false, ruta, modulo }) => {
	

	const [ classHome, setClassHome ] = useState("bg-fondo-primary")
	const [ visibleMenu, setVisibleMenu ] = useState(false)
	const { validarPermiso } = usePermission()

	const { name  } = useSelector((state) => state.users)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadUser())
		dispatch(getPermissionsUser())

		if(location.pathname==="/home"){
			setClassHome("bg-fondo-home")
		}else{
			setClassHome("bg-fondo-primary")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name]) 

	const logoutUser = async (event) => {
		event.preventDefault()
		const response = await dispatch(logout())
		if(response.status){
			history.push('/')
            window.location.reload()
		}
	}


	const getNameUser = () => {
		return <><img src={user} alt="user" /> <span className="text-white"> {name}</span></>
	}

	const hiddenMenu = () => {
		setVisibleMenu(!visibleMenu)
	}

	return (
	   <>
	   		<nav id="sidebar" className={`menu-left ${visibleMenu ? 'active' : ''}`}>
	   			<div className="menu-left--image">
   					<Link  to="/home">
   						<img src={logo} alt="Logo" className="logo--menu" />
   					</Link>
   				</div>
	            <ul className="list-unstyled components">
	            	<li className={`${modulo==='dashboard' ? 'active' : ''} `}>
	                    <a href="/home" className="nav-item-menu">
	                        <img src={dashboardImg} alt="Orden de flete manual" className="img-item-menu" />
	                        <span className="nav-text font-weight-bold">Dashboard</span>
	                    </a>
	                    <div className="pt-0 mt-2 nav-link">
	                        <hr className="mt-0 mb-0 border-hr" />
	                    </div>
	                </li>
	            	{validarPermiso("crear_orden_flete") && (
						<li className={`${modulo==='orders_create' ? 'active' : ''} `}>
		                    <a href="/orders/create" className="nav-item-menu" >
		                        <img src={truck} alt="Orden de flete manual" className="img-item-menu" />
		                        <span className="nav-text">Orden de flete manual</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
					)}
	                
	                {validarPermiso("consultar_ordenes_fletes") && (
		   				<li className={`${modulo==='orders_search' ? 'active' : ''} `}>
		                    <a href="/orders/search" className="nav-item-menu">
		                        <img src={question} alt="Consulta de órdenes" className="img-item-menu" />
		                        <span className="nav-text">Consulta de órdenes</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}   

		   			{validarPermiso("ordenes_recibidas") && (
		   				<li className={`${modulo==='orden_receive' ? 'active' : ''} `}>
		                    <a href="/orders/receive" className="nav-item-menu">
		                        <img src={question} alt="Órdenes Recibidass" className="img-item-menu" />
		                        <span className="nav-text">Órdenes Recibidas</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}  

					{validarPermiso("ordenes_recibidas") && (
		   				<li className={`${modulo==='orden_incomplete' ? 'active' : ''} `}>
		                    <a href="/orders/incompletas" className="nav-item-menu">
		                        <img src={question} alt="Órdenes Incompletas" className="img-item-menu" />
		                        <span className="nav-text">Órdenes Incompletas</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}  
	                
	                {validarPermiso("crear_nomina") && (
		   				<li className={`${modulo==='crear_nomina' ? 'active' : ''} `}>
		                    <a href="/nomina/create" className="nav-item-menu">
		                        <img src={shoppingList} alt="Crear nómina" className="img-item-menu" />
		                        <span className="nav-text">Crear nómina</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

					{validarPermiso("crear_nomina") && (
		   				<li className={`${modulo==='receive_ticket' ? 'active' : ''} `}>
		                    <a href="/recibir/paquete" className="nav-item-menu">
		                        <img src={shoppingList} alt="Crear nómina" className="img-item-menu" />
		                        <span className="nav-text">Recibir bultos</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{validarPermiso("guia_despacho") && (
		   				<li className={`${modulo==='guia_despacho' ? 'active' : ''} `}>
		                    <a href="/guide" className="nav-item-menu">
		                        <img src={guiaDespacho} alt="Guía despacho" className="img-item-menu" />
		                        <span className="nav-text">Guía despacho</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{(validarPermiso("entregar_orden") || validarPermiso("devolver_orden") || validarPermiso("factura_a_credito") || validarPermiso("ordenes_por_pagar")) && (
		   				<li className={`${modulo==='entregar_devolver' ? 'active' : ''} `}>
		                    <a href="/orders/deliver" className="nav-item-menu entrega-menu">
		                        <img src={delivery} alt="Entregar / Devolver" />
		                        <span className="nav-text pl-1">Entregar / Devolver</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}
	                
	                {validarPermiso("cerrar_caja") && (
		   				<li className={`${modulo==='cerrar_caja' ? 'active' : ''} `}>
		                    <a href="/rendir/sucursal" className="nav-item-menu">
		                        <img src={calculator} alt="Rendición de caja" className="img-item-menu" />
		                        <span className="nav-text">Rendición de caja</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}
	               
	                {validarPermiso("facturacion_cliente") && (
		   				<li className={`${modulo==='facturacion_cliente' ? 'active' : ''} `}>
		                    <a href="/facturacion" className="nav-item-menu">
		                        <img src={factura} alt="Facturación Cliente" className="img-item-menu" />
		                        <span className="nav-text">Facturación Cliente</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{validarPermiso("busqueda_factura_corriente") && (
		   				<li className={`${modulo==='busqueda_factura_corriente' ? 'active' : ''} `}>
		                    <a href="/facturacion/busqueda" className="nav-item-menu">
		                        <img src={factura} alt="Facturación Cliente" className="img-item-menu" />
		                        <span className="nav-text">Buscar Facturas</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{validarPermiso("crear_pagos_factura") && (
		   				<li className={`${modulo==='crear_pagos_factura' ? 'active' : ''} `}>
		                    <a href="/facturacion/pagos" className="nav-item-menu">
		                        <img src={factura} alt="Facturación Cliente" className="img-item-menu" />
		                        <span className="nav-text">Registrar Pago</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{validarPermiso("crear_retiro") && (
		   				<li className={`${modulo==='retiros' ? 'active' : ''} `}>
		                    <a href="/retiros" className="nav-item-menu">
		                        <img src={packageList} alt="Retiro" className="img-item-menu" />
		                        <span className="nav-text">Retiro</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}	

					{validarPermiso("asignar_retiro") && (
		   				<li className={`${modulo==='asignar-retiros' ? 'active' : ''} `}>
		                    <a href="/retiros/asignar/vehiculo" className="nav-item-menu">
		                        <img src={packageList} alt="Retiro" className="img-item-menu" />
		                        <span className="nav-text">Asignar vehículo</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

					{validarPermiso("nomina_retiro") && (
		   				<li className={`${modulo==='nominar-retiros' ? 'active' : ''} `}>
		                    <a href="/retiros/nominar" className="nav-item-menu">
		                        <img src={packageList} alt="Retiro" className="img-item-menu" />
		                        <span className="nav-text">Nominar</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{validarPermiso("consulta_retiro") && (
		   				<li className={`${modulo==='consulta-retiros' ? 'active' : ''} `}>
		                    <a href="/retiros/busqueda" className="nav-item-menu">
		                        <img src={packageList} alt="Retiro" className="img-item-menu" />
		                        <span className="nav-text">Consulta de Retiro</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}	

		   			{(validarPermiso("configurar_roles") || validarPermiso("configurar_usuarios") || validarPermiso("configurar_sucursales")
		   			|| validarPermiso("configurar_cliente") || validarPermiso("configurar_placas") || validarPermiso("configurar_ctas_depositos")
		   			|| validarPermiso("configurar_conductores") || validarPermiso("configurar_correlativos")) && (
						<li className={`${modulo==='administrar' ? 'active' : ''} `}>
		                    <a href="/admin" className="nav-item-menu">
		                        <img src={settings} alt="Administrar" className="img-item-menu" />
		                        <span className="nav-text">Administrar</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}

		   			{(validarPermiso("edicion_orden_fecha_creacion") || validarPermiso("edicion_orden_oficina") 
		   				|| validarPermiso("edicion_orden_fecha_entrega")) && (
						<li className={`${modulo==='solutions' ? 'active' : ''} `}>
		                    <a href="/orders/solution" className="nav-item-menu">
		                        <img src={settings} alt="Administrar" className="img-item-menu" />
		                        <span className="nav-text">Edición ordenes</span>
		                    </a>
		                    <div className="pt-0 mt-2 nav-link">
		                        <hr className="mt-0 mb-0 border-hr" />
		                    </div>
		                </li>
		   			)}
	            </ul>
	            <div className="div-copyright text-white text-center">
					<p className="mb-0">© 2021 CACEM - Santiago de Chile</p>
					<p className="mb-2">{version}</p>
				</div>
	        </nav>

	        <div id="content" className={`container-fluid ${classHome}`}>
	        	<Row className="pb-1">
   					<Col xs={12} className="p-0 menu-right--title-panel">
				    	<Navbar bg="primary" variant="dark" className="pt-3 pb-3">
				    		<button type="button" id="sidebarCollapse" 
				    				className={`btn btn-info ${visibleMenu ? 'rotate' : ''}`} onClick={()=>hiddenMenu()}>
	                            <FaChevronLeft />
	                        </button>
						  	<Navbar.Brand className="pl-5 d-flex">
						  		{isIcon && <Link to={`/${ruta}`} className="text-white mr-3"><FaChevronLeft /></Link>}
								<p className="title-panel p-0">{titlePanel}</p>
						  	</Navbar.Brand>
						  <Navbar.Toggle aria-controls="basic-navbar-nav" />
						  <Navbar.Collapse id="basic-navbar-nav">
						    <Nav className="ml-auto pr-5">
						      <NavDropdown title={getNameUser()} id="basic-nav-dropdown" className="text-white">
						        <NavDropdown.Item href="#" onClick={(event)=>logoutUser(event)}>Cerrar sesión</NavDropdown.Item>
						      </NavDropdown>
						    </Nav>
						  </Navbar.Collapse>
						</Navbar>
				    </Col>
   				</Row>
   				{children}
	        </div>
	   </>
	)
}

export default withRouter(Layout)
