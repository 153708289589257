import { useState } from "react"
import {  
	Row,
	Form,
	Col,
	Button,
	Card
} from 'react-bootstrap'

import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { addCamion, editCamion } from "../../../actions/camionAction"
import { validateOnlyNumber, onPaste, validateOnlyLetterNumberG } from "../../../utils/validationInput"


const Formulario = ({toast, datos}) => {
    
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm()
	const [ isLoading, setIsLoading ] = useState(false)
	
	const { caIsEdit  } = useSelector((state) => state.camions)

    
    const onSubmit = async (data, e) => {
		setIsLoading(true)
		
		let response 
		if(data?.numero_interno?.startsWith('0')){
			setIsLoading(false)
			toast.error("El número interno no debe comenzar con 0", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		if(data?.placa?.startsWith('0')){
			setIsLoading(false)
			toast.error("La palca no debe comenzar con 0", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		if(caIsEdit){
			response = await dispatch(editCamion(data))
		}else{
			response = await dispatch(addCamion(data))
		}
        
        if(response.status){
            toast.success(response.message,{position: toast.POSITION.TOP_RIGHT})
        }else{
            toast.error(response.message, {position: toast.POSITION.TOP_RIGHT})
        }
        setIsLoading(false)
    }

	return (
    	<Form id="frmCamion" onSubmit={handleSubmit(onSubmit)}>
        	<Card border="Light">
			    <Card.Header as="h6" className="bg-warn-blue text-dark">{!caIsEdit ? 'CREAR CAMION' : 'EDITAR CAMION' }</Card.Header>
			    <Card.Body className="bg-soft-blue padding-user">
			     	<Row>
			     		<Col lg={6} md={6} sm={12}>
					      	<Form.Group controlId="placa" >
					      		<Form.Label>Placa</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="placa" 
						                autoFocus
						                defaultValue={datos?.placa || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
										 onKeyPress={(event) => validateOnlyLetterNumberG(event)}
										 onPaste={(event) => onPaste(event)}
										 maxLength={20}
								    />
								{errors.placa && (
							    	<Form.Text className="text-danger">
							      		{errors?.placa?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					    <Col lg={6} md={6} sm={12}>
					    	<Form.Group controlId="numero_interno" >
					      		<Form.Label>Número interno</Form.Label>
					      		<Form.Control 
								    	type="text" 
								    	name="numero_interno" 
						                defaultValue={datos?.numero_interno || ""}
						             	ref={register({required: {value: true, message: 'Requerido'}})}
										 onKeyPress={(event) => validateOnlyNumber(event)}
										 onPaste={(event) => onPaste(event)}
										 maxLength={5}
								    />
								{errors.numero_interno && (
							    	<Form.Text className="text-danger">
							      		{errors?.numero_interno?.message}
							    	</Form.Text>
							    )}
					    	</Form.Group>
					    </Col>
					</Row>					
			    </Card.Body>
			</Card>
		   <Row>
		   	<Col xs={12} className="text-right mt-3">
				<Form.Group as={Col}>
			    	<Button variant="warning" type="submit" disabled={isLoading} className="pl-5 pr-5">
					    {isLoading ? 'Procesando...' : caIsEdit ? 'Modificar' : 'Guardar' }
					</Button>
			    </Form.Group>
			</Col>
		   </Row>
        </Form>
	)
}

export default Formulario