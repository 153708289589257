import React, { useEffect, useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import Layout from "../../components/Layouts/Layout";
import Sucursales from "../../components/Orders/Sucursales";
import Clientes from "../../components/Orders/Clientes/Principal";
import DetalleCarga from "../../components/Orders/Carga/DetalleCarga";
import Observaciones from "../../components/Orders/Observaciones";
import Pagos from "../../components/Orders/Pagos";
import ModalNota from "../../components/Orders/NotaCredito/ModalNota";
import ModalDocumentPayment from "../../components/Orders/ModalDocumentPayment";
import ModalOptionImpresion from "../../components/Orders/ModalOptionImpresion";
import ModalSpinner from "../../components/Custom/Spinner/ModalSpinner";
import ModalAnular from "../../components/Orders/ModalAnular";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ModalFactura from "../../components/Factura/ModalFactura";
import { useEditOrden } from "../../hooks/Order/useEditOrden";

const EditOrdenFlete = () => {
  const { numero } = useParams();
  const { actions, formElement, state } = useEditOrden();

  const {
    validateForm,
    isLoading,
    infoData,
    hidden,
    isVisible,
    dataOrders,
    initialOrden,
    disabledBtnCrear,
    statusValidate,
    openForm,
    nameModulo,
    disabledBtnAnular,
    disabledImprimir,
    showModalPrint,
    titleModulo,
    showIsModal,
    updateData,
    visibleCarga,
    isLoadingInitial,
    bloquear,
    setBloquear,
  } = state;
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
  } = formElement;
  const {
    handleblockPaidInput,
    setLoading,
    onSubmit,
    handleTipoDoc,
    setHidden,
    getDatInitial,
    handleCloseModalPayment,
    setOpenForm,
    handleCloseModal,
    handleNotaCredito,
    anularOrden,
    handleClosePrint,
    setShowIsModal,
    handleShow,
    editOrdenAction,
    handleGenerateModalNota,
  } = actions;

  const [loadingPago, setLoadingPago] = useState(false);

  useEffect(() => {
    getDatInitial(numero);

    register(
      { name: "cargas" },
      {
        required: { value: true, message: "Mínimo 1 detalle de carga" },
        validate: (value) => {
          return Array.isArray(value) ? value.length > 0 : !!value;
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOrden]);

  return (
    <Layout
      titlePanel="Orden de flete manual"
      isIcon={false}
      ruta="home"
      modulo="orders_create"
    >
      <Form id="frmOrders" onSubmit={handleSubmit(onSubmit)}>
        <Sucursales
          register={register}
          errors={errors}
          reset={reset}
          initial={false}
          ordenInfo={dataOrders}
          getValues={getValues}
          control={control}
          editOrden={true}
          setValue={setValue}
        />
        <Clientes
          register={register}
          errors={errors}
          setValue={setValue}
          ordenInfo={dataOrders}
          disabledBtnCrear={disabledBtnCrear}
          toast={toast}
          control={control}
          getValues={getValues}
        />
        <DetalleCarga
          register={register}
          errors={errors}
          getValues={getValues}
          ordenInfo={dataOrders}
          toast={toast}
          handleblockPaidInput={handleblockPaidInput}
          validateForm={validateForm}
          handleTipoDoc={handleTipoDoc}
          statusValidate={statusValidate}
          addOrden={false}
          visibleCarga={visibleCarga}
          setValue={setValue}
          control={control}
        />
        <Pagos
          register={register}
          errors={errors}
          setValue={setValue}
          ordenInfo={dataOrders}
          getValues={getValues}
          toast={toast}
          setOpenForm={setOpenForm}
          loadingPago={loadingPago}
          setLoadingPago={setLoadingPago}
          validateForm={validateForm}
          bloquear={bloquear}
          setBloquear={setBloquear}
        />
        <Observaciones
          register={register}
          errors={errors}
          ordenInfo={dataOrders}
        />
        <Form.Row className="mb-5 ml-2 mr-2 mt-3">
          <Col md={9} sm={12} className="btn-group-orden">
            {(dataOrders?.list_nota_credito?.length > 0 ||
              (dataOrders?.numero_documento?.length > 0 &&
                dataOrders?.numero_documento !== "B-1")) && (
              <Button
                variant="outline-warning"
                className="btn-orden mr-2"
                type="button"
                block
                onClick={() => handleGenerateModalNota()}
                //disabled={disabledBtnAnular}
              >
                NOTAS DE CRÉDITOS
              </Button>
            )}
            {dataOrders?.estado_orden !== "Pendiente tarifario" && (
              <Button
                variant="outline-warning"
                className="btn-orden mr-2 mt-0"
                type="button"
                block
                onClick={() => handleShow()}
                disabled={disabledBtnAnular}
              >
                ANULAR ORDEN
              </Button>
            )}
            <Button
              variant="outline-warning"
              className="btn-orden mr-2 mt-0"
              type="button"
              block
              disabled={disabledImprimir}
              onClick={(event) => handleClosePrint(event)}
            >
              IMPRIMIR ORDEN
            </Button>

            <a
              href="/orders/create"
              className="btn btn-outline-warning btn-block btn-orden mr-1 mt-0"
              type="button"
            >
              CREAR NUEVA ORDEN
            </a>
          </Col>
          {dataOrders?.estado_orden !== "Nulas" &&
            dataOrders?.estado_orden !== "Migración Reine" &&
            dataOrders?.estado_orden !== "Pendiente de retiro" &&
            dataOrders?.estado_orden !== "Recibido en oficina destino" &&
            dataOrders?.estado_orden !== "Vehículo asignado a retiro" &&
            dataOrders?.estado_orden !== "Encomienda retirada" &&
            dataOrders?.estado_orden !==
              "Encomienda no retirada por observación" &&
            dataOrders?.estado_orden !== "Retiro aplazado" &&
            dataOrders?.estado_orden !== "Pendiente" &&
            dataOrders?.estado_orden !== "Asignado" &&
            dataOrders?.estado_orden !== "Aplazado al dia siguiente" &&
            dataOrders?.estado_orden !== "Realizado" &&
            dataOrders?.estado_orden !== "Completado" &&
            dataOrders?.estado_orden !== "No Realizado" &&
            dataOrders?.estado_orden !== "Anulado" &&
            dataOrders?.estado_orden !== "Finalizado" && (
              <Col md={3} sm={12}>
                <Button
                  className="mt-1"
                  variant="warning"
                  type="submit"
                  block
                  disabled={disabledBtnAnular ? false : isLoading}
                >
                  {isLoading ? "Procesando..." : "EDITAR"}
                </Button>
              </Col>
            )}
        </Form.Row>
        <ToastContainer />
      </Form>

      {hidden &&
      (dataOrders?.numero_documento?.length === 0 ||
        dataOrders?.numero_documento === "B-1") ? (
        <ModalAnular
          setHidden={setHidden}
          hidden={hidden}
          data={infoData}
          toast={toast}
          funcFactura={anularOrden}
          title={titleModulo}
          isVisibleFactura={isVisible}
          nameModulo={nameModulo}
          setLoading={setLoading}
          isLoading={isLoading}
        />
      ) : (
        <ModalFactura
          setHidden={setHidden}
          hidden={hidden}
          data={infoData}
          toast={toast}
          funcFactura={nameModulo === "orden" ? editOrdenAction : anularOrden}
          title={titleModulo}
          isVisibleFactura={isVisible}
          nameModulo={nameModulo}
          setLoading={setLoading}
          isLoading={isLoading}
          updateData={updateData}
        />
      )}
      {showIsModal && (
        <ModalNota
          showIsModal={showIsModal}
          setShowIsModal={setShowIsModal}
          handleCloseModal={handleCloseModal}
          listado={dataOrders?.list_nota_credito}
          handleNotaCredito={handleNotaCredito}
          documento={document.getElementById("numero_documento").value}
          disabledBtnAnular={disabledBtnAnular}
        />
      )}

      {openForm && (
        <ModalDocumentPayment
          hidden={openForm}
          handleClose={handleCloseModalPayment}
          toast={toast}
          data={getValues()}
          loadingPago={loadingPago}
          setLoadingPago={setLoadingPago}
          setOpenForm={setOpenForm}
          setValue={setValue}
        />
      )}

      {showModalPrint && (
        <ModalOptionImpresion
          showModal={showModalPrint}
          handleClose={handleClosePrint}
          numero_orden={dataOrders.numero_orden}
          isView={dataOrders?.estado_orden !== "Migración Reine" ? true : false}
        />
      )}

      {isLoadingInitial && (
        <ModalSpinner
          spinnerShow={isLoadingInitial}
          description="Consultando la orden de flete..."
        />
      )}
    </Layout>
  );
};

export default EditOrdenFlete;
