import { useEffect, useState, forwardRef } from "react"
import {  
	Row,
	Form,
	Col,
	InputGroup,
	FormControl,
	Button
} from 'react-bootstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css"
import { Controller } from "react-hook-form"

import { toast } from 'react-toastify'
import { getDateYMD } from '../../../../utils/formatFecha';
import { BiCalendar } from "react-icons/bi";
import { validateOnlyNumber, onPaste } from "../../../../utils/validationInput"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'

registerLocale('es', es)

const FormAddConductor = ({ register, datos, errors, control, setValue, setFile, 
	file, coIsEdit, isVisiblePanel, isChangePhoto, handleFile }) => {

	const [ dateStart, setDateStart ] = useState(new Date())


	useEffect(() => {
		if(Object.keys(datos).length>0){
			setValue("rut", datos?.rut)
			if(datos.fecha_vencimiento!=null && datos.fecha_vencimiento!==""){
				setValue("fecha_vencimiento", new Date(datos.fecha_vencimiento))
				if(datos.imagen!==null){
					setFile(datos.imagen)
				}
			}else{
				setFile(undefined)
			}
		}
		return () => URL.revokeObjectURL(file)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const validateStartDateBlur = () => {

		const dateStart = getDateYMD(document.querySelector("#fecha_vencimiento").value)
	
		if(dateStart.error){
			toast.error(dateStart.message, {position: toast.POSITION.TOP_RIGHT})
			setDateStart(new Date())
			return false
		}
	}
	

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<InputGroup className="mb-3">
			<FormControl
				name="fecha_orden_c"
				className="fecha_venc"
				value={value} 
				readOnly
			/>
			<InputGroup.Append>
				<Button variant="warning" size="sm" onClick={onClick} ref={ref}>
					<BiCalendar />
				</Button>
			</InputGroup.Append>
		</InputGroup>
	  ));

	return (
		<>
	     	<Row>
	     		<Col sm={12} className="mt-4">
	     			<h5>Datos del conductor</h5>
	     			<hr/>
	     		</Col>
	     		<Col lg={6} md={6} sm={12}>
			      	<Form.Group controlId="rut" >
			      		<Form.Label>Rut</Form.Label>
						  <Controller
							autoComplete="off"
							render={(props) => (
								<MaskedInput
									mask={RutTextMask}
									className="form-control"
									id="rut" 
									name="rut"
									value={props.value}
									maxLength={12}
									defaultValue={datos?.rut || ""}
									onChange={(value)=>{
										props.onChange(value)
									}}
									//onBlur={(event) => handleBlurRut(event, setError)}
								/>
							)}
							control={control}
							name="rut"  
							rules={{required: {value: true, message: 'Requerido'}}}
						/>
						{errors.rut && (
					    	<Form.Text className="text-danger">
					      		{errors?.rut?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    </Col>
			    <Col lg={6} md={6} sm={12}>
			      	<Form.Group controlId="licencia_conducir" >
			      		<Form.Label>Licencia conducir</Form.Label>
			      		<Form.Control 
						    	type="text" 
						    	name="licencia_conducir" 
						    	maxLength="12"
				                defaultValue={datos?.licencia_conducir || ""}
				             	ref={register({required: {value: true, message: 'Requerido'}})}
								onKeyPress={(event) => validateOnlyNumber(event)}
								onPaste={(event) => onPaste(event)}
						    />
						{errors.licencia_conducir && (
					    	<Form.Text className="text-danger">
					      		{errors?.licencia_conducir?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    </Col>
			</Row>	

			<Row>
			    <Col lg={6} md={6} sm={12}>
			    	<Form.Group controlId="fecha_vencimiento" >
			      		<Form.Label>Fecha Vencimiento</Form.Label>
						<Controller
				            control={control}
				            name="fecha_vencimiento"
				            render={(props) => (
				              <DatePicker
				                dateFormat="dd/MM/yyyy"
				                placeholderText="dd/MM/yyyy"
				                onChange={(e) => {
									props.onChange(e)
									setDateStart(e)
								}}
				                selected={dateStart}
				                className="form-control"
				                id={props.name}
				                name={props.name}
				                autoComplete="off"
								locale="es"
								onCalendarClose={() => validateStartDateBlur()}
								customInput={<ExampleCustomInput />}
				              />
				            )}
				            //rules={{required: {value: true, message: 'Requerido'}}}
				          />
						{errors.fecha_vencimiento && (
					    	<Form.Text className="text-danger">
					      		{errors?.fecha_vencimiento?.message}
					    	</Form.Text>
					    )}
			    	</Form.Group>
			    </Col>
			</Row>	

			<Row>
				{coIsEdit && (
	     			<Col lg={12} md={12} sm={12}>
				      	<Form.Group>
						    <Form.Check 
						    	type="checkbox" 
						    	name="cambiar_foto" 
						    	id="cambiar_foto" 
		             			checked={isChangePhoto}
		             			label="Cambiar la foto del documento"
		             			onChange={(event)=>isVisiblePanel(event)}
		             			ref={register}
						   	/>
						 </Form.Group>
				    </Col>
	     		)}
				{(!coIsEdit || isChangePhoto) && (
					<Col lg={12} md={12} sm={12}>
				      	<Form.Group controlId="documento" >
							<Form.File 
								id="documento" 
								name="documento"
								label="Foto del documento" 
								onChange={(event)=>handleFile(event)}
								ref={register({required: {value: true, message: 'Requerido'}})}
								accept="image/*"
							/>
							{errors.documento && (
						    	<Form.Text className="text-danger">
						      		{errors?.documento?.message}
						    	</Form.Text>
						    )}
				    	</Form.Group>
				    </Col>
				)}
			</Row>	
			{file && (
				<Row>
					<Col lg={12} md={12} sm={12}>
				      	<Form.Group >
							<img src={file} width="150" className="img-thumbnail" alt="Licencia" />
				    	</Form.Group>
				    </Col>
				</Row>		
			)}
		</>
	)
}

export default FormAddConductor