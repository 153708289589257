import { useState, useEffect } from "react"

import { getHome } from "../../actions/orderAction"
import { searchRetiroByNro, selectedPreordenByRetiro } from "../../actions/retiroAction"
import { searchCliente } from "../../actions/clienteAction"
import { useSelector, useDispatch } from "react-redux"

export const useDetailRetiro = (numero_retiro, toast) => { 

	const dispatch = useDispatch()
	const { retiros, orders } = useSelector(state => {
		return {
			retiros: state.retiros,
			orders: state.orders
		}
	})
	const { detRetiro, preordenSelected, isLoadingDetRetiro, titleDetailRetiro } = retiros
	const { documentacionsCarga, sucursales, forma_pago } = orders

	const [show, setShow] = useState(false)
	const [completedModal, setCompletedModal ] = useState(false)

	const openCloseModal = (value) => {
		setShow(value)
	}

	const handleSelectedPreOrden = (item) => {
		openCloseModal(true)
		dispatch(selectedPreordenByRetiro(item))
	}

	const getDataInitial = async (numero_retiro) => {
		await dispatch(searchRetiroByNro(numero_retiro))
		if(sucursales.length===0){
			await dispatch(getHome())
		}
	}

	const openCloseModalCompleted = (value) => {
		setCompletedModal(value)
	}

	const handleCompletedPreOrden = (item) => {
		openCloseModalCompleted(true)
		dispatch(selectedPreordenByRetiro(item))
	}



	useEffect(() => {
		getDataInitial(numero_retiro)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numero_retiro])

	return {
		detRetiro, 
		preordenSelected, 
		isLoadingDetRetiro,
		documentacionsCarga, 
		sucursales, 
		forma_pago,
		openCloseModal,
		show,
		handleSelectedPreOrden,
		handleCompletedPreOrden,
		openCloseModalCompleted,
		completedModal,
		setShow,
		setCompletedModal,
		titleDetailRetiro,
		searchCliente
	}
}