import { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./Presentacions/Login/"
import CreatedOrder from "./Presentacions/Orders/CreatedOrder"
import EditOrdenFlete from "./Presentacions/Orders/EditOrdenFlete"
import Home from "./Presentacions/Home/"
import PreviewOrder from "./Presentacions/Orders/PreviewOrder"
import CreatedNomina from "./Presentacions/Nomina/CreatedNomina"
import PreviewNomina from "./Presentacions/Nomina/PreviewNomina"
import PanelOpciones from "./Presentacions/Entrega/PanelOpciones"
import Dashboard from "./Presentacions/Rendicion/Dashboard"
import Rendir from "./Presentacions/Rendicion/Rendir"
import PreviewRendir from "./Presentacions/Rendicion/PreviewRendir"
import SearchOrden from "./Presentacions/Orders/SearchOrden"
import PreviewConsulta from "./Presentacions/Orders/PreviewConsulta"
import Nomina from "./Presentacions/Retiro/Nomina"
import CreatedRetiro from "./Presentacions/Retiro/Created"
import AssignRetiro from "./Presentacions/Retiro/AssignRetiro"
import PreviewTicket from "./Presentacions/Retiro/PreviewTicket"
import Opciones from "./Presentacions/Admin/Opciones"
import Facturacion from "./Presentacions/Facturacion/Panel"
import NotFound from "./Presentacions/NotFound"
import Options from "./Presentacions/Despacho/Options"
import OpcionesEditarOrdenes from "./Presentacions/Soluciones/OpcionesEditarOrdenes"
import CompletedRetiro from "./Presentacions/Retiro/CompletedRetiro"
import SearchByParameters from "./Presentacions/Retiro/SearchByParameters"
import ReceiveOrden from "./Presentacions/Orders/ReceiveOrden"
import PreviewCorrelativos from "./Presentacions/Orders/PreviewCorrelativos"
import PreviewRetiro from "./Presentacions/Retiro/PreviewRetiro"
import MultiplePago from "./Presentacions/Facturacion/MultiplePago"
import SearchListInvoices from "./Presentacions/Facturacion/SearchListInvoices"
import DetailPaymentInvoice from "./Presentacions/Facturacion/DetailPaymentInvoice"
import ConfiguracionFactura from "./Presentacions/Configuracion/Factura"
import ListOrderIncomplete from "./Presentacions/Orders/ListOrderIncomplete"
import ReceiveTicket from "./Presentacions/Orders/ReceiveTicket"
import LoadingUserScreen from "./components/Global/LoadingUserScreen";
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/menu.css'

import packageJson from "../package.json";



function App() {

  const [ showPage, setShowPage ] = useState(true);

  const verifyLogged = (Component) => {
    if(JSON.parse(localStorage.getItem("user"))){
      setShowPage(false);

      return <Component />;

    }else{
      setShowPage(false);

        return <Redirect to="/" />
    }
  }

  useEffect(() => {
    let version = localStorage.getItem('version');
   
      if(version!==packageJson.version){
          if('caches' in window){
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
          }

        localStorage.clear();
        localStorage.setItem('version',packageJson.version);
      }      
  },[])

  return (
    <Fragment>
      <LoadingUserScreen showPage={showPage} />
      <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/orders/create" render={() => verifyLogged(CreatedOrder)} />

            <Route exact path="/orders/:numero/edit" render={() => verifyLogged(EditOrdenFlete)} />
            <Route exact path="/orders/incompletas" render={() => verifyLogged(ListOrderIncomplete)} />

            

            <Route exact path="/orders/:numero/ticket" render={() => verifyLogged(PreviewOrder)} />
            <Route exact path="/orders/:numero/correlativos" render={() => verifyLogged(PreviewCorrelativos)} />
            
            <Route exact path="/nomina/create" render={() => verifyLogged(CreatedNomina)} />
            
            <Route exact path="/nominas/:numero/ticket" render={() => verifyLogged(PreviewNomina)} />
            
            <Route exact path="/guide" render={() => verifyLogged(Options)} />
            
            <Route exact path="/orders/deliver" render={() => verifyLogged(PanelOpciones)} />
            
            <Route exact path="/rendir/search" render={() => verifyLogged(Rendir)} />
            
            <Route exact path="/rendir/sucursal" render={() => verifyLogged(Dashboard)} />
            
            <Route exact path="/rendir/sucursal/print" render={() => verifyLogged(PreviewRendir)} />
            
            <Route exact path="/orders/search" render={() => verifyLogged(SearchOrden)} />
            
            <Route exact path="/orders/preview/file" render={() => verifyLogged(PreviewConsulta)} />

            <Route exact path="/orders/receive" render={() => verifyLogged(ReceiveOrden)} />
            
            <Route exact path="/retiros" render={() => verifyLogged(CreatedRetiro)} />
            <Route exact path="/retiros/:numero_retiro/ticket" render={() => verifyLogged(PreviewTicket)} />
            <Route exact path="/retiros/asignar/vehiculo" render={() => verifyLogged(AssignRetiro)} />
            <Route exact path="/retiros/nominar" render={() => verifyLogged(Nomina)} />
            <Route exact path="/retiros/busqueda" render={() => verifyLogged(SearchByParameters)} />
            <Route exact path="/retiros/:numero_retiro" render={() => verifyLogged(CompletedRetiro)} />
            <Route exact path="/retiros/:numero_retiro/preordenes" render={() => verifyLogged(PreviewRetiro)} />
            
            <Route exact path="/admin" render={() => verifyLogged(Opciones)} />
            
            <Route exact path="/facturacion" render={() => verifyLogged(Facturacion)} />
            <Route exact path="/facturacion/pagos" render={() => verifyLogged(MultiplePago)} />
            <Route exact path="/facturacion/busqueda" render={() => verifyLogged(SearchListInvoices)} />
            <Route exact path="/facturacion/detalle/:payment_id/pago" render={() => verifyLogged(DetailPaymentInvoice)} />

            <Route exact path="/config/factura" render={() => verifyLogged(ConfiguracionFactura)} />         
            
            <Route exact path="/orders/solution" render={() => verifyLogged(OpcionesEditarOrdenes)} />
            <Route exact path="/recibir/paquete" render={() => verifyLogged(ReceiveTicket)} />
            
            <Route component={NotFound} />
          </Switch>
      </Router> 
    </Fragment>
  );
}

export default App;
