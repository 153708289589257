import React, { Fragment } from "react";
import { Form, Col, Row, OverlayTrigger } from "react-bootstrap";
import TitlePrincipal from "../../Layouts/TitlePrincipal";
import { useDispatch } from "react-redux";
import { searchCliente } from "../../../actions/clienteAction";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  onPaste,
  validateOnlyLetter,
  validateOnlyNumber,
  validateOnlyRUT,
} from "../../../utils/validationInput";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

const Remitente = ({
  register,
  errors,
  setValue,
  ordenInfo,
  disabledBtnCrear,
  validarRut,
  toast,
  setMessage,
  disabledRemitente,
  setDisabledRemitente,
  setHidden,
  disabledDestinatario,
  setDisabledDestinatario,
  popover,
  disabledRutRemitente,
  setDisabledRutRemitente,
  setDisabledRutDestinatario,
  disabledRutDestinatario,
  control,
}) => {
  const dispatch = useDispatch();

  const onKeyDownCliente = async (event, type) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente(type);
    }
  };

  const searchClienteRemitente = async (type) => {
    let rut = document
      .getElementById(
        type === "remitente" ? "rut_remitente" : "rut_destinatario"
      )
      .value.toString()
      .toUpperCase();

    const isValid = validarRut(rut);

    if (isValid.status) {
      let search = { search_type: "rut_cliente", value: rut };

      const data = await dispatch(searchCliente(search));

      if (data.status) {
        if (type === "remitente") {
          if (rut === RUT_MESON) {
            setValue("razon_social_remitente", "");
            setDisabledRemitente(false);
          } else {
            setValue("razon_social_remitente", data.cliente.nombre_completo);
            setDisabledRemitente(true);
          }
        } else {
          if (rut === RUT_MESON) {
            setValue("razon_social_destinatario", "");
            setValue("direccion_destinatario", data.cliente.direccion);
            setValue("telefono_avisas", data.cliente.telefono);
            setDisabledDestinatario(false);
          } else {
            setValue("razon_social_destinatario", data.cliente.nombre_completo);
            setValue("direccion_destinatario", data.cliente.direccion);
            setValue("telefono_avisas", data.cliente.telefono);
            setDisabledDestinatario(true);
          }
        }
      } else {
        if (type === "remitente") {
          setDisabledRemitente(false);
          setValue("razon_social_remitente", "");
        } else {
          setDisabledDestinatario(false);
          setValue("razon_social_destinatario", "");
          setValue("direccion_destinatario", "");
          setValue("telefono_avisas", "");
        }
        if (data.code === 1) {
          toast.error(data.message, { position: toast.POSITION.TOP_RIGHT });
          return false;
        }
        setMessage(data.message);
        setHidden(true);
      }
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleBlurRut = (event) => {
    if (event === "") {
      setValue("razon_social_destinatario", "");
      setValue("direccion_destinatario", "");
      setValue("telefono_avisas", "");
    }
  };

  return (
    <Fragment>
      <Col md={6} sm={12} className="pr-4">
        <TitlePrincipal title="REMITENTE" classTitle="bg-gray-wran" />
        <Row className="bg-soft-blue ">
          <Col xs={8} className="mb-1">
            <Form.Group as={Col} controlId="rut_remitente" className="mb-0">
              <Form.Label>RUT</Form.Label>
              <OverlayTrigger placement="right" overlay={popover}>
                <Controller
                  autoComplete="off"
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control"
                      id="rut_remitente"
                      name="rut_remitente"
                      value={props.value}
                      maxLength={12}
                      defaultValue={ordenInfo.rut_remitente}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                      onKeyDown={(event) =>
                        onKeyDownCliente(event, "remitente")
                      }
                      onKeyPress={(event) => validateOnlyRUT(event)}
                    />
                  )}
                  control={control}
                  name="rut_remitente"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {/**<Form.Control 
								    	type="text" 
								    	name="rut_remitente" 
								    	defaultValue={ordenInfo.rut_remitente}
										maxLength={12}
								    	onKeyDown={(event) => onKeyDownCliente(event, "remitente")}
						                ref={register({required: {value: true, message: 'Requerido'}})}
								    /> */}
              </OverlayTrigger>
              {errors.rut_remitente && (
                <Form.Text className="text-danger">
                  {errors?.rut_remitente?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group
              as={Col}
              controlId="razon_social_remitente"
              className="mb-0"
            >
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                type="text"
                name="razon_social_remitente"
                defaultValue={ordenInfo.razon_social_remitente}
                readOnly={disabledRemitente}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onKeyPress={(event) => validateOnlyLetter(event)}
                maxLength={150}
                onPaste={(event) => onPaste(event)}
              />
              {errors.razon_social_remitente && (
                <Form.Text className="text-danger">
                  {errors?.razon_social_remitente?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col md={6} sm={12}>
        <TitlePrincipal title="DESTINATARIO" />
        <Row className="bg-soft-blue">
          <Col className="mb-0">
            <Form.Group
              as={Col}
              controlId="rut_destinatario"
              className="pr-0 pl-0 mb-1"
            >
              <Form.Label>RUT</Form.Label>
              <OverlayTrigger placement="right" overlay={popover}>
                <Controller
                  autoComplete="off"
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control"
                      id="rut_destinatario"
                      name="rut_destinatario"
                      value={props.value}
                      maxLength={12}
                      defaultValue={ordenInfo.rut_destinatario}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                      onKeyDown={(event) =>
                        onKeyDownCliente(event, "destinatario")
                      }
                      onKeyPress={(event) => validateOnlyRUT(event)}
                      onBlur={(event) => handleBlurRut(event.target.value)}
                    />
                  )}
                  control={control}
                  name="rut_destinatario"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {/**<Form.Control 
								    	type="text" 
								    	name="rut_destinatario" 
								    	defaultValue={ordenInfo.rut_destinatario}
								    	onKeyDown={(event) => onKeyDownCliente(event, "destinatario")}
						                ref={register({required: {value: true, message: 'Requerido'}})}
										maxLength={12}
								    /> */}
              </OverlayTrigger>
              {errors.rut_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.rut_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6} className="mb-0">
            <Form.Group
              as={Col}
              controlId="razon_social_destinatario"
              className="pr-0 pl-0 mb-1"
            >
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                type="text"
                name="razon_social_destinatario"
                defaultValue={ordenInfo.razon_social_destinatario}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                readOnly={disabledDestinatario}
                onKeyPress={(event) => validateOnlyLetter(event)}
                maxLength={150}
                onPaste={(event) => onPaste(event)}
              />
              {errors.razon_social_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.razon_social_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group
              as={Col}
              controlId="direccion_destinatario"
              className="pr-0 pl-0"
            >
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                name="direccion_destinatario"
                readOnly={disabledDestinatario}
                defaultValue={ordenInfo.direccion_destinatario}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                maxLength={255}
              />
              {errors.direccion_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.direccion_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group as={Col} controlId="telefono_avisas" className="pl-0">
              <Form.Label>Avisar a (N° teléfono)</Form.Label>
              <Form.Control
                type="text"
                name="telefono_avisas"
                readOnly={disabledDestinatario}
                defaultValue={ordenInfo.telefono_avisas}
                maxLength={9}
                ref={register({
                  required: { value: true, message: "Requerido" },
                  minLength: { value: 9, message: "Mínimo 9 dígitos" },
                  //eslint-disable-next-line
                  pattern: {
                    value: /^([0-9]+)$/,
                    message: "Teléfono inválido",
                  },
                })}
                autoComplete="off"
                onKeyPress={(event) => validateOnlyNumber(event)}
                onPaste={(event) => onPaste(event)}
              />
              {errors.telefono_avisas && (
                <Form.Text className="text-danger">
                  {errors?.telefono_avisas?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

export default Remitente;
