import { useState } from "react"
import { useForm } from "react-hook-form"

import Swal from 'sweetalert2'
import { saveCargas, saveMultipleDestination, deleteDestination, 
	selectedDestination, resetDestination } from "../../actions/retiroAction"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"

export const useRetiroDestino = () => {

	const dispatch = useDispatch()
	const { infoDestino } = useSelector(state => state.retiros)
	const { register, handleSubmit, errors, setValue, getValues, control } = useForm({ defaultValues: infoDestino})
	const [modalShow, setModalShow] = useState(false);

	const handleModal = (value) => {
		setModalShow(value)
	}

	const handleOpenModalDestino = (data) => {
		if(data?.rut_remitente==="" || data?.razon_social_remitente==="" || data?.telefono_remitente===""
			|| data?.sucursal_origen_id==="" || data?.comuna_origen_id===""
			|| data?.direccion_retiro===""){
			toast.error("Debe completar los datos del Remitente", {position: toast.POSITION.TOP_RIGHT})
		}else{
			dispatch(resetDestination("edit"))
			setModalShow(true)
		}
	}

	const onSubmit = (data, event) => {
		event.preventDefault()

		if(typeof data?.cargas === "undefined"){
			toast.error("Debe anexar el detalle de la carga", {position: toast.POSITION.TOP_RIGHT})
			return false
		}

		let comuna_selected = document.querySelector("#comuna_id")

		const body = {
			rut: data?.rut,
			razon_social: data?.razon_social,
			telefono: data?.telefono,
			email: data?.email,
			comuna: data?.comuna_id,
			direccion: data?.direccion,
			cargas: data?.cargas,
			observacion: data?.observacion,
			visible: false,
			sucursal_id: data?.sucursal_id,
			comuna_name: comuna_selected.options[comuna_selected.selectedIndex].text,
			tipo_envio_id: data?.tipo_envio_id
		}

		let typeAction = infoDestino?.visible===true ? "edit" : "add"
		const response = dispatch(saveMultipleDestination(body, typeAction))
		if(response.status){
			toast.warn("Se guardará cuando se agregue el retiro",{position: toast.POSITION.TOP_RIGHT})
			event.target.reset()
			let modalCl = infoDestino?.visible===true ? false : true
			setModalShow(modalShow => modalCl)
			setValue("rut", "")
		}else {
			toast.error("Hubo un error al guardar el destino", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const saveCarga = (event) => {
		event.preventDefault()
		const data = getValues()

		if(data.alto!=="" && data.ancho!=="" && data.largo!=="" && data.cantidad!=="" && data.peso!==""){
			const infoCarga = { 
				alto: data.alto,
				ancho: data.ancho,
				largo: data.largo,
				cantidad: data.cantidad,
				peso: data.peso,
				visible: false,
				metraje_cubico: data?.metraje_cubico
			}
	
			dispatch(saveCargas(infoCarga, "add"))
			setValue("alto", "")
			setValue("ancho", "")
			setValue("largo", "")
			setValue("cantidad", "")
			setValue("peso", "")
			setValue("metraje_cubico", "")
		}else {
			toast.error("Debe de ingresar el detalle de la carga", {position: toast.POSITION.TOP_RIGHT})
		}
	}

	const deleteRow = (event, index) => {
		Swal.fire({
		  title: `¿Seguro desea eliminar el detalle de la carga?`,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  	dispatch(saveCargas({index}, "delete"))
		  }
		})
	}

	const editRow = (event, index, visible) => {

		if(visible===false){
			dispatch(saveCargas({index}, "enabled"))
		}else{
			const data = getValues()		
			let cargaIndex = data.cargas[index]
	
			if(Object.keys(cargaIndex).length>0){
				dispatch(saveCargas({ ...cargaIndex, index }, "edit"))
			}	
		}
	}

	const editDestino = (event, item, index) => {
		dispatch(selectedDestination(item, index))
		handleModal(true)
	}

	const deleteDestino = (event, index) => {
		event.preventDefault()

		Swal.fire({
		  title: `¿Seguro desea eliminar ese destinatario?`,
		  icon: 'question',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Sí',
		  cancelButtonText: 'No'
		}).then(async (result) => {
		  if (result.isConfirmed) {
		  	dispatch(deleteDestination({index}, "delete"))
		  }
		})		
	}

	return {
		register, 
		handleSubmit, 
		errors,
		onSubmit,
		setValue,
		saveCarga,
		deleteRow,
		editRow,
		infoDestino,
		editDestino,
		deleteDestino,
		modalShow,
		handleModal,
		handleOpenModalDestino,
		getValues,
		control
	}
}