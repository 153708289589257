import API from "../config/api"
import { 
	SEARCH_ORDEN_FECHA_CREACION,
	SEARCH_ORDEN_FECHA_ENTREGA,
	SEARCH_ORDEN_OFFICE,
	RESET_LISTADO_SOLUTIONS
} from "../types/solutionsOrdenType"

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const searchOrdenFechaCreacion = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listOrdenesFechaCreacion} = getState().solutions

		  	let response = await API.post(`soluctions/search-order`, body)

		    if(response.status){
		    	
		    	let listado = listOrdenesFechaCreacion
		    	if(response.orden.estado.estado_id!==5){
			    	const orden = {
			    		id: response.orden.id,
			    		fecha_orden: response.orden.fecha_orden_format,
			    		oficina_origen: response.orden.oficina_origen_name,
			    		sucursal_origen: response.orden.sucursal_origen_name,
						oficina_destino: response.orden.oficina_destino_name,
			    		sucursal_destino: response.orden.sucursal_destino_name,
			    		numero_orden: response.orden.numero_orden,
			    		total: response.orden.total,
			    		fecha_orden_now: "",
			    		forma_pago_name: response.orden.forma_pago_name,
			    		fecha_orden_format: response.orden.fecha_orden
			    	}

			    	const payload = { 
			    		listOrdenesFechaCreacion: [...listado, orden ] 
			    	}
		
			       dispatch(requestSuccess(SEARCH_ORDEN_FECHA_CREACION, payload))
			    }else{
			    	resolve({status: false, message: "La orden de flete tiene estatus Anulada" })
			    }
		    }
	      	resolve({status: response.status, message:response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}


export const saveDateUpdatedCreatedOrder = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		
			const data = body.map(item => {
				return {
					numero_orden: item.numero_orden,
					fecha_orden_now: item.fecha_orden_now,
					type: "origen"
				}
			})
			
		  	let response = await API.post(`soluctions/order-date`, {ordenes: data})

		    if(response.status){

		    	const payload = { 
		    		listOrdenesFechaCreacion: [] 
		    	}
	
		       dispatch(requestSuccess(SEARCH_ORDEN_FECHA_CREACION, payload))
		    }
	      	resolve({status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const searchOrdenFechaEntrega = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listOrdenesFechaEntrega} = getState().solutions

		  	let response = await API.post(`soluctions/search-order`, body)

		    if(response.status){
		    	let listado = listOrdenesFechaEntrega
		    	if(response.orden.estado.estado_id===6){
		    		const orden = {
			    		id: response.orden.id,
			    		fecha_orden: response.orden.fecha_orden_format,
			    		fecha_entrega: response.orden.fecha_entrega_format,
						oficina_destino: response.orden.oficina_destino_name,
			    		sucursal_destino: response.orden.sucursal_destino_name,
			    		numero_orden: body.numero_orden,
			    		total: response.orden.total,
			    		fecha_entrega_now: "",
			    		forma_pago_name: response.orden.forma_pago_name
			    	}

			    	const payload = { 
			    		listOrdenesFechaEntrega: [...listado, orden ] 
			    	}

			       dispatch(requestSuccess(SEARCH_ORDEN_FECHA_ENTREGA, payload))
			   }else{
			   		resolve({status: false, message: "La orden de flete no se encuentra con estatus Entregada" })
			   }
		    }
	      	resolve({status: response.status, message:response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const saveDateUpdatedDeliveryOrden = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		
			const data = body.map(item => {
				return {
					numero_orden: item.numero_orden,
					fecha_entrega_now: item.fecha_entrega_now
				}
			})
			
		  	let response = await API.post(`soluctions/order-delivery`, {ordenes: data})

		    if(response.status){

		    	const payload = { 
		    		listOrdenesFechaEntrega: [] 
		    	}
	
		       dispatch(requestSuccess(SEARCH_ORDEN_FECHA_ENTREGA, payload))
		    }
	      	resolve({status: response.status, message: response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const searchOrdenOfficeOrder = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
			const {listOrdenesOffice} = getState().solutions

		  	let response = await API.post(`soluctions/search-order`, body)

		    if(response.status){
		    	let listado = listOrdenesOffice

		    	const orden = {
		    		id: response.orden.id,
		    		fecha_orden: response.orden.fecha_orden_format,
		    		oficina_origen: response.orden.oficina_origen_name,
		    		sucursal_origen: response.orden.sucursal_origen_name,
					oficina_destino: response.orden.oficina_destino_name,
		    		sucursal_destino: response.orden.sucursal_destino_name,
		    		numero_orden: response.orden.numero_orden,
		    		total: response.orden.total,
		    		type_office: "",
		    		sucursal_id_now: "",
		    		oficina_id_now: "",
		    		forma_pago_name: response.orden.forma_pago_name
		    	}

		    	const payload = { 
		    		listOrdenesOffice: [...listado, orden ] 
		    	}

		       dispatch(requestSuccess(SEARCH_ORDEN_OFFICE, payload))
		    }
	      	resolve({status: response.status, message:response.message })
	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const saveOfficeOrden = (body) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try{
		
			const data = body.map(item => {
				return {
					numero_orden: item.numero_orden,
					sucursal_id_now: item.sucursal_id_now,
					oficina_id_now: item.oficina_id_now,
					type_office: item.type_office,
					change_data: true
				}
			})

		  	let response = await API.post(`soluctions/order-office`, {ordenes: data})

		    if(response.status){

		    	const payload = { 
		    		listOrdenesOffice: [] 
		    	}
	
		       dispatch(requestSuccess(SEARCH_ORDEN_OFFICE, payload))
		    }
	      	resolve({status: true, message: response.message })

	    }catch(error){
	      resolve({status: false, message:error})
	    }
	})
}

export const restListadoOrdenesSolutions = () => (dispatch, getState) => {
	const payload = {
		listOrdenesFechaCreacion: [],
		listOrdenesFechaEntrega: [],
		listOrdenesOffice: []
	}

	dispatch(requestSuccess(RESET_LISTADO_SOLUTIONS, payload))
}

