const Cliente = { 
	columnas: [
		{
			name: 'Cod interno',
			selector: (row, i) => row.numero_cuentas_join,
			sortable: true
		},
		{
			name: 'Rut',
			selector: (row, i) => row.rut,
			sortable: true
		},
		{
			name: 'Nombre y Apellido',
			selector: (row, i) => row.nombre_completo,
			sortable: true
		},
		{
			name: 'E-mail',
			selector: (row, i) => row.email,
			sortable: true
		},
		{
			name: 'Activo',
			selector: (row, i) => row.name_estatus,
			sortable: true
		},
		{
			name: 'Dirección',
			selector: (row, i) => row.direccion,
			sortable: true
		},
		{
			name: 'Teléfono',
			selector: (row, i) => row.telefono,
			sortable: true
		},
		{
			name: 'Sucursal',
			selector: (row, i) => row.sucursal,
			sortable: true
		},
		{
			name: 'Comuna',
			selector: (row, i) => row.comuna,
			sortable: true
		},
		{
			name: 'Rubro',
			selector: (row, i) => row.rubro,
			sortable: true
		}
	]
}

export default Cliente