import React, { Fragment } from "react"
import {  
	Form,
	Col,
	Table
} from 'react-bootstrap'
import TbodyOrdens from "./BodyOrden"
import { useSelector } from "react-redux"


const TablaOrden = ({ register, errors }) => {

	const { listOrdenEntregar  } = useSelector((state) => state.orders)


	return (
		<Fragment>
			<Form.Row>
				<Col xs={12}>
					<Form.Group as={Col}>
						<Table bordered size="sm" responsive className="table-global mb-0">
							  <thead className="bg-warn-blue">
							    <tr>
							      <th>N° de Orden</th>
							      <th>Fecha</th>
							      <th>Total</th>
							      <th>Estado</th>
							      <th>Remitente</th>
							      <th>Destinatario</th>
							    </tr>
							  </thead>
							  <tbody>
							  	{
							  		listOrdenEntregar?.length  > 0 ? (
							  			listOrdenEntregar?.map((orden, index) => {
							  				return (
							  					<TbodyOrdens 
							  						key={index}
							  						index={index}
							  						{...orden} 
							  						register={register}
							  					/>
							  				)
							  			})
							  		) : (
							  			<tr className="tr-vacia">
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  				<td></td>
							  			</tr>
							  		)
							  	}
							  </tbody>
						</Table>
					</Form.Group>
				</Col>
			</Form.Row>				
		</Fragment>

	)
}

export default TablaOrden

