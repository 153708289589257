import React, { useState, useEffect  } from "react"
import {  
	Col,
	Row,
	Button
} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import Layout from "../../components/Layouts/Layout"
import EditarOrdenFechaCreacion from "./EditarOrdenFechaCreacion"
import EditarOrdenOficina from "./EditarOrdenOficina"
import EditarOrdenFechaEntrega from "./EditarOrdenFechaEntrega"
import { useDispatch } from "react-redux"
import { getHome } from "../../actions/orderAction"
import { restListadoOrdenesSolutions } from "../../actions/solutionsOrdenAction"
//restListadoOrdenesSolutions
const OpcionesEditarOrdenes = () => {

	const dispatch = useDispatch()
	const [ isInicial, setInicial ] = useState(true)
	const [ isFechaCreacion, setIsFechaCreacion, ] = useState(true)
	const [ isOficina, setIsOficina ] = useState(false)
	const [ isFechaEntrega, setIsFechaEntrega ] = useState(false)
	

	const changeBtnFechaCreacion = (event) => {
		setIsFechaCreacion(true)
		setInicial(true)
		setIsOficina(false)
		setIsFechaEntrega(false)
		dispatch(restListadoOrdenesSolutions())
		if(document.getElementById("frmFechaCreacion")){
			document.getElementById("frmFechaCreacion").reset()
		}
		
	}

	const changeBtnOficina = (event) => {
		setIsFechaCreacion(false)
		setIsOficina(true)
		setInicial(true)
		setIsFechaEntrega(false)
		dispatch(restListadoOrdenesSolutions())
		if(document.getElementById("frmFechaEntrega")){
			document.getElementById("frmFechaEntrega").reset()
		}
	}

	const changeBtnFechaEntrega = (event) => {
		setIsFechaCreacion(false)
		setIsOficina(false)
		setInicial(true)
		setIsFechaEntrega(true)
		dispatch(restListadoOrdenesSolutions())
		if(document.getElementById("frmOficina")){
			document.getElementById("frmOficina").reset()
		}
	}



	const getDataInicial = async () => {
		await dispatch(getHome())
	}
	useEffect(() => {
		getDataInicial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Layout 
	    	titlePanel="Ediciones de Ordenes Múltiple"
	    	modulo="solutions"
	    >
	    	<Row className="mb-5">
	    		<Col xs={12} className="pt-4 pl-5 pb-4">
	    			<Button variant={isFechaCreacion ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2 solution-btn" onClick={(event) => changeBtnFechaCreacion(event)}>
			    		Fecha de Creación
			  		</Button>
			  		<Button variant={isOficina ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2 solution-btn" onClick={(event) => changeBtnOficina(event)}>
			    		Oficina
			  		</Button>
			  		<Button variant={isFechaEntrega ? "warning btn-sin-border-radius" : "outline-warning btn-sin-border-radius"} type="button" className="mr-2 solution-btn" onClick={(event) => changeBtnFechaEntrega(event)}>
			    		Fecha de Entrega
			  		</Button>		  		
	    		</Col>
	    	</Row>

	    	{
	    		(isInicial && isFechaCreacion) && (
	    				<EditarOrdenFechaCreacion toast={toast}  />
	    		)
	    	}
	    	{
	    		(isInicial && isOficina) && (
	    				<EditarOrdenOficina toast={toast} />
	    		)
	    	}
	    	{
    			(isInicial && isFechaEntrega) && (
	    			<EditarOrdenFechaEntrega toast={toast} />
	    		)
	    	}
	    	<ToastContainer />
	    </Layout>
	)
}

export default OpcionesEditarOrdenes



